import React from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { Link } from '../util/router'
import { RoutePath, routeWithSlugs } from '../util/routes'
import Divider from '@mui/material/Divider'
import { useAuth } from '../util/auth'

interface AccountMenuProps {
  menuState: any | null
  closeMenu: () => void
}

function AccountMenu({ menuState, closeMenu }: AccountMenuProps) {
  const auth = useAuth();
  const { signout } = useAuth()
  return (
    <Menu
      open={!!menuState}
      anchorEl={menuState && menuState.anchor}
      onClick={closeMenu}
      onClose={closeMenu}
      keepMounted
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      {auth?.user.role !== 'admin' && 
     <div>
   { auth?.user?.info_completed ? <MenuItem
        component={Link}
        to={routeWithSlugs(RoutePath.settings, 'general')}
      >
        Instellingen
      </MenuItem>
    : null  
    }

      <MenuItem component={Link} to={RoutePath.dashboard}>
        Dashboard
      </MenuItem>
     {auth?.user?.info_completed ? <MenuItem component={Link} to={RoutePath.library}>
        Bibliotheek
      </MenuItem> : null}
      <MenuItem component={Link} to={RoutePath.contact}>
        Contact
      </MenuItem>
      <Divider />
     </div>
     }
      <MenuItem onClick={() => signout()}>Afmelden</MenuItem>
    </Menu>
  )
}

export default AccountMenu
