import { Alert, Stack } from '@mui/material'
import { Close, NotificationsActiveOutlined } from '@mui/icons-material'
import Box from '@mui/material/Box'
import CountdownTimer from '../CountdownTimer'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'
import { RoutePath } from '../../util/routes'
import IconButton from '@mui/material/IconButton'
import React from 'react'
import { useAuth } from '../../util/auth'
import { addSeconds } from 'date-fns'
import { useHasDismissedNewUserDiscountAlert } from '../../hooks/useLocalStorage'

export function NewUserDiscountAlert() {
  const { user } = useAuth()
  const countdownExpirationDate = user?.created
    ? addSeconds(user.created.toDate(), 60 * 30)
    : null

  const [hasDismissed, setHasDismissed] = useHasDismissedNewUserDiscountAlert()

  if (
    !countdownExpirationDate ||
    countdownExpirationDate < new Date() ||
    hasDismissed
  ) {
    return null
  }

  return (
    <Alert
      sx={{
        position: {
          xs: 'absolute',
          md: 'relative'
        },
        right: {
          xs: 80,
          md: 0
        },
        top: 0,
        zIndex: 10,
        borderRadius: 0,
        mb: {
          xs: 0,
          md: 2
        },
        flex: 1,
        '& > div': { width: '100%' }
      }}
      variant="filled"
      severity="success"
      icon={false}
    >
      <Stack direction="row" flexGrow={1} alignItems="center" justifyContent="center">
        <Stack flexGrow={1} direction="row" alignItems="center" spacing={2}>
          <NotificationsActiveOutlined />
          <Box>
            {/* Wees snel en krijg de 1e maand 20% Korting <b>Actiecode</b>:
            ILOVEDIGILO1 */}
      Probeer Digilo nu voor 30 minuten. Kies daarna de startersoptie om Digilo 5 dagen gratis uit te proberen.
          </Box>
          <CountdownTimer
            expiryTimestamp={countdownExpirationDate}
            sx={{
              display: {
                xs: 'none',
                md: 'flex'
              }
            }}
          />
          
          <Button
            color="secondary"
            variant="outlined"
            component={Link}
            sx={{
              backgroundColor: 'background.paper',
              '&:hover': {
                backgroundColor: 'background.paper'
              },
              display: {
                xs: 'none',
                md: 'flex'
              }
            }}
            to={RoutePath.pricing}
          >
            {/* Naar Abonnementen */}
            Abonnementen
          </Button>
        </Stack>
        <IconButton
          sx={{ ml: 'auto!important' }}
          onClick={() => setHasDismissed(true)}
        >
          <Close />
        </IconButton>
      </Stack>
    </Alert>
  )
}
