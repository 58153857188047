import React, { useEffect, useState } from 'react';
import { Container, Box, Grid, Typography, Stack } from '@mui/material';
import Meta from '../components/Meta';
import CircularProgress from '@mui/material/CircularProgress';
import { get_all_blogs } from '../util/db';
import { useHistory } from '../util/router';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    paddingBottom: '70px',
    paddingTop: '50px'
  },
  wordWrapper: {
    padding: 30,
    borderRadius: 6,
    marginBottom: 25,
    boxShadow: '0 0 5px rgba(0,0,0,20%)'
  },
  title: {
    fontWeight: 600,
    fontSize: 26,
    marginBottom: 25,
    cursor: "pointer"
  },
  img: {
    width: '100%',
    height: '100%'
  },
  paragraph: {
    color: '#718096',
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 2
  }
});

const Blog = () => {
  const history = useHistory();
  const [blogData, setBlogData] = useState([]);
  
  console.log("testing---",blogData)
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    getBlogs();
  }, []);

  const getBlogs = async () => {
    setLoading(true);
    const data = await get_all_blogs();
    if (data) {
      setLoading(false);
      setBlogData(data);
    }
  };

  const convertSecondsToDate = (seconds) => {
    const date = new Date(seconds * 1000);
    return date.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    });
  }

  return (
    <Container>
      <Meta title="Digilo Blog" />
      {loading ? (
        <Box className='loader_cmn'><CircularProgress /></Box>
      ) : (
        <Box className={classes.root}>
          <Grid container spacing={3}>
            <Grid item sm={12} md={9}>
              {blogData.map((blogs, i) => (
                <Box
                  key={i}
                  className={classes.wordWrapper}
                  onClick={() => history.push(`/digiloblog/${blogs.url}`)}
                >
                  <Stack
                    spacing={{ xs: 1, sm: 2 }}
                    direction="row"
                    useFlexGap
                    sx={{
                      flexWrap: {
                        sm: 'wrap', // for extra small screens and above
                        xs: 'wrap', // for extra small screens and above
                        md: 'nowrap', // for medium screens and above
                      },
                    }}
                  >
                    <Box className='blog_image'>
                      <img
                        className={classes.img}
                        src={blogs.image ? blogs.image : '/img/blogone.png'}
                        alt=""
                      />
                    </Box>
                    <Box>
                      <img src="/img/ai.png" alt="" />
                      <Typography variant="h4" className={classes.title}>
                        {blogs.title}
                      </Typography>
                      <Typography variant="body1" className={classes.paragraph}>
                        {blogs.text.slice(0, 210) + '...'}
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
              ))}
            </Grid>
            <Grid item sm={3} sx={{ display: {xs:"none", sm: 'none', md:"block" } }}>
              <Box className="latest_blog">
                <Typography variant="h5" className={classes.title}>
                Recentste Artikel 
                </Typography>
                <ul>
                  {blogData.slice(0, 4).map((allBlogs, i) => (
                    <li
                      key={i}
                      onClick={() => history.push(`/digiloblog/${allBlogs.url}`)}
                    >
                      {allBlogs.title}
                    </li>
                  ))}
                </ul>
              </Box>
            </Grid>
          </Grid>
          <Typography  variant="h5" className={`cmn_heading ${classes.title}`}>
            Related Post
          </Typography>
          <Grid container spacing={2}>
            {blogData.slice(0, 4).map((allBlogs, i) => (
              <Grid
                item
                sm={6}
                md={3}
                xs={12}
                key={i}
                onClick={() => history.push(`/digiloblog/${allBlogs.url}`)}
              >
                <Box className="latest_wrapper">
                  <img
                    src={allBlogs.image ? allBlogs.image : '/img/blogone.png'}
                    alt=""
                  />
                  <Box className="content">
                    <Typography variant="subtitle1">
                      {allBlogs.title}
                    </Typography>
                    <Box className="time">
                      <img
                        src="/img/clock_png.png"
                        className="clock_icon"
                        alt=""
                      />{' '}
                      <span>{convertSecondsToDate(allBlogs.timeStamp.seconds)}</span>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </Container>
  );
};

export default Blog;
