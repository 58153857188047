import React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { Link } from '../util/router'
import { settingsPath } from '../util/routes'

function SettingsNav(props) {
  return (
    <Tabs
      value={props.activeKey}
      indicatorColor="primary"
      textColor="primary"
      centered
    >
      <Tab
        component={Link}
        to={settingsPath('general')}
        label="General"
        value="general"
      />
      <Tab
        component={Link}
        to={settingsPath('password')}
        label="Wachtwoord"
        value="password"
      />
      <Tab
        component={Link}
        to={settingsPath('billing')}
        label="Facturering"
        value="billing"
      />
    </Tabs>
  )
}

export default SettingsNav
