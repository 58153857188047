import React from 'react'
import { zip } from 'lodash-es'
import { WriterState } from '../ai-writer.model'
import CreditsInfo from '../../../components/CreditsInfo'
import EditableText from '../../../components/EditableText'

interface WriterResultProps {
  writerState: WriterState
}

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'flex-end'
  },
  content: {
    flex: '1 1 0',
    overflow: 'auto',
    width: '100%',
    overflowX: 'hidden'
  },
  creditsInfo: {
    root: {
      mb: 2,
      ml: 'auto'
    }
  }
}

function WriterResult({ writerState }: WriterResultProps) {
  const contents = zip(writerState.outline, writerState.generate)

  const getText = () => {
    return contents
      .map(([outline, generate]) => {
        if (!generate) return ''; // Handle the case when generate is undefined
        return generate
          .split('\n')
          .filter((x) => x)
          .map((x) => `<p>${x}</p>`)
          .join('');
      })
      .join('');
  };
  
  
  return (
    <>
      <CreditsInfo sx={styles.creditsInfo} />
      {!!writerState.generate.length && (
        <EditableText text={getText()} contentType="Digilo Blog" />
      )}
    </>
  );
  
}

export default WriterResult
