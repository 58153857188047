import { enumToNumberArray } from '../../util/util'

export enum WriterStep {
  Topic,
  Title,
  Description,
  Outline,
  Generate
}

export const writerSteps = enumToNumberArray(WriterStep) as WriterStep[]

export const writerStepLabels: Record<WriterStep, string> = {
  [WriterStep.Topic]: 'Kies een onderwerp waarover je wilt schrijven',
  [WriterStep.Title]: 'Kies een titel die het beste bij je artikel past',
  [WriterStep.Description]:
    'Kies een omschrijving die het beste bij je artikel past',
  [WriterStep.Outline]: 'Kies een overzicht die het beste bij je artikel past',
  [WriterStep.Generate]: 'Je artikel wordt geschreven'
}

export interface WriterState {
  topic: string
  title: string
  description: string
  outline: string[]
  generate: string[]
  language: WriterLanguage
}

export type WriterLanguage = 'english' | 'dutch'

export function createDefaultWriterState(): WriterState {
  return {
    topic: '',
    title: '',
    description: '',
    outline: [],
    generate: [],
    language: 'dutch'
  }
}
