import React, {
  ComponentType,
  Fragment,
  MouseEvent,
  ReactNode,
  useState
} from 'react'
import { usePaginationState } from '../../hooks/usePaginationState'
import Card from '@mui/material/Card'
import {
  IconButton,
  ListItemButton,
  Stack,
  TablePagination
} from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import List from '@mui/material/List'
import Grid from '@mui/material/Grid'
import ListItemText from '@mui/material/ListItemText'
import { format } from 'date-fns/esm'
import { DeleteOutlined, EditOutlined } from '@mui/icons-material'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import EditItemModal from '../../components/EditItemModal'
import { DbBaseItem } from '../../shared-types/db-types'
import { IconButtonWithLoading } from '../../hocs/canLoad'

interface ItemListProps<T extends DbBaseItem> {
  AdditionalButtons?: ComponentType<{ item: T }>
  paginationState: ReturnType<typeof usePaginationState>
  invalidateCount: () => void
  items: T[]
  onItemClick: (item: T) => void
  onItemDelete: (item: T) => Promise<any>
  onItemCreate: (data: any) => Promise<any>
  onItemUpdate: (id: string, item: any) => Promise<any>
  newButtonText?: string
  emptyPlaceholder: ReactNode
}

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  card: {
    my: 2
  },
  newItemButton: {
    alignSelf: 'flex-end',
    my: 1,
    px: {
      xs: 2,
      sm: 6
    }
  },
  pagination: {
    alignSelf: 'center'
  },
  spinner: {
    m: 2
  }
}

function ItemList<T extends DbBaseItem>({
  invalidateCount,
  items,
  onItemDelete,
  onItemClick,
  paginationState,
  onItemCreate,
  onItemUpdate,
  newButtonText,
  AdditionalButtons,
  emptyPlaceholder
}: ItemListProps<T>) {
  const [updateItem, setUpdateItem] = useState<T | null | undefined>(undefined)

  const [isDeleting, setIsDeleting] = useState(false)

  const handleDeleteProject = async (e: MouseEvent, item: T) => {
    e.stopPropagation()
    setIsDeleting(true)
    await onItemDelete(item)
    invalidateCount()
    setIsDeleting(false)
  }

  const handleEditProject = (e: MouseEvent, item: T) => {
    e.stopPropagation()
    setUpdateItem(item)
  }

  const handleProjectEditDone = (isCreated: boolean) => {
    setUpdateItem(undefined)
    if (isCreated) {
      invalidateCount()
      paginationState.onReset()
    }
  }

  return (
    <>
      <Card sx={styles.card}>
        {!items?.length ? (
          <Stack direction="row" justifyContent="center" padding={2}>
            {!items ? "Je hebt nog geen projecten aangemaakt" : <>{emptyPlaceholder}</>}
          </Stack>
        ) : (
          <List>
            {items.map((item, i) => (
              <Fragment key={i}>
                <ListItemButton
                  onClick={() => onItemClick(item)}
                  disableRipple={true}
                >
                  <Grid container alignItems="center">
                    <Grid item xs={12} md={3}>
                      <ListItemText
                        primary={item.name}
                        sx={{ wordBreak: 'break-all' }}
                      />
                    </Grid>
                    <Grid item xs={4} md={3}>
                      <ListItemText
                        primary={
                          item.createdAt
                            ? format(item.createdAt.toDate(), 'dd/MM/yyyy')
                            : ''
                        }
                      />
                    </Grid>
                    <Grid item sx={{ ml: { xs: 'auto', sm: 'none' } }}>
                      <IconButton
                        aria-label="edit"
                        onClick={(e) => handleEditProject(e, item)}
                      >
                        <EditOutlined />
                      </IconButton>
                      <IconButtonWithLoading
                        aria-label="delete"
                        isLoading={isDeleting}
                        onClick={(e) => handleDeleteProject(e, item)}
                      >
                        <DeleteOutlined />
                      </IconButtonWithLoading>

                      {AdditionalButtons && <AdditionalButtons item={item} />}
                    </Grid>
                  </Grid>
                </ListItemButton>
                {i !== items.length - 1 && <Divider component="li" />}
              </Fragment>
            ))}
          </List>
        )}
      </Card>
      <TablePagination
        title="Regels per pagina"
        rowsPerPageOptions={[10, 20, 50]}
        component="div"
        labelRowsPerPage="Regels per pagina"
        {...paginationState}
      />
      {newButtonText && (
        <Button
          color="secondary"
          variant="contained"
          onClick={() => setUpdateItem(null)}
          sx={styles.newItemButton}
        >
          {newButtonText}
        </Button>
      )}

      {updateItem !== undefined && (
        <EditItemModal
          id={updateItem?.id || ''}
          onDone={handleProjectEditDone}
          itemData={updateItem}
          itemTypeName="Project"
          onCreate={onItemCreate}
          onUpdate={onItemUpdate}
        />
      )}
    </>
  )
}

export default ItemList
