import React from 'react'
import { useLocation } from '../../util/router'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Box, CardContent, Grid, Stack, Tooltip } from '@mui/material'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import {
  ImproverFormValues,
  ImproverOption,
  improverOptions
} from '../../shared-types/improver.types'
import { improverOptionIcons } from './ai-improver.model'
import { NeedsCreditsButton } from '../../hocs/needsCredits'
import { values } from 'lodash-es'

interface ImproverFormProps {
  isStreaming: boolean
  onFormSubmit: (formValues: ImproverFormValues) => Promise<unknown>
}

function createInitialValues() {
  return {
    text: '',
    improverOption: '' as ImproverOption | ''
  }
}

const validationSchema = yup.object().shape({
  text: yup.string().required(),
  improverOption: yup.string().required()
})

const styles = {
  root: {
    height: '100%'
  },
  container: {
    height: '100%'
  },
  textField: {
    flex: 1,
    width: '100%',
    fontFamily:"Poppins !important",
    color:"#000",
    fontSize:"14px",
    '& .MuiInputBase-root': {
      height: '100%',
      display: 'flex',
      alignItems: 'stretch',
       fontFamily:"Poppins !important",
       color:"#000",
       fontSize:"14px",
    }
  },
  instructionsField: {
    width: '100%'
  },
  section: {
    width: '100%'
  },
  submitButton: {
    width: 240,
    p: 2,
    alignSelf: 'center'
  },
  improveButton: {
    borderRadius: 50,
    width: '100%'
  },
  form: {
    height: '100%'
  }
}

function ImproverForm({ onFormSubmit, isStreaming }: ImproverFormProps) {
  const [copieText,setCopieText] = React.useState('')
  const location = useLocation();

React.useEffect(() => {
  // this function is used to get data from the localstorage
  // Retrieve data from local storage
  const storedData = localStorage.getItem('text');
  if (localStorage.getItem('text')) {
    // Use setCopieText to set the state, but don't remove from local storage
    setCopieText(localStorage.getItem('text'));
    formik.setFieldValue('text', storedData ? storedData : '', true);
    //after setting up the data we are empty the localstorage
    localStorage.removeItem('text')
  }
}, []);

// React.useEffect(() => {
//   // Set form field value using formik if formik is properly set up

//     formik.setFieldValue('text', copieText ? copieText : '', true);
 
// }, []); // Add copieText and formik to the dependency array

// Rest of your component...



  const formik = useFormik({
    initialValues: createInitialValues(),
    validationSchema: validationSchema,
    validateOnMount: true,
    onSubmit: (values, formikHelpers) => {
      onFormSubmit(values).then(() => formikHelpers.setSubmitting(false))
    }
  })

  // When fc then the click does not work when previously a textfield was focused
  // --> use function instead
  const createImprovementButtons = () => {
    return (
      <Grid container spacing={1.5} width="100%">
        {Object.entries(improverOptions).map(
          ([key, tooltip]: [ImproverOption, string]) => {
            const Icon = improverOptionIcons[key]
            return (
              <Grid item key={key} xs={12 / 5}>
                <Tooltip title={tooltip}>
                  <Button
                    variant="contained"
                    sx={{
                      ...styles.improveButton,
                      backgroundColor:
                        formik.values.improverOption === key
                          ? 'primary.main'
                          : 'primary.light'
                    }}
                    onClick={() =>
                      formik.setFieldValue(
                        'improverOption',
                        formik.values.improverOption === key ? '' : key,
                        true
                      )
                    }
                  >
                    <Icon />
                  </Button>
                </Tooltip>
              </Grid>
            )
          }
        )}
      </Grid>
    )
  }

  return (
    <CardContent sx={styles.root}>
      <form onSubmit={formik.handleSubmit} style={styles.form}>
        <Stack sx={styles.container} spacing={2}>
          <Stack sx={{ ...styles.section, flex: 1 }}>
            <Typography variant="subtitle1">
              Plak je tekst in het veld hieronder
            </Typography>

            <TextField
              variant="outlined"
              // autoComplete="off"
              multiline={true}
              sx={styles.textField}
              error={formik.touched.text && Boolean(formik.errors.text)}
              {...formik.getFieldProps('text')}
              value = {formik.values.text}
              

            />
          </Stack>

          <Box>
            <Typography variant="subtitle1" sx={{ mb: 1.5 }}>
              Gebruik de knoppen hieronder om je tekst te verbeteren
            </Typography>

            {createImprovementButtons()}
          </Box>

          <NeedsCreditsButton
            type="submit"
            variant="contained"
            color="secondary"
            sx={styles.submitButton}
            disabled={isStreaming || formik.isSubmitting || !formik.isValid}
          >
            Verbeter mijn tekst
          </NeedsCreditsButton>
        </Stack>
      </form>
    </CardContent>
  )
}

export default ImproverForm
