import React, { PropsWithChildren } from 'react'
import SideNavbar from './SideNavbar/SideNavbar'
import Meta from './Meta'

interface AppLayoutProps extends PropsWithChildren<any> {
  title: string
  isFixedHeight?: boolean
}

function AppLayout({ children, title, isFixedHeight }: AppLayoutProps) {
  return (
    <>
      <Meta title="Dashboard" />
      <SideNavbar title={title} isFixedHeight={isFixedHeight}>{children}</SideNavbar>
    </>
  )
}

export default AppLayout
