import { streamApiRequest } from '../../util/requests'
import { ChatMessage } from '../../shared-types/assistance.types'

export async function textGenerateApi(
  prompt: string,
  previousMessages: ChatMessage[],
  callback: (message: string) => void
): Promise<void> {
  let openAnswer = ''
  await streamApiRequest(
    'text-generate',
    'backend',
    'POST',
    {
      messages: [
        ...previousMessages,
        { text: prompt, isAnswer: false } as ChatMessage
      ]
    },
    (data) => {
      openAnswer += parseAnswer(data)
      callback(openAnswer.trim())
    }
  )
}

// https://github.com/openai/openai-node/issues/18#issuecomment-1369996933
export function parseAnswer(data: ArrayBuffer) {
  try {
    return new TextDecoder().decode(data).replace(/^data: /, '')
  } catch (error) {
    console.error('Could not JSON parse stream message', error)
    return ''
  }
}
