import React from 'react'
import Chat from './chat'
import Typography from '@mui/material/Typography'
import { useChatState } from './chat-state.hook'
import CreditsInfo from '../../components/CreditsInfo'
import MainPanel from '../../components/MainPanel'
import MainPanelHeader from '../../components/MainPanelHeader'

const AiAssistance = () => {
  const { results, currentAnswer, sendPrompt } = useChatState()
  return (
    <MainPanel
      header={<MainPanelHeader additional={<CreditsInfo />} />}
      main={
        <Chat
          messages={results}
          onInputEnter={sendPrompt}
          currentWriting={currentAnswer}
        >
          <Typography variant="h4">Stel Digilo een vraag</Typography>
          <Typography variant="body1"></Typography>
        </Chat>
      }
    />
  )
}

export default AiAssistance
