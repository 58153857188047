import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import Snackbar from '@mui/material/Snackbar'
import { Alert } from '@mui/material'
import { FileCopyOutlined } from '@mui/icons-material'
import { isFunction } from 'lodash-es'

interface CopyToClipboardButtonProps {
  text: string | (() => string)
  formattedText?: string | (() => string)
  disabled?: boolean
}

function CopyToClipboardButton({
  text,
  disabled,
  formattedText
}: CopyToClipboardButtonProps) {
  const [copied, setCopied] = useState(false)
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    const clipboardItem = new ClipboardItem({
      'text/plain': new Blob([isFunction(text) ? text() : text], {
        type: 'text/plain'
      }),
      ...(formattedText
        ? {
            'text/html': new Blob(
              [isFunction(formattedText) ? formattedText() : formattedText],
              { type: 'text/html' }
            )
          }
        : {})
    })

    navigator.clipboard.write([clipboardItem])
    setCopied(true)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <IconButton
        disabled={disabled}
        onClick={handleClick}
        aria-label="Naar klembord kopiëren"
        title="Naar klembord kopiëren"
      >
        <FileCopyOutlined color={copied ? 'secondary' : 'inherit'} />
      </IconButton>
      <Snackbar open={open} autoHideDuration={1500} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Gekopieerd naar het klembord!
        </Alert>
      </Snackbar>
    </>
  )
}

export default CopyToClipboardButton
