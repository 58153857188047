import { signOut } from 'firebase/auth'
import { auth } from './auth'

export type BackendType = 'functions' | 'backend'

function buildUrl(path, type: BackendType) {
  return `${
    type === 'backend'
      ? process.env.REACT_APP_BACKEND_URL
      : process.env.REACT_APP_FUNCTIONS_URL
  }/${path}`
}

// Make an API request to `/api/{path}`
export async function apiRequest(
  path,
  backendType,
  method,
  data?: any
) {
  const accessToken = auth.currentUser
    ? await auth.currentUser.getIdToken()
    : undefined
  // console.log("token---",accessToken)
  const response = await fetch(buildUrl(path, backendType), {
    method,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    },
    body: data ? JSON.stringify(data) : undefined
  })
  // .then((response) => {
  //   if (response.status >= 400) {
  //     // Automatically signout user if accessToken is no longer valid
  //     if (response.status === 401) {
  //       signOut(auth)
  //     }
  //     throw CustomError(response.status, response.statusText)
  //   }
  //   return response.json()
  // })
  if(!response.ok){
    throw new Error(await response.text());
  }
  else{
    return await response.json(); 
  }
}

export async function streamApiRequest(
  path,
  backendType,
  method,
  data,
  callback
) {
  const accessToken = auth.currentUser
    ? await auth.currentUser.getIdToken()
    : undefined;

  const response = await fetch(buildUrl(path, backendType), {
    method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    body: data ? JSON.stringify(data) : undefined
  });

  if (!response.ok) {
    throw new Error(await response.text());
  }

  if (callback) {
    return await readAllChunks(response.body, callback);
  } else {
    return await response.json(); // Return the entire response if no callback is provided
  }
}

async function readAllChunks(readableStream, callback) {
  const reader = readableStream.getReader();
  const chunks = [];

  let done, value;
  while (!done) {
    ({ value, done } = await reader.read());
    if (done) {
      return chunks;
    }
    callback(value);
    chunks.push(value);
  }
}


// Make an API request to any external URL
export function apiRequestExternal(url, method = 'GET', data) {
  return fetch(url, {
    method,
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: data ? JSON.stringify(data) : undefined
  }).then((response) => response.json())
}

// Create an Error with custom message and code
export function CustomError(code, message): Error & { code: string } {
  const error = new Error(message) as Error & { code: string }
  error.code = code
  return error
}
