import React from 'react'
import {
  createProject,
  deleteProject,
  updateProject,
  useCountProjects,
  useProjectsByOwner
} from './projects-api'
import { useAuth } from '../../util/auth'
import { usePaginationState } from '../../hooks/usePaginationState'
import ItemList from './ItemList'
import { Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import ServiceTiles from './ServiceTiles'

interface ProjectsOverviewProps {
  setSelectedProject: (project: string | null) => void
}

function ProjectsOverview({ setSelectedProject }: ProjectsOverviewProps) {
  const { user } = useAuth()
  const { data: projectsCount, invalidateCount } = useCountProjects(user.id)
  const paginationState = usePaginationState(projectsCount || 0)
  const { data: projects } = useProjectsByOwner(
    user.id,
    paginationState.rowsPerPage,
    paginationState.page
  )

  return (
    <Stack>
      <ServiceTiles projectId={null} />
      <Typography variant="h5" mt={2}>
        Projecten
      </Typography>
      <ItemList
        invalidateCount={invalidateCount}
        items={projects}
        onItemClick={(item) => setSelectedProject(item.id)}
        onItemDelete={(item) => deleteProject(item.id)}
        paginationState={paginationState}
        onItemCreate={createProject}
        onItemUpdate={updateProject}
        newButtonText="Nieuw Project"
        emptyPlaceholder="Je hebt nog geen projecten aangemaakt"
      ></ItemList>
    </Stack>
  )
}

export default ProjectsOverview
