import React from 'react'
import Meta from '../components/Meta'
import HeroSection from '../components/HeroSection'
import HeroSection2 from '../components/HeroSection2'
import FeaturesSection from '../components/FeaturesSection'
import TestimonialsSection from '../components/TestimonialsSection'
import CtaSection from '../components/CtaSection'
import FaqSection from '../components/FaqSection'
import PricingSection from '../components/PricingSection'
import { useAuth } from '../util/auth.js'
import { authPath, RoutePath } from '../util/routes'
import FeaturesOtherSection from '../components/FeaturesOtherSection'
import ClientsSection from '../components/ClientsSection.js'
import WhyChoose from '../components/WhyChoose.js'
import TrailContent from '../components/TrailContent.js'
import Valuable from '../components/Valuable.js'
import Benifits from '../components/Benifits.js'
import DigiloAdvantage from '../components/DigiloAdvantage.js'

function IndexPage(props) {
  const auth = useAuth()
  const getStartedLink = auth.user ? RoutePath.dashboard : authPath('signup')
  const siteUrl = window.location.origin;
  return (
    <>
      <Meta  title="Digilo Home" image='/img/digilomain.png' domain={siteUrl}/>
      <HeroSection buttonText="Start Nu Gratis" buttonPath={getStartedLink} />
      <ClientsSection title="Vertrouwd door"></ClientsSection>
     
     
      <WhyChoose />
      <TrailContent/>
      <Valuable />
      <Benifits />
       <TrailContent/>

     
      <TestimonialsSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Dit zeggen onze gebruikers"
        subtitle=""
      />
        {/* <TrailContent/> */}
        <DigiloAdvantage/>
        <TrailContent/>

      <PricingSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Prijzen"
        subtitle="Kies het abonnement dat perfect aansluit bij je behoeften."
        class="homePrice"
        
      />
           <TrailContent/>
      {/* <HeroSection2
        bgColor="default"
        size="medium"
        title={
          <>Schrijf automatisch content met Digilo, de content assistent</>
        }
        titleSize={2}
        subtitle={
          <>
            Digilo is een slimme schrijver die je helpt je teksten te schrijven.
            Met Digilo hoef je geen tijd meer te verspillen aan het schrijven
            van saaie, technische teksten. Digilo schrijft je teksten voor je,
            zodat je je tijd kunt besteden aan andere belangrijke taken. Digilo
            heeft een gebruiksvriendelijke interface, zodat je snel en eenvoudig
            je teksten kunt schrijven. Het heeft ook een reeks krachtige
            Kunstmatige intelligentie-functies, waarmee je je teksten kunt
            verbeteren. Bijvoorbeeld, met Digilo kun je je teksten automatisch
            laten controleren op fouten, je teksten laten optimaliseren voor
            zoekmachines. Met Digilo kun je je teksten sneller schrijven dan je
            ooit voor mogelijk had gehouden. Digilo gebruikt kunstmatige
            intelligentie om je teksten te analyseren, waardoor het je kan
            helpen informatieve en betrokken teksten te schrijven. Bovendien kun
            je met Digilo je teksten personaliseren, zodat ze relevant zijn
            voor je publiek.
          </>
        }
      />
      <HeroSection2
        bgColor="default"
        size="medium"
        title={<>Geniet van een stroom van krachtige functies</>}
        titleSize={2}
        subtitle={
          <>
            Digilo biedt een reeks krachtige functies om je te helpen je teksten
            te schrijven. Het heeft een intelligente interface voor
            verschillende tekstsoorten. Bovendien heeft Digilo een reeks
            gebruiksvriendelijke gereedschappen, waarmee je je teksten kunt
            schrijven in verschillende stijlen. Digilo bevat boordevol functies
            waarmee je verschillende tekstsoorten schrijft.
          </>
        }
      /> */}
      <FeaturesSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        sectionId="features"
        title="Features"
        ctaLink={getStartedLink}
      />
      {/* <FeaturesOtherSection />
    
      <CtaSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Klaar om Digilo uit te proberen?"
        subtitle="Schrijf tot 10x sneller content me Digilo de content assistent."
        buttonText="Start nu"
        buttonColor="primary"
        buttonPath={getStartedLink}
      /> */}
      <FaqSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Veel Gestelde Vragen"
        subtitle=""
      />
     
    </>
  )
}

export default IndexPage
