import React, {
  forwardRef,
  Fragment,
  PropsWithChildren,
  ReactNode,
  useRef,
  useState
} from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import Logo from '../Logo'
import {
  ExpandLess,
  ExpandMore,
  Lock,
  QuestionMark,
  Settings,
  ShoppingCart
} from '@mui/icons-material'
import { RoutePath, settingsPath } from '../../util/routes'
import { Link, useRouter } from '../../util/router'
import {
  Avatar,
  Collapse,
  ListItemAvatar,
  Stack,
  SwipeableDrawer,
  Tooltip
} from '@mui/material'
import { useAuth } from '../../util/auth'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import AccountMenu from '../AccountMenu'
import { useMenu } from '../../hooks/useMenu'
import { cloneDeep, get, set } from 'lodash-es'
import {
  isListItem,
  LinkItem,
  LinkItemList,
  navbarItems,
  NestedListItem
} from './navbarItems'
import ProjectNavigation from './ProjectNavigation'
import { getPlanDetails } from '../../util/prices'
import { capitalizeFirstLetter } from '../../util/util'
import { useSelectedProject } from '../../hooks/useRouterState'
import { mapPlanToNumber } from '../../hocs/requirePlan'
import { useElementSize } from '../../hooks/useElementSize'
import { NewUserDiscountAlert } from './NewUserDiscountAlert'
import { auth } from 'firebase-admin'

interface SideNavbarProps {
  title: string
  isFixedHeight?: boolean
}

export const drawerWidth = 275

const useStyles = makeStyles((theme) => ({
  selectedItem: {
    '&.Mui-selected': {
      backgroundColor: theme.palette.action.disabled,
      '&:hover': {
        backgroundColor: theme.palette.action.disabled
      }
    }
  }
}))

const styles = {
  sideToolbar: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'background.default'
  },
  drawerScrollable: {
    overflowY: 'auto',
    width: '100%',
    flex: 1,
    '&::-webkit-scrollbar': {
      width: '0.2em'
    },
    // '&::-webkit-scrollbar-track': {
    //   '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    // },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#fff',
      outline: '1px solid slategrey'
    }
  },
  appToolbar: {
    pl: '0!important',
    backgroundColor: 'background.default',
    justifyContent: 'flex-end'
  },
  logo: {
    p: 2
  },
  main: {
    backgroundColor: 'background.appDefault',
    minHeight: '100%',
    flexGrow: 1,
    width: { xs: '100%', sm: `calc(100% - ${drawerWidth}px)` },
    display: 'flex',
    flexDirection: 'column'
  },
  drawer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    zIndex:"999"
  },
  profile: {
    mt: 'auto',
    display: 'flex'
  },
  profileIcon: {
    backgroundColor: 'secondary.main'
  },
  projectNavigation: {
    alignSelf: 'center',
    my: 2
  },
  mobileNavigation: {
    flex: '0 0 auto',
    height: 62.75, // needed for placeholder
    display: {
      xs: 'block',
      sm: 'none'
    },
    position: 'relative'
  },
  downIcon: {
    color: 'white',
    height: 24,
    width: 24
  },
  mobileSecondRow: {
    width: '100%'
  },
  mobileDownContainer: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0
  },
  mobileStartButton: {
    display: 'flex',
    justifyContent: 'center',
    mt: -1,
    mb: 1,
    mr: 1
  },
  mobileTitle: {
    textAlign: 'center'
  }
}

function SideNavbar({
  children,
  title,
  isFixedHeight
}: PropsWithChildren<SideNavbarProps>) {
  const [mobileOpen, setMobileOpen] = useState(false)
  const { pathname } = useRouter()
  const classes = useStyles()
  const [accordionState, setAccordionState] = useState<object>({})
  const { user } = useAuth()
  const auth = user?.role

const authrole = useAuth()

  const planDetails = getPlanDetails(user?.planId)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const DrawerContent = ({ mobileClose }: { mobileClose?: ReactNode }) => (
    <Box sx={styles.drawer}>
      <Toolbar sx={styles.sideToolbar}>
        <Box sx={styles.logo}>
        {authrole?.user?.role !== 'admin' ?  <Logo link={RoutePath.root} /> :  <Logo  link={RoutePath.AdminUsers}/> }
        </Box>
      </Toolbar>
      {authrole?.user?.role !== 'admin' &&<ProjectNavigation sx={styles.projectNavigation} /> }
   

      <Stack sx={styles.drawerScrollable}>
        <LinkList linkItems={navbarItems} />
      </Stack>

      <Profile />
      {mobileClose}
    </Box>
  )

  function LinkList({
    linkItems,
    path = []
  }: {
    linkItems: LinkItemList
    path?: string[]
  }) {
    return (
      <List disablePadding sx={{ ml: 1.5 * path.length }}>
        {linkItems.map((item) => {
          if (user?.role === "admin" && ((item.label === "Use Options") || (item.label === "User selected options") || (item.label === "Text Input Options")
            || (item.label === "Recommended Options") || (item.label === "About Options") || (item.label === "Users") || (item.label === "User Custom Options") || (item.label === "Blog List"))
          ) {
            return (
              <Fragment key={item.label}>
                {isListItem(item) ? (
                  <LinkListItem linkItem={item} />
                ) : (
                  <NestedLinkList nestedList={item} path={path} />
                )}
              </Fragment>

            )
          }
          if ((user?.role === "user" || !user?.role) &&

          ((item.label != "Use Options") && (item.label != "User selected options") && (item.label != "Text Input Options")
            && (item.label != "Recommended Options") && (item.label != "About Options") && (item.label != "Users") && (item.label !== "User Custom Options") && (item.label !== "Blog List") && (item.label !== "Add Blog") && (item.label !== "Edit Blog")) 
           ) {
            return (
              <Fragment key={item.label}>
                {isListItem(item) ? (
                  <LinkListItem linkItem={item} />
                ) : (
                  <NestedLinkList nestedList={item} path={path} />
                )}
              </Fragment> 

            )
          }

        })}
      </List>
    )
  }

  function NestedLinkList({
    nestedList: { label, linkItems, Icon },
    path
  }: {
    nestedList: NestedListItem
    path: string[]
  }) {
    const newPath = [...path, label]
    const pathString = newPath.join('.')
    const isOpen = !!get(accordionState, pathString)
    return (
      <>
        <ListItemButton
          onClick={() => {
            setAccordionState(
              set(cloneDeep(accordionState), pathString, !isOpen)
            )
          }}
        >
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
          <ListItemText primary={label} />
          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <LinkList linkItems={linkItems} path={newPath} />
        </Collapse>
      </>
    )
  }
  function LinkListItem({
    linkItem: { label, link, Icon, minimumPlan }
  }: {
    linkItem: LinkItem
  }) {
    // console.log(planDetails,"this is the planDetails planDetails")
    const isLockedLink = user?.paymentType == "subscription" ? user?.stripeSubscriptionStatus == "cancelled" || user?.stripeSubscriptionStatus == "cancelled" : user?.credits == 0
      // minimumPlan &&
      // planDetails.id  &&
      // mapPlanToNumber(planDetails.id) < mapPlanToNumber(minimumPlan)
    const linkItem = (
      <ListItem disablePadding>
        <ListItemButton
          component={Link}
          classes={{
            selected: classes.selectedItem
          }}     
          to={link}
          disabled={isLockedLink}
          selected={pathname.startsWith(link)}
        >
          <ListItemIcon className={label}>
            {label === 'Content Assistent' ? <svg  fill="#ffffff" id="Flat" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 255 255" width='24px' height='24px'>
  <path d="M224,120v88a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32h88a8,8,0,0,1,0,16H48V208H208V120a8,8,0,0,1,16,0Zm5.66-50.34-96,96A8,8,0,0,1,128,168H96a8,8,0,0,1-8-8V128a8,8,0,0,1,2.34-5.66l96-96a8,8,0,0,1,11.32,0l32,32A8,8,0,0,1,229.66,69.66Zm-17-5.66L192,43.31,179.31,56,200,76.69Z"/>
</svg> :   <Icon />}
           
          </ListItemIcon>
          <ListItemText primary={label} />
          {user?.credits == 0 || user?.stripeSubscriptionStatus == "active" || isLockedLink && (
            <ListItemIcon>
              <Lock />
            </ListItemIcon>
          )}
        </ListItemButton>
      </ListItem>
    )
    return isLockedLink ? (
      <Tooltip title="Upgrade naar premium om toegang te krijgen tot deze functie.">
        {linkItem}
      </Tooltip>
    ) : (
      linkItem
    )
  }
  const toolbarRef = useRef<HTMLDivElement>(null)
  const { height: toolbarHeight } = useElementSize(toolbarRef)
  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <AppToolbar
        onDrawerToggle={handleDrawerToggle}
        title={title}
        ref={toolbarRef}
      />
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <SwipeableDrawer
          variant="temporary"
          color="primary"
          anchor="top"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none',zIndex:"99999" },
            '& .MuiDrawer-paper': {
              border: 0,
              boxSizing: 'border-box',
              width: '100%',
              height: '100vh',
              zIndex:"99999"
            }
          }}
          onOpen={() => undefined}
        >
          <DrawerContent
            mobileClose={
              <Stack justifyItems="center">
                <DrawerToggleButton
                  isForOpening={false}
                  onDrawerToggle={() => handleDrawerToggle()}
                />
              </Stack>
            }
          />
        </SwipeableDrawer>
        <Drawer
          color="primary"
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              border: 0,
              boxSizing: 'border-box',
              width: drawerWidth
            }
          }}
          open
        >
          <DrawerContent />
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ ...styles.main, flex: isFixedHeight ? '1 1 0' : '0 0 auto' }}
      >
        <Box sx={{ height: toolbarHeight, flex: '0 0 auto' }} />
        {children}
      </Box>
    </Box>
  )
}
const AppToolbar = forwardRef<
  HTMLDivElement,
  {
    title: string
    onDrawerToggle: () => void
  }
>(({ onDrawerToggle, title }, ref) => {
  const { menuState, openMenu, closeMenu } = useMenu()
  const { projectId } = useSelectedProject()
  const auth = useAuth()
  return (
    <AppBar
      ref={ref}
      elevation={0}
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` }
      }}
    >
      <Toolbar variant="dense" sx={styles.appToolbar}>
        <NewUserDiscountAlert />
        {auth?.user?.role !== 'admin' && <IconButton aria-label="help" component={Link} to={RoutePath.help}>
          <QuestionMark />
        </IconButton>}


        {auth?.user?.role !== 'admin' &&   <IconButton
          aria-label="pricing"
          component={Link}
          to={RoutePath.pricing}
        >
          <ShoppingCart /> 
        </IconButton>}

        <IconButton
          edge="end"
          aria-label="open menu"
          onClick={(e) => openMenu(e, 'account-menu')}
          sx={{ xs: 'none', sm: 'block' }}
        >
          <MenuIcon />
        </IconButton>

        <AccountMenu closeMenu={closeMenu} menuState={menuState} />
      </Toolbar>
      <Box sx={styles.mobileNavigation}>
        <Stack alignItems="center">
          <Typography variant="h6" sx={styles.mobileTitle}>
            {capitalizeFirstLetter(title)}
          </Typography>
          <Stack
            direction="row"
            justifyContent="flex-end"
            sx={styles.mobileSecondRow}
          >
            <Box sx={styles.mobileDownContainer}>
              <DrawerToggleButton
                isForOpening={true}
                onDrawerToggle={onDrawerToggle}
              />
            </Box>
            <ProjectNavigation
              sx={styles.mobileStartButton}
              showOnMobile={projectId ? 'back' : false}
              size="small"
            />
          </Stack>
        </Stack>
      </Box>
    </AppBar>
  )
})
AppToolbar.displayName = 'AppToolbar'
function Profile() {
  const auth = useAuth()
  const name =
    auth?.user?.name?.split(' ')[0] 
  const firstLetter = name?.[0]?.toUpperCase()
  return (
    <List sx={styles.profile}>
      {auth.user?.role !== "admin" &&
        <ListItemButton
          component={Link}
          to={settingsPath('general')}
          alignItems="flex-start"
        >
          <ListItemAvatar>
            <Avatar sx={styles.profileIcon}>{firstLetter}</Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={<strong>{name}</strong>}
            secondary={<Typography variant="body2">Bekijk profiel</Typography>}
            disableTypography={true}
          />


          <Settings sx={{ alignSelf: 'center' }} />
        </ListItemButton>
      }

    </List>
  )
}
function DrawerToggleButton({
  onDrawerToggle,
  isForOpening
}: {
  isForOpening: boolean
  onDrawerToggle: (open: boolean) => void
}) {
  {
    return (
      <IconButton
        onClick={() => onDrawerToggle(isForOpening)}
        sx={{ m: 'auto' }}
      >
        <svg
          height="1024"
          width="767.5"
          fill="currentColor"
          viewBox="0 0 767.5 1024"
          style={{
            ...styles.downIcon,
            transform: !isForOpening ? 'rotate(180deg)' : 'rotate(0deg)'
          }}
        >
          <path d="M0 384l383.75 383.75L767.5 384H0z" />
        </svg>
      </IconButton>
    )
  }
}

export default SideNavbar
