import React from 'react'
import Button from '@mui/material/Button'
import { Stack } from '@mui/material'
import { useAuth } from '../../util/auth'
import FeedbackPopup from './FeedbackPopup'

function FeedbackButton() {
  const { user } = useAuth()

  const [open, setOpen] = React.useState(false)
  const [menuDialog , setMenuDialog] = React.useState(false)
  const [feedbackModalOne,setFeedbackModalOne] = React.useState(true)
  const [experienceStart,setExperienceStart] = React.useState(false)
  const handleClose = () => setOpen(false)

  if (!user) {
    return null
  }

  return (
    <>
      <FeedbackPopup onClose={handleClose} open={open} setFeedbackOpen={setOpen} setMenuDialog={setMenuDialog} feedbackModalOne={feedbackModalOne} setExperienceStart={setExperienceStart} />
      <Stack
        justifyContent="center"
        sx={{
          pointerEvents: 'none',
          position: 'fixed',
          right: 0,
          zIndex: 100,
          height: '100%'
        }}
      >
     {user.role !== "admin" &&
        <Button
        onClick={() => setOpen(true)}
        variant="outlined"
        color="secondary"
        sx={{
          pointerEvents: 'auto',
          transform: 'rotate(270deg)  translateX(50%)',
          transformOrigin: 'bottom right',
          backgroundColor: 'background.paper',
          '&:hover': {
            backgroundColor: 'background.paper'
          },
          display: {
            xs: 'none',
            md: 'block'
          }
        }}
      >
        Feedback
      </Button>
     }
      </Stack>
    </>
  )
}

export default FeedbackButton
