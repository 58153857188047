import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { makeStyles } from '@mui/styles'
import { Container, Box, Grid, Typography, Stack } from '@mui/material'
import VerifiedIcon from '@mui/icons-material/Verified'
import Meta from '../components/Meta'
const useStyles = makeStyles({
  root: {
    paddingBottom: '70px',
    paddingTop: '50px'
  },
  title: {
    textAlign: 'center',
    marginBottom: 30
  },
  icon: {
    textAlign: 'center',
    marginBottom: 30
  },
  svg: {
    fill: '#EF9834FF',
    fontSize: '50px'
  }
})

const Thankyou = () => {
  const classes = useStyles()
  useEffect(() => {
    const redirectTimer = setTimeout(() => {
      window.location.href = '/app'
    }, 5000)
    return () => clearTimeout(redirectTimer)
  }, [])

  return (
    <Container>
      <Helmet>
        <script
          type="text/javascript"
          src="https://app.paypro.nl/pat/pat.js"
          data-campaign-id="24898"
          // data-order-amount="AMOUNT_HERE" // Required when using variable commission
          // data-reference="REFERENCE_HERE" // Optional, but can be useful for administration purposes, or avoid duplicate conversions
        />
       <noscript>
       {`<img src="https://app.paypro.nl/pat/pat.gif?campaignId=24898&orderAmount=STATIC_AMOUNT&reference=STATIC_REFERENCE" height="0" width="0" alt="pat-gif" />`}
      </noscript>
      </Helmet>
      <Box className={classes.root}>
        <Box className={classes.icon}>
          <VerifiedIcon className={classes.svg} />
        </Box>
        <Typography variant="h1" className={classes.title}>
        Bedankt!
        </Typography>
        <Typography variant="h2" className={classes.title}>
        Binnen enkele seconden word je automatisch doorgestuurd naar Digilo. Wij wensen je veel plezier toe bij het gebruiken van Digilo.
        </Typography>
      </Box>
    </Container>
  )
}

export default Thankyou
