import React from 'react'
import Button, { ButtonProps } from '@mui/material/Button'
import { Link } from 'react-router-dom'
import { RoutePath } from '../../util/routes'
import { ArrowBack } from '@mui/icons-material'
import { useTheme } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'

interface ProjectNavigationProps extends Partial<ButtonProps> {
  showOnMobile?: 'back' | 'dashboard' | false
  showOnDesktop?: boolean
}

const styles = {
  button: {
    alignSelf: 'center',
    px: {
      xs: 2,
      md: 6
    }
  }
}

function ProjectNavigation(props: ProjectNavigationProps) {
  const {
    showOnMobile = 'dashboard',
    showOnDesktop = true,
    ...buttonProps
  } = props
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  if (isMobile) {
    switch (showOnMobile) {
      case 'back':
        return <MobileBackButton {...buttonProps} />
      case 'dashboard':
        return <DashboardButton {...buttonProps} />
    }
  } else {
    if (showOnDesktop) {
      return <DashboardButton {...buttonProps} />
    }
  }
  return <></>
}

function DashboardButton({ ...props }: ProjectNavigationProps) {
  return (
    <Button
      {...props}
      component={Link}
      to={RoutePath.app}
      color="secondary"
      variant="contained"
      sx={{ ...styles.button, ...props.sx }}
    >
      Dashboard
    </Button>
  )
}

function MobileBackButton(props) {
  return (
    <Button
      component={Link}
      to={RoutePath.app}
      {...props}
      color="secondary"
      variant="contained"
      sx={{ ...styles.button, ...props.sx }}
    >
      <ArrowBack />
    </Button>
  )
}

export default ProjectNavigation
