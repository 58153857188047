import React, { useEffect, useState } from 'react'
import AppLayout from '../components/AppLayout'
import MainPanel from '../components/MainPanel'
import { get_text_input_options } from '../util/db'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@material-ui/core';
import { Button } from '@mui/material';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { savetextoptions } from "../util/db"
import { handledeletetextoption } from "../util/db"
import { editTextinputoptions } from "../util/db"
import requirePlan from '../hocs/requirePlan';
import { requireAuth } from '../util/auth';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    border: "transparent"
};
const AdminTextinputoptions = () => {
    return (
        <AppLayout title='Adminuser'>
            <MainPanel

                main={<AdminTextinputoptionsmain />}
            />
        </AppLayout>
    )

}
const AdminTextinputoptionsmain = () => {
    const [data, setData] = React.useState([])
    const [open, setOpen] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleEditClose = () => setEditOpen(false)
    const [age, setAge] = React.useState('');
    const [editedname, seteditedname] = React.useState('')
    const [code, setcode] = React.useState('');
    const [name, setname] = React.useState('')
    const [initialId, setinitialId] = React.useState('')
    const [initialicon, setinitialicon] = React.useState('')
    const [initialcode, setinitialcode] = React.useState('')
    const [initialname, setinitialname] = React.useState('')



    useEffect(() => {
        getdata()
    }, [])


    const getdata = async () => {
        const details = await get_text_input_options()
        setData(details)
    }


    const handleChange = (event) => {
        setAge(event.target.value);
    };



    const handleEditedicon = (event) => {

        setinitialicon(event.target.value)
    }

    const handlecode = (event) => {
        setcode(event.target.value);
    };

    const handleEditedCode = (event) => {
        setinitialcode(event.target.value)
    }

    const handlesubmit = async () => {
        const data = {
            code: code,
            icon: age,
            name: name
        }
        const submitdetails = await savetextoptions(data)
        setOpen(false);
        setcode("")
        setAge("")
        setname("")
        getdata();

    }


    const handledelete = async (id) => {
        const deletedinfo = await handledeletetextoption(id)
        getdata();

    }


    const handleEditOpen = (id, name, icon, code) => {
        setinitialId(id)
        setinitialname(name)
        setinitialicon(icon)
        setinitialcode(code)
        setEditOpen(true)
    }



    const handleEditsubmit = async () => {
        const data = {
            name: initialname,
            code: initialcode,
            icon: initialicon
        }
        const updateresponse = await editTextinputoptions(initialId, data)
        setEditOpen(false)
        getdata()
    }



    return (
        <>
            <Stack
                justifyContent="space-between"
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
                alignItems="center"
            >
                <Typography variant="h6">
                    All Options
                </Typography>
                <Button variant="contained" onClick={handleOpen}>Add</Button>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Add
                        </Typography>
                        <form action="">

                            <FormControl fullWidth sx={{ mb: 3, mt: 3 }}>
                                <InputLabel id="demo-simple-select-label" sx={{ background: "#fff" }} >code</InputLabel>

                                <Select
                                  inputProps={{ autoComplete: 'off' }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={code}
                                    label="code"
                                    onChange={(event) => { handlecode(event) }}
                                >
                                    <MenuItem value={1} >1</MenuItem>
                                    <MenuItem value={2} >2</MenuItem>
                                    <MenuItem value={3} >3</MenuItem>


                                </Select>
                            </FormControl>
                            <FormControl fullWidth sx={{ mb: 3 }}>
                                <InputLabel id="demo-simple-select-label" sx={{ background: "#fff" }} >Icon Name</InputLabel>

                                <Select
                                  inputProps={{ autoComplete: 'off' }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={age}
                                    onChange={(event) => { handleChange(event) }}

                                >
                                    {
                                        data.map((Element, index) => {
                                            return (

                                                <MenuItem key={index} value={Element.icon} >{Element.icon}</MenuItem>

                                            )
                                        })
                                    }


                                </Select>
                            </FormControl>
                            <TextField   inputProps={{ autoComplete: 'off' }} id="outlined-basic" sx={{ mb: 3 }} label="name" variant="outlined" type='text' fullWidth value={name} onChange={(e) => setname(e.target.value)} />
                            <Button variant="contained" onClick={handlesubmit} >Submit</Button>
                        </form>

                    </Box>
                </Modal>
            </Stack>
            <TableContainer component={Paper} sx={{ mt: 3 }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 700 }}>Sr.no</TableCell>
                            <TableCell sx={{ fontWeight: 700 }} align="center">Code</TableCell>
                            <TableCell sx={{ fontWeight: 700 }} align="center">icon</TableCell>
                            <TableCell sx={{ fontWeight: 700 }} align="center">Name</TableCell>
                            <TableCell sx={{ fontWeight: 700 }} align="center">Actions</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data.map((element, index) => {
                                return (
                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                        <TableCell component="th" scope="row">
                                            {index + 1}
                                        </TableCell>
                                        <TableCell align="center" component="th" scope="row">
                                            {element.code}
                                        </TableCell>
                                        <TableCell align="center" component="th" scope="row">
                                            {element.icon}
                                        </TableCell>
                                        <TableCell align="center" component="th" scope="row">
                                            {element.name}
                                        </TableCell>

                                        <TableCell align="center" component="th" scope="row">
                                            <Button variant="contained" sx={{ mr: 2 }} onClick={() => { handleEditOpen(element.id, element.name, element.icon, element.code) }}>edit</Button>
                                            <Button variant="contained" onClick={() => handledelete(element.id)}>Delete</Button>
                                        </TableCell>

                                    </TableRow>
                                )
                            })
                        }


                    </TableBody>
                </Table>
            </TableContainer>
            <Modal
                open={editOpen}
                onClose={handleEditClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Edit
                    </Typography>
                    <form action="">
                        <FormControl fullWidth sx={{ mb: 3, mt: 3 }}>
                            <InputLabel id="demo-simple-select-label" sx={{ background: "#fff" }} >code</InputLabel>

                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={initialcode}
                                label="code"
                                onChange={(event) => { handleEditedCode(event) }}
                            >
                                <MenuItem value={1} >1</MenuItem>
                                <MenuItem value={2} >2</MenuItem>
                                <MenuItem value={3} >3</MenuItem>


                            </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <InputLabel id="demo-simple-select-label" sx={{ background: "#fff" }} >Icon Name</InputLabel>

                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                defaultValue={initialicon}
                                onChange={(event) => { handleEditedicon(event) }}
                            >
                                {
                                    data.map((Element, index) => {
                                        return (

                                            <MenuItem key={index} value={Element.icon} >{Element.icon}</MenuItem>

                                        )
                                    })
                                }

                            </Select>
                        </FormControl>
                        <TextField id="outlined-basic" sx={{ mb: 3 }} label="name" variant="outlined" type='text' fullWidth defaultValue={initialname} onChange={(e) => setinitialname(e.target.value)} />
                        <Button variant="contained" onClick={handleEditsubmit} >Submit</Button>
                    </form>
                </Box>
            </Modal>
        </>
    )
}


export default requireAuth(requirePlan(AdminTextinputoptions, 'premium'))