import React,{useEffect} from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import { get_text_input_options } from '../../util/db'
import { Dispatch, SetStateAction } from "react";
import { useHistory } from 'react-router-dom';
import { save_input_data , updateUser } from '../../util/db'
import { useAuth } from "../../util/auth.js";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const styles = {
    startButton: {
        px: 5
    },
    header: {
        display: {
            xs: 'none',
            sm: 'block'
        }
    },
    modalstyle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        bgcolor: 'background.paper',
        border: 'unset',
        boxShadow: 24,
        overflow: { xs: 'scroll', lg: 'unset' },
        height: { xs: '100%', lg: "auto" },
        p: 4,
        borderRadius:'6px',

        '&:focus-visible': {
            outline: 'none'
        },
    },
    drop: {

        borderRadius: "6px",
        boxShadow: "0px 0px 0px 3px rgb(235 103 105 / 35%)",
        border: "2px solid #eb6769",
        '&:hover': {
            border: "none"
        },
    },
    headings: {
        fontSize: "1.625rem" as const,
        lineHeight: "2.5rem" as const,
        textAlign: "center" as const,
        fontWeight: "600" as const
    },
    subtitle: {
        fontSize: 12 as any,
        color: "#9095a0" as any,
        textAlign: "center" as any,
        width: '95px' as any
    },
    card_text: {
        fontSize: 14
    },
    card: {
        boxShadow: "0px 0px 5px rgba(0,0,0,15%)",
        padding: 10,
        border: "unset",
        height: "100",
      
    },
    img:{
        width:"50px",
        margin:"auto"
    },
    cmn_btn: {
        color: "#ef9834",
        borderColor: "#ef9834"
    },
    btn_outer: {
        textAlign: "center" as const,
    },
    grey_bg: {
        backgroundColor: "#f3f4f6",
        padding: 15
    },

    boximage: {
        maxWidth: "100%" as const,
        border: "2px solid #1278bc" as const,
        borderRadius: 10,
        display: "flex" as const,
        padding: 10,
        backgroundColor: "#fff" as const,
        height:100 as const,
        cursor : "pointer"

    },
    boxTitle: {
        marginBottom: 10,
        fontWeight: 600
    },
    link: {
        fontWeight: '700',
        cursor: "pointer",
        '&:hover': {
            background: "#fff",
            color:"#fff"
         },
    },
    title: {
        fontSize: 14
    },
    active: {
        boxShadow:"0px 0px 5px #1278bc"
    }


}

type menuDialogProps = {
    handleMenuDialog: () => void,
    experienceEndCode: number,
    
    setMenuDialogId: Dispatch<SetStateAction<string>>,
    setMenuDialogName: Dispatch<SetStateAction<string>>,
    setPaymentModal: Dispatch<SetStateAction<boolean>>,
    setMenuDialog: Dispatch<SetStateAction<boolean>>,
    setEnablePrice: Dispatch<SetStateAction<boolean>>,
    setCongratulation: Dispatch<SetStateAction<boolean>>,
    experienceStartId: string,
    experienceId:string,
    experienceEndId:string,
    handleMenuDialogBack: () => void
}
const MenuDialog = (props: menuDialogProps) => {
    const history = useHistory()
    const auth = useAuth();
    const [open, setOpen] = React.useState(true);
    const [disable, setDisable] = React.useState(true)
    const [disabled,setDisabled] = React.useState(false)
    const [data, setData] = React.useState([]);
    const [colvalue, setColvalue] = React.useState(null);
    const handleOpen = () => setOpen(true);
    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false)
        }
    }
    React.useEffect(() => {
        getdata()
        if (props.experienceEndCode == 5) setColvalue(5)
        if (props.experienceEndCode == 2) setColvalue(11)
        if (props.experienceEndCode == 3) setColvalue(12)
        if (props.experienceEndCode == 1) setColvalue(7)
        if (props.experienceEndCode == 4) setColvalue(7)

    }, [])
    // fetching data from text_input collection from firebase
    const getdata = async () => {
        const data = await get_text_input_options()
        setData(data)
    }

    const handleSetValues = async (id, name) => {
        setDisabled(true)
    
    const data = {
      user_id : auth?.user?.id,
      recomended_option_id : props?.experienceStartId,
      use_option_id : props?.experienceEndId,
      about_option_id : props?.experienceId,
      text_input_option_id : id
    }

    const save_data = await save_input_data(data)
    if(save_data){
      updateUser(auth?.user?.id, { info_completed : true })
    }
        {name === "Artikel" && history.push('/app/generator/article')}
        {name === "Digilo" && history.push('/app/assistance')}
        {name === "Digilo Blog" && history.push('/app/writer')}
        {name === "Digilo Fix" && history.push('/app/improver')}
        {name === "Verhaal" && history.push('/app/generator/story')}
        {name === "Vergelijking" && history.push('/app/generator/comparison')}
        {name === "Biografie" && history.push('/app/generator/biography')}
        {name === "Gedicht" && history.push('/app/generator/poem')}
        {name === "Songtekst" && history.push('/app/generator/song')}
        {name === "Dagboek" && history.push('/app/generator/journal')}
        {name === "Brief" && history.push('/app/generator/letter')}
        {name === "Boekrecensie" && history.push('/app/generator/bookReview')}
        {name === "Memo" && history.push('/app/generator/note')}
        {name === "Rapport" && history.push('/app/generator/assessment')}
        {name === "Contract" && history.push('/app/generator/contract')}
        {name === "Briefing" && history.push('/app/generator/briefing')}
        {name === "Whitepaper" && history.push('/app/generator/whitePaper')}
        {name === "Presentatie" && history.push('/app/generator/presentation')}
        {name === "E-mail" && history.push('/app/generator/email')}
        {name === "Voorstel" && history.push('/app/generator/suggestion')}
        {name === "Offerte" && history.push('/app/generator/offer')}
        {name === "Overeenkomst" && history.push('/app/generator/agreement')}
        {name === "Facebook advertentie" && history.push('/app/generator/facebookAd')}
        {name === "Google advertentie" && history.push('/app/generator/googleAd')}
        {name === "Linkedln advertentie" && history.push('/app/generator/linkedInAd')}
        {name === "Twitter tweet" && history.push('/app/generator/twitterTweet')}
        {name === "Social media bericht" && history.push('/app/generator/socialMediaReport')}
        {name === "Productbeschrijving" && history.push('/app/generator/productDescription')}
        {name === "Thesis" && history.push('/app/generator/thesis')}
        {name === "Onderzoeksverslag" && history.push('/generator/researchReport')}
        {name === "Methodology" && history.push('/app/generator/methodology')}
        {name === "Hypothese" && history.push('/app/generator/hypothesis')}
        {name === "Bibliografie" && history.push('/app/generator/bibliography')}

        if (id !== "" && name !== "") {
            setDisable(false)
        }
        props.setMenuDialogId(id);
        props.setMenuDialogName(name)
        props?.setPaymentModal(true)
        props?.setMenuDialog(false)
        props?.setEnablePrice(true)
        props?.setCongratulation(true)
        
    
    }

    const handleRedirect = async () => {
        const data = {
            user_id : auth?.user?.id,
            recomended_option_id : props.experienceStartId,
            use_option_id : props.experienceEndId,
            about_option_id : props.experienceId,
            text_input_option_id : null
          }
      
          const save_data = await save_input_data(data)
          if(save_data){
            updateUser(auth?.user?.id, { info_completed : true })
            props.setPaymentModal(true)
            props?.setCongratulation(true)
          }
          
        
    }

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                keepMounted
            >
                <Box sx={styles.modalstyle}>
                    <Button onClick = {props.handleMenuDialogBack}><ArrowBackIcon/></Button>
                    <Grid container alignItems="center" justifyContent="center">
                        <Grid item xs={12} md={12}>
                            <Typography style={styles.headings} sx={{ mb: 5 }} id="modal-modal-title" variant="h2" component="h5" >
                                Kies een tekstsoort en laat Digilo de tekst schrijven

                            </Typography>

                        </Grid>

                        <Grid item xs={12} md={colvalue}>
                            <Box style={styles.grey_bg}>
                                {props.experienceEndCode == 5 && <Typography style={styles.boxTitle}  >
                                    Digilo
                                </Typography>}
                                {props.experienceEndCode == 3 && <Typography style={styles.boxTitle}  >
                                    Formeel
                                </Typography>}
                                {props.experienceEndCode == 2 && <Typography style={styles.boxTitle}  >
                                    Informeel
                                </Typography>}
                                {props.experienceEndCode == 1 && <Typography style={styles.boxTitle}  >
                                    Commercieel
                                </Typography>}
                                {props.experienceEndCode == 4 && <Typography style={styles.boxTitle}  >
                                    Wetenschappelijk
                                </Typography>}
                                {/* <Box style={styles.flexBox} display="grid" gridTemplateColumns="repeat(10, 1fr)" gap={2}> */}



                                <Grid container  spacing={1} >
                                    {/* use map function to reflect data on the modals */}

                                    {data?.length !== 0 &&
                                        data?.map((options, index) => {
                                            if (options?.code === props.experienceEndCode) {
                                                
                                                return <Grid item key={index}  onClick={() => {handleSetValues(options?.id, options?.name)}} >
                                                    <Box>
                                                        <Box style={{ ...styles.boximage, ...(options.style == true && styles.active) }} >
                                                            {options?.icon === "chat.png" ? <img src="/img/chat.png" alt="" style={styles.img} /> : null}
                                                            {options?.icon === "artikel.png" ? <img src="/img/artikel.png" alt="" style={styles.img} /> : null}
                                                            {options?.icon === "verhaal.png" ? <img src="/img/verhaal.png" alt="" style={styles.img} /> : null}
                                                            {options?.icon === "vergelijking.png" ? <img src="/img/vergelijking.png" alt="" style={styles.img} /> : null}
                                                            {options?.icon === "biografie.png" ? <img src="/img/biografie.png" alt="" style={styles.img} /> : null}
                                                            {options?.icon === "gedicht.png" ? <img src="/img/gedicht.png" alt="" style={styles.img} /> : null}
                                                            {options?.icon === "songtekst.png" ? <img src="/img/songtekst.png" alt="" style={styles.img} /> : null}
                                                            {options?.icon === "dogboek.png" ? <img src="/img/dogboek.png" alt="" style={styles.img} /> : null}
                                                            {options?.icon === "brief.png" ? <img src="/img/brief.png" alt="" style={styles.img} /> : null}
                                                            {options?.icon === "boekrecensie.png" ? <img src="/img/boekrecensie.png" alt="" style={styles.img} /> : null}
                                                            {options?.icon === "rapport.png" ? <img src="/img/rapport.png" alt="" style={styles.img} /> : null}
                                                            {options?.icon === "contract.png" ? <img src="/img/contract.png" alt="" style={styles.img} /> : null}
                                                            {options?.icon === "whitepaper.png" ? <img src="/img/whitepaper.png" alt="" style={styles.img} /> : null}
                                                            {options?.icon === "presentatie.png" ? <img src="/img/presentatie.png" alt="" style={styles.img} /> : null}
                                                            {options?.icon === "e-mail.png" ? <img src="/img/e-mail.png" alt="" style={styles.img} /> : null}
                                                            {options?.icon === "vaarstel.png" ? <img src="/img/vaarstel.png" alt="" style={styles.img} /> : null}
                                                            {options?.icon === "offerte.png" ? <img src="/img/offerte.png" alt="" style={styles.img} /> : null}
                                                            {options?.icon === "overeenkomst.png" ? <img src="/img/overeenkomst.png" alt="" style={styles.img} /> : null}
                                                            {options?.icon === "facebook.png" ? <img src="/img/facebook.png" alt="" style={styles.img} /> : null}
                                                            {options?.icon === "google.png" ? <img src="/img/google.png" alt="" style={styles.img} /> : null}
                                                            {options?.icon === "linkedin.png" ? <img src="/img/linkedin.png" alt="" style={styles.img} /> : null}
                                                            {options?.icon === "twitter.png" ? <img src="/img/twitter.png" alt="" style={styles.img} /> : null}
                                                            {options?.icon === "social-media.png" ? <img src="/img/social-media.png" alt="" style={styles.img} /> : null}
                                                            {options?.icon === "productbeschrijving.png" ? <img src="/img/productbeschrijving.png" alt="" style={styles.img} /> : null}
                                                            {options?.icon === "thesis.png" ? <img src="/img/thesis.png" alt="" style={styles.img} /> : null}
                                                            {options?.icon === "onderzoeksverslag.png" ? <img src="/img/onderzoeksverslag.png" alt="" style={styles.img} /> : null}
                                                            {options?.icon === "methodology.png" ? <img src="/img/methodology.png" alt="" style={styles.img} /> : null}
                                                            {options?.icon === "hypothese.png" ? <img src="/img/hypothese.png" alt="" style={styles.img} /> : null}
                                                            {options?.icon === "bibliografie.png" ? <img src="/img/bibliografie.png" alt="" style={styles.img} /> : null}
                                                            {options?.icon === "memo.png" ? <img src="/img/memo.png" alt="" style={styles.img} /> : null}
                                                            {options?.icon === "tool.png" ? <img src="/img/tool.png" alt="" style={styles.img} /> : null}
                                                        </Box>
                                                        <Typography style={styles.subtitle} sx={{ mt: 1 }}  >
                                                            {options?.name}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            }

                                        })

                                    }
                                </Grid>
                                {/* </Box> */}
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={styles.link} align='center' sx={{ mb: 3, mt: 3,  '&:hover': {
       color: "#1278bc",
    }, }} onClick={handleRedirect} >
                            Of klik hier om naar de startpagina te gaan

                            </Typography>
                            <Typography style={styles.title} align='center' sx={{ mb: 3, mt: 3 }}  >
                            Staat de tekstsoort die je zoekt er niet tussen? Laat ons deze dan toevoegen.
                            </Typography>
                            <div style={styles.btn_outer}>
                                <Button variant="outlined" style={styles.cmn_btn} onClick = {() => {props.handleMenuDialog()}}>Ik wil een tekstsoort laten toevoegen</Button>
                            </div>
                            <Typography sx={{mt:2}} style={styles.card_text} id="modal-modal-description" align="center" >
                      4/4
                        </Typography>
                        </Grid>

                    </Grid>
                                            
                </Box>

            </Modal>
            
        </>
    )
}
export default MenuDialog