import React from 'react'
import { useLocation,useHistory } from '../../util/router'
import AiImprover from '../../features/ai-improver/ai-improver'
import { requireAuth } from '../../util/auth'
import requirePlan from '../../hocs/requirePlan'
import AppLayout from '../../components/AppLayout'
import { useAuth } from '../../util/auth'

function Improver() {
  const location = useLocation()
  const title = 'Digilo Fix'
  const {user} = useAuth()
  const history = useHistory()
  const [timeInMinutes,setTimeInMinutes] = React.useState(null)
  const[currenturl, setCurrenturl] = React.useState("/app");
// setInterval(() => {
//       const SignUpTime = user?.created?.seconds;
//       const timestampInMilliseconds = SignUpTime;
//       const newTimestampInMilliseconds = timestampInMilliseconds;
//       let date: any = new Date(newTimestampInMilliseconds * 1000);
//       let currentTime: any = new Date();
//       var timeDifference = currentTime - date;
//       var time_in_seconds = timeDifference / 1000;
//       var time_in_minutes = time_in_seconds / 60;
//       time_in_minutes > 30 && user?.paymentType === undefined && user?.stripeSubscriptionStatus === undefined ? history.push("/app") : null
   
// }, 1000);
React.useEffect(() => {
  let redirectFlag = false; // Flag to track whether redirection has already occurred

  const intervalId = setInterval(() => {
    const SignUpTime = user?.created?.seconds;
    const timestampInMilliseconds = SignUpTime * 1000;
    const newTimestampInMilliseconds = timestampInMilliseconds;
    let date: any = new Date(newTimestampInMilliseconds);
    let currentTime: any = new Date();
    var timeDifference = currentTime - date;
    var time_in_seconds = timeDifference / 1000;
    var time_in_minutes = time_in_seconds / 60;
    // console.log(time_in_minutes, "total time passed");
    // console.log(user?.stripeSubscriptionStatus, "user?.stripeSubscriptionStatus");
    // console.log(user?.paymentStatus, "user?.paymentStatus");

    if (
      time_in_minutes > 30 &&
      user?.paymentType === undefined &&
      user?.stripeSubscriptionStatus === undefined &&
      location.pathname !== currenturl &&
      !redirectFlag
    ) {
      redirectFlag = true;
      history.push("/app");
    }
  }, 1000);

  return () => {
    clearInterval(intervalId);
  };
}, [user, history, location.pathname, currenturl])
  // React.useEffect(() => {
  //   if(location.pathname !== '/app/digiloFixAi'){
  //     localStorage.removeItem('text')
  //   }
  // },[])
  return (
    <AppLayout title={title} isFixedHeight={true}>
      <AiImprover title={title} />
    </AppLayout>
  )
}

export default requireAuth(Improver)

// export default Improver
