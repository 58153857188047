import React from 'react'
import Container from '@mui/material/Container'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { makeStyles } from '@mui/styles'
import Section from './Section'
import SectionHeader from './SectionHeader'

const useStyles = makeStyles((theme) => ({
  accordion: {
    // Remove shadow
    boxShadow: 'none',
    '&:before': {
      // Remove default divider
      display: 'none'
    },
    // Add a custom border
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.divider}`
    }
  },
  expanded: {
    margin: '0 !important'
  },
  summary: {
    minHeight: 78
  },
  summaryContent: {
    margin: '0 !important'
  }
}))

function FaqSection(props) {
  const classes = useStyles()

  const items = [
    {
      question: 'Wat is Digilo?',
      answer:
        'Digilo is een krachtige content generator die gebruik maakt van kunstmatige intelligentie (AI) om content te creëren. AI Writer gebruikt algoritmes om originele en unieke content te maken die aansluit bij je doelgroep.'
    },
    {
      question: 'Wat zijn de voordelen van Digilo?',
      answer:
        'Digilo biedt vele voordelen, waaronder het verminderen van de tijd en moeite die nodig is om content te produceren. Diglo is ook in staat om content te maken die aansluit bij je doelgroep en verkeer naar je website te genereren.'
    },
    {
      question: 'Is Digilo eenvoudig te gebruiken?',
      answer:
        'Ja, Digilo is eenvoudig te gebruiken. Je kunt eenvoudig een onderwerp opgeven en Digilo zal de content voor je creëren. Je kunt de content daarna bewerken en aanpassen aan jouw specificaties.'
    },
    {
      question: 'Hoeveel content kan Digilo produceren?',
      answer:
        'Digilo is ontworpen om zoveel mogelijk content te produceren. Het is in staat om bijna onbeperkte hoeveelheden content te produceren in een korte tijd.'
    },
    {
      question: 'Is Digilo veilig?',
      answer:
        "Digilo is een schrijver gebaseerd op kunstmatige intelligentie die veilig is om te gebruiken. Het wordt gecontroleerd op kwaliteit en veiligheid door technische teams die ervoor zorgen dat het volgens de beste praktijken wordt gebruikt, zonder privacy- of veiligheidsrisico's."
    },
    {
      question: 'Wat zijn de kosten van Digilo?',
      answer:
        'De kosten voor Digilo hangen af van welke functies je gebruikt. Je kunt Digilo gratis proberen en daarna upgraden naar de betaalde versie om de volledige functionaliteit te gebruiken. Kijk voor meer informatie op onze website.'
    },
    {
      question: 'Wat voor soort content kan Digilo produceren?',
      answer:
        'Digilo kan content produceren voor verschillende doeleinden, zoals blogposts, artikelen, advertenties, social media-berichten, enz. Digilo is gespecialiseerd in het produceren van content die is afgestemd op je doelgroep.'
    },
    {
      question: 'Kan Digilo mijn doelgroep bereiken?',
      answer:
        'Ja, Digilo is in staat om jouw content te richten op de juiste doelgroep. Je kunt specifieke doelgroepen vermelden en Digilo zal content maken die is afgestemd op deze doelgroepen.'
    },
    {
      question: 'Is Digilo betrouwbaar?',
      answer:
        'Ja, Digilo is over het algemeen betrouwbaar. De techniek is ontwikkeld door een team van technische experts die ervoor zorgen dat de algoritmen die worden gebruikt in de meeste gevallen betrouwbaar en accuraat zijn. Digilo wordt gecontroleerd op kwaliteit om ervoor te zorgen dat de content optimaal is. Het kan voorkomen dat Digilo onbetrouwbare informatie schrijft. Dus check de geschreven content altijd na voordat je het publiceert.'
    },
    {
      question: 'Wat voor soort ondersteuning biedt Digilo?',
      answer:
        'Je kunt contact opnemen met ons team als je vragen hebt of problemen hebt met het gebruik van Digilo. We zijn hier om je te helpen bij het maken van de beste content.'
    },
    {
      question: 'Is Digilo alleen geschikt voor professionele gebruikers?',
      answer:
        'Nee, Digilo is geschikt voor zowel professionele als niet-professionele gebruikers. Digilo biedt een intuïtieve gebruikersinterface die eenvoudig te begrijpen en te gebruiken is. Je kunt Digilo gebruiken voor zowel professionele als persoonlijke doeleinden.'
    },
    {
      question: 'Wat zijn de beste manieren om Digilo te gebruiken?',
      answer:
        'De beste manier om AI Writer te gebruiken is door zoveel mogelijk onderwerpen te geven aan Digilo om content te maken. Je kunt ook jouw eigen content gebruiken als inspiratiebron voor Digilo, om zo een unieke en originele content te maken. Digilo kan ook worden gebruikt om bestaande content te herschrijven en te verbeteren.'
    }
  ]

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      disableDivider={props.disableDivider}
    >
      <Container maxWidth="md">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />

        {items.map((item, index) => (
          <Accordion
            classes={{
              root: classes.accordion,
              expanded: classes.expanded
            }}
            key={index}
          >
            <AccordionSummary
              classes={{
                root: classes.summary,
                content: classes.summaryContent
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`faq-panel-${index}`}
            >
              <Typography variant="h6">{item.question}</Typography>
            </AccordionSummary>
            <AccordionDetails id={`faq-panel-${index}`}>
              <Typography>{item.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </Section>
  )
}

export default FaqSection
