import React from 'react'

// Map our custom plan IDs ("basic", "premium", etc) to Stripe price IDs
const stripePriceIds = {
  basic: process.env.REACT_APP_STRIPE_PRICE_BASIC,
  plus: process.env.REACT_APP_STRIPE_PRICE_PLUS,
  premium: process.env.REACT_APP_STRIPE_PRICE_PREMIUM,
  payperuse: process.env.REACT_APP_STRIPE_PRICE_PAY_PER_USE,
  starter: process.env.REACT_APP_STRIPE_PRICE_STARTER,
  standard: process.env.REACT_APP_STRIPE_PRICE_STANDARD
}

// Get Stripe priceId
export function getStripePriceId(planId) {
  return stripePriceIds[planId]
}

// Get friendly plan ID ("basic", "premium", etc) by Stripe plan ID
// Used in auth.js to include planId in the user object
export function getFriendlyPlanId(stripePriceId) {
  return Object.keys(stripePriceIds).find(
    (key) => stripePriceIds[key] === stripePriceId
  )
}

export const planDetails = [
  {
    id: null,
    name: 'Free',
    price: 'Free',
    words: 3000,
    description: ``,
    perks: []
  },
  {
    id: 'basic',
    name: 'Basic',
    price: '9,95',
    words: 8000,
    description: (
      <>
        Ontketen de kracht van Digilo met Digilo Basic! Dit onmisbare pakket
        biedt alles wat je nodig hebt om jouw schrijfbehoeften te vervullen, met
        maar liefst 8000 woorden tot je beschikking. Met deze magische tool kun
        je niet alleen informele en formele teksten schrijven, maar ook
        commerciële en wetenschappelijke stukken verzorgen. En dankzij Digilo
        Chat heb je keer op keer toegang tot deskundig advies over allerlei
        onderwerpen. Alles lijkt mogelijk met de onbegrensde mogelijkheden van
        Digilo Chat. Met Digilo Basic, ben je klaar om te schrijven als nooit
        tevoren!.
      </>
    ),
    perks: [
      '8.000 Woorden',
      'Digilo Chat',
      'Memo’s',
      'Rapporten',
      'Contracten',
      'Briefing',
      'E-mail',
      'Voorstel',
      'Offerte',
      'Overeenkomst',
      'Brief',
      'Verhaal',
      'Vergelijking',
      'Biografieën',
      'Gedichten',
      'Songteksten',
      'Dagboeken',
      'Artikelen',
      'Boekrecensies',
      'Facebook advertenties',
      'Google-advertenties',
      'Persberichten',
      'LinkedIn advertenties',
      'Twitter tweets',
      'Thesissen',
      'Onderzoekverslagen',
      'Methodologieën',
      'Bibliografieën'
    ]
  },

  {
    id: 'starter',
    name: 'Starter',
    price: 'Gratis',
    words: 3000,
    subdesc:"Na 5 dagen wordt je automatisch overgezet naar het standaard abonnement",
    description: (
      <>
        Toegang tot alle functionaliteiten. Schrijf bondige inhoud zoals nieuwsbrieven, sociale media advertenties, beknopte blogartikelen en alle soorten teksten die je zelf wenst.
      </>
    ),
    perks: [
      // 'Brainstorm Buddy  - De brainstorm assistent voor het bedenken van nieuwe content-ideeën en andere vraagstukken',
        <span key="brainstorm" dangerouslySetInnerHTML={{ __html: '<strong>Brainstorm Buddy</strong>  - De brainstorm assistent voor het bedenken van nieuwe content-ideeën en andere vraagstukken' }} />,
        <span key="brainstorm" dangerouslySetInnerHTML={{ __html: '<strong>Quick Fix</strong>  - De brainstorm assistent voor het bedenken van nieuwe content-ideeën en andere vraagstukken' }} />,
        <span key="brainstorm" dangerouslySetInnerHTML={{ __html: "<strong>Content Assistent</strong>  - Creëer bijna alle type tekstsoorten, e-books of verbeter bestaande teksten met behulp van Digilo's GPT4 AI technologie" }} />,
    
      'AVG & privacybescherming',
    ]
  },
  {
    id: 'payperuse',
    name: 'Pay Per Use',
    price: '10',
    words: 50000,
    description: (
      <>
       <u>Woorden tegoed</u> : Betalen per gebruik, ideaal voor persoonlijke, onderwijs of andere variërende contentbehoeften.
      </>
    ),
    perks: [
      'Alles opties uit  Starter',
      '50.000 Woorden ',    
      <span key="brainstorm" dangerouslySetInnerHTML={{ __html: "<strong>AVG & privacybescherming</strong>" }} />,
    ]
  },

  {
    id: 'standard',
    name: 'Standard',
    price: '29',
    words: 3000,
    description: (
      <>
        Het pakket voor digitale marketeers, webshop eigenaren
        ondernemers, freelancers en content creators.
      </>
    ),
    perks: [
      'Alle opties uit Starter',
       <span key="brainstorm" dangerouslySetInnerHTML={{ __html: "<u>Onbeperkt</u> woorden" }} />,
      'Chat support',
      'AVG & privacybescherming',
    ]
  },
  {
    id: 'Enterprise',
    name: 'Enterprise',
    price: '292',
    words: 30000,
    description: (
      <>
        Content op schaal - Voor teams en bedrijven.
      </>
    ),
    perks: [
      'Pro pakket',
      '5+ tot onbeperkte gebruikers',
      'Customized Brand Voice',
      'Bedrijfsinfo gebaseerde chat assistent',
      'Onboarding op maat',
      'White Label mogelijkheden',
      'Persoonlijke Account Manager',
    ]
  },
  {
    id: 'plus',
    name: 'Plus',
    price: '25',
    words: 19000,
    description: (
      <>
        Met Digilo Plus haal je alles uit de kast! Niet alleen krijg je alle
        vertrouwde functionaliteiten van Digilo Basic, maar ook 19000 woorden.
        Hiermee schrijf je moeiteloos pakkende commerciële teksten, formele
        rapporten en informele berichten - zelfs in meerdere talen! Maar daar
        houdt het niet op. Met Digilo Fix gaat er een wereld voor je open. Met
        één druk op de knop analyseer en verbeter je jouw geschreven werk tot in
        perfectie. Ervaar nu het gemak van Digilo Plus en laat jouw creativiteit
        de vrije loop!.
      </>
    ),
    perks: [
      '19.000 Woorden',
      'Digilo Chat',
      'Digilo Fix⭐',
      'Memo’s',
      'Rapporten',
      'Contracten',
      'Briefing',
      'E-mail',
      'Voorstel',
      'Offerte',
      'Overeenkomst',
      'Brief',
      'Verhaal',
      'Vergelijking',
      'Biografieën',
      'Gedichten',
      'Songteksten',
      'Dagboeken',
      'Artikelen',
      'Boekrecensies',
      'Facebook advertenties',
      'Google-advertenties',
      'Persberichten',
      'LinkedIn advertenties',
      'Twitter tweets',
      'Thesissen',
      'Onderzoekverslagen',
      'Methodologieën',
      'Bibliografieën'
    ]
  },
  {
    id: 'premium',
    name: 'Premium',
    price: '50',
    words: 40000,
    description: (
      <>
        Ontdek Digilo Premium en laat je betoveren door de nieuwe functies die
        Digilo Premium biedt, zoals Digilo Blog en het schrijven van Whitepapers
        en presentaties. Schrijf blogs tot wel 2000 woorden en ontvang maar
        liefst 40.000 woorden. Daarnaast krijg je regelmatig toegang tot nieuwe
        en fascinerende functionaliteiten. Dus wacht niet langer en ontdek de
        onbegrensde mogelijkheden van Digilo Premium!.
      </>
    ),
    perks: [
      '40.000 Woorden',
      'Digilo Chat',
      'Digilo Fix',
      'Digilo Blog ⭐',
      'Memo’s',
      'Rapporten',
      'Contracten',
      'Briefing',
      'Whitepaper ⭐',
      'Presentatie ⭐',
      'E-mail',
      'Voorstel',
      'Offerte',
      'Overeenkomst',
      'Brief',
      'Verhaal',
      'Vergelijking',
      'Biografieën',
      'Gedichten',
      'Songteksten',
      'Dagboeken',
      'Artikelen',
      'Boekrecensies',
      'Facebook advertenties',
      'Google advertenties',
      'Persberichten',
      'LinkedIn advertenties',
      'Twitter tweets',
      'Thesissen',
      'Onderzoekverslagen',
      'Methodologieën',
      'Bibliografieën'
    ]
  }
]

export function getPlanDetails(planId: string | null) {
  return planDetails.find((x) => x.id == planId)
}
