import React from 'react'
// import PricingPage from '../../pages/pricing'
import PricingSection from '../PricingSection';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box'

const styles = {

    modalstyle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        bgcolor: 'background.paper',
        border: 'unset',
        boxShadow: 24,
        borderRadius: '6px',
        p: 1,

        overflow: { xs: 'scroll', lg: 'unset'},
        height: { xs: '100%', lg: "100%" },
        display: 'block',
        '&:focus-visible': {
            outline: 'none'
        },
    },
}

const Payment = () => {
    const [open, setOpen] = React.useState(true);
    const handleClose = () => {
        setOpen(false)
    }
    return (
        <>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                keepMounted
                disableEnforceFocus 
            >
                <Box sx={styles.modalstyle} className="pricingModal">

                    <PricingSection
                    className="pricingmodal"
                        bgColor="default"
                        size="medium"
                        bgImage=""
                        bgImageOpacity={1}
                        title="Kies een passend abonnement"
                    />
                </Box>
            </Modal>
        </>
    )

}

export default Payment