import React from 'react'
import IconButton from '@mui/material/IconButton'
import { DownloadOutlined } from '@mui/icons-material'
import { Menu, MenuItem } from '@mui/material'
import { saveAs } from 'file-saver'

interface DownloadContentButtonProps {
  name: string
  htmlElement: HTMLElement
}

function DownloadContentButton({
  htmlElement,
  name
}: DownloadContentButtonProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleSaveAsWord = () => {
    saveAsWord(name, htmlElement)
    handleClose()
  }

  const handleSaveAsPdf = () => {
    saveAsPdf(name, htmlElement)
    handleClose()
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        onClick={handleClick}
        aria-label="download content"
        title="Download Content"
      >
        <DownloadOutlined />
      </IconButton>
      <Menu
        id="download-content-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleSaveAsPdf}>Download PDF</MenuItem>
        <MenuItem onClick={handleSaveAsWord}>Download Word</MenuItem>
      </Menu>
    </>
  )
}

async function saveAsWord(name: string, htmlElement: HTMLElement) {
  import('docx').then(({ Packer, Document, Paragraph, HeadingLevel }) => {
    function buildDocContent(htmlElement: HTMLElement) {
      const text = htmlElement.textContent || ''

      switch (htmlElement.tagName) {
        case 'H1':
          return new Paragraph({
            text,
            heading: HeadingLevel.HEADING_1
          })
        case 'H2':
          return new Paragraph({
            text,
            heading: HeadingLevel.HEADING_2
          })
        case 'H3':
          return new Paragraph({
            text,
            heading: HeadingLevel.HEADING_3
          })
        case 'H4':
          return new Paragraph({
            text,
            heading: HeadingLevel.HEADING_4
          })
        case 'H5':
          return new Paragraph({
            text,
            heading: HeadingLevel.HEADING_5
          })
        case 'H6':
          return new Paragraph({
            text,
            heading: HeadingLevel.HEADING_6
          })
        case 'DIV':
          return [...htmlElement.childNodes].flatMap((child) =>
            buildDocContent(child as HTMLElement)
          )
        case 'P':
        default:
          return new Paragraph({
            text
          })
      }
    }

    const doc = new Document({
      sections: [
        {
          children: buildDocContent(htmlElement)
        }
      ]
    })

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `${name}.docx`)
    })
  })
}

function removeFontFamily(elem: HTMLElement) {
  elem.style.fontFamily = ''
  elem.childNodes.forEach((child) => {
    if (child instanceof HTMLElement) {
      removeFontFamily(child)
    }
  })
}

async function saveAsPdf(name: string, htmlElement: HTMLElement) {
  import('html-to-pdfmake').then(({ default: htmlToPdfmake }) => {
    removeFontFamily(htmlElement)
    const html = htmlToPdfmake(htmlElement.outerHTML)

    import('pdfmake/build/vfs_fonts').then((pdfFonts) =>
      import('pdfmake/build/pdfmake').then(({ default: pdfMake }) => {
        pdfMake.vfs = pdfFonts.pdfMake.vfs
        const dd = { content: html }
        pdfMake.createPdf(dd).download(`${name}.pdf`)
      })
    )
  })
}

export default DownloadContentButton
