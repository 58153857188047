import React, { useEffect } from 'react'
import WriterPanel, {
  useGenerateContent,
  WriterStepExtendedProps
} from '../components/writer-panel'
import { createDefaultWriterState } from '../ai-writer.model'
import { generateArticleApi } from '../article-generate-api'
import { CircularProgress, Stack, Typography } from '@mui/material'
import Box from '@mui/material/Box'

function GenerateStep(props: WriterStepExtendedProps) {
  const contentGeneration = useGenerateContent(() => {
    const newState = {
      ...props.writerState,
      generate: createDefaultWriterState().generate
    }
    props.setWriterState(newState)
    return generateArticleApi(newState)
  })

  useEffect(() => {
    if (contentGeneration.generated) {
      props.setWriterState({ generate: contentGeneration.generated })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentGeneration.generated])

  const panelContent = (
    <Stack spacing={3} alignItems="center" paddingY={2}>
      <Typography
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        Dit duurt ongeveer 3 minuten. (laat dit scherm openstaan totdat het artikel is geschreven)
       
      </Typography>
      {!contentGeneration.generated && (
          <CircularProgress size={20} sx={{ m: 1, flex: "0 0 auto" }} />
        )}
      <Box>
        <img src={'/img/tiny-robot.png'} alt="robot" />
      </Box>
    </Stack>
  )

  return (
    <WriterPanel
      {...props}
      {...contentGeneration}
      loadingChildren={panelContent}
      mobileDisplayState={props.writerState.generate.length ? 'right' : 'left'}
    >
      {panelContent}
    </WriterPanel>
  )
}

export default GenerateStep
