import React, { useState } from 'react'
import {
    signInWithEmailAndPassword,
    getAuth
} from 'firebase/auth'
import { useHistory, useLocation } from 'react-router-dom'
import { Button, Container, Grid, TextField } from '@material-ui/core'
import { useAuth } from '../util/auth';


const AdminLogin = () => {
    const history = useHistory()
    const getDetail = useAuth()
    console.log(getDetail,'getDetail')
    const location = useLocation()
    const { pathname } = location;
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')



    const styles = {
        container: {
            width: "440px",
            paddingTop: "84px"
        },
        header: {
            textAlign: "center"

        },
        button: {
            color: "#ffff",
            borderColor: "#ffff",
            background: "#1278BC",
            fontSize: 14,
            margin: "12px",
            width: "100%"
        },
        filed: {
            width: "100%",
            margin: "12px"
        }
    }




    const handleFromSubmit = () => {
        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;

                history.push('/app/all-users')
                window.location.reload();

                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
            });
    }

    // React.useEffect(() => {
    //     console.log(getDetail?.user?.role,"getDetails")
    //     if(getDetail?.user?.role !== 'admin' || getDetail?.user?.role === undefined){
    //         history.push('/contact')
    //     }
    // },[])


    return (
        <>

            <div className='custom_view'>
                <Container style={styles.container}>


                    <h1 style={styles.header}>Welcome Admin</h1>


                    <div>
                        <TextField style={styles.filed} id="outlined-basic" label="Email" type="email" variant="outlined" onChange={(e) => setEmail(e.target.value)} />
                    </div>

                    <div>
                        <TextField style={styles.filed} id="outlined-basic" type="password" label="Password" variant="outlined" onChange={(e) => setPassword(e.target.value)} />

                    </div>
                    <div>

                        <Button style={styles.button} variant="contained" onClick={handleFromSubmit}>Aanmelden</Button>


                    </div>

                </Container>


            </div>




            {/* <h1>Admin Login</h1>
            <input type='text' name="email" placeholder="please ente your email" onChange={(e) => setEmail(e.target.value)}/>
            <br/>
            <input type='password' name="password" placeholder="please ente your password"  onChange={(e) => setPassword(e.target.value)}/>
            <br/>
            <button type='submit' onClick={handleFromSubmit}>login</button> */}

        </>
    )
}
export default AdminLogin

