import React from 'react'
import Section from './Section'
import SectionHeader from './SectionHeader'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button';
import { useHistory } from "react-router-dom";
const styles = {
  trailSection: {
    position: "relative",
    backgroundImage:' url(/img/trail_bg.png)',
    backgroundSize:"cover",
    backgroundRepeat:"no-repeat",
    // minHeight:"270px"
    padding:"4em 1em",
    textAlign:"center"

  },
 
  btn: {
    minWidth: "312px",
    height: "52px",
    backgroundColor: "#EF9834FF",
    color: "#fff"

  },
  trialHeading: {
    fontSize: {
      xs: "22px",
      md: "28px",
      lg: "40px"
    },
    color: "#EB6769FF"
  },

  trailcontent: {
    color: "#171A1FFF",
    fontSize: "18px",
    margin: "20px 0px"
  },
 
}

function TrailContent(props) {
 let history = useHistory();

  function handleClick() {
    history.push("/auth/signup");
  }

  return (
    <>
         <Box sx={styles.trailSection}>
      
      <Box sx={styles.trailWrapper}>
        <Typography variant="h2" component="h2" sx={styles.trialHeading}>
          5 dagen gratis uitproberen
        </Typography>
        <Typography sx={styles.trailcontent}>
          En schrijf onbeperkt alle tekstsoorten die je wilt
        </Typography>
        <Button onClick={handleClick} variant="contained" sx={styles.btn}>Start Nu </Button>
      </Box>
    </Box>
    
    </>
  )
}

export default TrailContent
