import { Box } from '@mui/material'
import React, { ReactNode } from 'react'
import Grid from '@mui/material/Grid'

interface MainPanelHeaderProps {
  additional?: ReactNode
}

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  additional: {
    ml: 'auto'
  },
  avatar: {
    height: 80,
    ml: 1.25
  },
  title: {
    my: 3
  },
  additionalContainer: {
    display: 'flex',
    py: {
      xs: 1,
      sm: 2
    }
  }
}

function MainPanelHeader({ additional }: MainPanelHeaderProps) {
  return (
    <Grid sx={styles.container} spacing={2} container>
      <Grid item xs={6}></Grid>

      <Grid item xs={6} sx={styles.additionalContainer}>
        {additional && <Box sx={styles.additional}>{additional}</Box>}
      </Grid>
    </Grid>
  )
}

export default MainPanelHeader
