import { streamApiRequest } from '../../util/requests'
import { parseAnswer } from '../ai-assistance/text-generate-api'
import {
  GeneratorType,
} from '../../shared-types/generator.types'

export async function generatorApi(
  type: GeneratorType,
  values: Record<string, string>,
  callback: (message: string) => void
): Promise<void> {
  let openAnswer = ''
  await streamApiRequest(
    `generator/${type}`,
    'backend',
    'POST',
    values,
    (data) => {
      openAnswer += parseAnswer(data)
      callback(openAnswer.trim())
    }
  )
}
