import { useEffect, useRef } from 'react'

// Hook that returns previous value of state
export function usePrevious<T>(state: T, defaultValue: T) {
  const ref = useRef(defaultValue)
  useEffect(() => {
    ref.current = state
  })
  return ref.current
}

export function enumToNumberArray(
  enumObject: Record<string | number, string | number>
): number[] {
  return Object.values(enumObject).filter((v): v is number => !isNaN(Number(v)))
}

export function capitalizeFirstLetter(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}
