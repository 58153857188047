import React from 'react'
import { useTimer } from 'react-timer-hook'
import Box from '@mui/material/Box'
import { Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import { SxProps } from '@mui/system'

function CountdownTimer({
  expiryTimestamp,
  sx
}: {
  expiryTimestamp: Date
  sx?: SxProps
}) {
  const { seconds, minutes, hours } = useTimer({ expiryTimestamp })

  return (
    <Stack direction="row" sx={sx}>
      <TimerDigits count={hours} />
      <DigitSpacer />
      <TimerDigits count={minutes} />
      <DigitSpacer />
      <TimerDigits count={seconds} />
    </Stack>
  )
}

function TimerDigits({ count }: { count: number }) {
  return (
    <>
      {String(count)
        .padStart(2, '0')
        .split('')
        .map((digit, i) => {
          return (
            <Box
              key={i}
              sx={{
                borderRadius: 1,
                p: 1,
                m: 0.25,
                backgroundColor: 'text.primary',
                color: 'white'
              }}
            >
              {digit}
            </Box>
          )
        })}
    </>
  )
}

function DigitSpacer() {
  return (
    <Typography sx={{ verticalAlign: 'middle', fontSize: 24, mx: 0.5 }}>
      :
    </Typography>
  )
}

export default CountdownTimer
