import { streamApiRequest } from '../../util/requests'
import { parseAnswer } from '../ai-assistance/text-generate-api'
import { ImproverPayload } from '../../shared-types/improver.types'

export async function improverApi(
  values: ImproverPayload,
  callback: (message: string) => void
): Promise<void> {
  let openAnswer = ''
  await streamApiRequest(`improver`, 'backend', 'POST', values, (data) => {
    openAnswer += parseAnswer(data)
    callback(openAnswer.trim())
  })
}
