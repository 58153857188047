import { Editor as EditorComponent } from '@tinymce/tinymce-react'
import { Editor } from 'tinymce'
import React, { forwardRef, useEffect } from 'react'
import { SxProps } from '@mui/system'
import Box from '@mui/material/Box'

interface RichTextEditorProps {
  text: string
  sx?: SxProps
  disabled?: boolean
}

function RichTextEditor(
  { text, sx = {}, disabled }: RichTextEditorProps,
  editorRef: React.MutableRefObject<Editor>
) {
  // useEffect(() => {
  //   // const formattedtext =  `<pre class="pree" style="white-space:pre-wrap;break-word;">${text}</pre>`
  //   editorRef.current?.setContent(text)
  // }, [text])

  return (
    <Box sx={sx}>
      <style>{` 
      .tox-tinymce { border: 0;}
      .tox-toolbar__group {
        display: contents;
        padding-left: 2px;
        padding-right: 2px;
      }
}.`}</style>
  <EditorComponent
  disabled={disabled}
  tinymceScriptSrc={'/tinymce/tinymce.min.js'} 
  onInit={(evt, editor) => (editorRef.current = editor)}
  initialValue={`<pre class="pree" style="white-space:pre-wrap;font-family: 'Poppins';">${text}</pre>`}
  init={{
    statusbar: false,
    resize: false,
    branding: false,
    height: '100%',
    menubar: false,
    toolbar_mode: 'floating',
    mobile: {
      toolbar_mode: 'floating'
    },
    
    plugins: [
      'advlist',
      'autolink',
      'lists',
      'image',
      'charmap',
      'anchor',
      'searchreplace',
      'visualblocks',
      'code',
      'fullscreen',
      'insertdatetime',
      'media',
      'table',
      'preview',
      'help'
    ],
    toolbar:
      'undo redo bold italic | forecolor blocks ' +
      'alignleft | aligncenter | alignright | alignjustify ' +
      'fontfamily fontsize ' +
      'bullist numlist | outdent indent ' +
      'removeformat| help',

      content_css: 'https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap', // Add Poppins font here 
      content_style: 'body { font-family: "Poppins";font-size: 14px; }',
  }}
/>
    </Box>
  )
}

export default forwardRef(RichTextEditor)
