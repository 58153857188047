import { useRouter } from '../util/router'
import { projectPath, RoutePath } from '../util/routes'

export function useSelectedProject() {
  const {
    params: { projectId },
    push
  } = useRouter()
  return {
    projectId,
    setProjectId: (projectId: string | null) => {
      if (projectId == null) {
        push(RoutePath.app)
      } else {
        push(projectPath(projectId))
      }
    }
  }
}
