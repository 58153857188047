import React from 'react'
import Section from '../components/Section'
import Container from '@mui/material/Container'
import Meta from '../components/Meta'
import Typography from '@mui/material/Typography'

function AboutPage(props) {
  const title = 'Over Ons'
  return (
    <>
      <Meta title={title} />

      <Section>
        <Container>
          <Typography variant="h3">{title}</Typography>
          <p>
            Digilo is een technologiebedrijf dat zich richt op het leveren van
            uitgebreide AI-schrijfdiensten. Ons doel is om iedereen te helpen
            bij het maken van kwalitatief hoogwaardige content die specifiek is
            afgestemd op hun doelen en behoeften.
          </p>

          <p>
            Digilo is gevestigd in Rotterdam en is gericht op het leveren van
            kwaliteitsdiensten voor bedrijven en bedrijven van alle maten. Onze
            AI-schrijfdiensten bieden een simpele en intuïtieve
            gebruikersinterface, die ervoor zorgt dat onze gebruikers
            gemakkelijk en snel hun doelen kunnen bereiken.
          </p>

          <p>
            Digilo maakt gebruik van de Open AI chat GPT AI-technologie, die
            gebruikers helpt bij het maken van de beste content. Onze
            ontwikkelaars weten de technische details van AI, waardoor ze een
            diepgaand begrip hebben van hoe AI-schrijfdiensten kunnen worden
            gebruikt om content te produceren die aansluit op de specifieke
            behoeften van elke gebruiker.
          </p>
          <p>
            Met Digilo kunnen klanten de beste content maken die aansluit bij
            hun specifieke doelgroepen en behoeften. We bieden een scala aan
            AI-schrijfdiensten, waaronder het genereren van content, het
            aanpassen van stijl en toon, het herschrijven van bestaande content,
            het maken van content voor verschillende doelgroepen, het
            herschrijven van content voor verschillende talen en het gebruik van
            AI-technologie voor het detecteren van verschillende stijlen en
            toon.
          </p>
          <p>
            Het team van Digilo staat 24/7 klaar om klanten te helpen bij het
            maken van de meest optimale content. Onze klantenondersteuning biedt
            een hoge prioriteit, zodat gebruikers snel en gemakkelijk hun doelen
            kunnen bereiken.
          </p>
        </Container>
      </Section>
    </>
  )
}

export default AboutPage
