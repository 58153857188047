import React from 'react'
import SectionHeader from './SectionHeader'
import Section from './Section'
import { Container } from '@mui/material'
import Typography from '@mui/material/Typography'
import { Check } from '@mui/icons-material'

const styles = {
  header: {
    mt: 2
  },
  body: {
    mb: 1
  },
  checkIcon: {
    color: 'success.main',
    verticalAlign: 'middle',
    mr: 1
  }
}

function FeaturesOtherSection(props) {
  return (
    <Section>
      <Container maxWidth="md">
        <SectionHeader
          textAlign="center"
          size={4}
          title="Digilo schrijft nog veel meer verschillende tekstsoorten"
        ></SectionHeader>

        <Typography variant="h6" sx={styles.header}>
          <Check sx={styles.checkIcon} />
          Offertes
        </Typography>
        <Typography sx={styles.body}>
          Met de offerte schrijver ben je in staat om heel snel en gemakkelijk
          concepten voor offertes te creëren - zonder hulp van externe partijen.
          Deze innovatieve tool vereenvoudigt het proces om offertes te maken,
          vermindert de tijd die nodig is om een offerte af te werken en helpt
          je bovendien om meer winstgevende deals te scoren.
        </Typography>

        <Typography variant="h6" sx={styles.header}>
          <Check sx={styles.checkIcon} />
          Overeenkomsten
        </Typography>
        <Typography sx={styles.body}>
          De overeenkomst schrijver helpt je bij het maken van de perfecte
          overeenkomsten, zodat je met een gerust hart kunt afspreken met je
          klanten en partners. Je hoeft geen uren meer te besteden aan maken van
          overeenkomsten. Deze tool zorgt ervoor dat alle informatie die je
          nodig hebt exact in de juiste volgorde wordt geschreven - wat het
          eenvoudiger maakt om op basis van de overeenkomst te handelen.
        </Typography>

        <Typography variant="h6" sx={styles.header}>
          <Check sx={styles.checkIcon} />
          Vergelijkingen
        </Typography>
        <Typography sx={styles.body}>
          Met de tool die vergelijkingen schrijft, kun je op eenvoudige manier
          vergelijkingen maken. Het is een gebruiksvriendelijke tool die jouw
          werk enorm vereenvoudigt. Je kan het gebruiken met verschillende
          soorten taal en grammatica-regels. Deze tool is ideaal voor iedereen
          die kritisch vergelijkingen moet maken. Je kan het effectief inzetten
          bij allerlei soorten projecten en opdrachten.
        </Typography>

        <Typography variant="h6" sx={styles.header}>
          <Check sx={styles.checkIcon} />
          Brieven
        </Typography>
        <Typography sx={styles.body}>
          Met deze briefschrijver schrijf je eenvoudig en snel persoonlijke
          brieven. De briefschrijver helpt je bij het schrijven van korte en
          informatieve brieven die gelezen worden door mensen die echt zullen
          luisteren. Met deze briefschrijver tool worden je brieven expressief
          en met passie geschreven.
        </Typography>

        <Typography variant="h6" sx={styles.header}>
          <Check sx={styles.checkIcon} />
          Memo’s
        </Typography>
        <Typography sx={styles.body}>
          De Memo-schrijver is een handige tool die je helpt om je memo's snel
          en efficiënt te schrijven. Het heeft een gebruiksvriendelijke
          interface die je zal helpen om je memo's snel en gemakkelijk te maken.
          Het biedt ook professionele sjablonen voor het schrijven van memo's
          die je kunt gebruiken om je memo's er professioneel uit te laten zien.
        </Typography>

        <Typography variant="h6" sx={styles.header}>
          <Check sx={styles.checkIcon} />
          Rapporten
        </Typography>
        <Typography sx={styles.body}>
          De rapportenschrijver helpt je bij het creëren van zakelijke rapporten
          met een professionele style.
        </Typography>
        <Typography sx={styles.body}>
          De rapportenschrijver is je ultieme hulp bij het maken van
          professionele, zakelijke rapporten. Professioneel schrijven was nog
          nooit zo gemakkelijk. Met rapporten schrijver kun je eindeloos veel
          documenten schrijven en bewerken in de stijl die het beste bij jouw
          bedrijf past.
        </Typography>

        <Typography variant="h6" sx={styles.header}>
          <Check sx={styles.checkIcon} />
          Contracten
        </Typography>
        <Typography sx={styles.body}>
          De contracten schrijver van Digilo maakt het schrijven van contracten
          eenvoudiger dan ooit. Het is de perfecte partner voor iedereen die
          professionele contracten moet schrijven. Met de contracten schrijver
          kun je moeiteloos contracten schrijven die juridisch gezien kloppen.
        </Typography>

        <Typography variant="h6" sx={styles.header}>
          <Check sx={styles.checkIcon} />
          Briefings
        </Typography>
        <Typography sx={styles.body}>
          De briefing schrijver is een krachtig hulpmiddel om je te helpen bij
          het maken van korte informatieve teksten. Met deze tool kan je op
          eenvoudige en snelle manier informatie verzamelen, structureren en
          formuleren in beknopte stijl.
        </Typography>
      </Container>
    </Section>
  )
}

export default FeaturesOtherSection
