import {
  createDefaultWriterState,
  WriterState,
  WriterStep
} from './ai-writer.model'

export function checkNextReady(step: WriterStep, state: WriterState): boolean {
  switch (step) {
    case WriterStep.Topic:
      return !!state.topic
    case WriterStep.Title:
      return !!state.title
    case WriterStep.Description:
      return !!state.description
    case WriterStep.Outline:
      return !!state.outline.length
    case WriterStep.Generate:
      return !!state.generate.length
  }
}

export function setBackState(
  step: WriterStep,
  setWriterState: (callback: (state: WriterState) => WriterState) => void
) {
  const overwriteState = (partialState: Partial<WriterState>) =>
    setWriterState((state) => ({ ...state, ...partialState }))
  const defaultState = createDefaultWriterState()
  switch (step) {
    case WriterStep.Title:
      return overwriteState({ title: defaultState.title })
    case WriterStep.Description:
      return overwriteState({ description: defaultState.description })
    case WriterStep.Outline:
      return overwriteState({ outline: defaultState.outline })
    case WriterStep.Generate:
      return overwriteState({ generate: defaultState.generate })
  }
}
