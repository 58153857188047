import React from 'react'
import TrailContent from './TrailContent'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon'

import ListItemText from '@mui/material/ListItemText';
const styles = {
    heading: {
        fontSize: {
            xs: "22px",
            md: "28px",
            lg: "40px"
        },
        textAlign: "center",
        color: "#171A1FFF"
    },
    headingTop: {
        fontSize: {
            xs: "22px",
            md: "28px",
            lg: "40px"
        },
        textAlign: "center",
        color: "#171A1FFF",
        borderBottom:"1px solid #ddd",
        paddingTop:{
       
                xs: "1.5em",
               
         
        },
        paddingBottom:"1.5em"
    },
    whyChooseHead: {
        fontSize: {
            xs: "22px",
            md: "28px",
            lg: "40px"
        },
        textAlign: "center",
        color: "#171A1FFF",
       
    },
    text: {
        fontSize: {
            xs: "14px",
            md: "22px",
            lg: "32px"
        },
        textAlign: "center",
        marginBottom: {
            xs: "30px",
            md: "40px",
            lg: "60px"
        },
        marginTop: "15px"
    },
    smallText: {
        fontSize: {
            xs: "14px",
            md: "18px",
            lg: "20px"
        },
        fontWeight: "600",
        color: "#323842FF"
    },
    whyChooseWrapper: {
        padding: {
            xs: "3em 1em",
            md: "2em",
            lg: "3em 2em"
        }
    },

    card: {
        background: ' #FFFFFFFF', /* white */
        borderRadius: "6px",/* border-m */
        borderWidth: " 5px",
        borderColor: "#BCC1CAFF", /* neutral-400 */
        borderStyle: "solid",
        padding: "30px 20px",
        height: "100%",
        width: "100%"
    },
    btn: {
        color: "#fff",
        backgroundColor: "#45A7E8FF",
        marginBottom: {
            xs: "20px",
            sm: "25px",
            md: "35px",
        },
        minWidth: "155px"
    },
    listItem: {
        paddingTop: 0.10,
        paddingBottom: 0.10,
        alignItems: "flex-start",
        columnGap: "15px"
    },
    lists: {
        span: {
            fontSize: "16px"
        }
    },
    perkIcon: {
        minWidth: 40,
        color: 'success.main',
        paddingTop: "10px"
    },
    listsHeading: {
        span: {
            fontSize: {
                xs: "24px",
                md: "28px",
                lg: "32px"
            },
            fontWeight: "bold"
        }

    },
    listSubHeading: {

        span: {
            fontSize: "18px",
            fontWeight: " 600",
            color: " #565E6CFF"/* neutral-600 */
        }
    },
    cards:{
        padding:{
            md:"15px",
            lg:"30px",
            xs:"15px",
        },
        boxShadow:"0px 0px 5px rgba(0,0,0,20%)",
        minHeight:"93%",
        margin: {
            lg:"60px 0px",
            md:"60px 15px",
            xs:"40px 15px"
        }
    },
    seperate:{
        height:"100%",
        width:"1px",
        background:"#ddd",
        margin:"auto"
    }

}

function WhyChoose(props) {

    return (

        <>
           <TrailContent/>
         <Box sx={styles.whyChooseWrapper}>
        
        <Typography variant="h2" component="h2" sx={styles.whyChooseHead}>
                    Waarom Digilo?
                </Typography>
                <Typography sx={styles.text}>
                    Digilo schrijft met <b> gebruiksvriendelijke tools</b> binnen seconden automatisch teksten en <b>bespaart</b> daarmee aanzienlijk op kosten!
                </Typography>
                <Typography sx={styles.smallText}>
                    Dit zijn de krachtige innovatie tools van Digilo:
                </Typography>
    
            <Grid container spacing={2} sx={{ marginTop: "15px" }}>
                <Grid item lg={3} md={6} sm={6}>
                    <Box sx={styles.card}>

                        <Box sx={{ textAlign: "center" }}>
                            <Button variant="contained" sx={styles.btn}>Brainstorm Buddy</Button>
                        </Box>
                        <List>
                            <ListItem disablePadding sx={{ display: 'block', marginBottom: "15px", listStyle: "inside" }}>
                                Brainstormbuddy bedenkt nieuwe ideeën voor content
                            </ListItem>
                            <ListItem disablePadding sx={{ display: 'block', marginBottom: "15px", listStyle: "inside" }}>
                                Brainstormt over oplossingen en vraagstukken over elk onderwerp
                                Helpt bij het ontwikkellen van nieuwe content strategieën.
                            </ListItem>
                            <ListItem disablePadding sx={{ display: 'block', marginBottom: "15px", listStyle: "inside" }}>
                                Schrijft diverse soorten korte teksten en kopieert deze naar <b>quick fix</b> of <b>content assistent</b> om ze te optimaliseren, personaliseren en om te zetten naar volledige <u>e-books</u> en andere digitale publicaties.
                            </ListItem>


                        </List>
                    </Box>

                </Grid>
                <Grid item lg={3} md={6} sm={6}>
                    <Box sx={styles.card}>
                        <Box sx={{ textAlign: "center" }}>
                            <Button variant="contained" sx={styles.btn}>Quik fix</Button>
                        </Box>
                        <List>
                            <ListItem disablePadding sx={{ display: 'block', marginBottom: "15px", listStyle: "inside" }}>
                                Verbetert snel je spelling en grammatica.
                            </ListItem>
                            <ListItem disablePadding sx={{ display: 'block', marginBottom: "15px", listStyle: "inside" }}>
                                Herschrijft teksten in de ik, wij, jij vorm.
                            </ListItem>
                            <ListItem disablePadding sx={{ display: 'block', marginBottom: "15px", listStyle: "inside" }}>
                                Herschrijft teksten in verschillende stijlen, zoals technisch, journalistisch en nog veel meer.

                            </ListItem>
                            <ListItem disablePadding sx={{ display: 'block', marginBottom: "15px", listStyle: "inside" }}>
                                Vereenvoudigt snel teksten door ze beknopter te maken.

                            </ListItem>
                            <ListItem disablePadding sx={{ display: 'block', marginBottom: "15px", listStyle: "inside" }}>
                                Legt complexe teksten binnen een paar seconden uit.

                            </ListItem>
                            <ListItem disablePadding sx={{ display: 'block', marginBottom: "15px", listStyle: "inside" }}>
                                Verbeterd je schrijfstijl door advies te geven.

                            </ListItem>


                        </List>
                    </Box>

                </Grid>
                <Grid item lg={3} md={6} sm={6}>
                    <Box sx={styles.card}>
                        <Box sx={{ textAlign: "center" }}>
                            <Button variant="contained" sx={styles.btn}>Content Assitent</Button>
                        </Box>
                        <List>
                            <ListItem disablePadding sx={{ display: 'block', marginBottom: "15px", listStyle: "inside" }}>
                                Met de <b> content assistent</b> kun je vrijwel alle soorten teksten creëren en deze nauwkeurig op maat maken
                            </ListItem>
                            <ListItem disablePadding sx={{ display: 'block', marginBottom: "15px", listStyle: "inside" }}>
                                Je bepaalt zelf de inhoud van de content, waardoor je snel een tekst met jouw persoonlijke boodschap kunt creëren.
                            </ListItem>
                            <ListItem disablePadding sx={{ display: 'block', marginBottom: "15px", listStyle: "inside" }}>
                                Je kunt teksten verbeteren in de "smart editor" van de content assistent, die je in staat stelt om met behulp van AI-technologie woorden, zinnen en paragrafen te verlengen, in te korten of van toon te wijzigen.
                            </ListItem>
                            <ListItem disablePadding sx={{ display: 'block', marginBottom: "15px", listStyle: "inside" }}>
                                Met de content assistent schrijf je volledige artikelen, blogs en e-books op basis van een enkel woord, zin, alinea of zelfs een hele tekst.
                            </ListItem>
                        </List>
                    </Box>

                </Grid>
                <Grid item lg={3} md={6} sm={6}>
                    <Box sx={styles.card}>
                        <Box sx={{ textAlign: "center" }}>
                            <Button variant="contained" sx={styles.btn}>Templates</Button>
                        </Box>
                        <List>
                            <ListItem disablePadding sx={{ display: 'block', marginBottom: "15px", listStyle: "inside" }}>
                                Met de schrijftemplates hoef je zelf niet na te denken over de inhoud en schrijf je korte:
                            </ListItem>
                            <Typography>
                                -Memo’s <br />
                                -Rapporten <br />
                                -Contracten <br />
                                -Whitepaper <br />
                                -Presentaties <br />
                                -E-mail <br />
                                -Voorstel <br />
                                -Offerte <br />
                                -Overeenkomsten/contracten <br />
                                -Boekrecensies <br />
                                -Facebook advertenties <br />
                                -Google advertenties <br />
                                -Persberichten <br />
                                -LinkedIn advertenties <br />
                                -Onderzoekverslagen <br />
                                -Methodologieën <br />
                                -Bibliografieën <br />
                                -En nog veel meer teksttypes <br />
                            </Typography>
                        </List>
                    </Box>

                </Grid>

            </Grid>
        </Box>
            
        </>
    )
}

export default WhyChoose
