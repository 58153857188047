import React from 'react'
import SettingsSection from '../../components/SettingsSection.js'
import { useRouter } from '../../util/router.js'
import { requireAuth } from '../../util/auth.js'
import AppLayout from '../../components/AppLayout'

function SettingsPage(props) {
  const router = useRouter()

  return (
    <AppLayout title="Instellingen">
      <SettingsSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        section={router.query.section}
        key={router.query.section}
      />
    </AppLayout>
  )
}

export default requireAuth(SettingsPage)
