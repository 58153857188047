import React from 'react'
import Typography from '@mui/material/Typography'
import { useAuth } from '../../util/auth'
import { usePaginationState } from '../../hooks/usePaginationState'
import { Stack } from '@mui/material'
import ItemList from './ItemList'
import {
  createContent,
  deleteContent,
  updateContent,
  useContentsByProject,
  useCountContents
} from './content-api'
import CopyToClipboardButton from '../../components/CopyToClipboardButton'
import { ContentItem } from '../../shared-types/db-types'
import DownloadContentButton from '../../components/DownloadContentButton'
import ServiceTiles from './ServiceTiles'
import { useProject } from './projects-api'
import { htmlToElement } from '../../util/dom'

interface ProjectContentProps {
  selectedProject: string
}

const AdditionalButtons = ({ item }: { item: ContentItem }) => {
  const itemElement = htmlToElement(item.text)
  const plainText = itemElement?.textContent || ''
  return (
    <>
      <CopyToClipboardButton text={plainText} formattedText={item.text} />
      <DownloadContentButton htmlElement={itemElement} name={item.name} />
    </>
  )
}

function ProjectContent({ selectedProject }: ProjectContentProps) {
  const { user } = useAuth()
  const { data: contentsCount, invalidateCount } = useCountContents(
    selectedProject,
    user.id
  )
  const { data: project } = useProject(selectedProject)
  const paginationState = usePaginationState(contentsCount || 0)
  const { data: projects } = useContentsByProject(
    selectedProject,
    user.id,
    paginationState.rowsPerPage,
    paginationState.page
  )

  return (
    <Stack>
      <ServiceTiles projectId={selectedProject} />
      <Typography variant="h5" mt={2}>
        {project?.name ? `${project.name} Content` : 'Content'}
      </Typography>
      <ItemList
        emptyPlaceholder={
          <span>
            Je hebt nog geen teksten opgeslagen. Kies een tekstsoort uit de{' '}
            <b>Bibliotheek</b> en laat Digilo de tekst schrijven. Sla het op in
            je project via het dropdown menu.
          </span>
        }
        invalidateCount={invalidateCount}
        items={projects}
        onItemClick={() => undefined}
        onItemDelete={(item) => deleteContent(item.id, selectedProject)}
        paginationState={paginationState}
        onItemCreate={(content) => createContent(selectedProject, content)}
        onItemUpdate={(contentId, content) =>
          updateContent(contentId, selectedProject, content)
        }
        AdditionalButtons={AdditionalButtons}
      ></ItemList>
    </Stack>
  )
}

export default ProjectContent
