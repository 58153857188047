import React from 'react'
import { requireAuth } from '../../util/auth.js'
import AiGenerator from '../../features/ai-generator/ai-generator'
import { useRouter } from '../../util/router'
import {
  GeneratorConfig,
  generatorConfigs,
  GeneratorType
} from '../../shared-types/generator.types'
import requirePlan from '../../hocs/requirePlan'
import AppLayout from '../../components/AppLayout'

function WriterPage() {
  const {
    params: { type }
  } = useRouter()
  const generatorConfig = generatorConfigs[
    type as GeneratorType
  ] as GeneratorConfig

  // use key to clear and reinit component when the type changes --> prevent formik problems
  // https://stackoverflow.com/a/21750576
  return (
    <AppLayout title={generatorConfig.name} isFixedHeight={true}>
      <AiGenerator
        generatorType={type}
        generatorConfig={generatorConfig}
        key={type}
      />
    </AppLayout>
  )
}

const premiumTypes: GeneratorType[] = ['whitePaper', 'presentation']

export default requireAuth(
  requirePlan(WriterPage, 'premium', {
    type: premiumTypes
  })
)
