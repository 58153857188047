import React from 'react'
import WriterPanel, {
  useGenerateContent,
  WriterStepExtendedProps
} from '../components/writer-panel'
import { createDefaultWriterState } from '../ai-writer.model'
import { generateOutlineApi } from '../article-generate-api'
import WriterSelection from '../components/writer-selection'

function OutlineStep(props: WriterStepExtendedProps) {
  const contentGeneration = useGenerateContent(() => {
    props.setWriterState({ outline: createDefaultWriterState().outline })
    return generateOutlineApi(props.writerState)
  })

  return (
    <WriterPanel {...props} {...contentGeneration}>
      <WriterSelection
        displayValue={(values) => (
          <ul>
            {values.map((value, i) => (
              <li key={i}>{value}</li>
            ))}
          </ul>
        )}
        onSelection={(outline) => props.setWriterState({ outline })}
        values={contentGeneration.generated}
      />
    </WriterPanel>
  )
}

export default OutlineStep
