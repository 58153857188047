import { useQuery } from 'react-query'
import {
  addDoc,
  collection,
  doc,
  getCountFromServer,
  getDoc,
  limit,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where
} from 'firebase/firestore'
import { createQuery, db, format, useQueryPagination } from '../../util/db'
import { useState } from 'react'
import { UseQueryResult } from 'react-query/types/react/types'
import { ProjectItem } from '../../shared-types/db-types'
import { apiRequest } from '../../util/requests'

export function useProject(id: string): UseQueryResult<ProjectItem> {
  return useQuery(
    ['project', { id }],
    createQuery(() => doc(db, 'projects', id)),
    { enabled: !!id }
  )
}

export function useProjectOnce(id) {
  return useQuery(
    ['project', { id }],
    // When fetching once there is no need to use `createQuery` to setup a subscription
    // Just fetch normally using `getDoc` so that we return a promise
    () => getDoc(doc(db, 'projects', id)).then(format),
    { enabled: !!id }
  )
}

function projectsByOwnerQuery(owner: string) {
  return [collection(db, 'projects'), where('owner', '==', owner)] as const
}

export function useProjectsByOwner(
  owner: string,
  pageSize: number,
  page: number
) {
  const { pageQuery, updateQueryResult } = useQueryPagination<ProjectItem>(
    page,
    (doc) => doc.createdAt
  )
  const queryResult = useQuery(
    ['projects', { owner, pageSize, pageQuery }],
    createQuery(() =>
      query(
        ...projectsByOwnerQuery(owner),
        orderBy('createdAt', 'desc'),
        ...pageQuery,
        limit(pageSize)
      )
    ),
    { enabled: !!owner }
  )
  return updateQueryResult(queryResult as UseQueryResult<ProjectItem[]>)
}

export function createProject(data) {
  return addDoc(collection(db, 'projects'), {
    ...data,
    createdAt: serverTimestamp()
  })
}

export function updateProject(id, data) {
  return updateDoc(doc(db, 'projects', id), data)
}

export async function deleteProject(id: string) {
  return apiRequest('recursiveDelete', 'functions', 'POST', {
    path: `projects/${id}`
  })
}

export function useCountProjects(owner: string) {
  const [invalidateCount, setInvalidateCount] = useState<number | null>(null)
  return {
    ...useQuery(
      ['projectsCount', { owner, invalidateCount }],
      async () => {
        const query_ = query(...projectsByOwnerQuery(owner))
        const snapshot = await getCountFromServer(query_)
        return snapshot.data().count
      },
      { enabled: !!owner, keepPreviousData: true }
    ),
    invalidateCount: () => setInvalidateCount(Date.now())
  }
}
