import React from 'react'
import { Link } from '../util/router'
import { RoutePath } from '../util/routes'
import { useDarkMode } from '../util/theme'
import { makeStyles } from '@mui/styles'
import Box from '@mui/material/Box'
import { SxProps } from '@mui/system'

const logoSrc = {
  default: '/img/digilo-logo.png',
  inverted: '/img/digilo-logo-inverted.png'
}

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 48
  },
  logoLink: {
    alignSelf: 'stretch',
    display: 'flex',
    alignItems: 'center'
  }
}))

interface LogoProps {
  link?: string | false
  sx?: SxProps
}

function Logo({ link, sx }: LogoProps) {
  const darkMode = useDarkMode()
  const logo = darkMode.value ? logoSrc.inverted : logoSrc.default
  const classes = useStyles()

  return (
    <Box
      sx={sx}
      component={link !== false ? Link : 'div'}
      to={link || RoutePath.root}
      className={classes.logoLink}
    >
      <img src={logo} alt="Logo" className={classes.logo} />
    </Box>
  )
}

export default Logo
