import React, { ReactNode, useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import { WriterState } from '../ai-writer.model'
import { CardContent, CircularProgress, useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import WriterRetryButton from './writer-retry-button'
import MainPanel from '../../../components/MainPanel'
import WriterResult from './writer-result'
import useMediaQuery from '@mui/material/useMediaQuery'

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  header: {
    mx: 2,
    my: 1,
    textAlign: 'center'
  },
  actionRow: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    position: 'relative'
  },
  actionChildren: {
    display: 'flex',
    position: 'absolute',
    right: 0
  },
  container: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  }
}

interface WriterStepProps {
  title: string
  header: ReactNode
  navigationButtonRow: ReactNode
  writerState: WriterState
}

interface WriterStepOnlyProps<T> extends Partial<GenerateContent<T>> {
  children: ReactNode
  actionChildren?: ReactNode
  loadingChildren?: ReactNode
  mobileDisplayState?: 'left' | 'right'
}

export interface WriterStepExtendedProps extends WriterStepProps {
  setWriterState: (partialState: Partial<WriterState>) => void
}

function WriterPanel<T>({
  title,
  children,
  generated,
  retry,
  actionChildren,
  loadingChildren,
  navigationButtonRow,
  header,
  writerState,
  mobileDisplayState = 'left'
}: WriterStepProps & WriterStepOnlyProps<T>) {
  const { breakpoints } = useTheme()
  const isMobile = useMediaQuery(breakpoints.down('md'))
  const isRightPanelActive = mobileDisplayState === 'right'
  const isRetryButtonActive = retry && generated
  return (
    <MainPanel
      mobileDisplayState={mobileDisplayState}
      header={header}
      mainLeft={
        <CardContent sx={styles.cardContent}>
          <Box sx={styles.root}>
            <Typography variant="h4" sx={styles.header}>
              {title}
            </Typography>

            {retry && !generated ? (
              loadingChildren ?? (
                <Box sx={styles.container}>
                  {/* <CircularProgress /> */}
                  <img src="/img/tiny-robot.png" className="roboat" alt="" />
                </Box> 
              )
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  overflow: 'auto',
                  width: '100%',
                  flex: '1 1 0',
                  flexDirection: 'column'
                }}
              >
                {children}
                {isRetryButtonActive && !isRightPanelActive && (
                  <WriterRetryButton retry={retry} />
                )}
              </Box>
            )}

            <Box sx={styles.actionRow}>
              {actionChildren && (
                <Box sx={styles.actionChildren}>{actionChildren}</Box>
              )}
            </Box>
          </Box>
          {(!isRightPanelActive || !isMobile) && navigationButtonRow}
        </CardContent>
      }
      mainRight={
        <CardContent sx={styles.cardContent}>
          <WriterResult writerState={writerState} />
          {isRetryButtonActive && isRightPanelActive && (
            <WriterRetryButton retry={retry} sx={{ mt: 1 }} />
          )}
          {isRightPanelActive && isMobile && navigationButtonRow}
        </CardContent>
      }
    />
  )
}

interface GenerateContent<T> {
  retry: () => void
  generated: T
}

export function useGenerateContent<T>(
  apiCall: () => Promise<T>
): GenerateContent<T> {
  const [generated, setGenerated] = useState<T | undefined>()

  useEffect(() => {
    generate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const generate = async () => {
    setGenerated(undefined)
    const result = await apiCall()
    // console.log(result,"resultresult")
    setGenerated(result)
  }

  return {
    generated,
    retry: generate
  }
}

export default WriterPanel
