import React, { useEffect, useState } from 'react'
import { get_about_options } from '../util/db'
import AppLayout from '../components/AppLayout'
import MainPanel from '../components/MainPanel'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@material-ui/core';
import { Button } from '@mui/material';
import Stack from '@mui/material/Stack';
import { handledeleteuser } from '../util/db'
import { save_details } from '../util/db';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { updateaboutoptions } from '../util/db'
// import requirePlan from '../../hocs/requirePlan'
import requirePlan from '../hocs/requirePlan';
import { requireAuth } from '../util/auth';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    border: "transparent",
    borderRadius: "8px"
};
const AboutOptions = () => {
    return (
        <AppLayout title='Adminuser'>
            <MainPanel

                main={<AboutOptionsMain />}
            />
        </AppLayout>
    )
}
const AboutOptionsMain = () => {
    const [open, setOpen] = React.useState(false);
    const [editopen, setEditOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [initialId, setInitialID] = React.useState("")
    const [initialName, setInitialName] = React.useState("")
    const handleCloseedit = () => setEditOpen(false);
    const [data, setData] = React.useState([])
    const [newData, setnewData] = React.useState('')
    const [updateddata, setupdateddata] = React.useState('')

    useEffect(() => {
        getdata()
    }, [])


    const getdata = async () => {
        const getaboutoptions = await get_about_options();

        setData(getaboutoptions)

    }

    const handledelete = async (id) => {

        let deleteinfo = await handledeleteuser(id);

        getdata()
    }




    const handlesubmit = async () => {

        const data = {
            name: newData
        }
        const submitdetails = await save_details(data)
        setOpen(false);
        setnewData('')
        getdata();
    }


    // Edit query
    const handleedit = async () => {

        const data = {
            name: initialName
        }
        const updatedname = await updateaboutoptions(initialId, data)

        setEditOpen(false);
        getdata()
    }
    // update query
    const handleupdate = (id, name) => {
        setInitialID(id)
        setInitialName(name)
        setEditOpen(true);
    }


    return (
        <>
            <Stack
                justifyContent="space-between"
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
                alignItems="center"
            >
                <Typography variant="h6">
                    All Options
                </Typography>
                <Button variant="contained" onClick={handleOpen}>Add</Button>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Add Data
                        </Typography>

                        <form action="" >

                            <TextField id="outlined-basic" sx={{ my: 3 }} label="Name" variant="outlined" type='text' fullWidth onChange={(e) => setnewData(e.target.value)} />

                            <Button variant="contained" onClick={handlesubmit} >Submit</Button>
                        </form>
                    </Box>
                </Modal>
            </Stack>
            <TableContainer component={Paper} sx={{ mt: 3 }}>
                <Table sx={{ minWidth: 650 }} aria-label="table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 700 }} >Sr.no</TableCell>
                            <TableCell sx={{ fontWeight: 700 }} align="center">Name</TableCell>
                            <TableCell sx={{ fontWeight: 700 }} align="center">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data.map((element, index) => {
                                return (
                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell>
                                            {index + 1}
                                        </TableCell>
                                        <TableCell align="center">
                                            {element.name}
                                        </TableCell>


                                        <TableCell align="center">
                                            <Button variant="contained" sx={{ mr: 2 }} onClick={() => handleupdate(element.id, element.name)}>Edit</Button>

                                            <Button variant="contained" onClick={() => handledelete(element.id)} >Delete</Button>
                                        </TableCell>

                                    </TableRow>
                                )

                            })
                        }


                    </TableBody>
                </Table>
            </TableContainer>
            <Modal
                open={editopen}
                onClose={handleCloseedit}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        edit
                    </Typography>

                    <form action="">

                        <TextField defaultValue={initialName} id="outlined-basic" sx={{ my: 3 }} label="Name" variant="outlined" type='text' fullWidth onChange={(e) => setInitialName(e.target.value)} />

                        <Button variant="contained" onClick={handleedit}>Submit</Button>
                    </form>
                </Box>
            </Modal>
        </>
    )
}


export default requireAuth(requirePlan(AboutOptions, 'premium'))