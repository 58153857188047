import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import AuthForm from './AuthForm'
import AuthSocial from './AuthSocial'
import { useRouter } from '../util/router'
import { useHistory } from '../util/router'

function Auth(props) {
  const history = useHistory()
  // console.log(props,"ndfnvjnfvnfndfnsn")
  const router = useRouter()
  const [formAlert, setFormAlert] = useState(null)

  const handleAuth = (user) => {
    // router.push(props.afterAuthPath)
    if(props?.type === "signup"){
      history.push("/app/dashboard")
    }
    else if(props?.type === "signin"){
      history.push('/app/assistance')
    }
  }

  const handleFormAlert = (data) => {
    setFormAlert(data)
  }

  return (
    <>
      {formAlert && (
        <Box mb={3}>
          <Alert severity={formAlert.type}>{formAlert.message}</Alert>
        </Box>
      )}

      <AuthForm
        type={props.type}
        buttonAction={props.buttonAction}
        onAuth={handleAuth}
        onFormAlert={handleFormAlert}
      />

      {['signup', 'signin'].includes(props.type) && (
        <>
          {props.providers && props.providers.length && (
            <>
              <Box textAlign="center" fontSize={12} my={2}>
                OF
              </Box>
              <AuthSocial
                buttonAction={props.buttonAction}
                providers={props.providers}
                showLastUsed
                onAuth={handleAuth}
                onError={(message) => {
                  handleFormAlert({
                    type: 'error',
                    message
                  })
                }}
              />
            </>
          )}
        </>
      )}
    </>
  )
}

export default Auth
