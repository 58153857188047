import useLocalStorageState from 'use-local-storage-state'

export const useDarkModeStorage = () => {
  return useLocalStorageState<boolean>('isDarkMode', {
    defaultValue: false
  })
}

export const useHasDismissedNewUserDiscountAlert = () => {
  return useLocalStorageState<boolean>('hasDismissedNewUserDiscountAlert', {
    defaultValue: false
  })
}
