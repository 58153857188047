import React from 'react'
import Meta from '../components/Meta'
import AuthSection from '../components/AuthSection'
import { useRouter } from '../util/router'
import { RoutePath } from '../util/routes'

function AuthPage() {
  const router = useRouter()

  return (
    <>
      <Meta title="Auth" />
      <AuthSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        type={router.query.type}
        providers={['google']}
        afterAuthPath={router.query.next || RoutePath.dashboard}
      />
    </>
  )
}

export default AuthPage
