import Analytics from 'analytics'
import googleAnalyticsPlugin from '@analytics/google-analytics'
import { history } from './router.js'
import googleTagManager from '@analytics/google-tag-manager';
// Initialize analytics and plugins
// Documentation: https://getanalytics.io
const analytics = Analytics({
  debug: process.env.NODE_ENV !== 'production',
  plugins: [
    googleAnalyticsPlugin({
      trackingId: process.env.REACT_APP_GA_TRACKING_ID
    }),
    googleTagManager({
      containerId: 'GTM-NJNKLLJ',
      dataLayerName: 'customDataLayer',
    })
  ]
})
// Function to track conversion event
const trackConversion = () => {
  analytics.track('conversion', {
    send_to: 'AW-11037615715/cchvCNvbpJsZEOPMko8p',
    // transaction_id: '' // Your transaction ID here if applicable
  });
};
// Track initial pageview     
if (typeof window !== 'undefined') {
  analytics.page() 
  trackConversion();
}

// Track pageview on route change
history.listen(() => {
  analytics.page()
  trackConversion();
})


// // Call the function to track conversion event


export default analytics
