import React, { useState, useEffect } from 'react';
import { Container, Box, Grid, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Meta from '../components/Meta';
import { makeStyles } from '@mui/styles';
import { get_selected_blog, get_all_blogs } from '../util/db';
import { useParams, useHistory } from '../util/router';

const useStyles = makeStyles({
  root: {
    paddingBottom: '70px',
    paddingTop: '40px',
  },
  wordWrapper: {
    borderRadius: 6,
    marginBottom: 50,
    boxShadow: '0 0 5px rgba(0,0,0,20%)',
  },
  title: {
    fontWeight: 600,
    fontSize: 26,
    marginBottom: 25,
  },
  paragraph: {
    color: '#718096',
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 2,
    marginTop: 10,
    marginBottom: 10,
  },
  contentWrapper: {
    padding: 20,
  },
  blogtext: {
    '& img': {
      width: '100%',
    },
  },
});

const BlogTemplate = ({ data: initialData, blogData: initialBlogData }) => {
  const { title } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const [data, setData] = useState(initialData);
  const [blogData, setBlogData] = useState(initialBlogData);
  const [loading, setLoading] = useState(!initialData || !initialBlogData);

  useEffect(() => {
    if (!initialData || !initialBlogData) {
      const fetchData = async () => {
        setLoading(true);
        try {
          const [fetchedData, fetchedBlogData] = await Promise.all([
            get_selected_blog(title),
            get_all_blogs(),
          ]);
          setData(fetchedData);
          setBlogData(fetchedBlogData);
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    }
  }, [title, initialData, initialBlogData]);

  const randomNumber = Math.floor(Math.random() * 10) + 1;

  const convertSecondsToDate = (seconds) => {
    const date = new Date(seconds * 1000);
    return date.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
  };

  if (loading) {
    return (
      <Box className="loader_table">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Meta
        title={data.url}
        description={data.meta_desc}
        keywords={data.meta_keyword}
        image={data.image}
        domain=""
      />

      <Container>
        <Box className={classes.root}>
          <Grid container spacing={3}>
            <Grid item sm={12} md={9}>
              <Box className={classes.wordWrapper}>
                <Box>
                  <img className="blog_main_image" src={data.image} alt={data.title} />
                </Box>
                <Box className={classes.contentWrapper}>
                  <Typography variant="h4" className={classes.title}>
                    {data.title}
                  </Typography>
                  <Box dangerouslySetInnerHTML={{ __html: data.description }} className={classes.blogtext} />
                </Box>
              </Box>
            </Grid>
            <Grid item sm={3} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
              <Box className="latest_blog">
                <Typography variant="h5" className={classes.title}>
                  Recentste Artikel
                </Typography>
                <ul>
                  {blogData.slice(randomNumber, randomNumber + 4).map((blog, i) => (
                    <li key={i} onClick={() => history.push(`/digiloblog/${blog.url}`)}>
                      {blog.title}
                    </li>
                  ))}
                </ul>
              </Box>
            </Grid>
          </Grid>

          <Typography variant="h5" className={`cmn_heading ${classes.title}`}>
            Related Post
          </Typography>

          <Grid container spacing={2}>
            {blogData.slice(randomNumber, randomNumber + 4).map((blog, i) => (
              <Grid
                item
                sm={6}
                md={3}
                xs={12}
                key={i}
                onClick={() => history.push(`/digiloblog/${blog.url}`)}
              >
                <Box className="latest_wrapper">
                  <img src={blog.image || '/img/blogone.png'} alt={blog.title} />
                  <Box className="content">
                    <Typography variant="subtitle1">{blog.title}</Typography>
                    <Box className="time">
                      <img src="/img/clock_png.png" className="clock_icon" alt="" />
                      <span>{convertSecondsToDate(blog.timeStamp.seconds)}</span>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default BlogTemplate;
