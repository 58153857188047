import React from 'react'
import { useAuth } from '../util/auth'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'

function needsCredits<T extends { disabled?: boolean }>(
  Component: React.ComponentType<T>
) {
  const ComponentNeedsCredit = (props: T) => {
    const { user } = useAuth()

    const isDisabled = (user?.credits || 0) <= 0 && !user?.admin
    const mergedDisabled = isDisabled || !!props?.disabled
    return <Component {...props} disabled={mergedDisabled} />
  }
  return ComponentNeedsCredit
}

export const NeedsCreditsButton = needsCredits(Button)
export const NeedsCreditsTextField = needsCredits(TextField)
