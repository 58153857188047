import { Box, ButtonBase, Stack, Typography } from '@mui/material'
import { useHistory } from '../../util/router'
import React, { useState } from 'react'
import MainPanel from '../../components/MainPanel'
import MainPanelHeader from '../../components/MainPanelHeader'
import Meta from '../../components/Meta'
import Grid from '@mui/material/Grid'; 
import Card from '@mui/material/Card';
import {
  isListItem,
  libraryItems,
  LinkItem,
  LinkItemList,
  NestedListItem
} from '../../components/SideNavbar/navbarItems'
import ProjectNavigation from '../../components/SideNavbar/ProjectNavigation'
import { Link,useLocation } from 'react-router-dom'
import { requireAuth } from '../../util/auth'
import AppLayout from '../../components/AppLayout'
import { useAuth } from '../../util/auth'
import { getUseOptions,get_text_input_options } from '../../util/db'
const styles = {
  tileContainer: {
    mr: 2,
    mb: 2,
    textAlign: 'center',
    width: 90
  },
  tile: {
    border: 2,
    borderColor: 'primary.main',
    borderRadius: 4,
    backgroundColor: 'background.default',
    height: 90
  },
  tileIcon: {
    color: 'primary.main',
    width: '100%',
    height: '100%',
    p: 2.5
  },
  sectionTitle: {
    my: 1
  },
  card_text: {
    fontSize: 18,
    fontWeight : 'bold',
    color:"#000"
  },
  card: {
    boxShadow: "0px 0px 5px rgba(0,0,0,15%)",
    padding: 10,
    border: "1px solid transparent",
    height:"100%",
    cursor : "pointer"
  },
  iconPostions:{
    position:"absolute" as const,
    // width : 40
   },
   card_desc:{
    fontSize:14
  },
  icon_title:{
    fontSize:10,
    // wordBreak:"break-all" as const
   
  },
  iconBox:{
    width:'60px',
    textAlign:'center' as const,
  },
  pageTitle:{
      color:"#1091F4FF",
      fontWeight:700,
      textAlign:"center" as const,
      marginBottom:10
 
  },
  pageSubtitle:{
    color:"#565E6CFF",
    fontWeight:600,
    fontSize: 18,
    textAlign:"center" as const,
    marginBottom:35
  }
}

function Library(history) {
  const location = useLocation();
  const title = 'Bibliotheek'
  const hisotry = useHistory()
  const { user } = useAuth()

  // console.log(user,"all deta sdfjdfdsfvsdafvsdfvsdafvsdncv,msdncvksdnfvklsdafvjsdfvsdjcvbjscbadksj")
  const [timeInMinutes,setTimeInMinutes] = React.useState(null)
const[currenturl, setCurrenturl] = React.useState("/app");

React.useEffect(() => {
  let redirectFlag = false; // Flag to track whether redirection has already occurred

  const intervalId = setInterval(() => {
    const SignUpTime = user?.created?.seconds;
    const timestampInMilliseconds = SignUpTime * 1000;
    const newTimestampInMilliseconds = timestampInMilliseconds;
    let date: any = new Date(newTimestampInMilliseconds);
    let currentTime: any = new Date();
    var timeDifference = currentTime - date;
    var time_in_seconds = timeDifference / 1000;
    var time_in_minutes = time_in_seconds / 60;


    if (
      time_in_minutes > 30 &&
      user?.paymentType === undefined &&
      user?.stripeSubscriptionStatus === undefined &&
      location.pathname !== currenturl &&
      !redirectFlag
    ) {
      redirectFlag = true;
      history.push("/app");
    }
  }, 1000);

  return () => {
    clearInterval(intervalId);
  };
}, [user, history, location.pathname, currenturl]);


  return (
    <AppLayout title={title}>
      <MainPanel
        header={
          <MainPanelHeader
            additional={""}
            // additional={<ProjectNavigation showOnMobile={false} />}
          />
        }
        main={<LibraryMain/>}
      />
    </AppLayout>
  )
}

// function LibraryMain() {


//   return (
//     <div className='demo'>
//       <Stack spacing={1}>



//         {libraryItems.map((section) => {
//           console.log(section, "section---")
//           {

//             return <LibrarySection key={section.label} section={section} />
//           }
//         })}
//       </Stack>
//     </div>
//   )
// }

// function LibrarySection({ section }: { section: NestedListItem }) {
//   const { user } = useAuth();
//   console.log(user, 'roleeeeeeeeeeeeee')
//   const flattenItems = (items: LinkItemList): LinkItem[] =>
//     items.flatMap((item) =>
//       isListItem(item) ? [item] : flattenItems(item.linkItems)
//     )
//     console.log(flattenItems(section.linkItems), 'flattenItems(section.linkItems).map')

//   return (
//     <Stack>
//       <Typography variant="h6" sx={styles.sectionTitle}>
//         {section.label}
//       </Typography>

//       <Stack direction="row" flexWrap="wrap">
//         {flattenItems(section.linkItems).map((items) => {
//           if ((user?.email === "hankish.lohia@ultivic.com" || !user.role) &&

//             ((items.label != "Use Options") && (items.label != "User selected options") && (items.label != "Text Input Options")
//               && (items.label != "Recommended Options") && (items.label != "About Options") && (items.label != "Users") && (items.label !== "User Custom Options"))
//           ) {
//             return (
//               <LibraryTile key={items.label} item={items} />
//             )
//           }
//           console.log(items, "items")

//         }
//         )}
//       </Stack>
//     </Stack>
//   )
// }

// function LibraryTile({ item: { Icon, label, link } }: { item: LinkItem }) {
//   return (
//     <Stack sx={styles.tileContainer}>
//       <ButtonBase sx={styles.tile} component={Link} to={link}>
//         <Icon sx={styles.tileIcon} />
//       </ButtonBase>
//       <Typography variant="caption">{label}</Typography>
//     </Stack>
//   )
// }

function LibraryMain() {
  const [data, setData] = React.useState([]);
  const [iconsOptions, setIconsOptions] = React.useState<any>([]);
  // console.log("options",iconsOptions)
  // console.log("data",data)
  const history = useHistory()
 
  const auth = useAuth();
  React.useEffect(() => {
    getdata()
    getOptions()
  },[])
  // fetching information from the use_options collection from firebase
  const getdata = async () => {
    const data = await getUseOptions()
    setData(data)
  }
  const mydata = {
    user_id : auth.user.id,
  
  }
  const getOptions = async () => {
    const data:any = await get_text_input_options()
    setIconsOptions(data)
  }
return(
<div className='demo'>
<Typography variant="h2" className='startTitle' component="h2"  style={styles.pageTitle} >Welkom bij Digilo! Ontdek Ons Schrijfplatform</Typography>
<Typography variant="h5" component="h5"  style={styles.pageSubtitle} >Begin met het verkennen van tools voor elke schrijfstijl, van creatief tot academisch</Typography>
  <Grid container spacing={2} rowSpacing={2}>
                   {
                    data.length !== 0 &&
                    [data[10], data[0], data[2], data[1],data[3],data[4],data[5],data[6],data[7],data[8],data[9],data[11]].map((options,index) => {
                      return <Grid item xs={12} md={6} lg={4} sm={12} key = {index}>
                      <Card style={styles.card} variant="outlined">
                       <Box style={styles.iconPostions}>
                         {options?.icon === "E-commerce.png" && <img style={styles.iconPostions}  src="/img/E-commerce.png"/>}
                         {options?.icon === "Marketing.png" && <img style={styles.iconPostions}  src="/img/Marketing.png"/>}
                         {options?.icon === "Social_media.png" && <img style={styles.iconPostions}  src="/img/Social_media.png"/>}
                         {options?.icon === "Creatief_schrijven.png" && <img style={styles.iconPostions}  src="/img/Creatief_schrijven.png"/>}
                         {options?.icon === "Onderwijs.png" && <img style={styles.iconPostions}  src="/img/Onderwijs.png"/>}
                         {options?.icon === "Zakelijk.png" && <img style={styles.iconPostions}  src="/img/Zakelijk.png"/>}
                         {options?.icon === "Juridisch.png" && <img style={styles.iconPostions}  src="/img/Juridisch.png"/>}
                         {options?.icon === "Academisch.png" && <img style={styles.iconPostions}  src="/img/Academisch.png"/>}
                         {options?.icon === "Fitness.png" && <img style={styles.iconPostions}  src="/img/Fitness.png"/>}
                         {options?.icon === "Anders.png" && <img style={styles.iconPostions}  src="/img/Anders.png"/>}
                         {options?.icon === "Financieel.png" && <img style={styles.iconPostions}  src="/img/Financieel.png"/>}
                         {options?.icon === "Content_creatie.png" && <img style={styles.iconPostions}  src="/img/Wrench.png"/>}
                       </Box>
                        <Typography style={styles.card_text} id="modal-modal-description" align="center">
                        {options?.name}
                        </Typography>
                        <Typography style={styles.card_desc} sx={{ mb:1, mt:1 }} id="modal-modal-description" align="center">
                        {options?.description}
                        </Typography>

                        <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap" sx={{marginTop:"25px"}}>
                          {/* {libraryItems?.map((data,i) => {
                            console.log(data,"data")
                          })} */}

                      {iconsOptions?.map((curElem, index) => {
                        if(options?.name === "Creatief schrijven" && (curElem?.name === "Biografie" || curElem?.name === "Gedicht" || curElem?.name === "Songtekst" || curElem?.name === "Dagboek" || curElem?.name === "Brief" || curElem?.name === "Boekrecensie")){
                          return(
                            <Box key={index}>
                              {curElem?.icon === "biografie.png" ? <Box onClick={() => history.push('/app/generator/biography')} style={styles.iconBox}><img className='icon_image' src="/img/biografie.png" alt="" />
                              <Typography style={styles.icon_title} id="modal-modal-description" align="center">Biografie</Typography>
                              </Box> : null}
                              {curElem?.icon === "gedicht.png" ? <Box onClick={() => history.push('/app/generator/poem')} style={styles.iconBox}><img className='icon_image' src="/img/gedicht.png" alt="" />
                              <Typography style={styles.icon_title} id="modal-modal-description" align="center">Gedicht</Typography>
                              </Box> : null}
                              {curElem?.icon === "songtekst.png" ? <Box onClick={() => history.push('/app/generator/song')} style={styles.iconBox}><img className='icon_image' src="/img/songtekst.png" alt="" />
                              <Typography style={styles.icon_title} id="modal-modal-description" align="center">Songtekst</Typography>
                              </Box> : null}
                              {curElem?.icon === "dogboek.png" ? <Box onClick={() => history.push('/app/generator/journal')} style={styles.iconBox}><img className='icon_image' src="/img/dogboek.png" alt="" />
                              <Typography style={styles.icon_title} id="modal-modal-description" align="center">Dagboek</Typography>
                              </Box> : null}
                              {curElem?.icon === "brief.png" ? <Box onClick={() => history.push('/app/generator/letter')} style={styles.iconBox}> <img className='icon_image' src="/img/brief.png" alt="" />
                              <Typography style={styles.icon_title} id="modal-modal-description" align="center">Brief</Typography>
                              </Box> : null}
                              {curElem?.icon === "boekrecensie.png" ? <Box onClick={() => history.push('/app/generator/bookReview')} style={styles.iconBox}><img className='icon_image' src="/img/boekrecensie.png" alt="" />
                              <Typography style={styles.icon_title} id="modal-modal-description" align="center">Boekrecensie</Typography>
                              </Box> : null}
                            </Box>
                          )
                        }
                      })}

                       {iconsOptions?.map((curElem, index) => {
                        if(options?.name === "Content creatie" && (curElem?.name === "Artikel" || curElem?.name === "Verhaal")){
                          return(
                            <Box key={index}>
                              {curElem?.icon === "verhaal.png" ? <Box onClick={() => history.push('/app/generator/story')} style={styles.iconBox}><img className='icon_image' src="/img/verhaal.png" alt="" />
                              <Typography style={styles.icon_title} id="modal-modal-description" align="center">Verhaal</Typography>
                              </Box> : null}
                              {curElem?.icon === "artikel.png" ? <Box onClick={() => history.push('/app/assistance')}  style={styles.iconBox}><img className='icon_image' src="/img/chat_blue.png" alt="" />
                              <Typography style={styles.icon_title} id="modal-modal-description" align="center">Brainstorm Buddy</Typography>
                              </Box> : null}
                            </Box>
                          )
                        }
                      })}
                      {/* // 2nd loop */}
                        {iconsOptions?.map((curElem, index) => {
                        if(options?.name === "Juridisch schrijven" && (curElem?.name === "Voorstel" || curElem?.name === "Offerte" || curElem?.name === "Overeenkomst" || curElem?.name === "Contract")){
                          return(
                            <Box key={index}>
                              {curElem?.icon === "vaarstel.png" ? <Box onClick={() => history.push('/app/generator/suggestion')}  style={styles.iconBox} ><img className='icon_image' src="/img/vaarstel.png" alt="" />
                              <Typography style={styles.icon_title} id="modal-modal-description" align="center">Voorstel</Typography>
                              </Box> : null}
                              {curElem?.icon === "overeenkomst.png" ? <Box onClick={() => history.push('/app/generator/agreement')} style={styles.iconBox}><img className='icon_image' src="/img/overeenkomst.png" alt="" />
                              <Typography style={styles.icon_title} id="modal-modal-description" align="center">Overeenkomst</Typography>
                              </Box> : null}
                              {curElem?.icon === "offerte.png" ? <Box onClick={() => history.push('/app/generator/offer')} style={styles.iconBox}> <img className='icon_image' src="/img/offerte.png" alt="" />
                              <Typography style={styles.icon_title} id="modal-modal-description" align="center">Offerte</Typography>
                              </Box> : null}
                              {curElem?.icon === "contract.png" ? <Box onClick={() => history.push('/app/generator/contract')} style={styles.iconBox}><img className='icon_image' src="/img/contract.png" alt="" />
                              <Typography style={styles.icon_title} id="modal-modal-description" align="center">Contract</Typography>
                              </Box> : null}
                            </Box>
                          )
                        }
                       })}
                       {/* 3rd loop */}
                  
                      {/* 4th loop */}
                      {iconsOptions?.map((curElem, index) => {
                        if(options?.name === "Financieel schrijven" && (curElem?.name === "Rapport")){
                          return(
                            <Box key={index}>
                              {curElem?.icon === "rapport.png" ? <Box onClick={() => history.push('/app/generator/assessment')} style={styles.iconBox}><img className='icon_image' src="/img/rapport.png" alt="" />
                              <Typography style={styles.icon_title} id="modal-modal-description" align="center">Rapport</Typography>
                              </Box> : null}
                            </Box>
                          )
                        }
                      })}
                      {/* 5th loop */}
                      {iconsOptions?.map((curElem, index) => {
                        if(options?.name === "E-commerce" && (curElem?.name === "Artikel" || curElem?.name === "Productbeschrijving")){
                          return(
                            <Box key={index}>
                              {curElem?.icon === "productbeschrijving.png" ? <Box onClick={() => history.push('/app/generator/productDescription')} style={styles.iconBox}><img className='icon_image' src="/img/productbeschrijving.png" alt="" />
                              <Typography style={styles.icon_title} id="modal-modal-description" className='word_wrap' align="center">Product <br /> beschrijving</Typography>
                              </Box> : null}
                              {curElem?.icon === "artikel.png" ? <Box onClick={() => history.push('/app/generator/article')} style={styles.iconBox}><img className='icon_image' src="/img/artikel.png" alt="" />
                              <Typography style={styles.icon_title} id="modal-modal-description" align="center">Artikel</Typography>
                              </Box> : null}
                            </Box>
                          )
                        }
                      })}
                      {iconsOptions?.map((curElem, index) => {
                        if(options?.name === "Academisch schrijven" && (curElem?.name === "Thesis" || curElem?.name === "Onderzoeksverslag" || curElem?.name === "Methodology" || curElem?.name === "Hypothese" || curElem?.name === "Bibliografie")){
                          return(
                            <Box key={index}>
                              {curElem?.icon === "thesis.png" ? <Box onClick={() => history.push('/app/generator/thesis')} style={styles.iconBox}><img className='icon_image' src="/img/thesis.png" alt="" />
                              <Typography style={styles.icon_title} id="modal-modal-description" align="center">Thesis</Typography>
                              </Box> : null}
                              {curElem?.icon === "onderzoeksverslag.png" ? <Box onClick={() => history.push('/app/generator/researchReport')} style={styles.iconBox}><img className='icon_image' src="/img/onderzoeksverslag.png" alt="" />
                              <Typography style={styles.icon_title} id="modal-modal-description" className='word_wrap' align="center">Onderzoeksverslag</Typography>
                              </Box> : null}
                              {curElem?.icon === "methodology.png" ? <Box onClick={() => history.push('/app/generator/methodology')} style={styles.iconBox}><img className='icon_image' src="/img/methodology.png" alt="" />
                              <Typography style={styles.icon_title} id="modal-modal-description" align="center">Methodology</Typography>
                              </Box> : null}
                              {curElem?.icon === "hypothese.png" ? <Box onClick={() => history.push('/app/generator/hypothesis')} style={styles.iconBox}><img className='icon_image' src="/img/hypothese.png" alt="" />
                              <Typography style={styles.icon_title} id="modal-modal-description" align="center">Hypothese</Typography>
                              </Box> : null}
                              {curElem?.icon === "bibliografie.png" ? <Box onClick={() => history.push('/app/generator/bibliography')} style={styles.iconBox}><img className='icon_image' src="/img/bibliografie.png" alt="" />
                              <Typography style={styles.icon_title} id="modal-modal-description" align="center">Bibliografie</Typography>
                              </Box> : null}
                            </Box>
                          )
                        }
                      })}
                      {/* 7th loop */}
                      {iconsOptions?.map((curElem, index) => {
                        if(options?.name === "Social media" && (curElem?.name === "Facebook advertentie" || curElem?.name === "Google advertentie" || curElem?.name === "Linkedln advertentie" || curElem?.name === "Twitter tweet" || curElem?.name === "Social media bericht" || curElem?.name === "Productbeschrijving")){
                          return(
                            <Box key={index}>
                              {curElem?.icon === "facebook.png" ? <Box onClick={() => history.push('/app/generator/facebookAd')} style={styles.iconBox}><img className='icon_image' src="/img/facebook.png" alt="" />
                              <Typography style={styles.icon_title} id="modal-modal-description" align="center">Facebook advertentie</Typography>
                              </Box> : null}
                              {curElem?.icon === "google.png" ? <Box onClick={() => history.push('/app/generator/googleAd')} style={styles.iconBox}><img className='icon_image' src="/img/google.png" alt="" />
                              <Typography style={styles.icon_title} id="modal-modal-description" align="center">Google advertentie</Typography>
                              </Box> : null}
                              {curElem?.icon === "linkedin.png" ? <Box onClick={() => history.push('/app/generator/linkedInAd')} style={styles.iconBox}><img className='icon_image' src="/img/linkedin.png" alt="" />
                              <Typography style={styles.icon_title} id="modal-modal-description" align="center">Linkedln advertentie</Typography>
                              </Box> : null}
                              {curElem?.icon === "twitter.png" ? <Box onClick={() => history.push('/app/generator/twitterTweet')} style={styles.iconBox}><img className='icon_image' src="/img/twitter.png" alt="" />
                              <Typography style={styles.icon_title} id="modal-modal-description" align="center">Twitter tweet</Typography>
                              </Box> : null}
                              {curElem?.icon === "social-media.png" ? <Box onClick={() => history.push('/app/generator/socialMediaReport')} style={styles.iconBox}><img className='icon_image' src="/img/social-media.png" alt="" />
                              <Typography style={styles.icon_title} id="modal-modal-description" align="center">Social media bericht</Typography>
                              </Box> : null}
                              {curElem?.icon === "productbeschrijving.png" ? <Box onClick={() => history.push('/app/generator/productDescription')} style={styles.iconBox}><img className='icon_image' src="/img/productbeschrijving.png" alt="" />
                              <Typography style={styles.icon_title} id="modal-modal-description" className='word_wrap' align="center">Product <br /> beschrijving </Typography>
                              </Box> : null}
                            </Box>
                          )
                        }
                      })}
                      {/* 8th loop */}
                      {iconsOptions?.map((curElem, index) => {
                        if(options?.name === "Zakelijk schrijven" && (curElem?.name === "Briefing" || curElem?.name === "E-mail" || curElem?.name === "Memo" || curElem?.name === "Presentatie")){
                          return(
                            <Box key={index}>
                              {curElem?.icon === "brief.png" ? <Box onClick={() => history.push('/app/generator/briefing')} style={styles.iconBox}><img className='icon_image' src="/img/brief.png" alt="" />
                              <Typography style={styles.icon_title} id="modal-modal-description" align="center">Briefing</Typography>
                              </Box> : null}
                              {curElem?.icon === "e-mail.png" ? <Box onClick={() => history.push('/app/generator/email')} style={styles.iconBox}><img className='icon_image' src="/img/e-mail.png" alt="" />
                              <Typography style={styles.icon_title} id="modal-modal-description" align="center">E-mail</Typography>
                              </Box> : null}
                              {curElem?.icon === "memo.png" ? <Box onClick={() => history.push('/app/generator/note')} style={styles.iconBox}><img className='icon_image' src="/img/memo.png" alt="" />
                              <Typography style={styles.icon_title} id="modal-modal-description" align="center">Memo</Typography>
                              </Box> : null}
                              {curElem?.icon === "presentatie.png" ? <Box onClick={() => history.push('/app/generator/presentation')} style={styles.iconBox}><img className='icon_image' src="/img/presentatie.png" alt="" />
                              <Typography style={styles.icon_title} id="modal-modal-description" align="center">Presentatie</Typography>
                              </Box> : null}
                            </Box>
                          )
                        }
                      })}
                       {iconsOptions?.map((curElem, index) => {
                        if(options?.name === "Marketing en reclame" && (curElem?.name === "Whitepaper" || curElem?.name === "Facebook advertentie" || curElem?.name === "Google advertentie" || curElem?.name === "Linkedln advertentie")){
                          return(
                            <Box key={index}>
                              {curElem?.icon === "whitepaper.png" ? <Box onClick={() => history.push('/app/generator/whitePaper')} style={styles.iconBox}><img className='icon_image' src="/img/whitepaper.png" alt="" />
                              <Typography style={styles.icon_title} id="modal-modal-description" align="center">Whitepaper</Typography>
                              </Box> : null}
                              {curElem?.icon === "facebook.png" ? <Box onClick={() => history.push('/app/generator/facebookAd')} style={styles.iconBox}><img className='icon_image' src="/img/facebook.png" alt="" />
                              <Typography style={styles.icon_title} id="modal-modal-description" align="center">Facebook advertentie</Typography>
                              </Box> : null}
                              {curElem?.icon === "google.png" ? <Box onClick={() => history.push('/app/generator/googleAd')} style={styles.iconBox}><img className='icon_image' src="/img/google.png" alt="" />
                              <Typography style={styles.icon_title} id="modal-modal-description" align="center">Google advertentie"</Typography>
                              </Box> : null}
                              {curElem?.icon === "linkedin.png" ? <Box onClick={() => history.push('/app/generator/linkedInAd')} style={styles.iconBox}><img className='icon_image' src="/img/linkedin.png" alt="" />
                              <Typography style={styles.icon_title} id="modal-modal-description" align="center">Linkedln advertentie</Typography>
                              </Box> : null}
                            </Box>
                          )
                        }
                      })}
                      {/* 10th loop */}
                      {iconsOptions?.map((curElem, index) => {
                        if(options?.name === "Onderwijs en training" && (curElem?.name === "Vergelijking")){
                          return(
                            <Box key={index}>
                              {curElem?.icon === "vergelijking.png" ? <Box onClick={() => history.push('/app/generator/comparison')} style={styles.iconBox}><img className='icon_image' src="/img/vergelijking.png" alt="" />
                              <Typography style={styles.icon_title} id="modal-modal-description" align="center">Vergelijking</Typography>
                              </Box> : null}
                            </Box>
                          )
                        }
                      })}
                      {/* 11th loop */}
                      {iconsOptions?.map((curElem, index) => {
                        if(options?.name === "Teksten verbeteren" && (curElem?.name === "Artikel" || curElem?.name === "Digilo Fix" || curElem?.name === "Digilo Fix")){
                          return(
                            <Box key={index} sx={{display:"flex"}}>
                             
                          
                              {curElem?.icon === "artikel.png" ? <Box onClick={() => history.push('/app/assistance')} style={styles.iconBox}><img className='icon_image' src="/img/chat_blue.png" alt="" />
                              <Typography style={styles.icon_title} id="modal-modal-description" align="center">Brainstorm Buddy</Typography>
                              </Box> : null}
                              {curElem?.icon === "artikel.png" ? <Box onClick={() => history.push('/app/improver')} style={styles.iconBox}>
                                <img className='icon_image' src="/img/wrench_blue.png" alt="" />
                              <Typography style={styles.icon_title} id="modal-modal-description" align="center">Quick Fix</Typography>
                              </Box> 
                              : null}    {curElem?.icon === "tool.png" ? <Box onClick={() => history.push('/app/digiloFixAi')} style={styles.iconBox}><img className='icon_image' src="/img/edit_blue.png" alt="" />
                              <Typography style={styles.icon_title} id="modal-modal-description" align="center">Content assistent</Typography>
                              </Box> : null}
                            </Box>
                          )
                        }
                      })}
                      {/* 12th loop */}
                      {iconsOptions?.map((curElem, index) => {
                        if(options?.name === "Anders" && (curElem?.name === "Artikel")){
                          return(
                            <Box key={index}>
                              {curElem?.icon === "artikel.png" ? <Box onClick={() => history.push('/app/assistance')} style={styles.iconBox}><img className='icon_image' src="/img/chat_blue.png" alt="" />
                              <Typography style={styles.icon_title} id="modal-modal-description" align="center">Brainstorm Buddy</Typography>
                              </Box> : null}
                            </Box>
                          )
                        }
                      })}
                      </Stack>
                      </Card>
                    </Grid> 
                    })
                  }

                </Grid> 
</div>
)

}

export default requireAuth(Library)
