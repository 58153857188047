import { Box, Container } from '@mui/material'
import React, { ReactNode } from 'react'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'

interface MainPanelProps {
  header?: ReactNode
  mainLeft?: ReactNode
  mainRight?: ReactNode
  main?: ReactNode
  mobileDisplayState?: 'left' | 'right'
}

const getStyles = (isSinglePanel: boolean) => ({
  wrapper: {
    backgroundColor: 'background.appDefault',
    flex: isSinglePanel ? '0 0 auto' : '1 0 0'
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    maxWidth:"100%",
    py: {
      xs: 1,
      sm: 2
    },
    px: isSinglePanel
      ? 2
      : {
          xs: 0,
          sm: 2
        },
    boxSizing: 'border-box'
  },
  main: {
    flex: 1
  },
  cardGrid: {
    height: '100%'
  },
  card: {
    width: '100%',
    height: '100%'
  }
})

function MainPanel({
  header,
  mainRight,
  mainLeft,
  main,
  mobileDisplayState
}: MainPanelProps) {
  const isSinglePanel = !!main
  const styles = getStyles(isSinglePanel)


  const hidePanelSx = {
    display: {
      xs: 'none',
      md: 'block'
    }
  }
  const content = main ? (
    main
  ) : (
    <Grid container spacing={2} sx={styles.cardGrid}>
      <Grid
        item
        xs={12}
        md={6}
        sx={mobileDisplayState === 'right' ? hidePanelSx : {}}
      >
        <Card sx={styles.card}>{mainLeft}</Card>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={mobileDisplayState === 'left' ? hidePanelSx : {}}
      >
        <Card sx={styles.card}>{mainRight}</Card>
      </Grid>
    </Grid>
  )

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.container}>
        <Box>{header}</Box>

        <Box sx={styles.main}>{content}</Box>
      </Box>
    </Box>
  )
}

export default MainPanel
