import React, { useState } from 'react'
import MainPanel from '../../components/MainPanel'
import MainPanelHeader from '../../components/MainPanelHeader'
import CreditsInfo from '../../components/CreditsInfo'
import GeneratorForm from './generator-form'

import {
  GeneratorConfig,
  GeneratorType
} from '../../shared-types/generator.types'
import Typography from '@mui/material/Typography'
import { CardContent, CardHeader } from '@mui/material'
import GeneratorOutput from './generator-output'
import { generatorApi } from './generator-api'
import { capitalizeFirstLetter } from '../../util/util'

interface AiGeneratorProps {
  generatorType: GeneratorType
  generatorConfig: GeneratorConfig
}

function AiGenerator({ generatorType, generatorConfig }: AiGeneratorProps) {
  const [output, setOutput] = useState<string | null>(null)
  const [values, setValues] = useState<null | Record<string, string>>(null)
  const [isRetrying, setIsRetrying] = useState(false)
  const [showOutput, setShowOutput] = useState(false)

  async function handleRetry() {
    setIsRetrying(true)
    await generatorApi(generatorType, values, setOutput)
    setIsRetrying(false)
  }

  async function handleSubmit(values: Record<string, string>) {
    setShowOutput(true)
    setValues(null)
    await generatorApi(generatorType, values, setOutput)
    setValues(values)
  }

  return (
    <MainPanel
      mobileDisplayState={showOutput ? 'right' : 'left'}
      header={<MainPanelHeader additional={<CreditsInfo />} />}
      mainLeft={
        <>
          <Header
            header={capitalizeFirstLetter(
              capitalizeFirstLetter(generatorConfig.name)
            )}
            description={generatorConfig.description}
          />
          <CardContent>
            <GeneratorForm
              generatorConfig={generatorConfig}
              isRetrying={isRetrying}
              onGeneration={handleSubmit}
            />
          </CardContent>
        </>
      }
      mainRight={
        <GeneratorOutput
          onBack={() => setShowOutput(false)}
          generatorConfig={generatorConfig}
          output={output}
          values={values}
          onRetry={handleRetry}
          isRetrying={isRetrying}
        />
      }
    />
  )
}

function Header({
  header,
  description
}: {
  header: string
  description?: string
}) {
  return (
    <CardHeader
      title={<Typography variant="h4">{header}</Typography>}
      subheader={
        description && (
          <Typography variant="subtitle1">{description}</Typography>
        )
      }
    ></CardHeader>
  )
}

export default AiGenerator
