import React from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { FormControl, MenuItem, Select } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { NeedsCreditsButton } from '../../hocs/needsCredits'
import { getKeys, tuple } from '../../shared-types/types'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import {
  GeneratorConfig,
  GeneratorConfigField
} from '../../shared-types/generator.types'

const useStyles = makeStyles((theme) => ({
  field: {
    width: '100%'
  },
  form: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  submitButton: {
    width: 'auto',
    alignSelf: 'center',
    margin: theme.spacing(2)
  }
}))

function createInitialValues(
  formFields: Record<string, GeneratorConfigField>
): Record<string, string> {
  return Object.fromEntries(
    Object.entries(formFields).map(
      ([key, val]: [string, GeneratorConfigField]) =>
        tuple([key, val.options?.[0] ?? ''])
    )
  )
}

function createValidationSchema(
  formFields: Record<string, GeneratorConfigField>
) {
  return yup.object(
    Object.fromEntries(
      getKeys(formFields).map((key) => [key, yup.string().required()])
    )
  )
}

interface GeneratorFormProps {
  onGeneration: (prompt: Record<string, string>) => Promise<void>
  generatorConfig: GeneratorConfig
  isRetrying: boolean
}

function GeneratorForm({
  onGeneration,
  generatorConfig,
  isRetrying
}: GeneratorFormProps) {
  const classes = useStyles()
  const fields = generatorConfig.fields
  const formFields = Object.entries(generatorConfig.fields) as [
    string,
    GeneratorConfigField
  ][]

  const formik = useFormik({
    initialValues: createInitialValues(fields),
    validationSchema: createValidationSchema(fields),
    validateOnMount: true,
    onSubmit: async (values, formikHelpers) => {
      await onGeneration(values)
      formikHelpers.setSubmitting(false)
    }
  })

  function createFormField(key: string, field: GeneratorConfigField) {
    const isSelect = !!field.options
    return (
      <Grid item xs={field.isSmall ? 6 : 12} key={key}>
        <Typography variant="subtitle1">{field.label}</Typography>
        {isSelect
          ? createSelectField(key, field)
          : createInputField(key, field)}
      </Grid>
    )
  }

  function createSelectField(key: string, field: GeneratorConfigField) {
    return (
      <FormControl size="small" className={classes.field}>
        <Select
          variant="outlined"
          {...formik.getFieldProps(key)}
          error={formik.touched[key] && Boolean(formik.errors[key])}
        >
          {field.options?.map((textType) => (
            <MenuItem key={textType} value={textType}>
              {textType}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }

  function createInputField(key: string, field: GeneratorConfigField) {
    return (
      <TextField
        size="small"
        variant="outlined"
        placeholder={field.placeholder}
        autoComplete="off"
        multiline={field.isTextArea}
        minRows={field.isTextArea ? 2 : 1}
        maxRows={field.isTextArea ? 3 : 1}
        className={classes.field}
        value={formik.values[key]}
        error={formik.touched[key] && Boolean(formik.errors[key])}
        {...formik.getFieldProps(key)}
      />
    )
  }

  return (
    <form onSubmit={formik.handleSubmit} className={classes.form}>
      <Grid container spacing={2}>
        {formFields.map(([key, field]) => createFormField(key, field))}
      </Grid>
      <NeedsCreditsButton
        color="secondary"
        variant="contained"
        type="submit"
        disabled={formik.isSubmitting || !formik.isValid || isRetrying}
        className={classes.submitButton}
      >
        {generatorConfig.action || 'Creer'}
      </NeedsCreditsButton>
    </form>
  )
}

export default GeneratorForm
