import { GeneratorType } from '../shared-types/generator.types'

export enum RoutePath {
  // app routes
  app = '/app',
  project = '/app/project/:projectId',
  help = '/app/help',
  dashboard = '/app/dashboard',
  settings = '/app/settings/:section',
  assistance = '/app/assistance',
  writer = '/app/writer',
  generator = '/app/generator/:type',
  improver = '/app/improver',
  quickWriter = '/app/quick-writer',
  library = '/app/library',
  // landing page routes
  root = '/',
  contact = '/contact',
  auth = '/auth/:type',
  pricing = '/pricing',
  about = '/about',
  legal = '/legal/:section',
  purchase = '/purchase/:plan',
  firebaseAction = '/firebase-action',
  AdminLogin = '/admin',
  AdminUsers = '/app/all-users',
  AboutOptions='/app/all-options',
  AdminRecommendedOptions='/app/recommended-options',
  AdminUseoptions ='/app/use-options',
  AdminTextinputoptions='/app/textinput-options',
  AdminUserTextOptions='/app/userselected-options',
  AdminCustomOptions = '/app/custom-options',
  openAi = '/app/openAi',
  digiloFixAi = '/app/digiloFixAi',
  DigiloPurchase="/app/digiloPurchase",
  addBlog="/app/addBlog",
  createBlog="/app/createBlog",
  blog="/digiloblog",
  thankyou="/thankyou",
  blogTemplate="/digiloblog/:title",
  editBlog = "/app/editBlog/:id"
}

export function settingsPath(section: 'general' | 'password' | 'billing') {
  return routeWithSlugs(RoutePath.settings, section)
}

export function generatorPath(type: GeneratorType) {
  return routeWithSlugs(RoutePath.generator, type)
}

export function projectPath(projectId: string) {
  return routeWithSlugs(RoutePath.project, projectId)
}

export function authPath(type: 'signin' | 'signup' | 'forgotpass') {
  return routeWithSlugs(RoutePath.auth, type)
}

export function legalPath(section: 'terms-of-service' | 'privacy-policy') {
  return routeWithSlugs(RoutePath.legal, section)
}

export function routeWithSlugs(routePath: RoutePath, ...slugs: string[]) {
  let counter = 0
  return routePath.replaceAll(/:[^/]+/g, () => slugs[counter++])
}
