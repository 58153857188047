import React from 'react'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

const styles = {
    heading: {
        fontSize: {
            xs: "22px",
              md: "28px",
              lg: "40px"
        },
        textAlign: "center",
        color: "#171A1FFF",
        paddingTop: "0px !important",
        padding: {
            xs: "15px",
            sm: "1em"
        },
    },
    text: {
        fontWeight: "700",
        marginBottom: "10px",
        fontSize: "16px",
    },
    smallText: {
        fontSize: {
            xs: "14px",
            md: "18px",
            lg: "20px"
        },
        fontWeight: "600",
        color: "#323842FF"
    },
    whyChooseWrapper: {
        backgroundColor: "#66C1FF26",
        padding: {
            xs: "3em 1em",
            sm: "3em",
            md: "3em 2em",
            lg: "6em 2em"
        }
    },

    card: {
        background: ' #FFFFFFFF', /* white */
        borderRadius: "6px",/* border-m */
        borderWidth: " 5px",
        borderColor: "#BCC1CAFF", /* neutral-400 */
        borderStyle: "solid",
        padding: "30px 20px",
        height: "100%",
        width: "100%"
    },
    btn: {
        textAlign: "center",
        color: "#fff",
        backgroundColor: "#45A7E8FF",
        marginBottom: {
            xs: "20px",
            sm: "25px",
            md: "35px",
        }
    },
    desc: {
        fontSize: "14px",
        color: "#565E6CFF",
        marginBottom: "20px"
    }
}

function Benifits(props) {

    return (
        <Box sx={styles.whyChooseWrapper}>
            <Typography variant="h2" component="h2" sx={styles.heading}>
                Voordelen per gebruikerstype
            </Typography>

            <Grid container spacing={4}>
                <Grid item lg={3} md={6} sm={6}>
                    <Box sx={styles.card}>
                        <Box sx={{ textAlign: "center" }}>
                            <Button variant="contained" sx={styles.btn}>Contentmakers & Digitale marketeers </Button>
                        </Box>
                        <Typography sx={styles.text}>
                            Content writers en copywriters:
                        </Typography>
                        <Typography sx={styles.desc}>
                            Verbeteren met Quick Fix de efficiëntie en behoud de uniformiteit in hun werk.
                        </Typography>
                        <Typography sx={styles.text}>
                            SEO specialisten
                        </Typography>
                        <Typography sx={styles.desc}>
                            Produceren met de content assistent eenvoudig inhoud die geoptimaliseerd is voor zoekmachines.
                        </Typography>
                        <Typography sx={styles.text}>
                            Marketingteams:
                        </Typography>
                        <Typography sx={styles.desc}>
                            Produceren  de content assistent snel boeiend campagnemateriaal dat aanspreekt bij hun publiek.
                        </Typography>
                        <Typography sx={styles.text}>
                            Managers van sociale media:
                        </Typography>
                        <Typography sx={styles.desc}>
                            Creëren  met de content assistent regelmatig hoogwaardige berichten zonder het risico op uitputting.
                        </Typography>
                        <Typography sx={styles.text}>
                            Bloggers:
                        </Typography>
                        <Typography sx={styles.desc}>
                            Produceren met de brainstorm buddy meer blogposts in een sneller tempo, waardoor hun online zichbaarheid  verbetert.
                        </Typography>
                    </Box>

                </Grid>
                <Grid item lg={3} md={6} sm={6}>
                    <Box sx={styles.card}>
                        <Box sx={{ textAlign: "center" }}>
                            <Button variant="contained" sx={styles.btn}>E-commerce bedrijven en Ondernemers</Button>
                        </Box>
                        <Typography sx={styles.text}>
                            Freelance schrijvers:
                        </Typography>
                        <Typography sx={styles.desc}>
                            Behandelen meerdere projecten tegelijkertijd zonder concessies te doen aan de kwaliteit.
                        </Typography>
                        <Typography sx={styles.text}>
                            E-mailmarketeers:
                        </Typography>
                        <Typography sx={styles.desc}>
                            Creëren met templates of de content assitent boeiende nieuwsbrieven die interactie en conversies bevorderen.
                        </Typography>
                        <Typography sx={styles.text}>
                            Kleine ondernemers:
                        </Typography>
                        <Typography sx={styles.desc}>
                            Creëren met de  content assitent professioneel uitziende webteksten en benadrukken hun unieke verkoopargumenten zonder de noodzaak van een duur marketingbureau.
                        </Typography>
                        <Typography sx={styles.text}>
                            Digitale marketing bureaus:
                        </Typography>
                        <Typography sx={styles.desc}>
                            Implementeren met alle tools van Digilo schaalbare strategieën die meerdere klanten tegelijkertijd kunnen bedienen.
                        </Typography>
                        <Typography sx={styles.text}>
                            E-commerce bedrijven:
                        </Typography>
                        <Typography sx={styles.desc}>
                            Creëren met de content assistent productomschrijvingen die hun producten verkopen met de hoogst mogelijke conversieratio.
                        </Typography>
                    </Box>

                </Grid>
                <Grid item lg={3} md={6} sm={6}>
                    <Box sx={styles.card}>
                        <Box sx={{ textAlign: "center" }}>
                            <Button variant="contained" sx={styles.btn}>Onderwijsgevenden </Button>
                        </Box>
                        <Typography sx={styles.text}>
                            Volwasseneneducatie docenten:
                        </Typography>
                        <Typography sx={styles.desc}>
                            Creëeren met de content assistent trainingsmateriaal dat informatie duidelijk en eenvoudig voor bijvoorbeeld niet nederlandstaligen beschrijft.
                        </Typography>
                        <Typography sx={styles.text}>
                            Online docenten en Privéleraren:
                        </Typography>
                        <Typography sx={styles.desc}>
                            Ontwikkelen met de brainstrom buddy en content assistent gepersonaliseerd studiemateriaal dat de leerstof helder en begrijpelijk uitlegt.
                        </Typography>
                        <Typography sx={styles.text}>
                            Coaches
                        </Typography>
                        <Typography sx={styles.desc}>
                            Benutten me de brainstorm buddy en content assistent om op maat gemaakte ontwikkelingsplannen te creëren die gedragsverandering en persoonlijke groei stimuleren.
                        </Typography>
                        <Typography sx={styles.text}>
                            Consultants
                        </Typography>
                        <Typography sx={styles.desc}>
                            Gebruiken de brainstorm buddy om complexe bedrijfsstrategieën en -concepten op een eenvoudige en begrijpelijke manier te presenteren.
                        </Typography>

                    </Box>

                </Grid>
                <Grid item lg={3} md={6} sm={6}>
                    <Box sx={styles.card}>
                        <Box sx={{ textAlign: "center" }}>
                            <Button variant="contained" sx={styles.btn}>Studenten PRoffesionals</Button>
                        </Box>
                        <Typography sx={styles.text}>
                            Studenten:
                        </Typography>
                        <Typography sx={styles.desc}>
                            Gebruiken templates, de quick fix tool en de content assistent voor het schrijven van essays, het genereren van bibliografieën en het opstellen van onderzoeksvoorstellen. Ze maken ook gebruik van de brainstorm buddy om ideeën te genereren voor onderwerpen. De brainstorm buddy doet suggesties  op basis van sleutelwoorden of korte beschrijvingen. Tenslotte gebruiken ze de quick fix om snel samenvattingen te maken van lange teksten, zoals lezingen of hoofdstukken uit een boek. Dit helpt studenten om snel de belangrijkste punten te herzien.
                        </Typography>
                        <Typography sx={styles.text}>
                            Professionals
                        </Typography>
                        <Typography sx={styles.desc}>
                            Benutten de content assistent om complexe bedrijfsconcepten en -processen eenvoudig te maken

                            Schrijven met templates en de content assitent  rapporten, presentaties, voorstellen, e-mails en nog veel meer documenten
                        </Typography>


                    </Box>

                </Grid>

            </Grid>
        </Box>
    )
}

export default Benifits
