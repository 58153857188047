import React from 'react'
import WriterPanel, {
  WriterStepExtendedProps
} from '../components/writer-panel'
import WriterInput from '../components/writer-input'
import Typography from '@mui/material/Typography'
import { FormControl, MenuItem, Select, Stack } from '@mui/material'
import { WriterLanguage } from '../ai-writer.model'
import Box from '@mui/material/Box'

const styles = {
  select: {
    width: '100%'
  },
  root: {
    display: 'flex',
    mt: {
      xs: 2,
      sm: 4
    },
    flexDirection: 'column',
    alignItems: 'stretch'
  }
}

function TopicStep(props: WriterStepExtendedProps) {
  return (
    <WriterPanel {...props}>
      <>
        <Stack spacing={2} sx={styles.root}>
          <Typography variant="h6">Selecteer de taal</Typography>
          <Box>
            <Typography variant="subtitle1">Taal</Typography>

            <LanguageSelect
              value={props.writerState.language}
              onChange={(language) => props.setWriterState({ language })}
            />
          </Box>

          <Typography variant="h6">Kies een onderwerp</Typography>
          <Box>
            <Typography variant="subtitle1">Onderwerp</Typography>

            <WriterInput
              value={props.writerState.topic}
              onChange={(topic) => props.setWriterState({ topic })}
              placeholder="Schrijf hier het onderwerp van je artikel"
            />
          </Box>
        </Stack>
      </>
    </WriterPanel>
  )
}

function LanguageSelect({
  value,
  onChange
}: {
  value: WriterLanguage
  onChange: (value: WriterLanguage) => void
}) {
  return (
    <FormControl size="small" sx={styles.select}>
      <Select
        variant="outlined"
        value={value}
        onChange={(e) => onChange(e.target.value as WriterLanguage)}
      >
        <MenuItem value="dutch">Nederlands</MenuItem>
        <MenuItem value="english">Engels</MenuItem>
      </Select>
    </FormControl>
  )
}

export default TopicStep
