import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Alert from '@mui/material/Alert'
import ReauthModal from './ReauthModal'
import SettingsNav from './SettingsNav'
import SettingsGeneral from './SettingsGeneral'
import SettingsPassword from './SettingsPassword'
import SettingsBilling from './SettingsBilling'
import { useAuth } from '../util/auth'
import MainPanel from './MainPanel'
import MainPanelHeader from './MainPanelHeader'

function SettingsSection(props) {
  const auth = useAuth()
  const [formAlert, setFormAlert] = useState(null)

  // State to control whether we show a re-authentication flow
  // Required by some security sensitive actions, such as changing password.
  const [reauthState, setReauthState] = useState({
    show: false
  })

  const validSections = {
    general: true,
    password: true,
    billing: true
  }

  const section = validSections[props.section] ? props.section : 'general'

  // Handle status of type "success", "error", or "requires-recent-login"
  // We don't treat "requires-recent-login" as an error as we handle it
  // gracefully by taking the user through a re-authentication flow.
  const handleStatus = ({ type, message, callback }) => {
    if (type === 'requires-recent-login') {
      // First clear any existing message
      setFormAlert(null)
      // Then update state to show re-authentication modal
      setReauthState({
        show: true,
        // Failed action to try again after reauth
        callback
      })
    } else {
      // Display message to user (type is success or error)
      setFormAlert({
        type,
        message
      })
    }
  }

  return (
    <MainPanel
      header={<MainPanelHeader title="Instellingen" />}
      main={
        <>
          {reauthState.show && (
            <ReauthModal
              callback={reauthState.callback}
              provider={auth.user.providers[0]}
              onDone={() => setReauthState({ show: false })}
            />
          )}

          <SettingsNav activeKey={section} />
          <Box mt={5}>
            <Container maxWidth="xs">
              {formAlert && (
                <Box mb={4}>
                  <Alert severity={formAlert.type}>{formAlert.message}</Alert>
                </Box>
              )}

              {section === 'general' && (
                <SettingsGeneral onStatus={handleStatus} />
              )}

              {section === 'password' && (
                <SettingsPassword onStatus={handleStatus} />
              )}

              {section === 'billing' && (
                <SettingsBilling onStatus={handleStatus} />
              )}
            </Container>
          </Box>
        </>
      }
    />
  )
}

export default SettingsSection
