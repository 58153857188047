import React from 'react'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import Section from './Section'
import SectionHeader from './SectionHeader'
import { Link } from '../util/router'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Typewriter from 'typewriter-effect';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
function HeroSection(props) {
  const ctaButton = (
    <Button
      component={Link}
      to={props.buttonPath}
      variant="contained"
      size="large"
      sx={{ backgroundColor: 'primary.light' }}
    >
      {props.buttonText}
    </Button>
  )

  return (

    <Section
      size={props.size}
      sx={{
        padding: { xs: "10px", sm: "48px", md: "70px" },
        alignItems: 'flex-end',
        color: 'black !important',
        background:
          'linear-gradient(128deg, rgba(255,103,103,1) 0%, rgba(255,207,159,1) 22%, rgba(255,255,255,1) 39%, rgba(255,255,255,1) 51%, rgba(255,255,255,1) 61%, rgba(255,208,157,1) 82%, rgba(255,90,90,1) 100%) !important'
      }}
    >

      <Container >
        <Grid
          sx={{ paddingTop: "0px" }}
          spacing={2}
          container
          style={{
            alignItems: 'flex-start'
          }}
        >
          <Grid item xs={12}>
         
          <Box sx={{ alignSelf: 'flex-end', my: 1,textAlign:"right" }}>{ctaButton}</Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{ paddingTop: "0px" }}>

            <Box sx={{ minHeight: "70px" }}>
              <h1 className='mainHeading'>
                Digilo, de content assistent schrijft voor
                <Typewriter
                  options={{
                    strings: ['<span>Digitale Marketeers</span>', '<span>Webshop Eigenaren</span>', '<span>Social Media Marketeers</span>', '<span>Ondernemers</span>', '<span>Content Creators</span>'],
                    autoStart: true,
                    loop: true,
                  }}
                />
                automatisch content!
               
              </h1>

            </Box>
            {/* <SectionHeader
              sx={{ display: { xs: 'none', sm: 'block' } }}
              title="Digilo, de content assistent schrijft automatisch jouw content!"
              size={1}
            /> */}

            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
              <TrailerVideo />
            </Box>
              {/* <Typography variant="h6" sx={{marginTop:"10px"}}>
              Automatisering Ontmoet Creativiteit!
              </Typography> */}
            <Typography variant="body1" sx={{ mb: 2 }}>
            Klaar om je teksten naar een volgend niveau te brengen? Digilo, de content assistant, is hier om je te helpen. Met Digilo kun je snel, eenvoudig en effectief je teksten schrijven.

            </Typography>
            {/* <List>
                            <ListItem disablePadding sx={{ display: 'list-item', marginBottom: "15px", listStyle: "inside" }}>
                            Efficiënt, kwalitatief en consistent

                            </ListItem>
                            <ListItem disablePadding sx={{ display: 'list-item', marginBottom: "15px", listStyle: "inside" }}>
                            Laat technologie het zware werk doen

                            </ListItem>
                            <ListItem disablePadding sx={{ display: 'list-item', marginBottom: "15px", listStyle: "inside" }}>
                            Meer content, minder moeite


                            </ListItem>

                            </List> */}
            <Typography variant="body1" sx={{ mb: 2, fontWeight: 'bold' }}>
              <b>Probeer Digilo nu gratis uit!</b>
            </Typography>
            {ctaButton}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: { xs: 'none', md: 'flex' },
              flexDirection: 'column',
              justifyContent: 'center'
            }}
          >
           
            <TrailerVideo />
          </Grid>
        </Grid>
      </Container>
    </Section>
  )
}

function TrailerVideo() {
  return (
    <video width="100%" controls playsInline muted autoPlay>
      <source src="/video/landing-page-trailer-01.mp4" type="video/mp4" />
    </video>
  )
}

export default HeroSection
