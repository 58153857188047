import React from 'react'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import CheckIcon from '@mui/icons-material/Check'
import ListItemText from '@mui/material/ListItemText'
import Button from '@mui/material/Button'
import Section from './Section'
import SectionHeader from './SectionHeader'
import { Link } from '../util/router'
import { useAuth } from '../util/auth'
import { RoutePath } from '../util/routes'
import { planDetails } from '../util/prices'

const styles = {
  card: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingBottom: "0px",
    maxWidth: {
      xs: '100%',
      // md: 280,
      lg: '100%'
    }
  },

  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '4px', /* shadow-xs */
    backgroundColor: "#DEE1E661",
    paddingBottom: "0px",
    boxShadow: "unset",
    '&:last-child': {
      paddingBottom: "0px"
    },
    '&:hover': {
      background: '#DEE1E6FF',
      // '& .pill': {
      //   backgroundColor: "#46CDE6FF",

      // },
      // '& .btn': {
      //   backgroundColor: "#EF9834FF",
      //   border: "1px solid #EF9834FF",
      //   color: "#fff",

      // },
      // '& .svgColr': {
      //   fill: "#fff"
      // }
    },
  },
  price: {
    display: 'flex',
    alignItems: 'baseline'
  },
  listItem: {
    paddingTop: 0.10,
    paddingBottom: 0.10
  },
  perkIcon: {
    minWidth: 34,
    color: 'success.main'
  },
  description: {
    color: "#9095A0FF",
    marginTop: "10px",
    textAlign: "center",
    fontSize: "14px",

  },
  descWraper: {
    minHeight: {
      md: 120,
      lg: 135,
      xl: 105,
      xxl: 110
    }
  },
  pill: {
    backgroundColor: "#F1FBFDFF",
    display: "inline-block",
    fontSize: "11px",
    padding: ' 5px 10px',
    borderRadius: '35px',
    color: "#106878FF",
    marginBottom: "10px"
  },
  pillbtn: {
    backgroundColor: "#46CDE6FF",
    display: "inline-block",
    fontSize: "11px",
    padding: ' 5px 10px',
    borderRadius: '35px',
    color: "#106878FF",
    marginBottom: "10px"
  },
  innnerWrap: {
    backgroundColor: "#fff",
    p: 2,
    marginBottom: "4px",
    borderRadius: "6px",


  },
  innnerWrapContent: {
    backgroundColor: "#fff",
    p: 2,
    marginBottom: "4px",
    borderRadius: "6px",
    height: '100%',
    display: "flex",
    flexDirection: "column"

  },
  btn: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#fff",
    boxShadow: "unset",
    border: "1px solid #9095A0FF",
    color: "#000",
    fontSize: "14px",
    '&:hover': {
      backgroundColor: "#EF9834FF",
      border: "1px solid #EF9834FF",
      color: "#fff",
      '& .svgColr': {
        fill: "#fff"
      }
    }
  },
  header_top: {
    padding: {
      xs: "15px 1em",
      sm: "15px 2em"
    },
    boxShadow: "0px 0px 5px rgba(0,0,0,15%)",
    marginBottom: { xs: "0px", sm: "10px" },
    borderTop: "1px solid #ddd",
    backgroundColor: "#fff"
  },
  lists: {
    span: {
      fontSize: "14px"
    }
  }


}

function PricingSection(props) {
  const auth = useAuth()

  return (
    <>
      <Box sx={styles.header_top} className={`pricing_header ${props.class}`}>
        <Grid container justifyContent="center" spacing={2} alignItems={"center"}>

          <Grid item xs={12} md={6} lg={6}>
            <Typography
              variant='h2'
              sx={{
                fontSize: {
                  xs: "22px",
                  md: "28px",
                  lg: "40px"
                }
              }}
            >
              {props.title}
            </Typography>

            <Typography
              variant='h6'
              sx={{ color: "#9095A0FF", fontWeight: "400", fontSize: "14px", marginTop: "10px" }}>
              Probeer nu gratis gedurende 5 dagen. Er zijn geen kosten verbonden en er wordt geen geld van je bankrekening of creditcard afgeschreven.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
           <Box sx={{display:"flex",gap:"10px"}}>
           <Box>
                <img src="/img/garantie.png" alt="" width={"78"} height={"78"}  />
              </Box>
              <Box>
                <Typography  variant='h6'>
                Niet Goed, Geld Terug Garantie
                </Typography>
                <Typography> 
                Vertrouwen staat centraal bij Digilo. Ben je niet tevreden binnen 30 dagen? Dan krijg je bij de abonnementen Starter, Pay per Use en Standaard  jouw geld terug, zonder vragen.
                </Typography>
              </Box>
           </Box>
          </Grid>
        </Grid>

      </Box>
      <Section
        sx={{ border: "unset !important", paddingLeft: { xs: "1em", sm: "2em" }, paddingRight: { xs: "1em", sm: "2em" }, paddingTop: "0px !important" }}
        bgColor={props.bgColor}
        size={props.size}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
      >
        {/* <Container> */}

        {/* <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="left"
        /> */}
        <Box sx={{ textAlign: "right" }}>
          <Typography sx={styles.pillbtn} className='pill' variant="h6" component="h2">
            Maandelijks
          </Typography>
        </Box>

        <Grid container justifyContent="center" spacing={2} className='price_card'>
          {planDetails.slice(1).map((plan, index) => (
            <Grid item xs={12} md={6} lg={3} key={index} id={plan.id} className='price_wrapper'>
              <Card sx={styles.card}>
                <CardContent sx={styles.cardContent} className='price_content'>
                  <Box sx={styles.innnerWrap}>
                    <Typography sx={styles.pill} className='pill' variant="h6" component="h2">
                      {plan.name}
                    </Typography>
                    <Box sx={styles.price} mt={1}>
                      {/* <Typography sx={{ fontWeight: "600", textTransform: "capitalize" }} variant="h3">{plan.price}</Typography> */}
                      <Typography sx={{ fontWeight: "600", textTransform: "capitalize", fontSize: { sm: "18px", xs: "16px", lg: "20px" } }} variant="h4">{plan.price === "10" && "€10/Per Bundel"}</Typography>
                      <Typography sx={{ fontWeight: "600", textTransform: "capitalize", fontSize: { sm: "18px", xs: "16px", lg: "20px" } }} variant="h4">{plan.price === "29" && "€29/PM"}</Typography>
                      <Typography sx={{ fontWeight: "600", textTransform: "capitalize", fontSize: { sm: "18px", xs: "16px", lg: "20px" } }} variant="h4">{plan.price === "Gratis" && "Gratis"}</Typography>
                      <Typography sx={{ fontWeight: "600", fontSize: { sm: "18px", xs: "16px", lg: "26px" } }} variant="h4">{plan.price === "292" && "Prijs op Aanvraag"}</Typography>
                      {/* <Typography sx={{ fontWeight: "600", textTransform: "capitalize" }} variant="h4">
                        /maand
                      </Typography> */}
                    </Box>


                    {/* <Typography sx={{ fontWeight: "600", textTransform: "capitalize" }} variant="h3">{plan.price === "Gratis" && "Graits"}</Typography>
                      <Typography sx={{ fontWeight: "600", textTransform: "capitalize" }} variant="h3">{plan.price === "10" && "Graits"}</Typography>  */}


                    <Box mt={2} sx={styles.descWraper}>
                      {plan.subdesc &&
                        <Typography
                          className='info_text'
                          variant='h6'>
                          {plan.subdesc}
                        </Typography>
                      }
                      {plan.description && (
                        <Typography
                          component="p"
                          color="textSecondary"
                          sx={styles.description}
                        >
                          {plan.description}
                        </Typography>
                      )}
                    </Box>

                  </Box>

                  <Box sx={styles.innnerWrapContent}>
                    {plan.perks && (
                      <Box>
                        <List aria-label="perks" sx={{ padding: "0px" }}>
                          {plan.perks.map((perk, index) => (
                            <ListItem
                              sx={styles.listItem}
                              disableGutters
                              key={index}
                            >
                              <ListItemIcon sx={styles.perkIcon}>
                                {/* <CheckIcon /> */}
                                <svg xmlns="http://www.w3.org/2000/svg" width='20' height='20' fill="#54E087FF" id="Flat" viewBox="0 0 256 256">
                                  <path d="M243.33,90.91,114.92,219.31a16,16,0,0,1-22.63,0l-71.62-72a16,16,0,0,1,0-22.61l24-24a16,16,0,0,1,22.57-.06l36.64,35.27.11.11h0l92.73-91.37a16,16,0,0,1,22.58,0l24,23.56A16,16,0,0,1,243.33,90.91Z" />
                                </svg>
                              </ListItemIcon>
                              <ListItemText sx={styles.lists}>{perk}</ListItemText>
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    )}

                    <Box mt="auto" pt={2}>
                      <Button
                        component={Link}
                        to={
                          plan.price !== "292"
                            ?
                            auth.user
                              ? plan.id
                                ? `/purchase/${plan.id}`
                                : RoutePath.dashboard
                              : plan.id
                                ? `/auth/signup?next=/purchase/${plan.id}`
                                : RoutePath.auth
                            : RoutePath.contact
                        }
                        variant="contained"
                        sx={styles.btn}
                        className="btn"
                        size="large"
                        fullWidth
                      >
                        {plan.price === "292" ? "Neem contact met ons op" : "Selecteer"}
                        <svg xmlns="http://www.w3.org/2000/svg" className="svgColr" width="24" height="24" fill="#000" id="Flat" viewBox="0 0 256 256">
                          <path d="M221.66,133.66l-72,72A8,8,0,0,1,136,200V136H40a8,8,0,0,1,0-16h96V56a8,8,0,0,1,13.66-5.66l72,72A8,8,0,0,1,221.66,133.66Z" />
                        </svg>
                      </Button>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        {/* </Container> */}
      </Section></>
  )
}

export default PricingSection
