import React, { useEffect, useState } from 'react'
import Alert from '@mui/material/Alert'
import Meta from '../components/Meta'
import PageLoader from '../components/PageLoader'
import { requireAuth, useAuth } from '../util/auth'
import { useRouter } from '../util/router'
import { redirectToCheckout } from '../util/stripe'
import { settingsPath } from '../util/routes'

function PurchasePage(props) {
  const router = useRouter()
  const auth = useAuth()
  // console.log(auth,"this is the auth section")
  const [formAlert, setFormAlert] = useState()

  useEffect(() => {
    if (auth.user.planIsActive) {
      // If user already has an active plan
      // then take them to Stripe billing
      router.push(settingsPath('billing'))
    } else {
      let mode = router.query.plan === "payperuse" ? "payment" : "subscription"
      // Otherwise go to checkout
      redirectToCheckout(router.query.plan,mode).catch((error) => {
        setFormAlert({
          type: 'error',
          message: error.message
        })
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Meta title="Purchase" />
      <PageLoader>
        {formAlert && (
          <Alert severity={formAlert.type} style={{ maxWidth: '500px' }}>
            {formAlert.message}
          </Alert>
        )}
      </PageLoader>
    </>
  )
}

export default requireAuth(PurchasePage)
