import React from 'react'
import { Box, Stack, Tooltip } from '@mui/material'
import CardContent from '@mui/material/CardContent'
import { School, TipsAndUpdates } from '@mui/icons-material'
import {
  ImproverExplainOption,
  improverExplainOptions
} from '../../shared-types/improver.types'
import { NeedsCreditsButton } from '../../hocs/needsCredits'
import Button from '@mui/material/Button'
import EditableText from '../../components/EditableText'

interface ImproverOutputProps {
  outputText: string
  isStreaming: boolean
  onExplainAction: (
    improverExplainOption: ImproverExplainOption
  ) => Promise<unknown>
  onRetry: () => Promise<unknown>
  onBack: () => void
}

const styles = {
  root: {
    height: '100%'
  },
  container: {
    height: '100%'
  },
  fixButton: {
    backgroundColor: '#1DD75BFF',
    color: '#0A4D20FF',
    borderRadius: 50,
    width: 140,
    alignSelf: 'center',
    '& > svg': {
      mr: 1
    },
    ':hover': {
      backgroundColor: '#0ea942'
    }
  },
  retryButton: {
    width: 240,
    p: 2,
    alignSelf: 'center'
  },
  fixButtonContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  backButton: {
    display: { xs: 'block', md: 'none' },
    mt: 4,
    alignSelf: 'center'
  }
}

function ImproverOutput({
  outputText,
  isStreaming,
  onRetry,
  onExplainAction,
  onBack
}: ImproverOutputProps) {
  const areButtonsDisabled = isStreaming || !outputText

  return (
    <CardContent sx={styles.root}>
      <Stack sx={styles.root} spacing={1}>
        <EditableText
          text={outputText}
          contentType={'Digilo Fix'}
          isReadonly={!outputText || isStreaming}
        />

        <Tooltip title={improverExplainOptions['explanation']}>
          <Box sx={styles.fixButtonContainer}>
            <NeedsCreditsButton
              disabled={areButtonsDisabled}
              variant="contained"
              sx={styles.fixButton}
              onClick={() => onExplainAction('explanation')}
            >
              <School />
              Leer
            </NeedsCreditsButton>
          </Box>
        </Tooltip>

        <Tooltip title={improverExplainOptions['suggestion']}>
          <Box sx={styles.fixButtonContainer}>
            <NeedsCreditsButton
              variant="contained"
              sx={styles.fixButton}
              disabled={areButtonsDisabled}
              onClick={() => onExplainAction('suggestion')}
            >
              <TipsAndUpdates />
              Tip
            </NeedsCreditsButton>
          </Box>
        </Tooltip>

        <NeedsCreditsButton
          color="secondary"
          variant="contained"
          sx={styles.retryButton}
          disabled={areButtonsDisabled}
          onClick={onRetry}
        >
          Nogmaals proberen
        </NeedsCreditsButton>

        <Button
          sx={styles.backButton}
          variant="contained"
          color="inherit"
          onClick={onBack}
        >
          Vorige
        </Button>
      </Stack>
    </CardContent>
  )
}

export default ImproverOutput
