import React from 'react'
import WriterPanel, {
  useGenerateContent,
  WriterStepExtendedProps
} from '../components/writer-panel'
import { createDefaultWriterState } from '../ai-writer.model'
import { generateDescriptionApi } from '../article-generate-api'
import WriterSelection from '../components/writer-selection'

function DescriptionStep(props: WriterStepExtendedProps) {
  const contentGeneration = useGenerateContent(() => {
    props.setWriterState({ description: createDefaultWriterState().description })
    return generateDescriptionApi(props.writerState)
  })
  return (
    <WriterPanel {...props} {...contentGeneration}>
      <WriterSelection
        onSelection={(description) => props.setWriterState({ description })}
        values={contentGeneration.generated}
      />
    </WriterPanel>
  )
}

export default DescriptionStep;
