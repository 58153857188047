import React from 'react'
import Box from '@mui/material/Box'

const styles = {
  root: {
    content: "''",
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    zIndex: 0
  }
}

function BackgroundImage(props) {
  const { image, opacity, ...otherProps } = props

  return (
    <Box
      sx={styles.root}
      style={{
        backgroundImage: `url(${image})`,
        opacity
      }}
      {...otherProps}
    />
  )
}

export default BackgroundImage
