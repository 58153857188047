import React from 'react'
import { NeedsCreditsButton } from '../../../hocs/needsCredits'
import { SxProps } from '@mui/system'

interface WriterRetryButtonProps {
  retry: () => void
  sx?: SxProps
}

const styles = {
  root: {
    m: 'auto'
  }
}

function WriterRetryButton({ retry, sx = {} }: WriterRetryButtonProps) {
  return (
    <NeedsCreditsButton
      sx={{ ...styles.root, ...sx }}
      variant="contained"
      color="secondary"
      onClick={retry}
    >
      Vernieuwen
    </NeedsCreditsButton>
  )
}

export default WriterRetryButton
