import React, { useState } from 'react'
import { useExitIntent } from 'use-exit-intent'
import {
  Alert,
  Card,
  FormControl,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  Snackbar,
  Stack,
  useTheme
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { Close } from '@mui/icons-material'
import Logo from '../Logo'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import { useFormik } from 'formik'
import TextField from '@mui/material/TextField'
import { createExitReason } from './exitReasonApi'
import { useAuth } from '../../util/auth'
import Button from '@mui/material/Button'
import { startOfDay } from 'date-fns'
import useMediaQuery from '@mui/material/useMediaQuery'

const styles = {
  modal: {
    px: {
      xs: 1,
      sm: 4
    },
    '&:focus': {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  card: {
    width: '100%',
    maxWidth: 800,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  closeButton: {
    position: 'absolute',
    right: 0
  }
}

const exitReasons = [
  'Technische oorzaak',
  'Tekstresultaten waren niet naar verwachting',
  'De portal is ongebruiksvriendelijk om doorheen te navigeren.',
  'Prijs',
  ''
]

function createInitialValues() {
  return {
    exitReason: exitReasons[0],
    otherReason: ''
  }
}

function ExitPopup() {
  const { registerHandler, unsubscribe, resetState } = useExitIntent({
    cookie: {
      key: 'exit-intent',
      daysToExpire: 365
    }
  })
  const { user } = useAuth()

  const [open, setOpen] = React.useState(false)
  const handleOpen = () => {
    if (user) {
      resetState()
      return
    }

    if (user?.created?.toDate() < startOfDay(new Date())) {
      return
    }
    unsubscribe()
    setOpen(true)
  }
  const handleClose = () => setOpen(false)

  registerHandler({
    id: 'openModal',
    handler: handleOpen
  })

  const formik = useFormik({
    initialValues: createInitialValues(),
    onSubmit: async ({ exitReason, otherReason }, formikHelpers) => {
      await createExitReason({
        owner: user?.uid,
        exitReason: exitReason || `Anders: ${otherReason}`
      })
      formikHelpers.setSubmitting(false)
      setSnackbarOpen(true)
      handleClose()
    }
  })

  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const handleSnackbarClose = () => setSnackbarOpen(false)

  const { breakpoints } = useTheme()
  const isMobile = useMediaQuery(breakpoints.down('md'))

  return (
    <>
      <Modal open={open} onClose={handleClose} sx={styles.modal}>
        <Card sx={styles.card}>
          <Stack
            justifyContent="center"
            direction="row"
            position="relative"
            width="100%"
          >
            <Logo link={false} sx={{ py: 2 }} />
            <IconButton onClick={handleClose} sx={styles.closeButton}>
              <Close />
            </IconButton>
          </Stack>
          <CardContent>
            <Typography variant="h4" textAlign="center">
              {isMobile ? <>Ga je weg?</> : <>Jammer dat je weggaat!</>}
            </Typography>

            <Typography variant="h5" textAlign="center">
              Kun je misschien aangeven wat de reden is, zodat we Digilo kunnen
              verbeteren?
            </Typography>

            <Stack
              component="form"
              onSubmit={formik.handleSubmit}
              sx={{ mt: 2 }}
            >
              <Typography>
                Wat is de reden dat je geen gebruik hebt gemaakt van Digilo?
              </Typography>

              <Card>
                <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                  <FormControl>
                    <RadioGroup {...formik.getFieldProps('exitReason')}>
                      {exitReasons.map((reason) => (
                        <FormControlLabel
                          key={reason}
                          value={reason}
                          control={<Radio />}
                          label={reason || 'Anders:'}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>

                  {!formik.values.exitReason && (
                    <TextField
                      size="small"
                      {...formik.getFieldProps('otherReason')}
                    />
                  )}
                </CardContent>
              </Card>
              <Button
                disabled={formik.isSubmitting}
                color="secondary"
                type="submit"
                variant="contained"
                sx={{ alignSelf: 'flex-end', mt: 1 }}
              >
                Verstuur
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={1500}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="success">
          Bedankt voor je feedback!
        </Alert>
      </Snackbar>
    </>
  )
}

export default ExitPopup
