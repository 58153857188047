import { useState } from 'react'

export function useMenu() {
  const [menuState, setMenuState] = useState(null)

  const openMenu = (event, id) => {
    setMenuState({ anchor: event.currentTarget, id })
  }

  const closeMenu = () => {
    setMenuState(null)
  }

  return {
    menuState,
    openMenu,
    closeMenu
  }
}
