import React from 'react'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import Section from './Section'
import SectionHeader from './SectionHeader'
import Button from '@mui/material/Button'
import { castArray } from 'lodash-es'
import { Link } from '../util/router'

const useStyles = makeStyles((theme) => ({
  itemsContainer: {
    marginTop: 60
  },
  row: {
    // Reverse every other row
    '&:nth-of-type(even)': {
      flexDirection: 'row-reverse'
    },

    // Spacing between rows
    '&:not(:last-child)': {
      marginBottom: `${theme.spacing(3)}`
    }
  },
  video: {
    height: 'auto',
    width: '100%'
  },
  ctaArrows: {
    width: 240,
    textAlign: 'center'
  }
}))

const styles = {
  feature: {
    py: 2
  }
}

function FeaturesSection(props) {
  const classes = useStyles()

  const items = [
    {
      title: 'Brainstorm Buddy',
      description: (
        <>
          Met Digilo Chat kun je met een simpele opdracht texten creëren, advies
          krijgen en zelfs Excel-formules laten schrijven. Digilo Chat biedt de
          ultieme manier om teksten te creëren, inzichten over verschillende
          onderwerpen te krijgen en zelfs Excel-formules voor je te laten
          schrijven. Digilo Chat is zo krachtig dat de mogelijkheden eindeloos
          lijken.
        </>
      ),
      video: '/video/digilo-chat-trailer.mp4',
      ctaText: 'Probeer Digilo Chat nu gratis uit!'
    },
    {
      title: 'Digilo Blog',
      description: [
        <>
          Digilo Blog biedt een functie waarmee bloggers artikelen kunnen
          schrijven over elk onderwerp. Met deze functie kunnen gebruikers
          binnen enkele minuten diverse artikelen schrijven.Of je nu schrijft
          over een actueel onderwerp of een persoonlijke ervaring; Digilo Blog
          geeft je de mogelijkheid om verhalen te vertellen
        </>,
        'Met Digilo Blog kun je blogs schrijven van minimaal 1500 woorden, waardoor je echt meer diepgang kunt creëren in je verhaal en je ideeën goed kunt uitdiepen. Zo worden langere blogs over elk onderwerp mogelijk gemaakt.'
      ],
      video: '/video/digilo-blog-trailer.mp4',
      ctaText: 'Probeer Digilo Blog nu gratis uit!'
    },
    {
      title: 'Quick Fix',
      description:
        'Digilo Fix stelt jou in staat om binnen de kortste keren je teksten en zinnen te verbeteren met slechts één knop! Maar daar stopt het niet mee. Digilo Fix geeft je duidelijkheid over hoe je tekst is verbeterd door middel van inzichten, waardoor je zelf je schrijfskills kunt verbeteren.',
      video: '/video/digilo-fix-trailer.mp4',
      ctaText: 'Probeer Digilo Fix nu gratis uit!'
    },
    {
      title: 'Artikelen',
      description: [
        'De artikelen schrijver is een fantastisch hulpmiddel om perfecte artikelen te schrijven. Of je nu een beginnende schrijver bent of al professioneel bent, deze artikelen schrijver kan je helpen bij het creëren en perfectioneren van je stukken.',
        'Je kunt eindeloos variëren met deze artikelen schrijver. Hiermee kun je snel en gemakkelijk al je ideeën omzetten in een stuk dat lezers aanspreekt. Deze artikelen schrijver is zo gevarieerd dat hij iedereen in staat stelt om teksten te creëren die de aandacht trekken.'
      ],
      video: '/video/digilo-artikel-trailer.mp4',
      ctaText: 'Probeer de artikelen schrijver van Digilo nu gratis uit!'
    },
    {
      title: 'E-mail',
      description: [
        'Met de E-mail Schrijver krijg je de perfecte e-mails die perfect zijn opgesteld. Of je nu een zakelijk bericht stuurt of een informeel bericht naar familie, met de E-mail Schrijver heb je altijd de juiste woorden bij de hand. Met deze schrijver bereik je het juiste doel met elke tekst. Weet je niet goed hoe je netjes in tekst moet schrijven? Geen probleem! Je bent er zeker van dat iedereen je mails begrijpt en weet wat er bedoeld wordt. Laat je inspireren door ons prachtige tool om perfecte mails te schrijven, waarmee je je communicatie naar een hoger niveau tilt!'
      ],
      video: '/video/digilo-email-trailer.mp4',
      ctaText: 'Probeer de e-mail schrijver van Digilo nu gratis uit!'
    },
    {
      title: 'Voorstellen',
      description: [
        'De voorstel schrijver helpt je om overtuigende voorstellen te maken. Ben je op zoek naar een manier om jouw ideeën en gedachten op een professionele manier over het voetlicht te brengen? Dan biedt de voorstel schrijver de perfecte oplossing.'
      ],
      video: '/video/digilo-voorstel-trailer.mp4',
      ctaText: 'Probeer de voorstellen schrijver van Digilo nu gratis uit!'
    },
    {
      title: 'Advertentie',
      description: [
        'Met de advertentie schrijver kun je krachtige advertenties maken. Deze gebruiksvriendelijke tool stelt bedrijven in staat om effectieve advertenties te creëren voor Google, Facebook en LinkedIn. Met de advertentie schrijver hoef je geen tijd meer te verspillen aan het opzetten en onderhouden van complexe reclamecampagnes. De gebruiker heeft slechts een paar minuten nodig om een simpele campagne te configureren, waardoor je bedrijf meer klanten voor jouw product of dienst kan bereiken.'
      ],
      video: '/video/digilo-advertenties-trailer.mp4',
      ctaText: 'Probeer de advertentie schrijver van Digilo nu gratis uit!'
    }
  ]

  // @ts-ignore
  return (
    <Section
      disableDivider={true}
      sectionId={props.sectionId}
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <Container
          maxWidth="lg"
          disableGutters
          className={classes.itemsContainer}
        >
          <Grid container spacing={8}>
            {items.map((item, index) => (
              <Grid
                className={classes.row}
                container
                item
                sx={styles.feature}
                alignItems="center"
                columnSpacing={4}
                rowSpacing={2}
                key={index}
              >
                <Grid item xs={12} md={6}>
                  <Box
                    textAlign={{
                      xs: 'center',
                      md: 'left'
                    }}
                  >
                    <Typography variant="h6" gutterBottom sx={{fontSize:{xs:"18px",sm:"1.25rem"}}}>
                      {item.title}
                    </Typography>
                    {castArray(item.description).map((description, i) => (
                      <Typography key={i} variant="body1" sx={{ py: 2 }}>
                        {description}
                      </Typography>
                    ))}
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <video
                    playsInline
                    muted
                    loop
                    autoPlay
                    className={classes.video}
                  >
                    <source src={item.video} type="video/mp4" />
                  </video>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{ display: 'flex', justifyContent: 'center' }}
                >
                  <img
                    src="/img/cta-arrows.gif"
                    className={classes.ctaArrows}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  justifyContent="center"
                  sx={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Button
                    component={Link}
                    to={props.ctaLink}
                    variant="contained"
                    sx={{
                      backgroundColor: '#EF9834FF',
                      py: 1,
                     
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ fontSize: { xs: '0.6rem', md: '1.25rem' } }}
                    >
                      {item.ctaText}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Container>
    </Section>
  )
}

export default FeaturesSection
