import React from 'react'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import LinkMui from '@mui/material/Link'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import { makeStyles } from '@mui/styles'
import Section from './Section'
import { Link } from '../util/router'
import { useDarkMode } from '../util/theme'

const useStyles = makeStyles((theme) => ({
  sticky: {
    marginTop: 'auto'
  },
  brand: {
    display: 'block',
    height: 32
  },
  listItem: {
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 12,
    paddingRight: 12
  },
  listItemTextHeader: {
    fontWeight: 'bold'
  },
  socialIcon: {
    minWidth: 30
  },
  legal: {
    marginTop: theme.spacing(3),
    fontSize: '0.875rem',
    opacity: 0.6,
    '& a': {
      color: 'inherit',
      marginLeft: '0.8rem'
    }
  }
}))

function Footer(props) {
  const classes = useStyles()

  const darkMode = useDarkMode()
  // Use inverted logo if specified
  // and we are in dark mode
  const logo =
    props.logoInverted && darkMode.value ? props.logoInverted : props.logo

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      className={props.sticky && classes.sticky}
    >
      <Container>
        <Grid container justifyContent="space-between" spacing={4}>
          <Grid item xs={12} md={4}>
            <Link to="/">
              <img src={logo} alt="Logo" className={classes.brand} />
            </Link>

            <Box mt={3}>
              <Typography component="p">
                Digilo is een revolutionaire manier om kunstmatige intelligentie
                te gebruiken om artikelen en andere tekstsoorten sneller en
                efficiënter te schrijven.
              </Typography>
            </Box>

            <div className={classes.legal}>
              {props.copyright}
              <LinkMui component={Link} to="/legal/terms-of-service">
                Algemene Voorwaarden
              </LinkMui>
              <LinkMui component={Link} to="/legal/privacy-policy">
                Privacy
              </LinkMui>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <List disablePadding>
                  <ListItem className={classes.listItem}>
                    <Typography
                      variant="overline"
                      className={classes.listItemTextHeader}
                    >
                      Product
                    </Typography>
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/pricing"
                    button
                    className={classes.listItem}
                  >
                    <ListItemText>Prijs</ListItemText>
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} md={4}>
                <List disablePadding>
                  <ListItem className={classes.listItem}>
                    <Typography
                      variant="overline"
                      className={classes.listItemTextHeader}
                    >
                      Digilo
                    </Typography>
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/about"
                    button
                    className={classes.listItem}
                  >
                    <ListItemText>Over ons</ListItemText>
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} md={4}>
                <List disablePadding>
                  <ListItem className={classes.listItem}>
                    <Typography
                      variant="overline"
                      className={classes.listItemTextHeader}
                    >
                      Social
                    </Typography>
                  </ListItem>
                  <ListItem
                    button
                    component="a"
                    href="https://twitter.com/DigiloInfo"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.listItem}
                  >
                    <ListItemIcon className={classes.socialIcon}>
                      <img
                        src="https://uploads.divjoy.com/icon-twitter.svg"
                        alt="Facebook"
                      />
                    </ListItemIcon>
                    <ListItemText>Twitter</ListItemText>
                  </ListItem>
                  <ListItem
                    button
                    component="a"
                    href="https://www.facebook.com/profile.php?id=100089498560799"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.listItem}
                  >
                    <ListItemIcon className={classes.socialIcon}>
                      <img
                        src="https://uploads.divjoy.com/icon-facebook.svg"
                        alt="Facebook"
                      />
                    </ListItemIcon>
                    <ListItemText>Facebook</ListItemText>
                  </ListItem>
                  <ListItem
                    button
                    component="a"
                    href="https://www.instagram.com/digilo.io/"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.listItem}
                  >
                    <ListItemIcon className={classes.socialIcon}>
                      <img
                        src="https://uploads.divjoy.com/icon-instagram.svg"
                        alt="Facebook"
                      />
                    </ListItemIcon>
                    <ListItemText>Instagram</ListItemText>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Section>
  )
}

export default Footer
