import { satisfy } from '../../shared-types/types'
import { ImproverOption } from '../../shared-types/improver.types'
import {
  Assistant,
  Attribution,
  AutoFixHigh,
  BusinessCenter,
  Campaign,
  Carpenter,
  Diversity1,
  Engineering,
  Palette,
  PanToolAlt,
  People,
  Person,
  ShortText,
  Summarize,
  SvgIconComponent,
  Troubleshoot
} from '@mui/icons-material'
import React from 'react'
import { OverridableComponent } from '@mui/material/OverridableComponent'

export const improverOptionIcons = satisfy<
  Record<ImproverOption, SvgIconComponent>
>()({
  improve: AutoFixHigh,
  correct: Troubleshoot,
  rewriteMe: Person,
  rewriteYou: rotateIcon(PanToolAlt),
  rewriteWe: People,
  objective: Diversity1,
  subjective: Attribution,
  professionalize: BusinessCenter,
  create: Palette,
  technical: Engineering,
  journalistic: Campaign,
  condense: ShortText,
  summary: Summarize,
  explainToChild: Carpenter,
  taskAndSteps: Assistant
})

function rotateIcon<T extends OverridableComponent<any>>(
  Component: OverridableComponent<any>
): T {
  const RotatedIcon = (props: any) => {
    return <Component {...props} sx={{ transform: 'rotate(45deg)' }} />
  }
  return RotatedIcon as T
}
