import { loadStripe } from '@stripe/stripe-js'
import { apiRequest } from './requests'
import { getStripePriceId } from './prices'
import {RoutePath, settingsPath} from './routes'

let stripe
// Load the Stripe script
loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, {
  // Pin to specific version of the Stripe API
  apiVersion: '2020-08-27'
}).then((stripeInstance) => {
  // Set stripe so all functions below have it in scope
  stripe = stripeInstance
})

export async function redirectToCheckout(planId,mode) {
  // Create a checkout session
  const session = await apiRequest(
    'stripeCreateCheckoutSession',
    'functions',
    'POST',
    {
      priceId: getStripePriceId(planId),
      successUrl: `${window.location.origin}${RoutePath.thankyou}?paid=true`,
      cancelUrl: `${window.location.origin}${RoutePath.pricing}`,
      paymentMode : mode
    }
  )

  // Ensure if user clicks browser back button from checkout they go to /pricing
  // instead of this page or they'll redirect right back to checkout.
  window.history.replaceState({}, '', '/pricing')

  // Redirect to checkout
  return stripe.redirectToCheckout({
    sessionId: session.id
  })
}

export async function redirectToBilling() {
  // Create a billing session
  const session = await apiRequest(
    'stripeCreateBillingSession',
    'functions',
    'POST',
    {
      returnUrl: `${window.location.origin}${settingsPath("general")}`
    }
  )

  // Ensure if user clicks browser back button from billing they go to /app/settings/general
  // instead of this page or they'll redirect right back to billing.
  window.history.replaceState({}, '', settingsPath("general"))

  // Redirect to billing session url
  window.location.href = session.url
}
