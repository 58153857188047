import React, { useEffect, useState, useCallback } from 'react'
import AppLayout from '../../components/AppLayout'
import MainPanel from '../../components/MainPanel'
import { requireAuth } from '../../util/auth.js'
import { Typography } from '@material-ui/core'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import { CancelSharp, ThumbUpOffAlt, ThumbDownOffAlt, ThumbUp, AutoFixHigh, Done, Close, Add, ShortText, ContentCopy, Html, Download, DriveFolderUpload, Search, Menu, AddLink, FormatBold, FormatItalic, FormatUnderlined, Code, ChevronRight, KeyboardArrowDown, KeyboardVoice, AutoAwesome, BrowserNotSupported, FilterList, HelpOutline, BorderColor, RssFeedSharp, AssignmentSharp } from '@mui/icons-material';
import { makeStyles } from '@mui/styles'
import FormControl, { useFormControl } from '@mui/material/FormControl';
import { Input } from '@mui/material';
import './digiloFixAi.css'
import { Configuration, OpenAIApi } from 'openai'
import { TopicOptions } from './digiloFixAiOptions'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { apiRequest } from '../../util/requests'
import { useAuth } from '../../util/auth.js'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
// import { saveAs } from 'file-saver';
import Tooltip from '@mui/material/Tooltip';
// import { Editor } from 'tinymce'
import { Editor } from '@tinymce/tinymce-react'
import Modal from '@mui/material/Modal';
import Snackbar from '@mui/material/Snackbar'
import { Alert } from '@mui/material'
import SaveContentButton from '../../components/SaveContentButton'
import FeedbackPopup from '../../components/FeedbackPopup/FeedbackPopup'
import { digiloGeneratorApi } from '../../features/ai-generator/digilofix-editor'
import { Radio, FormControlLabel, RadioGroup } from '@mui/material';
import { useLocation } from "react-router-dom";
import CreditsInfo from '../../components/CreditsInfo'
import MainPanelHeader from '../../components/MainPanelHeader'
import { useHistory } from '../../util/router'
import { streamApiRequest } from '../../util/requests'
import { parseAnswer } from '../../features/ai-assistance/text-generate-api'
// import RichTextEditor from './RichTextEditor'
const DigiloFixAi = () => {
    return (
        <div>
            <AppLayout title='Adminuser'>
                <MainPanel
                    main={<DigiloFixAiMain />}
                    header={<MainPanelHeader additional={<CreditsInfo />} />}
                />
            </AppLayout>
        </div>
    )
}
const useStyles = makeStyles((theme) => ({

    spantext: {
        color: "white",
        fontSize: '18px !important',
        margin: "15px 0px !important",
        fontWeight: "Bold !important"
    },
    typography: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    radio: {
        padding: 0, // Set padding to 0 to remove default padding
    },

    list: {
        display: "none"
    },
    list_data: {
        display: "block"
    },
    redo_btn: {
        background: "rgba(0,0,0,20%)",
        color: "#fff"
    },
    cmn_popup: {
        background: "#1278BCFF",
        borderRadius: 1,
        padding: 10,
        marginTop: 10,
        minWidth: "366px", borderRadius: "6px"
    },
    cmn_width: {
        background: "#1278BCFF",
        borderRadius: 1,
        padding: 10,
        marginTop: 10,
        minWidth: "430px",
        borderRadius: "6px"
    },
    hideDilaoge: {
        display: "none"
    },
    whiteText: {
        color: "#fff",
        fontSize: "14px",

    },
    dropDownHead: {
        position: "relative"
    },
    dropDown: {
        position: "absolute",
        background: "#1278BCFF",
        borderRadius: 1,
        padding: 10,
        right: '-180px',
        minWidth: '150px',
        top: "0px", borderRadius: "6px"
    },
    dropDownhide: {
        display: "none"
    },
    textFields: {
        color: "#fff",

        '& .MuiInputBase-input': {
            color: '#fff', // Change the text color
        },
    },
    text_white: {
        color: "#fff",
        margin: "0px !important",
        padding: "0px",
        textAlign: "left",
        justifyContent: "flex-start",

    },
    headingOne: {
        fontSize: '28px !important'
    },
    headingTwo: {
        fontSize: '24px !important'
    },
    headingThree: {
        fontSize: '20px !important'
    },
    textField: {
        '& .MuiInputBase-root': {
            border: '1px solid #fff', // Change the border color to your desired color
            color: "#fff !important",
            '& input::placeholder': {
                color: '#fff !important', // Change the placeholder color to your desired color
            },
        },
    },
    inputLabel: {
        color: '#fff'
    }

}))
const Modalstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: "10px"
};
const DigiloFixAiMain = () => {
    const [copieText, setCopieText] = useState('')
    useEffect(() => {
        // Retrieve data from local storage
        const storedData = localStorage.getItem('text');
        if (storedData) {
            setCopieText(storedData);
            // Clear local storage after setting the state
            localStorage.removeItem('text');
        }
    }, []);


    const location = useLocation();
    const history = useHistory()
    // const anchorFix = location?.state?.anchorFix ? location?.state?.anchorFix : "";
    const achrorUpdatedText = `<pre class="pree" style="white-space:pre-wrap;break-word;font-family:'Poppins'">${copieText ? copieText : ""}</pre>`
    const [open, setOpen] = React.useState(false);
    const classes = useStyles()
    const [show, setShow] = useState(false)
    const [showdialoge, setShowdialoge] = useState(false)
    const [dilogetext, showDilogetext] = useState('')
    const [showdropdown, setShowdropdown] = useState(false)
    const [showdropdownOne, setShowdropdownOne] = useState(false)
    const [text, setText] = useState("");
    const [blogText, setBlogText] = useState("");
    const [promtText, setPromtText] = useState("");
    const [showpromptText, setshowpromptText] = useState("");
    const editorRef = React.useRef(null);
    const ChildEditorRef = React.useRef(null);
    const blogEditorRef = React.useRef(null);
    const promptEditorRef = React.useRef(null);
    const { options } = TopicOptions();
    const [showtextFormatter, setShowtextFormatter] = useState(false)
    const [showtoolbar, setShowToolbar] = useState(false)
    const [promptOptions, setPromptOptions] = useState("")
    const [disablePrompt, setDisablePrompt] = useState(false)
    const [disableOption, setDisableOption] = useState(false)
    const [disableInput, setDisableInput] = useState(false)
    const [context, setContext] = useState("")
    const [taal, setTaal] = useState("")
    const [woorden, setWoorden] = useState()
    const [alienas, setAlienas] = useState(null)
    const [toon, setToon] = useState("")
    const [doel, setDoel] = useState("")
    const [style, setStyle] = useState("")
    const [diffrent, setDiffrent] = useState(null)
    const [disabledAi, setDisabledAi] = useState(true)
    const [disableText, setDisableText] = useState(true)
    const [karakter, setKarakters] = useState("")
    const [woordenRadio, setWoordenRadio] = useState(true)
    const [chracterRadio, setChracterRadio] = useState(false)
    const configuration = new Configuration({
        apiKey: "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",
    });
    const [customOption, setCustomOption] = useState("")
    const [copiedText, setCopiedText] = useState("")
    const openai = new OpenAIApi(configuration);
    const [prompt, setPrompt] = useState(false);
    const [disableButton, setDisableButton] = useState(true);
    const [aiResponse, setApiResponse] = useState("");
    const [aiBlogResponse, setBlogApiResponse] = useState("");
    const [loading, setLoading] = useState(false);
    const [showLink, setShowLink] = useState(false);
    const [linkUrl, setLinkUrl] = useState('');
    const [showFileUploader, setshowFileUploader] = useState(false)
    const [store, setStore] = useState()
    const [showAskAi, setShowAskAi] = useState(false);
    const [aidiloge, setAidiloge] = useState(false);
    const [promtContnet, setPromtContnet] = useState(false);
    const [disabled, setdisabled] = useState(false)
    const [wordCount, setWordCount] = useState(0)
    const [chracterCount, setChracterCount] = useState(0)
    const [unlike, setUnlike] = useState(false)
    const [like, setlike] = useState(false)
    const [showalert, setShowalert] = useState(false)
    const [showalerTtext, setShowalertText] = useState("")
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [feedbackopen, setFeedbackOpen] = React.useState(false)
    const [feedbackModalOne, setfedbackmodalone] = React.useState(false)
    const [showcontextChild, setShowContextChild] = useState(false);
    const [situation, setSituation] = useState("")
    const [reason, setReason] = useState("")
    const [person, setPerson] = useState("")
    const [happen, setHappen] = useState("")
    const [howHappen, setHowHappen] = useState("")
    const [consequences, setConsequences] = useState("")
    const [shake, setShake] = useState(true)
    const [timeInMinutes,setTimeInMinutes] = useState(false)
    const[currenturl, setCurrenturl] = useState("/app");
    // fortmated showpromptText text
    const formattedPromtText = `<pre class="pree" style="white-space:pre-wrap;break-word;font-family:'Poppins'">${showpromptText}</pre>`
    const formattedAiResponse = `<pre class="pree" style="white-space:pre-wrap;break-word;font-family:'Poppins'">${aiResponse} </pre>`
    const formattedaiBlogResponse = `<pre class="pree" style="white-space:pre-wrap;break-word;font-family:'Poppins'">${aiBlogResponse}</pre>`

    const handleMinimize = () => {
        setShowalert(false)
    }
    const handLikeUnlike = () => {
        setFeedbackOpen(true)
    }
    const handleLike = () => {
        setlike(true)
    }
    const user = useAuth()
    const creadit = user?.user?.credits
    const aiData = async (data) => {
        setLoading(true);
        try {
            const result = await openai.createCompletion({
                model: "text-davinci-003",
                prompt: data,
                temperature: 0.7,
                top_p: 1,
                max_tokens: 3000,
                frequency_penalty: 0,
                presence_penalty: 0,
                n: 1
            });
            setApiResponse(result.data.choices[0].text);
            setLoading(false)
        } catch (e) {
            setApiResponse("Something is going wrong, Please try again.");
        }
    }


    //     setInterval(() => {
    //       const SignUpTime = user?.user?.created?.seconds;
    //       const timestampInMilliseconds = SignUpTime;
    //       const newTimestampInMilliseconds = timestampInMilliseconds;
    //       let date = new Date(newTimestampInMilliseconds * 1000);
    //       let currentTime = new Date();
    //       var timeDifference = currentTime - date;
    //       var time_in_seconds = timeDifference / 1000;
    //       var time_in_minutes = time_in_seconds / 60;
    //       time_in_minutes > 30 && user?.user?.paymentType === undefined && user?.user?.stripeSubscriptionStatus === undefined ? history.push("/app") : null
        
       
    // }, 1000);
    useEffect(() => {
        let redirectFlag = false; // Flag to track whether redirection has already occurred
      
        const intervalId = setInterval(() => {
          const SignUpTime = user?.created?.seconds;
          const timestampInMilliseconds = SignUpTime * 1000;
          const newTimestampInMilliseconds = timestampInMilliseconds;
          let date = new Date(newTimestampInMilliseconds);
          let currentTime = new Date();
          var timeDifference = currentTime - date;
          var time_in_seconds = timeDifference / 1000;
          var time_in_minutes = time_in_seconds / 60;
        //   console.log(time_in_minutes, "total time passed");
        //   console.log(user?.stripeSubscriptionStatus, "user?.stripeSubscriptionStatus");
        //   console.log(user?.paymentStatus, "user?.paymentStatus");
      
          if (
            time_in_minutes > 30 &&
            user?.paymentType === undefined &&
            user?.stripeSubscriptionStatus === undefined &&
            location.pathname !== currenturl &&
            !redirectFlag
          ) {
            redirectFlag = true;
            history.push("/app");
          }
        }, 1000);
      
        return () => {
          clearInterval(intervalId);
        };
      }, [user, history, location.pathname, currenturl])


    //selection function
    // const handleSelectionChange = (selectedText) => {
    //     setText(selectedText)

    // };
    // useEffect(() => {

    // }, [third])


    const handleBlog = async () => {
        setApiResponse("");
        setLoading(true);
        const state = {
            "option": "Blog",
            "text": blogText
        }
        const result = await digiloGeneratorApi('digiloFixAi',state,setApiResponse)
        setBlogApiResponse(result.results[0]);
        setLoading(false)
    }

    const showDialogebtn = async (option) => {
        setApiResponse("");
        setDisableButton(true);
        if (creadit === 0) {
            toast("Words limit finished!")
        } else {
            setShowdialoge(true);
            setShow(false);
            // setLoading(true);
            // console.log(text, "text-----")
            const state = {
                "option": option,
                "text": text
            }
            await digiloGeneratorApi(`digiloFixAi`, state, setApiResponse)
            setDisableButton(false);
            // const result = await apiRequest('digiloFix', 'backend', 'POST', state)
            // setApiResponse(result.results[0]);
            // setLoading(false)
        }


    }
    const showCutomPrompt = async (option) => {
        setPromtContnet(true);
        setLoading(true);
        let openAnswer = ''
        const state = {
            "option": option,
            "text": text
        }
        const result = await streamApiRequest('digiloFix', 'backend', 'POST', state,)
        setshowpromptText(result.results[0])
        // setPromtText(" ")
        setText('')
        setLoading(false)
    }
    const showTonedropdown = () => {
        setShowdropdown(!showdropdown)
    }

    const showTonedropdownOne = () => {
        setShowdropdownOne(!showdropdown)
    }

    //  bold text function
    const handleBoldButtonClick = () => {
        setShowLink(false);
        setShowtextFormatter(false)
        const editor = ChildEditorRef.current;
        const selectedText = editor.selection.getContent({ format: 'text' });
        if (selectedText) {
            editor.execCommand('Bold');
        }
        setPrompt(false)
    };

    // useEffect(() => {
    //      const width = window.innerWidth;
    // const styleLink = document.getElementById('main-style');

    // if (width < 768) {
    //   alert("on touch devices")
    // } else {
    // alert("on desktop")
    // }
    // })
    // italic text function
    const handleItalicButtonClick = () => {
        setShowLink(false);
        setShowtextFormatter(false)
        const editor = ChildEditorRef.current;
        const selectedText = editor.selection.getContent({ format: 'text' });
        if (selectedText) {
            editor.execCommand('Italic');
        }
        setPrompt(false)
    };
    // heading one function
    const handleHeadingOneButtonClick = () => {
        const editor = ChildEditorRef.current;
        const selectedText = editor.selection.getContent({ format: 'text' });
        if (selectedText) {
            editor.execCommand('FormatBlock', false, 'h1');
        }
        setShowtextFormatter(false)
    };
    // heading two function
    const handleHeadingTwoButtonClick = () => {
        const editor = ChildEditorRef.current;
        const selectedText = editor.selection.getContent({ format: 'text' });
        if (selectedText) {
            editor.execCommand('FormatBlock', false, 'h2');
        }
        setShowtextFormatter(false)

    };
    // heading three function
    const handleHeadingThreeButtonClick = () => {
        const editor = ChildEditorRef.current;
        const selectedText = editor.selection.getContent({ format: 'text' });
        if (selectedText) {
            editor.execCommand('FormatBlock', false, 'h3');
        }
        setShowtextFormatter(false)
    };
    // Bullet list function
    const handleBulletListClick = () => {
        const editor = ChildEditorRef.current;
        const selectedText = editor.selection.getContent({ format: 'text' });
        if (selectedText) {
            editor.execCommand('InsertUnorderedList');
        }
        setShowtextFormatter(false)
    };
    // Numbered list function
    const handleNumberedListClick = () => {
        const editor = ChildEditorRef.current;
        const selectedText = editor.selection.getContent({ format: 'text' });
        if (selectedText) {
            editor.execCommand('InsertOrderedList');
        }
        setShowtextFormatter(false)
    };


    const handleCode = () => {
        setShowLink(false);
        setShowtextFormatter(false)
        const editor = ChildEditorRef.current;
        const selectedText = editor.selection.getContent({ format: 'text' });
        if (selectedText) {
            editor.formatter.toggle('code');
        }
        setPrompt(false)
    }
    // blockQuote function
    const handleBlockQuoteClick = () => {
        const editor = ChildEditorRef.current;
        const selectedText = editor.selection.getContent({ format: 'text' });
        if (selectedText) {
            editor.execCommand('FormatBlock', false, 'blockquote');
        }
        setShowtextFormatter(false)
    };
    const handleUnderlineClick = () => {
        setShowLink(false);
        setShowtextFormatter(false)
        const editor = ChildEditorRef.current;
        const selectedText = editor.selection.getContent({ format: 'text' });
        if (selectedText) {
            editor.execCommand('Underline');
        }

    };
    // replace editor selected text with ai respoonse
    const replaceAiSelection = () => {
        const editor = editorRef.current;
        // const selectedText = editor.selection.getContent({ format: 'text' });
        const customText = aiResponse;
        editor.selection.setContent(customText);
        setShowdialoge(false);
        setApiResponse('')
    }
    const handelReplacePromt = () => {
        const editor = editorRef.current;
        // const selectedText = editor.selection.getContent({ format: 'text' });
        const customText = showpromptText;
        editor.selection.setContent(customText);
        setPromtContnet(false)
        // setshowpromptText("")
    }
    const handelInsertbelowPrompt = () => {
        const editor = editorRef.current;
        const selectedText = editor.selection.getContent({ format: 'text' });
        const customText = showpromptText;
        editor.selection.setContent(selectedText + "<br>" + formattedPromtText);
        setPromtContnet(false);
        // setshowpromptText("")
    }

    const handelInsertTop = () => {
        const editor = editorRef.current;
        const insertedText = `<div class="pre_wraper"><pre class="pree" style="white-space:pre-wrap;break-word;font-family:'Poppins'">${aiBlogResponse}</pre><div>`// Replace with your desired text
        const content = editor.getContent({ format: 'html' });
        editor.setContent(insertedText + content);
        setBlogApiResponse("");
        setBlogText('');
        setShowAskAi(false);
        setAidiloge(false)
    }

    const handelInsertLast = () => {
        const editor = editorRef.current;
        const insertedText = aiBlogResponse // Replace with your desired text
        const content = editor.getContent({ format: 'text' });
        editor.setContent(content + insertedText);
        setBlogApiResponse("");
        setBlogText('');
        setShowAskAi(false);
        setAidiloge(false)
    }

    const handleInsertContent = () => {
        const editor = editorRef.current;
        const selectedText = editor.selection.getContent({ format: 'text' });
        const customText = aiResponse;
        editor.selection.setContent(selectedText + "<br>" + customText);
        setShowdialoge(false);
        setApiResponse('')
    }
    // Apply link
    const handleApplyLinkClick = () => {
        // setShowToolbar(false);
        const editor = ChildEditorRef.current;
        const selectedText = editor.selection.getContent({ format: 'text' });
        if (selectedText) {
            editor.execCommand('mceInsertLink', false, {
                href: linkUrl, // Replace with your desired URL
                text: selectedText,
                target: '_blank',
            });
        }
        setShowLink(false);
        setLinkUrl("")
    };

    // remove link
    const handleRemoveLinkClick = () => {
        const editor = ChildEditorRef.current;
        const selectedText = editor.selection.getContent({ format: 'text' });
        if (selectedText) {
            setLinkUrl('')
            editor.execCommand('unlink');
        }
        setShowLink(false);
    };
    // link onchange
    const handleLinkInputChange = (e) => {
        setLinkUrl(e.target.value)
    };

    const showLinkdata = () => {
        setShow(false)
        setShowToolbar(true);
        setShowLink(!showLink);
        setShowtextFormatter(false);
        setPrompt(false)
    }
    const handleText = (e) => {
        e.stopPropagation()

    }
    const handleExportClick = () => {
        const editorData = editorRef.current.getContent();

        const htmlContent = `
        <!DOCTYPE html>
        <html>
            <head>
            <meta charset="UTF-8">
            <style>
                body {
                font-family: Poppins;
                }
            </style>
            </head>
            <body>
            ${editorData}
            </body>
        </html>
        `;
        const blob = new Blob([htmlContent], { type: 'text/html' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'Digilo-export.doc';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };


    const handleCopyHtml = () => {
        const editor = editorRef.current;
        const content = editor.getContent({ format: 'html' });

        // Create a temporary textarea element
        const textarea = document.createElement('textarea');
        textarea.value = content;

        // Append the textarea to the document body
        document.body.appendChild(textarea);

        // Copy the content from the textarea to the clipboard
        textarea.select();
        document.execCommand('copy');

        // Remove the temporary textarea element
        document.body.removeChild(textarea);
    };
    const handleCopy = () => {
        const editor = editorRef.current;
        const content = editor.getContent();
        const plainText = content.replace(/<[^>]*>/g, '\n');

        // Create a temporary textarea element
        const textarea = document.createElement('textarea');

        // Use DOMParser to decode HTML entities
        const parser = new DOMParser();
        const decodedText = parser.parseFromString(`<!doctype html><body>${plainText}`, 'text/html').body.textContent;
        textarea.value = decodedText;

        // Append the textarea to the document body
        document.body.appendChild(textarea);

        // Select and copy the content from the textarea to the clipboard
        textarea.select();
        try {
            document.execCommand('copy');
            console.log('Text copied to clipboard');
        } catch (err) {
            console.error('Unable to copy text to clipboard:', err);
        }

        // Remove the temporary textarea element
        document.body.removeChild(textarea);
    };

    // let storedWord = '';
    // const convertToBase64 = (file) => {
    //     return new Promise((resolve, reject) => {
    //         const reader = new FileReader();
    //         reader.onload = () => resolve(reader.result);
    //         reader.onerror = (error) => reject(error);
    //         reader.readAsDataURL(file);
    //     });
    // };
    // // Function to handle the file reading
    // const handleFileRead = async (e) => {
    //     const content = e.target.result;
    //     const htmlcontent = `<iframe src='${content}' title="W3Schools Free Online Web Tutorials"></iframe>

    //     `;
    //     setStore(htmlcontent)

    // };

    // const handleFile = (e) => {

    //     const file = e.target.files[0];
    //     if(file.type === "application/pdf"){
    //         const reader = new FileReader();
    //         reader.onloadend = handleFileRead;
    //         reader.readAsDataURL(file);
    //         if (file) {
    //             convertToBase64(new Blob([file]))
    //                 .then((base64String) => {
    //                     setImageData(base64String);
    //                 })
    //                 .catch((error) => {
    //                 });
    //         }
    //     }else{
    //         const reader = new FileReader();
    //         reader.onloadend = handleFileRead;
    //         reader.readAsText(file);
    //         if (file) {
    //             convertToBase64(new Blob([file]))
    //                 .then((base64String) => {
    //                     setImageData(base64String);
    //                 })
    //                 .catch((error) => {
    //                 });
    //         }
    //     }


    // }


    // const handleFileSubmit = () => {
    //     const editor = editorRef.current;
    //     editor.setContent(store)
    // }
    let storedWord = '';
    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(file);
        });
    };

    // const convertPDFToWord = async (pdfBytes) => {
    //     try {
    //       const pdfDoc = await PDFDocument.load(pdfBytes);

    //       // Extract text from the PDF (you can modify this based on your requirements)
    //       const pages = pdfDoc.getPages();
    //       let extractedText = '';
    //       for (const page of pages) {
    //         const content = page.getTextContent();
    //         extractedText += content.items.map((item) => item.str).join(' ');
    //       }

    //       // Convert the extracted text to a Word document
    //       const doc = new Document();
    //       const paragraph = doc.createParagraph(extractedText);
    //       doc.addParagraph(paragraph);

    //       // Save the Word document as a Blob
    //       const packer = new Packer();
    //       const blob = await packer.toBlob(doc);

    //       // Process the Word document as needed (e.g., extract word and styling)
    //       const wordContent = await blob.text();
    //       const storedWord = wordContent.trim();
    //       setStore(storedWord);
    //     } catch (error) {
    //     }
    //   };

    const getFileExtension = (filename) => {
        const parts = filename.split('.');
        return parts[parts.length - 1].toLowerCase();
    };

    //   const handleFileRead = (content) => {
    //     const storedWord = content.trim();
    //     setStore(storedWord);
    //   };

    //   const handleFile = (e) => {
    //     const file = e.target.files[0];
    //     if (!file) {
    //       return;
    //     }
    //     const reader = new FileReader();
    //     reader.onloadend = () => {
    //       const content = reader.result;
    //       const extension = getFileExtension(file.name);
    //       if (extension === 'pdf') {
    //         convertPDFToWord(content);
    //       } else {
    //         handleFileRead(content);
    //       }
    //     };
    //     reader.readAsText(file);
    //   };
    // Function to handle the file reading
    const handleFileRead = (e) => {
        const content = e.target.result;

        const bodyRegex = /<body[^>]*>([\s\S]*?)<\/body>/i;
        const bodyContent = bodyRegex.exec(content);

        setTimeout(() => {
            if (!bodyContent || bodyContent[1].trim() === '') {
                // Display an error message to the user
                alert("Sorry dit bestand is leeg")
                // Optionally, you can set the store to an empty string or null to clear any existing content
                setStore('');
            } else {
                const formattedContent = `<pre class="pree" style="white-space:pre-wrap;break-word;font-family:'Poppins'">${content}</pre>`; // Wrap content in <pre> tags
                setStore(formattedContent);
            }
        })
    };




    const handleFile = (e) => {
        const file = e.target.files[0];
        if (file.type === "application/pdf") {
            setdisabled(true)
            alert("Sorry Pdf is not allowed")

        } else if (file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
            setdisabled(false)
            const formData = new FormData();
            formData.append('file', file);

            fetch('https://ai-writer-service-3ir4o.ondigitalocean.app/convert', {
                method: 'POST',
                body: formData,
            })
                .then((response) => response.text())
                .then((html) => {
                    setStore(html);
                })
                .catch((error) => {
                    console.error('Error converting file:', error);
                });
        }
        else {
            setdisabled(false)
            const reader = new FileReader();
            reader.onloadend = handleFileRead;
            reader.readAsText(file);
        }
    };

    const handleFileSubmit = () => {
        if (store === undefined) {
            alert("please select a file")
        } else {
            const editor = editorRef.current
            editor.setContent(store)
            setshowFileUploader(false)
        }
    }

    const handleEditorChange = () => {
        const editor = editorRef.current;
        const word = editor.getContent({ format: 'text' });
        if (word.length === 0) {
            setDisableText(false)
        } else {
            setDisableText(true)
        }
        const mycontent = word.length;
        let WordCount = 0
        let Chracter = 0

        if (word.trim() !== '') {
            WordCount = word.split(' ').length;
            Chracter = word.length
        }
        setWordCount(WordCount)
        setChracterCount(Chracter)
    }

    const handleEditorSelect = () => {
        const editor = ChildEditorRef.current;
        const myContent = editor.getContainer({ format: 'text' })
    }

    const setPromptValue = async () => {
        setDisableButton(true);

        setShowAskAi(false)

        setShowdialoge(false);
        setShow(false);
        // setLoading(true);
        setAidiloge(true)
        const state = {
            "option": promptOptions,
            "text": context,
            "taal": taal,
            "woorden": woorden * 1,
            "alienas": alienas * 1,
            "toon": toon,
            "doel": doel,
            "style": style,
            "diffrent": diffrent,
            "situation": situation,
            "happen": happen,
            "reason": reason,
            "howHappen": howHappen,
            "person": person,
            "consequences": consequences,
            "karakter": karakter
        }
        // const result = await apiRequest('digiloFixPrompt', 'backend', 'POST', state)
        await digiloGeneratorApi(`digiloFixNote`, state, setBlogApiResponse)
        setDisableButton(false);
        // setBlogApiResponse(result.results);
        setPromptOptions("");
        setContext("");
        setTaal("");
        setWoorden();
        setAlienas();
        setToon("");
        setDoel("");
        setStyle("");
        setDiffrent();

    }
    const handleBlogCopy = () => {
        const editor = blogEditorRef.current;
        const content = editor.getContent({ format: 'text' });
        const plainText = content.replace(/<[^>]*>/g, '\n');
        // Create a temporary textarea element
        const textarea = document.createElement('textarea');
        textarea.value = plainText;

        // Append the textarea to the document body
        document.body.appendChild(textarea);

        // Copy the content from the textarea to the clipboard
        textarea.select();
        document.execCommand('copy');

        // Remove the temporary textarea element
        document.body.removeChild(textarea);
    }
    const handleBlogDownload = () => {
        const editorData = blogEditorRef.current.getContent({ format: 'text' });
        const htmlContent = `
    <!DOCTYPE html>
    <html>
        <head>
        <meta charset="UTF-8">
        <style>
            body {
            font-family: Poppins;
            }
        </style>
        </head>
        <body>
        ${editorData}
        </body>
    </html>
    `;
        const blob = new Blob([htmlContent], { type: 'text/html' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'Digilo-export.doc';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }



    const getEditedPlainText = useCallback(
        () => editorRef.current?.getContent({ format: 'text' }) || '',
        [editorRef.current]
    )

    const getEditedFormattedText = useCallback(
        () => editorRef.current?.getContent({ format: 'html' }) || '',
        [editorRef.current]
    )

    // const handlePromptOptions = (e) => {
    //     if (e.target.value === undefined) {
    //         setDisableOption(false)
    //         setPromptOptions("")
    //     } else {
    //         setPromptOptions(e.target.value);
    //         setDisableInput(true)
    //     }
    // }
    // const handlePromptInput = (e) => {
    //     if (e.target.value === "") {
    //         setDisableInput(false)
    //         setPromptOptions("")
    //     } else {
    //         setPromptOptions(e.target.value);
    //         setDisableInput(true)
    //     }
    // }
    useEffect(() => {
        setTimeout(() => {
            setShake(false)
        }, 2000);

    }, [])

    useEffect(() => {

        if (promptOptions !== "" && diffrent !== null && doel !== "" && toon !== "" && alienas !== null && style !== "") {
            setDisabledAi(false)
        }
    }, [promptOptions, context, diffrent, doel, toon, alienas, style])

    const handleWoorden = () => {
        setWoordenRadio(true);
        setChracterRadio(false)
    }

    const handleChracter = () => {
        setWoordenRadio(false);
        setChracterRadio(true)
    }

    const handleFormPopUpOption = (e) => {
        if (e.target.value === "Custom") {
            setPromptOptions("");
            setDisableOption(true)
        } else {
            setPromptOptions(e.target.value);
            setDisableOption(false)
        }


    }

    const handleTouchSelection = (evt, editor) => {

        const width = window.innerWidth;
        const selectedText = editor.selection.getContent({ format: 'text' });
        const toolbar = document.getElementById('editor-toolbar');

        if (width < 768 || width >= 768) {
            if (selectedText.length > 0) {
                if (window.innerWidth < 768) {
                    setText((prevText) => prevText + selectedText)
                }
                const selection = editor.selection.getRng();


                const selectionRect = selection.getBoundingClientRect();

                const editorRect = editor.getContainer().getBoundingClientRect();
                const toolbarTop = selectionRect.bottom + editorRect.top;
                const toolbarLeft = editorRect.left;
                toolbar.style.display = 'block';
                toolbar.style.position = 'absolute';
                toolbar.style.top = `${toolbarTop}px`;
                toolbar.style.left = `${toolbarLeft}px`;
            }
        }
    };

    // const handleTouchMove = (evt) => {
    //     handleTouchSelection(evt);
    // };

    // const handleTouchEnd = (evt) => {
    //     handleTouchSelection(evt);
    // };




    return (

        <Box sx={{ position: "relative" }}>
            {/* like unlike */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={Modalstyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Reason for unliking the Digilo result
                    </Typography>
                    <TextField size="small" multiline maxRows={4} sx={{ marginBottom: "10px", marginTop: "10px" }} id="outlined-basic" label="Reason" variant="outlined" fullWidth />
                    <Button sx={styles.btn_orange} onClick={() => handleClose()}>Submit</Button>
                </Box>
            </Modal>
            {/* like unlike */}
            <Box className="words">
                <p className='words_count'>woorden: {wordCount}</p>
                <p className='words_count'>karakter: {chracterCount}</p>
            </Box>

            <Box className="ask_ai">
                <Button className={shake ? 'ai_btn ai_bg' : "ai_bg"} startIcon={<AutoAwesome />} onClick={() => { setShowAskAi(!showAskAi); setAidiloge(false); setBlogApiResponse(""); setShowContextChild(false); }} variant="text">Vraag het aan Digilo</Button>

                {showAskAi &&
                    <Box className={'drop_down_card'}>
                        <Box sx={{ width: "100%" }}>
                            <FormControl fullWidth sx={{ paddingBottom: "5px" }}>
                                <Typography variant="subtitle2" gutterBottom>Schrijf een</Typography>
                                <Select
                                    value={promptOptions}
                                    // onChange={handleChange}
                                    size="small"
                                    onChange={(e) => { handleFormPopUpOption(e) }}
                                // disabled={disableOption}
                                >
                                    {/* <MenuItem >None</MenuItem> */}
                                    <MenuItem value={"Custom"}><strong>Ik kies zelf een tekstsoort</strong></MenuItem>
                                    <MenuItem value={"SEO artikel"}>SEO artikel</MenuItem>
                                    <MenuItem value={"Nieuwsbreeven"}>Nieuwsbrief</MenuItem>
                                    <MenuItem value={"Email"}>E-mail</MenuItem>
                                    <MenuItem value={"Social Media Post"}>Social Media Post</MenuItem>
                                    <MenuItem value={"Blog"}>Blog</MenuItem>
                                    <MenuItem value={"Artikel"}>Artikel</MenuItem>
                                    <MenuItem value={"Facebook Advertentie"}>Facebook advertentie</MenuItem>
                                    <MenuItem value={"Google Advertentie"}>Google advertentie</MenuItem>
                                    <MenuItem value={"Tweet"}>Twitter tweet</MenuItem>
                                    <MenuItem value={"Linked in advertentie"}>Linkedin advertentie</MenuItem>
                                    <MenuItem value={"Productbeschrijving"}>Productbeschrijving</MenuItem>
                                    <MenuItem value={"FAQ"}>FAQ</MenuItem>
                                    <MenuItem value={"Testimonial"}>Testimonial</MenuItem>
                                    <MenuItem value={"ProductRecensie"}>Product recensie</MenuItem>
                                    <MenuItem value={"Whitepaper"}>Whitepaper</MenuItem>
                                    <MenuItem value={"Presentatie"}>Presentatie</MenuItem>
                                    <MenuItem value={"Landingspagina"}>Landingspagina </MenuItem>
                                    <MenuItem value={"Persbericht schrijven"}>Persbericht</MenuItem>
                                    <MenuItem value={"Case studie"}>Case studie</MenuItem>
                                </Select>
                            </FormControl>
                            {disableOption && <FormControl fullWidth sx={{ paddingBottom: "5px" }}>
                                <Typography variant="subtitle2" gutterBottom>Tekstsoort </Typography>
                                <TextField id="outlined-basic" value={promptOptions} label="Noteer hier de gewenste tekstsoort" variant="outlined" size="small" fullWidth onChange={(e) => { setPromptOptions(e.target.value) }} />
                            </FormControl>}

                            <Box className="context_wrapper" >
                                <FormControl fullWidth sx={{ paddingBottom: "5px" }}>
                                    <Typography variant="subtitle2" gutterBottom >Context en details</Typography>
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        multiline
                                        maxRows={4}
                                        value=""
                                        size="small" fullWidth
                                        onClick={() => { setShowContextChild(!showcontextChild) }}
                                    />
                                </FormControl>
                                {showcontextChild && <Box className="content_child">
                                    <FormControl fullWidth sx={{ paddingBottom: "5px" }}>
                                        <Box sx={{ textAlign: "right", paddingBottom: "10px" }}>
                                            <CancelSharp className='close_context' onClick={() => { setShowContextChild(false) }} />
                                        </Box>
                                        <Typography variant="subtitle2" gutterBottom style={{ fontWeight: 600, paddingBottom: "22px" }}>Noteer zoveel mogeliijk context en details</Typography>
                                        <Typography variant="subtitle2" gutterBottom>Onderwerp of zoekwoord?</Typography>
                                        <TextField className='placeholderText'
                                            id="outlined-multiline-flexible"
                                            label="Noteer hier het onderwerp of zoekwoord"
                                            size="small" fullWidth
                                            value={context}
                                            onChange={(e) => { setContext(e.target.value) }}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth sx={{ paddingBottom: "5px" }}>
                                        <Typography variant="subtitle2" gutterBottom>Situatie/gebeurtenis</Typography>
                                        <TextField className='placeholderText'
                                            id="outlined-multiline-flexible"
                                            label="Noteer hier de gebeurtenis/situatie"
                                            size="small" fullWidth
                                            value={situation}
                                            onChange={(e) => { setSituation(e.target.value) }}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth sx={{ paddingBottom: "5px" }}>
                                        <Typography variant="subtitle2" gutterBottom>Aanleiding/reden?</Typography>
                                        <TextField className='placeholderText'
                                            id="outlined-multiline-flexible"
                                            label="Noteer hier de aanleiding/reden"
                                            size="small" fullWidth
                                            value={reason}
                                            onChange={(e) => { setReason(e.target.value) }}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth sx={{ paddingBottom: "5px" }}>
                                        <Typography variant="subtitle2" gutterBottom>Betrokkenen?</Typography>
                                        <TextField className='placeholderText'
                                            id="outlined-multiline-flexible"
                                            label="Noteer hier namen, rollen etc. etc."
                                            size="small" fullWidth
                                            value={person}
                                            onChange={(e) => { setPerson(e.target.value) }}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth sx={{ paddingBottom: "5px" }}>
                                        <Typography variant="subtitle2" gutterBottom>Waar en/of wanneer?</Typography>
                                        <TextField className='placeholderText'
                                            id="outlined-multiline-flexible"
                                            label="Noteer hier de locatie(s), tijden, dagen etc. etc."
                                            size="small" fullWidth
                                            value={happen}
                                            onChange={(e) => { setHappen(e.target.value) }}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth sx={{ paddingBottom: "5px" }}>
                                        <Typography variant="subtitle2" gutterBottom>Hoe?</Typography>
                                        <TextField className='placeholderText'
                                            id="outlined-multiline-flexible"
                                            label="Noteer hier hoe het is gebeurd/gaat gebeuren"
                                            size="small" fullWidth
                                            value={howHappen}
                                            onChange={(e) => { setHowHappen(e.target.value) }}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth sx={{ paddingBottom: "5px" }}>
                                        <Typography variant="subtitle2" gutterBottom>Gevolgen?</Typography>
                                        <TextField className='placeholderText'
                                            id="outlined-multiline-flexible"
                                            label="Noteer hier het gevolg of de gewenste gevolgen"
                                            size="small" fullWidth
                                            value={consequences}
                                            onChange={(e) => { setConsequences(e.target.value) }}
                                        />
                                    </FormControl>
                                    <Box sx={{ marginTop: "7px" }}>
                                        <Button fullWidth sx={styles.btn_orange} onClick={() => { setShowContextChild(!showcontextChild) }} >Invoer voltooid</Button>
                                    </Box>

                                </Box>}
                            </Box>
                            <FormControl fullWidth sx={{ paddingBottom: "5px" }}>
                                <Typography variant="subtitle2" gutterBottom>Taal</Typography>
                                <Select
                                    value={taal}
                                    size="small"
                                    onChange={(e) => { setTaal(e.target.value) }}
                                >

                                    <MenuItem value={"Nederlands"}>Nederlands</MenuItem>
                                    <MenuItem value={"Belgisch"}>Belgisch</MenuItem>
                                    <MenuItem value={"Braziliaans"}>Braziliaans</MenuItem>
                                    <MenuItem value={"Duits"}>Duits</MenuItem>
                                    <MenuItem value={"Engles"}>Engles</MenuItem>
                                    <MenuItem value={"Frans"}>Frans</MenuItem>
                                    <MenuItem value={"Italiaans"}>Italiaans</MenuItem>
                                    <MenuItem value={"Japans"}>Japans</MenuItem>
                                    <MenuItem value={"Marokkaans"}>Marokkaans</MenuItem>
                                    <MenuItem value={"Pools"}>Pools</MenuItem>
                                    <MenuItem value={"Russisch"}>Russisch</MenuItem>
                                    <MenuItem value={"Spaans"}>Spaans</MenuItem>
                                    <MenuItem value={"Turks"}>Turks</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl fullWidth sx={{ paddingBottom: "5px" }}>

                                <Typography variant="subtitle2" gutterBottom disabled={woordenRadio} className={classes.typography}>Kies aantal woorden<FormControlLabel label="" value="option1" control={<Radio onClick={handleWoorden} checked={woordenRadio} classes={{ root: classes.radio }} />} /></Typography>
                                {/* <TextField type="number" id="outlined-basic" label="Woorden" value={woorden} size="small" fullWidth onChange={(e) => { setWoorden(e.target.value) }} /> */}
                                <Select
                                    size="small"
                                    onChange={(e) => { setWoorden(e.target.value) }}
                                    disabled={!woordenRadio}
                                >

                                    <MenuItem value={"250"}>250</MenuItem>
                                    <MenuItem value={"500"}>500</MenuItem>
                                    {/* <MenuItem value={"1000"}>1000</MenuItem>
                                    <MenuItem value={"1500"}>1500</MenuItem>
                                    <MenuItem value={"2000"}>2000</MenuItem> */}
                                </Select>
                            </FormControl>
                            <FormControl fullWidth sx={{ paddingBottom: "5px" }}>
                                <Typography variant="subtitle2" gutterBottom className={classes.typography} >Of aantal letters/tekens<FormControlLabel label="" value="option2" control={<Radio onClick={handleChracter} checked={chracterRadio} classes={{ root: classes.radio }} />} /> </Typography>
                                <TextField type="number" id="outlined-basic" label="" value={karakter} size="small" fullWidth onChange={(e) => { setKarakters(e.target.value) }} disabled={!chracterRadio} />
                            </FormControl>
                            <FormControl fullWidth sx={{ paddingBottom: "5px" }}>
                                <Typography variant="subtitle2" gutterBottom>Aantal alinea's</Typography>
                                <TextField type="number" id="outlined-basic" label="" value={alienas} size="small" fullWidth onChange={(e) => { setAlienas(e.target.value) }} />
                            </FormControl>
                            <FormControl fullWidth sx={{ paddingBottom: "5px" }}>
                                <Typography variant="subtitle2" gutterBottom> Toon</Typography>
                                <Select
                                    value={toon}
                                    size="small"
                                    onChange={(e) => setToon(e.target.value)}
                                >

                                    <MenuItem value={"Informeel"}>Informeel</MenuItem>
                                    <MenuItem value={"Formeel"}>Formeel</MenuItem>
                                    <MenuItem value={"Persoonlijk"}>Persoonlijk</MenuItem>
                                    <MenuItem value={"Profesioneel"}>Profesioneel</MenuItem>
                                    <MenuItem value={"Satirisch"}>Satirisch</MenuItem>
                                    <MenuItem value={"Academisch"}>Academisch</MenuItem>
                                    <MenuItem value={"Journalistsch"}>Journalistsch</MenuItem>
                                    <MenuItem value={"Technisch"}>Technisch</MenuItem>
                                    <MenuItem value={"Creatief"}>Creatief</MenuItem>
                                    <MenuItem value={"Wetenschappelijk"}>Wetenschappelijk</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl fullWidth sx={{ paddingBottom: "5px" }}>
                                <Typography variant="subtitle2" gutterBottom>Doel</Typography>
                                <Select
                                    value={doel}
                                    size="small"
                                    onChange={(e) => setDoel(e.target.value)}
                                >

                                    <MenuItem value={"Informeren"}>Informeren</MenuItem>
                                    <MenuItem value={"Instrueren"}>Instrueren</MenuItem>
                                    <MenuItem value={"Adviseren"}>Adviseren</MenuItem>
                                    <MenuItem value={"Activeren"}>Activeren</MenuItem>
                                    <MenuItem value={"Emotioneren"}>Emotioneren</MenuItem>
                                    <MenuItem value={"Inspireren"}>Inspireren</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl fullWidth sx={{ paddingBottom: "5px" }}>
                                <Typography variant="subtitle2" gutterBottom> Stijl</Typography>
                                <Select
                                    value={style}
                                    size="small"
                                    onChange={(e) => { setStyle(e.target.value) }}
                                >

                                    <MenuItem value={"Actief"}>Actief</MenuItem>
                                    <MenuItem value={"Beschrijvend"}>Beschrijvend</MenuItem>
                                    <MenuItem value={"Verhalend"}>Verhalend (Storytelling)</MenuItem>
                                    <MenuItem value={"Verklaend"}>Verklarend</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl fullWidth sx={{ paddingBottom: "5px" }}>
                                <Typography variant="subtitle2" gutterBottom>Aantal tekstvariaties</Typography>
                                <Select
                                    value={diffrent}
                                    size="small"
                                    onChange={(e) => { setDiffrent(e.target.value) }}
                                >

                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                    <MenuItem value={6}>6</MenuItem>
                                </Select>
                            </FormControl>
                            <Box sx={{ marginTop: "7px" }}>
                                <Button fullWidth sx={styles.btn_orange} disabled={disabledAi} onClick={setPromptValue}>Schrijf content</Button>
                            </Box>

                        </Box>

                    </Box>
                }
                <Box className={aidiloge ? `${classes.cmn_popup}` : `${classes.hideDilaoge} `}>
                    {loading ?
                        <div className='loader_wrapper'>
                            <img src="/img/digilo-head.png" className='logo_head' alt="" />
                            <img src="/img/dotanimation.gif" className='gif_loader' alt="" />
                        </div>
                        :
                        <Box>
                            <Box component="form" noValidate autoComplete="off" sx={{ mt: 1, mb: 1 }}>

                                <Editor
                                  tinymceScriptSrc={'/tinymce/tinymce.min.js'} 
                                    onInit={(evt, editor) => (blogEditorRef.current = editor)}
                                    disabled={true}
                                    initialValue={formattedaiBlogResponse}
                                    init={{
                                        readonly: 1,
                                        min_height: 100,
                                        statusbar: false,
                                        resize: false,
                                        branding: false,
                                        toolbar: false,
                                        menubar: false,
                                        init_instance_callback: function (editor) {
                                            var freeTiny = document.querySelector('.tox-notifications-container');
                                            if(freeTiny){
                                                freeTiny.style.display = 'none';
                                            }
                                        },
                                        content_css: 'https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap', // Add Poppins font here 
                                        content_style: 'body { font-family: "Poppins";font-size: 14px; }',

                                    }}
                                />
                                <Stack
                                    className='blog_nav_items'
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Tooltip title="Unlike">
                                        <IconButton className='button_like' onClick={() => { handLikeUnlike('unlike') }} sx={styles.btn_orange} aria-label="delete">
                                            <ThumbDownOffAlt />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Like">
                                        <IconButton className='button_like' onClick={() => { handleLike('like'); setShowalertText("Liked"); setShowalert(true) }} sx={styles.btn_orange} aria-label="delete">
                                            {like ? <ThumbUp /> : <ThumbUpOffAlt />}
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                            </Box>
                            <Snackbar open={showalert} autoHideDuration={1500} onClose={handleMinimize}>
                                <Alert onClose={handleMinimize} severity="success">
                                    {showalerTtext}
                                </Alert>
                            </Snackbar>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                spacing={2}
                            >
                                <Button className={classes.redo_btn} onClick={handelInsertTop} endIcon={<Done />} disabled={disableButton}>
                                    Tekst gebruiken
                                </Button>
                                <Button className={classes.redo_btn} onClick={handelInsertLast} endIcon={<Add />} disabled={disableButton}>
                                    Laatste invoegen
                                </Button>
                                <Button onClick={() => { setShowAskAi(false); setAidiloge(false); }} className={classes.redo_btn} endIcon={<Close />} disabled={disableButton}>
                                    Weggooien
                                </Button>

                            </Stack>
                        </Box>
                    }
                </Box>
            </Box>
            <div className='editor_wrapper'>
                <div className="editor_inner">
                    <Editor
                      tinymceScriptSrc={'/tinymce/tinymce.min.js'} 
                        onEditorChange={handleEditorChange}
                        initialValue={achrorUpdatedText ? achrorUpdatedText : ""}
                        init={{
                            statusbar: false,
                            resize: false,
                            branding: false,
                            menubar: false,
                            toolbar_mode: 'floating',
                            mobile: {
                                toolbar_mode: 'floating'
                            },
                            theme: 'silver',
                            plugins: [
                                'advlist',
                                'lists',
                                'image',
                                'charmap',
                                'anchor',
                                'searchreplace',
                                'visualblocks',
                                'code',
                                'fullscreen',
                                'insertdatetime',
                                'media',
                                'table',
                                'preview',
                                'help',
                                'touch',
                                'link',
                                // 'autolink',
                                'wordcount',
                                'visualchars'
                            ],
                            toolbar: 'bold italic forecolor | alignright alignjustify ' +
                                'alignleft aligncenter link | code blocks' +
                                'fontfamily fontsize | bullist numlist outdent indent | ' +
                                'removeformat | help ',
                            directionality: 'ltr',
                            language: 'sv_SE',
                            content_css: 'https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap', // Add Poppins font here 
                            content_style: 'body { font-family: "Poppins";font-size: 14px; }',


                        }}



                        onInit={(evt, editor) => (editorRef.current = editor)
                            // editor.on('touchend', () => updateToolbarDisplay(editor))}
                        }

                        onMouseMove={(evt, editor) => {
                            handleTouchSelection(evt, editor)
                        }}

                        // onTouchEnd={(evt, editor) => {
                        //     showCustomContextMenu(evt);
                        //   }}

                        onTouchStart={handleTouchSelection}
                        onTouchMove={handleTouchSelection}
                        onTouchEnd={handleTouchSelection}


                        onMouseUp={(evt, editor) => {
                            const selectedText = editor.selection.getContent({ format: 'text' });
                            const toolbar = document.getElementById('editor-toolbar');
                            if (selectedText.length > 0) {
                                setText(selectedText)
                                const selection = editor.selection.getRng();


                                const selectionRect = selection.getBoundingClientRect();

                                const editorRect = editor.getContainer().getBoundingClientRect();
                                const toolbarTop = selectionRect.bottom + editorRect.top;
                                const toolbarLeft = editorRect.left;
                                toolbar.style.display = 'block';
                                toolbar.style.position = 'absolute';
                                toolbar.style.top = `${toolbarTop}px`;
                                toolbar.style.left = `${toolbarLeft}px`;

                                // Get the current selection
                                // var selection = editor.selection.getContent({ format: 'html' });

                                // Get the container element for the selection
                                var container = editor.selection.getNode();

                                // Create a temporary element to wrap the selection content
                                var wrapper = document.createElement('div');
                                wrapper.appendChild(container.cloneNode(false));
                                wrapper.innerHTML = selection;

                                // Get the HTML content of the selection with its surrounding tags
                                var selectedTextWithTag = wrapper.innerHTML;
                                setCopiedText(selectedTextWithTag)
                            } else {

                                toolbar.style.display = 'none';
                                setText('')
                            }

                        }}

                        onKeyUp={(evt, editor) => {
                            const selectedText = editor.selection.getContent({ format: 'text' });
                            const toolbar = document.getElementById('editor-toolbar');

                            if (selectedText.length > 0) {
                                // editor.on('touchend', () => updateToolbarDisplay(editor))                                                                                                                                                           
                                // handleSelectionChange(selectedText);
                                setText(selectedText)
                                const selection = editor.selection.getRng();
                                const selectionRect = selection.getBoundingClientRect();
                                const editorRect = editor.getContainer().getBoundingClientRect();
                                const toolbarTop = selectionRect.bottom + editorRect.top;
                                const toolbarLeft = editorRect.left;
                                toolbar.style.display = 'block';
                                toolbar.style.position = 'absolute';
                                toolbar.style.top = `${toolbarTop}px`;
                                toolbar.style.left = `${toolbarLeft}px`;
                                var container = editor.selection.getNode();
                                var wrapper = document.createElement('div');
                                wrapper.appendChild(container.cloneNode(false));
                                wrapper.innerHTML = selection;
                                var selectedTextWithTag = wrapper.innerHTML;
                            }
                            else {
                                toolbar.style.display = 'none';
                            }
                        }}






                    >
                    </Editor>
                </div>
                <Box sx={{ paddingBottom: "2em" }}>
                    <Button>woorden over {user?.user?.paymentType === "subscription" || user?.user?.stripeSubscriptionStatus === "active" ? "Unlimited" : creadit}</Button>
                </Box>

                <div id="editor-toolbar" className='editor-toolbar' style={{ display: 'none' }}>
                    <Box
                        sx={styles.aiwrapper}
                    >
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={1}
                        >
                            <Button startIcon={<AutoAwesome sx={{ fontSize: "20px" }} />} sx={styles.btnAi} variant="text" onClick={() => { setShow(!show); setShowLink(false); setShowdropdown(false); setShowdialoge(false); setShowtextFormatter(false); setPrompt(false) }}>Vraag Digilo</Button>
                            <Box className='text_formater_wrapper'>
                                <Button endIcon={<KeyboardArrowDown />} sx={styles.btnAi} onClick={() => { setShowtextFormatter(!showtextFormatter); setShowLink(false); setShow(false); setPrompt(false) }} variant="text">Tekst</Button>
                                {showtextFormatter && <Box className='text_formater'>
                                    <Typography className={classes.spantext} > worden
                                    </Typography>

                                    <Button fullWidth className={`${classes.text_white} ${classes.headingOne}`} onClick={handleHeadingOneButtonClick}> Koptekst 1</Button>


                                    <Button fullWidth className={`${classes.text_white} ${classes.headingTwo}`} onClick={handleHeadingTwoButtonClick}>  Koptekst 2</Button>


                                    <Button fullWidth className={`${classes.text_white} ${classes.headingThree}`} onClick={handleHeadingThreeButtonClick}>
                                        Koptekst 3
                                    </Button>

                                    <Button fullWidth className={`${classes.text_white}`} onClick={handleBulletListClick}>Lijst met opsommingstekens</Button>
                                    <Button fullWidth className={`${classes.text_white}`} onClick={handleNumberedListClick}>Genummerde lijst</Button>
                                    <Button fullWidth className={`${classes.text_white}`} onClick={handleBlockQuoteClick}>Citaten</Button>
                                </Box>}
                            </Box>
                            {/* content prompt */}

                            <Box className='text_formater_wrapper'>
                                <IconButton sx={styles.btnAi} onClick={showLinkdata} aria-label="delete">
                                    <AddLink sx={{ fontSize: "20px" }} />
                                </IconButton>
                                {showLink ? <Box className='text_formater'>
                                    <Box className="search_box" component="form" >
                                        <Search />
                                        <TextField
                                            id="linkInput"
                                            className={classes.textField}
                                            variant="outlined"
                                            fullWidth
                                            value={linkUrl}
                                            onChange={(e) => {
                                                handleLinkInputChange(e);
                                            }}
                                        />
                                    </Box>
                                    <Box sx={{ marginBottom: "10px", marginTop: '10px' }}>
                                        <Button sx={styles.btnAi} fullWidth onClick={handleApplyLinkClick}>
                                            Koppeling toepassen
                                        </Button>
                                    </Box>
                                    <Button sx={styles.btnAi} fullWidth onClick={handleRemoveLinkClick}>
                                        Koppeling verwijderen
                                    </Button>
                                </Box> : null}
                            </Box>

                            <IconButton sx={styles.btnAi} aria-label="delete" onClick={handleBoldButtonClick}>
                                <FormatBold sx={{ fontSize: "20px" }} />
                            </IconButton>
                            <IconButton sx={styles.btnAi} aria-label="delete" onClick={handleItalicButtonClick}>
                                <FormatItalic sx={{ fontSize: "20px" }} />
                            </IconButton>
                            <IconButton sx={styles.btnAi} aria-label="delete" onClick={handleUnderlineClick}>
                                <FormatUnderlined sx={{ fontSize: "20px" }} />
                            </IconButton>
                            <IconButton sx={styles.btnAi} onClick={handleCode} aria-label="delete" >
                                <Code sx={{ fontSize: "20px" }} />
                            </IconButton>
                        </Stack>
                        <List sx={{ paddingLeft: 1.5, paddingRight: 1.5 }} className={!show ? `${classes.list}` : `${classes.list_data}`}>
                            <Typography className={classes.spantext}>
                                Gebruik Digilo om je tekst te verbeteren
                            </Typography>
                            <ListItem disablePadding>
                                <ListItemButton sx={{ padding: 0 }} title="Digilo verbetert de geselecteerde tekst op zijn eigen manier." onClick={() => { showDialogebtn("Verbeter tekst"); showDilogetext("Verbeter tekst") }}>
                                    <ListItemIcon>
                                        <AutoFixHigh sx={{ color: "#fff" }} />
                                    </ListItemIcon>
                                    <ListItemText className={classes.whiteText} sx={{ fontSize: "14px" }} primary="Verbeter tekst" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton sx={{ padding: 0 }} title="Digilo verbetert jouw geselecteerde tekst op het gebied van grammatica en spelling." onClick={() => { showDialogebtn("Repareer spelling en grammatica"); showDilogetext("Repareer spelling en grammatica") }}>
                                    <ListItemIcon>
                                        <Done sx={{ color: "#fff" }} />
                                    </ListItemIcon>
                                    <ListItemText className={classes.whiteText} primary="Repareer spelling en grammatica" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton sx={{ padding: 0 }} onClick={() => { showDialogebtn("Maak korter"); showDilogetext("Maak korter") }}>
                                    <ListItemIcon>
                                        <ShortText sx={{ color: "#fff" }} />
                                    </ListItemIcon>
                                    <ListItemText className={classes.whiteText} primary="Maak korter" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton sx={{ padding: 0 }} title="Met deze functie maakt Digilo de geselecteerde tekst langer. Maak korter - Met deze functie verkort Digilo jouw geselecteerde tekst." onClick={() => { showDialogebtn("Maak langer"); showDilogetext("Maak langer") }}>
                                    <ListItemIcon>
                                        <Menu sx={{ color: "#fff" }} />
                                    </ListItemIcon>
                                    <ListItemText className={classes.whiteText} primary="Maak langer" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding className={classes.dropDownHead}>
                                <ListItemButton sx={{ padding: 0 }} title="Met deze functie verandert Digilo de toon van de tekst." onClick={showTonedropdown}>
                                    <ListItemIcon>
                                        <KeyboardVoice sx={{ color: "#fff" }} />
                                    </ListItemIcon>
                                    <ListItemText className={classes.whiteText} primary="Toon veranderen" /> <ChevronRight sx={{ color: "#fff" }} />
                                </ListItemButton>
                                <Box className={showdropdown ? `${classes.dropDown}` : `${classes.dropDownhide}`}>
                                    <ListItemButton sx={{ padding: 0 }} onClick={() => { showDialogebtn("Professioneel"); showDilogetext("Professioneel") }} >  <ListItemText className={classes.whiteText} primary="Professioneel" /></ListItemButton>
                                    <ListItemButton sx={{ padding: 0 }} onClick={() => { showDialogebtn("Zelfverzekerd"); showDilogetext("Zelfverzekerd") }}><ListItemText className={classes.whiteText} primary="Zelfverzekerd" /></ListItemButton>
                                    <ListItemButton sx={{ padding: 0 }} onClick={() => { showDialogebtn("Creatief"); showDilogetext("Creatief") }}><ListItemText className={classes.whiteText} primary="Creatief" /></ListItemButton>
                                    <ListItemButton sx={{ padding: 0 }} onClick={() => { showDialogebtn("Vriendelijk"); showDilogetext("Vriendelijk") }}><ListItemText className={classes.whiteText} primary="Vriendelijk" /></ListItemButton>
                                    <ListItemButton sx={{ padding: 0 }} onClick={() => { showDialogebtn("Energiek"); showDilogetext("Energiek") }}> <ListItemText className={classes.whiteText} primary="Energiek" /></ListItemButton>
                                    <ListItemButton sx={{ padding: 0 }} onClick={() => { showDialogebtn("Grappig"); showDilogetext("Grappig") }}><ListItemText className={classes.whiteText} primary="Grappig" /></ListItemButton>
                                    <ListItemButton sx={{ padding: 0 }} onClick={() => { showDialogebtn("Neutraal"); showDilogetext("Neutraal") }}><ListItemText className={classes.whiteText} primary="Neutraal" /></ListItemButton>
                                </Box>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton sx={{ padding: 0 }} title="Met deze functie maakt Digilo de geselecteerde tekst korter en bondiger." onClick={() => { showDialogebtn("Tekst vereenvoudigen"); showDilogetext("Tekst vereenvoudigen") }}>
                                    <ListItemIcon>
                                        <AutoAwesome sx={{ color: "#fff" }} />
                                    </ListItemIcon>
                                    <ListItemText className={classes.whiteText} primary="Tekst vereenvoudigen" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton sx={{ padding: 0 }} title="Met deze functie maakt Digilo jouw tekst menselijker, waardoor detectie door AI-tekst detectoren moeilijker wordt" onClick={() => { showDialogebtn("Onherkenbaar maken"); showDilogetext("Onherkenbaar maken") }}>
                                    <ListItemIcon>
                                        <BrowserNotSupported sx={{ color: "#fff" }} />
                                    </ListItemIcon>
                                    <ListItemText className={classes.whiteText} primary="Onherkenbaar maken" />
                                </ListItemButton>
                            </ListItem>
                            <Typography className={classes.spantext} >
                                Hulpmiddelen
                            </Typography>
                            <ListItem disablePadding>
                                <ListItemButton sx={{ padding: 0 }} title="Digilo vat met deze functie de geselecteerde tekst samen." onClick={() => { showDialogebtn("Tekst Samenvatten"); showDilogetext("Tekst samenvatten") }}>
                                    <ListItemIcon>
                                        <FilterList sx={{ color: "#fff" }} />
                                    </ListItemIcon>
                                    <ListItemText className={classes.whiteText} primary="Tekst samenvatten" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton sx={{ padding: 0 }} title="Met deze functie legt Digilo de geselecteerde tekst eenvoudig uit." onClick={() => { showDialogebtn("Tekst verklaren"); showDilogetext("Tekst verklaren") }}>
                                    <ListItemIcon>
                                        <HelpOutline sx={{ color: "#fff" }} />
                                    </ListItemIcon>
                                    <ListItemText className={classes.whiteText} primary="Tekst verklaren" />
                                </ListItemButton>
                            </ListItem>
                            <Typography className={classes.spantext} >
                                Gebruik Digilo om je tekst te schrijven
                            </Typography>
                            <ListItem disablePadding>
                                <ListItemButton sx={{ padding: 0 }} title="Met deze functie schrijft Digilo een nieuw blogartikel op basis van de geselecteerde tekst." onClick={() => { showDialogebtn("Blog"); showDilogetext("Blog") }}>
                                    <ListItemIcon>
                                        <RssFeedSharp sx={{ color: "#fff" }} />
                                    </ListItemIcon>
                                    <ListItemText className={classes.whiteText} primary="Blog" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton sx={{ padding: 0 }} title="Met deze functie borduurt Digilo voort de geselecteerde tekst en vult de rest zelf aan." onClick={() => { showDialogebtn("Verder schrijven"); showDilogetext("Verder schrijven") }}>
                                    <ListItemIcon>
                                        <AssignmentSharp sx={{ color: "#fff" }} />
                                    </ListItemIcon>
                                    <ListItemText className={classes.whiteText} primary="Verder schrijven" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton sx={{ padding: 0 }} title="Met deze functie laat Digilo jou zelf bepalen hoe de door jou geselecteerde tekst moet worden aangepast." onClick={() => { showDilogetext("Aangepaste prompt"); setPrompt(!prompt); setShow(false); setText(''); }}>
                                    <ListItemIcon>
                                        <BorderColor sx={{ color: "#fff" }} />
                                    </ListItemIcon>
                                    <ListItemText className={classes.whiteText} primary="Aangepaste prompt" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Box>
                    {prompt &&
                        <Box className={`${classes.cmn_popup} `}>
                            <Box className="content_prompt">
                                <Box className={prompt ? `${classes.cmn_popuddp} ` : `${classes.hideDilaoge} `} sx={{ display: "flex", gap: "10px" }}>
                                    <Input
                                        value={customOption}
                                        onChange={(e) => { setText(copiedText); setCustomOption(e.target.value); }}
                                        sx={{ border: "1px solid #fff", height: "30px", borderRadius: "6px", color: "#fff", flexGrow: "1", paddingLeft: "10px" }} type='text' />
                                    <Button startIcon={<AutoAwesome />} sx={styles.btnAi} variant="text" onClick={() => { showCutomPrompt(customOption) }} disabled={text == "" ? true : false} >Vraag Digilo</Button>
                                </Box>
                                <Box className={promtContnet ? `${classes.cmn_widtddh}` : `${classes.hideDilaoge} `} sx={{ mt: 2 }}>
                                    {loading ?

                                        <div className='loader_wrapper'>
                                            <img src="/img/digilo-head.png" className='logo_head' alt="" />
                                            <img src="/img/dotanimation.gif" className='gif_loader' alt="" />
                                        </div>
                                        :
                                        <Box>
                                            <Box component="form" noValidate autoComplete="off" sx={{ mt: 1, mb: 1 }}>

                                                <Editor
                                                  tinymceScriptSrc={'/tinymce/tinymce.min.js'} 
                                                    onInit={(evt, editor) => (promptEditorRef.current = editor)}
                                                    disabled={true}
                                                    initialValue={showpromptText}
                                                    init={{
                                                        readonly: 1,
                                                        min_height: 100,
                                                        statusbar: false,
                                                        resize: false,
                                                        branding: false,
                                                        toolbar: false,
                                                        menubar: false,

                                                        init_instance_callback: function (editor) {
                                                            var freeTiny = document.querySelector('.tox-notifications-container');
                                                          if(freeTiny){
                                                            freeTiny.style.display = 'none';
                                                          }
                                                        },
                                                        content_css: 'https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap', // Add Poppins font here 
                                                        content_style: 'body { font-family: "Poppins";font-size: 14px; }',

                                                    }}


                                                />

                                            </Box>
                                            <Stack
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                spacing={1}
                                            >
                                                <Button className={classes.redo_btn} onClick={handelReplacePromt} endIcon={<Done />} >
                                                    Selectie vervangen
                                                </Button>
                                                <Button className={classes.redo_btn} onClick={handelInsertbelowPrompt} endIcon={<Add />} >
                                                    Hieronder invoegen
                                                </Button>
                                                <Button onClick={() => { setPrompt(false); setPromtContnet(false); showpromptText(''); }} className={classes.redo_btn} endIcon={<Close />} >
                                                    Weggooien
                                                </Button>

                                            </Stack>
                                        </Box>
                                    }
                                </Box>
                            </Box>
                        </Box>
                    }
                    <Box className={showdialoge ? `${classes.cmn_popup}` : `${classes.hideDilaoge} `}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}
                        >
                            <Box>
                                <Typography className={classes.whiteText}>    <AutoFixHigh sx={{ color: "#fff", mr: 1, fontSize: 16 }} /> {dilogetext}
                                </Typography>


                            </Box>
                            {!loading ? <Button className={classes.redo_btn} onClick={() => showDialogebtn(dilogetext)} disabled={disableButton}>Opnieuw proberen</Button> : null}
                        </Stack>
                        {loading ?
                            <div className='loader_wrapper'>
                                <img src="/img/digilo-head.png" className='logo_head' alt="" />
                                <img src="/img/dotanimation.gif" className='gif_loader' alt="" />
                            </div>
                            :

                            <Box>
                                <Box component="form" noValidate autoComplete="off" sx={{ mt: 1, mb: 1 }}>


                                    <Editor
                                      tinymceScriptSrc={'/tinymce/tinymce.min.js'} 
                                        onEditorChange={handleEditorSelect}
                                        onInit={(evt, editor) => (ChildEditorRef.current = editor)}
                                        initialValue={aiResponse}
                                        init={{
                                            readonly: 1,
                                            min_height: 100,
                                            statusbar: false,
                                            resize: false,
                                            branding: false,
                                            toolbar: false,
                                            menubar: false,
                                            newline_behavior: 'linebreak',
                                            content_css: 'https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap', // Add Poppins font here 
                                            content_style: 'body { font-family: "Poppins";font-size: 14px; }',

                                        }}


                                        onKeyUp={(evt, editor) => {
                                            const selectedText = editor.selection.getContent({ format: 'text' });
                                            const toolbar = document.getElementById('editor-toolbar');
                                        }}


                                    />
                                </Box>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Button className={classes.redo_btn} endIcon={<Done />} onClick={replaceAiSelection} disabled={disableButton}>
                                        Selectie vervangen
                                    </Button>
                                    <Button className={classes.redo_btn} endIcon={<Add />} onClick={handleInsertContent} disabled={disableButton}>
                                        Hieronder invoegen
                                    </Button>
                                    <Button onClick={() => { setShowdialoge(false); }} className={classes.redo_btn} endIcon={<Close />} disabled={disableButton}>
                                        Weggooien
                                    </Button>

                                </Stack>
                            </Box>
                        }
                    </Box>

                </div>
                <FeedbackPopup onClose={handleClose} open={feedbackopen} setFeedbackOpen={setFeedbackOpen} feedbackModalOne={feedbackModalOne} />

            </div>


            <Stack
                className='icons_handel'
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}
            >

                {wordCount !== 0 && <SaveContentButton className="orange_icon"
                    text={getEditedFormattedText}
                    type="Ask Digilo"
                />}

                {wordCount !== 0 && <Tooltip title="Copy Text">
                    <IconButton onClick={() => { handleCopy(); setShowalertText("Text Copied"); setShowalert(true) }} sx={styles.btn_orange} aria-label="delete">
                        <ContentCopy />
                    </IconButton>
                </Tooltip>}
                {wordCount !== 0 && <Tooltip title="Copy Html">
                    <IconButton onClick={() => { handleCopyHtml(); setShowalertText("HTML Cpoied"); setShowalert(true) }} sx={styles.btn_orange} aria-label="delete">
                        <Html />
                    </IconButton>
                </Tooltip>}

                {wordCount !== 0 && <Tooltip title="Download">
                    <IconButton onClick={() => { handleExportClick(); setShowalertText("Downloaded"); setShowalert(true) }} sx={styles.btn_orange} aria-label="delete">
                        <Download />
                    </IconButton>
                </Tooltip>}
                <Box className="file_upload">

                    <Tooltip title="Upload File">
                        <IconButton onClick={() => { setshowFileUploader(!showFileUploader) }} sx={styles.btn_orange} aria-label="delete">
                            <DriveFolderUpload />
                        </IconButton>
                    </Tooltip>
                    {
                        showFileUploader &&
                        <Box className="file_wrapper">
                            <Input sx={{ border: "1px solid #fff", marginBottom: "10px", color: "#fff" }} type='file' onChange={(e) => { handleFile(e) }} />
                            <Button sx={styles.btnAi} fullWidth onClick={handleFileSubmit} disabled={disabled} >
                                Upload bestand
                            </Button>
                        </Box>
                    }

                </Box>
            </Stack>

        </Box>
    )
}

const styles = {
    aiwrapper: {
        background: "#1278BCFF",
        borderRadius: 1,
        padding: 1,
        minWidth: "300px"
    },
    btnAi: {
        background: "rgba(0,0,0,20%)",
        color: "#fff",
        borderRadius: "6px",
        padding: "5px 10px",
        fontSize: "12px"
    },
    btn_orange: {
        background: "#EF9834FF",
        color: "#fff",
        borderRadius: "6px",
        padding: "5px 5px",
        fontSize: "12px",
        '&:hover': {
            background: "#EF9834FF"
        }
    },
    btn_red: {
        background: "#EF9834FF",
        color: "#fff",
        borderRadius: "6px",
        padding: "5px 10px",
        fontSize: "6px",
        '&:hover': {
            background: "#EF9834FF"
        }
    }


}
export default requireAuth(DigiloFixAi)
