import { getKeys, satisfy } from './types'
import {
  generatorGoals,
  generatorLanguageMapping,
  generatorStyleMapping,
  generatorToneMapping,
  getLanguageMapping,
  getStyleMapping,
  getToneMapping
} from './generator-mappings'

export interface GeneratorConfigField {
  label: string
  placeholder?: string
  isSmall?: boolean
  options?: string[]
  isTextArea?: boolean
}

export interface GeneratorConfig<T extends string = string> {
  name: string
  description?: string
  title: string
  fields: Record<T, GeneratorConfigField>
  action: string
  prompt: (values: Record<T, string>, additional?: string) => string
}

function createLanguageFormField(isSmall?: boolean): GeneratorConfigField {
  return {
    label: 'Taal',
    isSmall,
    options: getKeys(generatorLanguageMapping)
  }
}

function createGoalFormField(isSmall?: boolean): GeneratorConfigField {
  return {
    label: 'Doel',
    options: generatorGoals,
    isSmall
  }
}

function createStyleFormField(isSmall?: boolean): GeneratorConfigField {
  return {
    label: 'Stijl',
    options: getKeys(generatorStyleMapping),
    isSmall
  }
}

function createToneFormField(isSmall?: boolean): GeneratorConfigField {
  return {
    label: 'Toon',
    options: getKeys(generatorToneMapping),
    isSmall
  }
}

type GeneratorValues<T> = Record<keyof T, string>

function createGeneratorTopicConfig(name: string): GeneratorConfig {
  const fields = {
    topic: {
      label: 'Onderwerp',
      isTextArea: true
    },
    language: createLanguageFormField(),
    goal: createGoalFormField(),
    tone: createToneFormField(true),
    style: createStyleFormField(true)
  }
  return {
    name,
    title: `Digilo schrijft graag een ${name} voor je`,
    fields,
    prompt(
      { topic, language, tone, style, goal }: GeneratorValues<typeof fields>,
      additional: string = ''
    ): string {
      return `Schrijf in de ${getLanguageMapping(
        language
      )} een ${name} ${additional}over "${topic}". Schrijf deze ${name} om de lezer te "${goal}". Schrijf deze ${name} in een ${getToneMapping(
        tone
      )} en een ${getStyleMapping(
        style
      )}. Schrijf deze gehele tekst alleen in de ${getLanguageMapping(
        language
      )}.`
    },
    action: `Schrijf ${name}`
  }
}

function createGeneratorReceiverConfig(
  name: string,
  alternativeReceiverLabel?: string
): GeneratorConfig {
  const base = createGeneratorTopicConfig(name)
  const fields = {
    receiver: {
      label: alternativeReceiverLabel ?? 'Ontvanger'
    },
    ...base.fields
  }
  return {
    ...base,
    fields,
    prompt({ receiver, ...values }: GeneratorValues<typeof fields>) {
      return base.prompt(values, `gericht aan ${receiver} dat gaat `)
    }
  }
}

function createGeneratorAdConfig(name: string): GeneratorConfig {
  const fields = {
    productName: {
      label: 'Naam product/service'
    },
    productDescription: {
      label: 'Beschrijving product/service'
    },
    language: createLanguageFormField(),
    goal: createGoalFormField(),
    tone: createToneFormField(true),
    style: createStyleFormField(true)
  }
  return {
    name,
    title: `Digilo schrijft je ${name}`,
    fields,
    prompt({
      productName,
      language,
      tone,
      style,
      goal,
      productDescription
    }: GeneratorValues<typeof fields>): string {
      return `Schrijf in de ${getLanguageMapping(
        language
      )} een gedetaileerde ${name} over ${productName}. Verwerk "${productDescription}" in deze advertentie en Schrijf deze ${name} om de lezer te "${goal}". Schrijf deze ${name} in een ${getToneMapping(
        tone
      )} en een ${getStyleMapping(
        style
      )}. Schrijf deze gehele tekst alleen in de ${getLanguageMapping(
        language
      )}.`
    },
    action: `Schrijf ${name}`
  }
}

function createGeneratorProductDescriptionConfig(
  name: string
): GeneratorConfig {
  const base = createGeneratorAdConfig(name)
  const fields = {
    productName: base.fields.productName,
    productProperties: {
      label: 'Product eigenschappen'
    },
    productDescription: base.fields.productDescription,
    language: base.fields.language,
    goal: base.fields.goal,
    tone: base.fields.tone,
    style: base.fields.style
  }
  return {
    ...base,
    fields,
    prompt({
      productName,
      language,
      tone,
      style,
      goal,
      productProperties,
      productDescription
    }: GeneratorValues<typeof fields>): string {
      return `Schrijf in de ${getLanguageMapping(
        language
      )} een gedetaileerde ${name} over ${productName} met de deze eigenschappen "${productProperties}" en verwerk ook in deze product beschrijving dit "${productDescription}". Schrijf deze ${name} om de lezer te "${goal}". Schrijf deze ${name} in een ${getToneMapping(
        tone
      )} en een ${getStyleMapping(
        style
      )}. Schrijf deze gehele tekst alleen in de ${getLanguageMapping(
        language
      )}`
    }
  }
}

function createGeneratorSuggestionConfig(name: string): GeneratorConfig {
  const fields = {
    productOrService: {
      label: 'Welk product of dienst ga je leveren?',
      placeholder: 'Noteer hier wat je  gaat  doen of leveren?'
    },
    whatCause: {
      label: 'Wat is de aanleiding?',
      placeholder: 'Noteer hier waarom'
    },
    how: {
      label: 'Hoe ga je de dienst of product leveren?',
      placeholder: 'Leg uit hoe je dit doet'
    },
    steps: {
      label: 'Welke stappen ga je ondernemen?',
      placeholder: 'Noteer de stappen of het proces'
    },
    price: {
      label: 'Voor welke prijs of tarief?',
      placeholder: 'Noteer hier de bedragen'
    },
    howDelivered: {
      label: 'Hoe wordt het geleverd?',
      placeholder: 'Via post, op locatie of remote?'
    },
    tone: createToneFormField(true),
    language: createLanguageFormField(true)
  }
  return {
    name,
    title: `Digilo schrijft graag een ${name}`,
    fields,
    prompt({
      language,
      tone,
      how,
      price,
      steps,
      whatCause,
      howDelivered,
      productOrService
    }: GeneratorValues<typeof fields>): string {
      return `Schrijf in de ${getLanguageMapping(
        language
      )} of vertaling met signaalwoorden en meerdere alinea's een uitgebreid en getailleerd ${name} over "${productOrService}"? En verwerk op duidelijk manier dat "${whatCause}"? Dit ga je "${how}"? Beschrijf ook op een duidelijke en gedetailleerde manier doormiddel van "${steps}". Vertel duidelijk dat dit kost "${price}"? Levering vindt plaats via "${howDelivered}"? Beschrijf daarna duidelijk en uitgebreid van wat de lezer naar levering kan verwachten. Schrijf de gehele tekst in een ${getToneMapping(
        tone
      )}. Schrijf dit ook allemaal in het ${getLanguageMapping(language)}.`
    },
    action: `Schrijf ${name}`
  }
}

function createGeneratorTopicWithDescriptionConfig(
  name: string,
  description: string
): GeneratorConfig {
  return {
    ...createGeneratorTopicConfig(name),
    description
  }
}

export const generatorConfigs = satisfy<Record<string, GeneratorConfig>>()({
  note: createGeneratorReceiverConfig('memo'),
  assessment: createGeneratorTopicConfig('rapport'),
  contract: createGeneratorTopicConfig('contract'),
  briefing: createGeneratorTopicConfig('briefing'),
  whitePaper: createGeneratorTopicWithDescriptionConfig(
    'whitepaper',
    '*Digilo schrijft  geen volledige whitepapers maar beknopte versies'
  ),
  presentation: createGeneratorTopicWithDescriptionConfig(
    'presentatie',
    '*Digilo schrijft  geen volledige presentaties maar beknopte versies'
  ),
  email: createGeneratorReceiverConfig('e-mail'),
  suggestion: createGeneratorSuggestionConfig('voorstel'),
  offer: createGeneratorReceiverConfig('offerte'),
  agreement: createGeneratorReceiverConfig('overeenkomst'),
  letter: createGeneratorReceiverConfig('brief'),
  story: createGeneratorTopicConfig('verhaal'),
  comparison: createGeneratorTopicConfig('vergelijking'),
  biography: createGeneratorReceiverConfig('biografie', 'naam'),
  poem: createGeneratorTopicConfig('gedicht'),
  song: createGeneratorTopicConfig('songtekst'),
  journal: createGeneratorTopicConfig('dagboek'),
  article: createGeneratorTopicConfig('artikel'),
  bookReview: createGeneratorTopicConfig('boekrecensie'),
  facebookAd: createGeneratorAdConfig('facebook advertentie'),
  googleAd: createGeneratorAdConfig('google advertentie'),
  linkedInAd: createGeneratorAdConfig('linkedIn advertentie'),
  twitterTweet: createGeneratorTopicConfig('twitter tweet'),
  socialMediaReport: createGeneratorTopicConfig('social media bericht'),
  pressRelease: createGeneratorTopicConfig('pers bericht'),
  productDescription: createGeneratorProductDescriptionConfig(
    'productbeschrijving'
  ),
  thesis: createGeneratorTopicConfig('thesis'),
  researchReport: createGeneratorTopicConfig('onderzoeksverslag'),
  methodology: createGeneratorTopicConfig('methodology'),
  hypothesis: createGeneratorTopicConfig('hypothese'),
  bibliography: createGeneratorTopicConfig('bibliografie')
})

export type GeneratorType = keyof typeof generatorConfigs
