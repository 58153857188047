import React from 'react'
import Container from '@mui/material/Container'
import Section from './Section'
import SectionHeader from './SectionHeader'
import Auth from './Auth'
import AuthFooter from './AuthFooter'

function AuthSection(props) {
  // Options by auth type
  const optionsByType = {
    signup: {
      // Top Title
      title: 'Registreer nu',
      subtitle: (
        <>
       Maak een account aan voor een gratis proefsessie van 30 minuten. Om vervolgens te genieten van een <b>5-daagse proefperiode</b>, selecteer je eenvoudig het starterspakket wanneer de 30 minuten voorbij zijn.
        </>
      ),
      // Button text
      buttonAction: 'Registreren',
      // Footer text and links
      showFooter: true,
      signinText: 'Heb je al een account?',
      signinAction: 'Aanmelden',
      signinPath: '/auth/signin',
      // Terms and privacy policy agreement
      showAgreement: true,
      termsPath: '/legal/terms-of-service',
      privacyPolicyPath: '/legal/privacy-policy'
    },
    signin: {
      title: 'Welkom terug',
      buttonAction: 'Aanmelden',
      showFooter: true,
      signupAction: 'Account aanmaken',
      signupPath: '/auth/signup',
      forgotPassAction: 'Wachtwoord vergeten?',
      forgotPassPath: '/auth/forgotpass'
    },
    forgotpass: {
      title: 'Get a new password',
      buttonAction: 'Reset password',
      showFooter: true,
      signinText: 'Remember it after all?',
      signinAction: 'Aanmelden',
      signinPath: '/auth/signin'
    },
    changepass: {
      title: 'Kies een nieuw wachtwoord',
      buttonAction: 'Wachtwoord wijzigen'
    }
  }

  // Ensure we have a valid auth type
  const type = optionsByType[props.type] ? props.type : 'signup'

  // Get options object for current auth type
  const options = optionsByType[type]

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="xs">
        <SectionHeader
          title={options.title}
          subtitle={options.subtitle}
          size={4}
          textAlign="center"
          sx={{fontWeight:400}}
        />
       {/* <h4>
          {options.title}
       </h4>
        <h4>
          {options.subtitle}
        </h4> */}
        <Auth
          type={type}
          buttonAction={options.buttonAction}
          providers={props.providers}
          afterAuthPath={props.afterAuthPath}
          key={type}
        />

        {options.showFooter && <AuthFooter type={type} {...options} />}
      </Container>
    </Section>
  )
}

export default AuthSection
