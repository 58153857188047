import React, { useEffect, useState } from 'react'

export function usePaginationState(count: number) {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handlePageChange = (
    _e: React.MouseEvent<HTMLButtonElement> | null,
    value: number
  ) => setPage(value)

  useEffect(() => {
    if (checkIfPageIsInvalid(count, page, rowsPerPage)) {
      setPage(ensureValidPage(count, page, rowsPerPage))
    }
  }, [count, page, rowsPerPage])

  return {
    page: ensureValidPage(count, page, rowsPerPage),
    count,
    onPageChange: handlePageChange,
    rowsPerPage,
    onReset: () => handlePageChange(null, 0),
    onRowsPerPageChange: handleChangeRowsPerPage
  }
}

function checkIfPageIsInvalid(
  count: number,
  page: number,
  rowsPerPage: number
) {
  return count <= page * rowsPerPage
}

function ensureValidPage(count: number, page: number, rowsPerPage: number) {
  return checkIfPageIsInvalid(count, page, rowsPerPage)
    ? Math.max(Math.floor((count - 1) / rowsPerPage), 0)
    : page
}
