import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { useForm } from 'react-hook-form'
import { useAuth } from '../util/auth'
import { Alert } from '@mui/material'

interface EditItemModalProps {
  itemTypeName: string
  id: string | ''
  itemData: any | null
  onUpdate: (id: string, data: any) => Promise<any>
  onCreate: (data: any) => Promise<any>
  onDone: (isCreated: boolean) => void
}

const styles = {
  actionRow: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}

function EditItemModal(props: EditItemModalProps) {
  const auth = useAuth()
  const [pending, setPending] = useState(false)
  const [formAlert, setFormAlert] = useState(null)

  const { register, handleSubmit, errors } = useForm()

  // If we are updating an existing item
  // don't show modal until item data is fetched.
  if (props.id && !props.itemData) {
    return null
  }

  const onSubmit = (data) => {
    setPending(true)

    const query = props.id
      ? props.onUpdate(props.id, data)
      : props.onCreate({ owner: auth.user.uid, ...data })

    query
      .then(() => {
        // Let parent know we're done so they can hide modal
        props.onDone(!props.id)
      })
      .catch((error) => {
        // Hide pending indicator
        setPending(false)
        // Show error alert message
        setFormAlert({
          type: 'error',
          message: error.message
        })
      })
  }

  return (
    <Dialog open onClose={props.onDone}>
      <DialogTitle>
        Naam wijzigen
      </DialogTitle>
      <DialogContent>
        {formAlert && (
          <Box mb={4}>
            <Alert severity={formAlert.type}>{formAlert.message}</Alert>
          </Box>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                variant="outlined"
                type="text"
                label="Naam"
                name="name"
                autoComplete="off"
                defaultValue={props.itemData && props.itemData.name}
                error={!!errors.name}
                helperText={errors.name && errors.name.message}
                fullWidth
                autoFocus
                inputRef={register({
                  required: 'Please enter a name'
                })}
              />
            </Grid>
            <Grid item xs={12} sx={styles.actionRow}>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                type="submit"
                disabled={pending}
              >
                {!pending && <span>Save</span>}

                {pending && <CircularProgress size={28} />}
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default EditItemModal
