import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  Stack,
  Typography
} from '@mui/material'
import React, { ReactNode } from 'react'
import {
  BarChart,
  DiamondOutlined,
  ShoppingBag,
  SvgIconComponent
} from '@mui/icons-material'
import { Link } from 'react-router-dom'
import Button from '@mui/material/Button'
import { RoutePath, settingsPath } from '../../util/routes'
import { useAuth } from '../../util/auth'
import { getPlanDetails } from '../../util/prices'
import { useCountContents } from './content-api'

interface ServiceTilesProps {
  projectId: string | null
}

const styles = {
  iconBox: {
    border: 1,
    borderColor: 'white',
    color: 'white',
    borderRadius: 1,
    p: 1,
    mt: 0.5,
    display: { xs: 'none', md: 'flex' },
    mb: 'auto',
    ml: 'auto'
  },
  tileCard: {
    backgroundColor: 'primary.light',
    // height: { xs: '100%', md: 175 },
    display: 'flex',
    flexDirection: 'column'
  },
  tileContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    px: {
      xs: 0.5,
      md: 2
    },
    py: {
      xs: 1,
      md: 2
    }
  },
  tileValue: {
    textAlign: 'center',
    my: 1,
    fontSize: { xs: '0.65rem', md: '1rem' }
  },
  actionWrapper: {
    justifyContent: 'flex-end'
  },
  actionButton: {
    textDecoration: 'underline'
  },
  tileAction: {
    display: { xs: 'none', md: 'block' }
  },
  tileLabel: {
    textAlign: 'center',
    fontSize: { xs: '0.65rem', md: '1rem' }
  },
  tileWrapper: {
    height: {
      xs: '100%',
      md: 'auto'
    }
  }
}

function ServiceTiles({ projectId }: ServiceTilesProps) {
  const { user } = useAuth()
  // console.log(user,"all details are here")
  const { data: contentCount } = useCountContents(projectId, user.uid)

  const planDetails = getPlanDetails(user.planId)
  const result = user?.paymentType ? user?.paymentType === "subscription" ? "Unlimited" : 50000 : 3000

  const tiles: TileProps[] = [
    {
      label: user?.stripePriceId  ? ` ${user?.stripePriceId === 'price_1NaEGHEPNZTV1hsCFvTSRNUL' === false  && 'Pay Per Use' || user?.stripePriceId === 'price_1NaEAdEPNZTV1hsCyP46W4Vb' && 'Gratis' || user?.stripePriceId === 'price_1NaEDREPNZTV1hsCzQkA58el' && 'Standaard' || user?.stripePriceId === 'price_1Mg5M4EPNZTV1hsCWVQdXVgy' && 'Plus' || user?.stripePriceId === 'price_1Mg5L6EPNZTV1hsCXXZ8JKPo' && 'Premium' || user?.stripePriceId === 'price_1MmF5lEPNZTV1hsC7dx4xots' && 'Digilo Basic'}` : "Digilo 30 Minuten",

    value:result  ,
   
      Icon: DiamondOutlined,
      action: planDetails.id !== 'premium' && (
        <Button
          sx={styles.actionButton}
          component={Link}
          to={RoutePath.pricing}
          variant="contained"
          color="secondary"
        >
          Upgrade nu je abonnement en krijg meer woorden
        </Button>
      )
    },
    {
      label: 'Woorden Deze Maand',
      value: user?.paymentType === "subscription" ? "Unlimited" : user.credits,
      Icon: ShoppingBag,
      action: null
    },
    {
      label: 'Bewaarde Tekstsoorten',
      value: contentCount,
      Icon: BarChart,
      action: null
    }
  ]

  return (
    <Grid container spacing={1} my={2}>
      {tiles.map((tile) => (
        <Grid item xs={4} key={tile.label}>
          <Tile {...tile} />
        </Grid>
      ))}
    </Grid>
  )
}

interface TileProps {
  label: string
  value: string | number
  Icon: SvgIconComponent
  action: ReactNode | null
}

function Tile({ label, value, Icon, action }: TileProps) {
  return (
    <Card sx={styles.tileCard}>
      <Box sx={styles.tileContent}>
        <Stack direction="row" justifyContent="center" sx={styles.tileWrapper}>
          <Stack height="100%" justifyContent="space-between">
            <Typography sx={styles.tileLabel}>{label}</Typography>

            <Typography fontWeight="bold" sx={styles.tileValue}>
              {value}
            </Typography>
          </Stack>

          <Box sx={styles.iconBox}>
            <Icon></Icon>
          </Box>
        </Stack>
      </Box>

      {/* {action && <CardActions sx={styles.tileAction}>{action}</CardActions>} */}
    </Card>
  )
}

export default ServiceTiles
