import React from 'react'
import PageLoader from '../components/PageLoader'
import { useAuth } from '../util/auth'
import { getPlanDetails, planDetails } from '../util/prices'
import NotFoundPage from '../pages/404'
import { useRouter } from '../util/router'

function requirePlan<T>(
  Component: React.ComponentType<T>,
  minimumPlanId: 'plus' | 'premium',
  restrictedParams?: Record<string, string[]>
): React.ComponentType<T> {
  const PremiumRestrictedComponent = (props: T) => {
    const { user } = useAuth()
    const { params } = useRouter()

    if (!user) {
      return <PageLoader />
    }

    const planDetails = getPlanDetails(user.planId)
    const matchesParams =
      !restrictedParams ||
      Object.entries(restrictedParams).every(([paramType, paramValues]) =>
        paramValues.includes(params[paramType])
      )

    const hasMinimumPlan =
      !planDetails.id ||
      mapPlanToNumber(planDetails.id) >= mapPlanToNumber(minimumPlanId)

    if (!hasMinimumPlan && matchesParams) {
      return <NotFoundPage location={window.location} />
    }

    return <Component {...props} />
  }
  return PremiumRestrictedComponent
}

export function mapPlanToNumber(planId: string): number {
  const planValue = planDetails.findIndex(({ id }) => planId === id)
  return planValue === -1 ? 0 : planValue
}

export default requirePlan
