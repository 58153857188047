import { useState } from 'react'
import { ChatMessage } from '../../shared-types/assistance.types'
import {textGenerateApi} from "./text-generate-api";

interface ChatState {
  currentAnswer: string
  results: ChatMessage[]
  sendPrompt: (prompt: string) => Promise<void>
}

export function useChatState({sendPrevious = true}: Partial<{sendPrevious: boolean}> = {}): ChatState {
  const [results, setResults] = useState<ChatMessage[]>([])
  const [currentAnswer, setCurrentAnswer] = useState<string>()

  const sendPrompt = async (promptText) => {
    const previousMessages = sendPrevious ? results : []
    setResults((results) => [...results, { text: promptText, isAnswer: false }])
    setCurrentAnswer('')
    let currentMessage = ''
    await textGenerateApi(promptText, previousMessages, (message) => {
      currentMessage = message
      setCurrentAnswer(currentMessage)
    })
    setCurrentAnswer(undefined)
    setResults((results) => [
      ...results,
      { text: currentMessage, isAnswer: true }
    ])
  }

  return {
    currentAnswer,
    results,
    sendPrompt
  }
}
