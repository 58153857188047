import { Box, Step, StepLabel, Stepper, useTheme } from '@mui/material'
import React, { useState } from 'react'
import {
  createDefaultWriterState,
  WriterState,
  WriterStep,
  writerStepLabels,
  writerSteps
} from './ai-writer.model'
import TopicStep from './steps/topic-step'
import { checkNextReady, setBackState } from './ai-writer-utils'
import TitleStep from './steps/title-step'
import DescriptionStep from './steps/description-step'
import OutlineStep from './steps/outline-step'
import GenerateStep from './steps/generate-step'
import { NeedsCreditsButton } from '../../hocs/needsCredits'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import useMediaQuery from '@mui/material/useMediaQuery'

const styles = {
  stepper: {
    pb: 1,
    justifyContent: 'center'
  },
  buttonRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    py: 2,
    '& > button': {
      mx: 1
    }
  }
}

function AiWriter() {
  const [activeStep, setActiveStep] = useState(WriterStep.Topic)
  const [writerState, setWriterState] = useState<WriterState>(
    createDefaultWriterState()
  )
  const isNextReady = checkNextReady(activeStep, writerState)
  const { breakpoints } = useTheme()
  const isMobile = useMediaQuery(breakpoints.down('md'))

  //this is next page step
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setBackState(activeStep, setWriterState)
    setBackState(activeStep - 1, setWriterState)
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReset = () => {
    setWriterState(createDefaultWriterState())
    setActiveStep(0)
  }

  function getWriterPanel(activeStep: WriterStep) {
    switch (activeStep) {
      case WriterStep.Topic:
        return TopicStep
      case WriterStep.Title:
        return TitleStep
      case WriterStep.Description:
        return DescriptionStep
      case WriterStep.Outline:
        return OutlineStep
      case WriterStep.Generate:
        return GenerateStep
    }
  }

  const WriterPanel = getWriterPanel(activeStep)

  const navigationButtonRow = (
    <ButtonRow
      isNextReady={isNextReady}
      onReset={handleReset}
      activeStep={activeStep}
      onBack={handleBack}
      onNext={handleNext}
    />
  )

  const stepper = (
    <Stepper
      activeStep={activeStep}
      sx={styles.stepper}
      connector={<StepperConnector />}
    >
      {writerSteps.map((step, i) => {
        if (
          isMobile &&
          (![activeStep, activeStep + 1].includes(step) ||
            step === writerSteps.length - 1)
        ) {
          return null
        }

        return (
          <Step
            key={step}
            sx={{
              borderBottom: activeStep === step ? 3 : 0,
              borderColor: 'secondary.main',
              py: {
                xs: 1,
                sm: 2
              },
              display: {
                xs: activeStep === step ? 'block' : 'none',
                md: 'block'
              }
            }}
          >
            <StepLabel StepIconComponent={StepperIcon}>Stap {i + 1}</StepLabel>
          </Step>
        )
      })}
    </Stepper>
  )

  return (
    <WriterPanel
      setWriterState={(partialState) =>
        setWriterState((state) => ({ ...state, ...partialState }))
      }
      writerState={writerState}
      title={writerStepLabels[activeStep]}
      header={stepper}
      navigationButtonRow={navigationButtonRow}
    />
  )
}

function StepperConnector() {
  return <ArrowForwardIosIcon sx={{ mx: { xs: 4, md: 'auto' } }} />
}

function StepperIcon({ active }: { active?: boolean }) {
  return (
    <Box
      sx={{
        height: 32,
        width: 32,
        borderRadius: '50%',
        border: 1,
        borderColor: 'grey.400',
        backgroundColor: active ? 'secondary.main' : 'background.main'
      }}
    ></Box>
  )
}

function ButtonRow({
  activeStep,
  onNext,
  onBack,
  onReset,
  isNextReady
}: {
  isNextReady: boolean
  activeStep: number
  onBack: () => void
  onNext: () => void
  onReset: () => void
}) {
  const isLastStep = activeStep === writerSteps.length - 1
  const handleNext = () => (isLastStep ? onReset() : onNext())
  return (
    <Box sx={styles.buttonRow}>
      <NeedsCreditsButton
        color="inherit"
        variant="contained"
        disabled={activeStep === 0}
        onClick={onBack}
      >
        Vorige
      </NeedsCreditsButton>
      <NeedsCreditsButton
        color="inherit"
        variant="contained"
        onClick={handleNext}
        disabled={!isNextReady}
      >
        {isLastStep ? 'Resetten' : 'Volgende'}
      </NeedsCreditsButton>
    </Box>
  )
}

export default AiWriter
