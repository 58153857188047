export function scrollToBottom() {
  window.scrollTo(0, document.body.scrollHeight)
}

export function htmlToElement(html) {
  const template = document.createElement('template')
  html = html.trim() // Never return a text node of whitespace as the result
  template.innerHTML = `<div>${html}</div>`
  return template.content.firstChild as HTMLElement
}
