import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { useForm } from 'react-hook-form'
import { useAuth } from '../util/auth'

function SettingsGeneral(props) {
  const auth = useAuth()
  const [pending, setPending] = useState(false)

  const { register, handleSubmit, errors } = useForm()

  const onSubmit = (data) => {
    // Show pending indicator
    setPending(true)

    return auth
      .updateProfile(data)
      .then(() => {
        // Set success status
        props.onStatus({
          type: 'success',
          message: 'Your profile has been updated'
        })
      })
      .catch((error) => {
        if (error.code === 'auth/requires-recent-login') {
          props.onStatus({
            type: 'requires-recent-login',
            // Resubmit after reauth flow
            callback: () => onSubmit(data)
          })
        } else {
          // Set error status
          props.onStatus({
            type: 'error',
            message: error.message
          })
        }
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false)
      })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            type="text"
            label="Naam"
            name="name"
            placeholder="Name"
            defaultValue={auth.user.name}
            error={!!errors.name}
            helperText={errors.name && errors.name.message}
            fullWidth
            inputRef={register({
              required: 'Please enter your name'
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            type="email"
            label="E-mail"
            name="email"
            placeholder="user@example.com"
            defaultValue={auth.user.email}
            error={!!errors.email}
            helperText={errors.email && errors.email.message}
            fullWidth
            inputRef={register({
              required: 'Please enter your email'
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            disabled={pending}
            fullWidth
          >
            {!pending && <span>Opslaan</span>}

            {pending && <CircularProgress color="warning" size={28} />}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default SettingsGeneral
