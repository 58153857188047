import { Card, Dialog, Modal, Stack } from '@mui/material'
import React, { useEffect } from 'react'
import IconButton from '@mui/material/IconButton'
import { Close } from '@mui/icons-material'
import { useAuth } from '../util/auth'
import { updateUser } from '../util/db'
import { Dispatch, SetStateAction } from "react";
 
const styles = {
  modal: {
    px: {
      xs: 1,
      sm: 4
    },
    '&:focus': {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  card: {
    width: '100%',
    maxWidth: 1024
  },
  video: {
    width: '100%'
  },
  closeButton: {
    alignSelf: 'flex-end'
  }
}

type handleProps = {
  stateTrue? : boolean,
}

export function NewUserPopup(props : handleProps) {
  const { user } = useAuth()
  const isOnboarded = !!user?.isOnboarded
  const userId = user?.id

  const [open, setOpen] = React.useState(false)
  const [video,setVideo] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  useEffect(() => {
    if (!isOnboarded && userId) {
      handleOpen()
      updateUser(userId, { isOnboarded: true , info_completed : false })
    }
  }, [isOnboarded, userId,user])

  return (
video &&     <Modal open={open} onClose={handleClose} sx={styles.modal}>
<Card sx={styles.card}>
  <Stack>
    <IconButton onClick={handleClose} sx={styles.closeButton}>
      <Close /> 

      {/* the video will shows after submetting information after signup */}
    </IconButton> 
{user?.info_completed === true && <NewUserVideo /> }
  </Stack>
</Card>
</Modal>
  )
}

export function NewUserVideo() {
  return (
    <video style={styles.video} controls playsInline muted autoPlay>
      <source src="/video/digilo-welcome.mp4" type="video/mp4" />
    </video>
  )
}

export default NewUserPopup
