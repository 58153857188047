import React from 'react'
import WriterPanel, {
  useGenerateContent,
  WriterStepExtendedProps
} from '../components/writer-panel'
import WriterSelection from '../components/writer-selection'
import { generateTitleApi } from '../article-generate-api'
import { createDefaultWriterState } from '../ai-writer.model'

function TitleStep(props: WriterStepExtendedProps) {
  const contentGeneration = useGenerateContent(() => {
    props.setWriterState({ title: createDefaultWriterState().title })
    return generateTitleApi(props.writerState)
  })

  return (
      <WriterPanel {...props} {...contentGeneration}>
      <WriterSelection
        onSelection={(title) => props.setWriterState({ title })}
        values={contentGeneration.generated}
      />
    </WriterPanel>
  )
}

export default TitleStep
