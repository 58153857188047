import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Avatar } from '@mui/material'
import { Send } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import Card from '@mui/material/Card'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import { useAuth } from '../../util/auth'
import { scrollToBottom } from '../../util/dom'
import CopyToClipboardButton from '../../components/CopyToClipboardButton'
import {
  NeedsCreditsButton,
  NeedsCreditsTextField
} from '../../hocs/needsCredits'
import { drawerWidth } from '../../components/SideNavbar/SideNavbar'
import SaveContentButton from '../../components/SaveContentButton'
import { ChatMessage } from "../../shared-types/assistance.types";
interface ChatProps {
  messages: ChatMessage[]
  onInputEnter: (value: string) => void
  disableInput?: boolean
  currentWriting?: string
  disableAvatars?: boolean
  scrollToPrompt?: boolean
}

const useStyles = makeStyles((theme) => ({
  chatInput: {
    flexGrow: 1
  },
  chatForm: { display: 'flex' },
  sendButton: {},
  chatMessage: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px ${theme.palette.action.disabled} solid`,
    padding: '16px 0'
  },
  messageText: {
    whiteSpace: 'pre-line',
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  messagePrompt: {
    fontWeight: 'bold'
  },
  inputContainer: {
    position: 'fixed',
    top: 'auto',
    width: 'auto',
    right: 0,
    bottom: 0,
    background: theme.palette.background.appDefault
  },
  inputCard: {
    marginBottom: theme.spacing(4)
  },
  userAvatar: {
    alignSelf: 'flex-start',
    marginRight: theme.spacing(2),
    background: theme.palette.secondary.main
  },
  botAvatar: {
    height: 40,
    marginRight: 6,
    alignSelf: 'flex-start', width: "40px"
  },
  infoCard: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: 'min(100%, 800px)',
    margin: `${theme.spacing(15)} auto 0`,
    padding: theme.spacing(4)
  },
  copyButton: {
    marginLeft: "auto",
    display: "flex",
    [theme.breakpoints.down("md")]: {
         width:"150px",
         
  },

},
  placeholderAvatar: {
  position: 'absolute',
  bottom: '100%',
  marginBottom: theme.spacing(2)
},
  assitant_wrap: {
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexWrap:"wrap"
  }
},
  message: {
  margin: "0",
  [theme.breakpoints.down("md")]: {
    width: "84%"
},

}
}))

const Chat: React.FC<ChatProps> = ({
  messages,
  onInputEnter,
  currentWriting,
  disableInput,
  disableAvatars,
  scrollToPrompt
}) => {
  const classes = useStyles()
  const auth = useAuth()
  const messagesContainerRef = useRef<HTMLDivElement>()

  const [inputValue, setInputValue] = useState<string>('')

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    onInputEnter(inputValue)
    setInputValue('')
  }

  const messagesWithCurrent: (ChatMessage & { isStreaming?: boolean })[] =
    useMemo(
      () =>
        currentWriting != null
          ? [
            ...messages,
            {
              text: [currentWriting, '...'].join(''),
              isAnswer: true,
              isStreaming: true
            }
          ]
          : messages,
      [currentWriting, messages]
    )

  useEffect(() => {
    if (!scrollToPrompt) {
      scrollToBottom()
    }
  }, [messagesWithCurrent.length, messages.length])

  const lastPromptIndex = messagesWithCurrent
    .map(({ isAnswer }) => isAnswer)
    .lastIndexOf(false)
  useEffect(() => {
    if (scrollToPrompt) {
      const children = messagesContainerRef.current?.children
      children?.[lastPromptIndex]?.scrollIntoView(true)
    }
  }, [lastPromptIndex, scrollToPrompt])

  const handleInputKeyDown = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      handleSubmit(e)
    }
  }

  return (
    <div>
      <div ref={messagesContainerRef}>
        {messagesWithCurrent.map((message, index) => (
          <div key={index} className={classes.chatMessage}>

            <div
              className={`${classes.messageText} ${!message.isAnswer ? classes.messagePrompt : ''
                }`}
            >

              <div className={classes.assitant_wrap}>
                {!disableAvatars &&
                  (message.isAnswer ? (
                    <img
                      className={classes.botAvatar}
                      src="/img/digilo-head.png"
                      alt="Logo"
                    />
                  ) : (
                    <Avatar className={classes.userAvatar}>
                      {auth?.user.email?.[0]?.toUpperCase()}
                    </Avatar>
                  ))}
                <p className={classes.message}>{message.text}</p>
                {message.isAnswer && !message.isStreaming && (
                  <Box className={classes.copyButton}>
                    <SaveContentButton text={message.text} type="Digilo" />
                    <CopyToClipboardButton text={message.text} />
                  </Box>
                )}
              </div>


            </div>
          </div>
        ))}
      </div>

      {scrollToPrompt && messages.length ? (
        <Box sx={{ height: '100vh' }} />
      ) : (
        <Box sx={{ height: 70 }}></Box>
      )}

      {!disableInput && (
        <Container
          className={classes.inputContainer}
          sx={{ left: { xs: 0, sm: drawerWidth } }}
        >
          {/* {!messagesWithCurrent.length && (
            <img
              className={`${classes.botAvatar} ${classes.placeholderAvatar}`}
              src="/img/digilo-avatar.png"
              alt="Logo"
            />
          )} */}
          <Card className={classes.inputCard}>
            <form onSubmit={handleSubmit} className={classes.chatForm}>
              <NeedsCreditsTextField
                type="text"
                multiline
                minRows={1}
                maxRows={6}
                disabled={currentWriting !== undefined}
                placeholder="Stel hier je vraag"
                variant="outlined"
                value={inputValue}
                onChange={handleChange}
                onKeyDown={handleInputKeyDown}
                className={classes.chatInput}
              />
              <NeedsCreditsButton
                type="submit"
                disabled={currentWriting !== undefined}
                className={classes.sendButton}
              >
                <Send />
              </NeedsCreditsButton>
            </form>
          </Card>
        </Container>
      )}
    </div>
  )
}

export default Chat
