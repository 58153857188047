import React, { useEffect, useState } from 'react'
import AppLayout from '../components/AppLayout'
import MainPanel from '../components/MainPanel'
import requirePlan from '../hocs/requirePlan';
import { requireAuth } from '../util/auth';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@material-ui/core';
import { get_custom_options, getAllUsers, handledeletetSuggestedOptions } from '../util/db';
import { Button } from '@mui/material';

const style = {
    backgroundColor: " red",
    Padding: "20px"
}
const AdminCustomOptions = () => {
    return (
        <AppLayout title='Custom Options'>
            <MainPanel

                main={<AdminCustomOptionsMain />}
            />
        </AppLayout>
    )
}
const AdminCustomOptionsMain = () => {
    const [data, setdata] = useState([])
    const [users, setUsers] = useState([])
    useEffect(() => {


        adminCustomdata();
        getUser();

    }, [])
    const adminCustomdata = async () => {
        const adminCustomData = await get_custom_options();
        setdata(adminCustomData);
    }
    const getUser = async () => {
        const userData = await getAllUsers();
        setUsers(userData)
    }
    const handledelete = async (id) => {
        const deleteItems = await handledeletetSuggestedOptions(id);
        adminCustomdata();

    }

    return (
        <>
        

            <TableContainer component={Paper} sx={{ mt: 3 }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 700 }}>Sr. no</TableCell>
                            <TableCell sx={{ fontWeight: 700 }} align="center">User Name</TableCell>
                            <TableCell sx={{ fontWeight: 700 }} align="center">From Modal</TableCell>
                            <TableCell sx={{ fontWeight: 700 }} align="center">Suggest Name</TableCell>
                            <TableCell sx={{ fontWeight: 700 }} align="center">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((curelem, index) => {
                            return (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        {users.map((name, childindex) => {
                                            if (name.id === curelem.user_id) {
                                                return <span key={childindex}>{name.name}</span>
                                            }
                                        })}
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        {curelem.modal}
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        {curelem.name}
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        <Button variant="contained" onClick={() => handledelete(curelem.id)}>Delete</Button>
                                    </TableCell>

                                </TableRow>
                            )
                        })}


                    </TableBody>
                </Table>
            </TableContainer>



        </>
    )
}

export default requireAuth(requirePlan(AdminCustomOptions, 'premium'))