import React, { useRef, useState } from 'react'
import { Container } from '@mui/material'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { Editor } from '@tinymce/tinymce-react'
import { Box } from '@material-ui/core'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { subtle } from 'crypto'
import { useHistory } from '../../util/router'
import { useParams } from '../../util/router'
import { get_selected_blog } from '../../util/db'
import { update_blog } from '../../util/db'
import AppLayout from '../../components/AppLayout'
import MainPanel from '../../components/MainPanel'
import LoadingButton from '@mui/lab/LoadingButton'
import requirePlan from '../../hocs/requirePlan'
import { requireAuth } from '../../util/auth'
import Snackbar from '@mui/material/Snackbar'
const styles = {
  root: {
    paddingBottom: '70px',
    paddingTop: '70px'
  },
  title: {
    my: 4,
    textAlign: 'left'
  },
  subtitle: {
    my: 4,
    color: 'grey.600'
  },
  cmn_bg: {
    backgroundColor: '#EF9834FF'
  },
  cmn_border: {
    borderColor: '#EF9834FF',
    color: '#EF9834FF'
  },
  btns: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}
const EditBlog = () => {
  return (
    <AppLayout title="Add Blog">
      <MainPanel main={<EditBlogMain />} />
    </AppLayout>
  )
}
const EditBlogMain = () => {
  const history = useHistory()
  const editorRef = useRef(null)
  const params = useParams()
  const { id } = params
  const [title, setTitle] = useState('')
  const [subTitle, setSubTitle] = useState('')
  const [metaDesc, setMetaDesc] = useState('')
  const [metaKey, setMetaKey] = useState('')
  const [selectedFile, setSelectedFile] = useState(null)
  const [imagePreview, setImagePreview] = useState(null)
  const [dataUri, setDataUri] = useState(null)
  const [data, setData] = useState(null)
  const [open, setOpen] = React.useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const [errorFormat, setErrorFormat] = useState(false)
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }
  console.log(data)
  const [description, setDescription] = useState(null)
  const [loading, setLoading] = useState(false)
  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    setSubmitted(true);
    const editor = editorRef.current
    const word = editor.getContent({ format: 'html' })
    const wordText = editor.getContent({ format: 'text' })

    // Check if any required field is empty
    if (!title || !subTitle || !word || !metaDesc || !metaKey || !dataUri) {
      setLoading(false)
      return
    }

    const url = title.replaceAll(' ','-')

    const data = {
      title: title,
      subtitle: subTitle,
      description: word,
      meta_keyword: metaKey,
      meta_desc: metaDesc,
      image: dataUri,
      text: wordText,
      url:url,
      updated_at: new Date()
    }

    try {
      const response = await update_blog(id, data)
      setLoading(false)
      history.push('/app/addBlog')
    } catch (error) {
      console.error('Error updating blog:', error)
      alert('An error occurred while updating the blog. Please try again.')
      setLoading(false)
      setSubmitted(false);
    }
  }

  React.useEffect(() => {
    setTitle(data?.title)
    setSubTitle(data?.subtitle)
    setMetaDesc(data?.meta_desc)
    setMetaKey(data?.meta_keyword)
    setDataUri(data?.image)
    setDescription(data?.description)
  }, [data])

  React.useEffect(() => {
    const get_blog = async () => {
      const data = await get_selected_blog(id)
      setData(data)
    }
    get_blog()
  }, [id])

  const fileToDataUri = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = (event) => {
        resolve(event.target.result)
      }
      reader.readAsDataURL(file)
    })

    const handleFileChange = (event) => {
      const file = event.target.files[0];
    
      // Check if a file was selected
      if (file) {
        // Check if the file is an image
        if (!file.type.startsWith('image/')) {
          // If it's not an image, display an error message or handle it as you prefer
          setErrorFormat(true);
          setErrorMessage(false);
          return;
        }
        else{
          setErrorFormat(false);
        }
    
        // Check the file size
        if (file.size > 1024 * 1024) {
          // 1MB limit
          setOpen(true);
          setErrorMessage(true);
          return;
        } else {
          setErrorMessage(false);
        }
    
        // Convert the selected file to a data URI
        fileToDataUri(file).then((dataUri) => {
          setDataUri(dataUri);
        });
    
        // Create a temporary URL for the selected file
        const imageUrl = URL.createObjectURL(file);
        setImagePreview(imageUrl);
      }
    
      // If no file was selected, clear any previous data
      setSelectedFile(file);
    };
  return (
    <Container style={styles.root}>
      <Snackbar
        open={open}
        autoHideDuration={1500}
        onClose={handleClose}
        message="Please select an image smaller than 1MB"
      />
      <form onSubmit={handleSubmit}>
        <Box style={styles.btns}>
          <Typography variant="h4" sx={styles.title}>
            Edit Blog
          </Typography>
          <LoadingButton
            className="loading_btns"
            style={styles.cmn_bg}
            loading={loading}
            variant="contained"
            type="submit"
          >
            <span>Publish</span>
          </LoadingButton>
        </Box>
        <Grid container spacing={3}>
          <Grid item sm={12} md={8}>
            <TextField
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              fullWidth
              id="fullWidth"
              label="Title"
              variant="outlined"
              error={!title && submitted} // Check if title is empty
              helperText={!title && submitted && 'Title is required'} // Display error message if title is empty
            />
            <Box sx={{ margin: '20px 0px' }}>
              <TextField
                fullWidth
                id="fullWidth"
                value={subTitle}
                onChange={(e) => setSubTitle(e.target.value)}
                label="SubTitle"
                variant="outlined"
                error={!subTitle && submitted}
                helperText={!subTitle && submitted && 'SubTitle is required'}
              />
            </Box>
            <Box className="blog_editor">
              <Editor
                tinymceScriptSrc={'/tinymce/tinymce.min.js'}
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={description}
                init={{
                  min_height: 100,
                  statusbar: true,
                  resize: true,
                  branding: false,
                  menubar: true,
                  plugins: [
                    'advlist',
                    'autolink',
                    'lists',
                    'link',
                    'image',
                    'charmap',
                    'preview',
                    'anchor',
                    'searchreplace',
                    'visualblocks',
                    'code',
                    'fullscreen',
                    'insertdatetime',
                    'media',
                    'table',
                    'code',
                    'help',
                    'wordcount'
                  ],
                  toolbar:
                    'undo redo | blocks | ' +
                    'bold italic forecolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help',

                  content_css:
                    'https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap', // Add Poppins font here
                  content_style:
                    'body { font-family: "Poppins"; font-size: 14px; }'
                }}
              />
            </Box>
          </Grid>
          <Grid item sm={12} md={4}>
            <Box className="upload_image">
              {imagePreview ? (
                <img src={imagePreview} alt="Selected File" />
              ) : (
                <img src={dataUri} alt="" />
              )}
            </Box>
            <Box sx={{ marginTop: '20px',marginBottom:"10px" }}>
              <TextField
                onChange={handleFileChange}
                type="file"
                fullWidth
                id="fullWidth"
                label=""
                error={!dataUri && submitted}
                helperText={!dataUri && submitted && 'Image is required'}
              />
             <Box sx={{ color: "black"}}>Maximum size 1mb</Box>
             {errorMessage && <Box sx={{ color: "red" }}>Please select an image smaller than 1MB</Box>}
             {errorFormat && <Box sx={{ color: "red" }}>Selected file is not an image</Box>}
            </Box>
            <Box sx={{ marginTop: '20px' }}>
              <TextField
                fullWidth
                value={metaDesc}
                onChange={(e) => setMetaDesc(e.target.value)}
                id="fullWidth"
                label="Meta Description"
                variant="outlined"
                multiline
                error={!metaDesc && submitted}
                helperText={!metaDesc && submitted && 'Meta Description is required'}
              />
            </Box>
            <Box sx={{ margin: '20px 0px' }}>
              <TextField
                fullWidth
                id="fullWidth"
                value={metaKey}
                onChange={(e) => setMetaKey(e.target.value)}
                label="Meta Keywords"
                variant="outlined"
                multiline
                error={!metaKey && submitted}
                helperText={!metaKey && submitted && 'Meta Keyword is required'}
              />
            </Box>
          </Grid>
        </Grid>
      </form>
    </Container>
  )
}

export default requireAuth(requirePlan(EditBlog, 'premium'))
