import React, { useEffect } from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { Backdrop } from '@material-ui/core';
import { getOptions,saveExperiencemodal } from '../../util/db'
import { useAuth } from '../../util/auth.js'
import { Dispatch, SetStateAction } from "react";
import TextField from '@mui/material/TextField';

const styles = {
  startButton: {
    px: 5
  },
  header: {
    display: {
      xs: 'none',
      sm: 'block'
    }
  },
  modalstyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '85%',
    bgcolor: 'background.paper',
    border: 'unset',
    borderRadius:'6px',
    p: 4,
    overflow: { xs: 'scroll', lg: 'unset' },
    height: { xs: '100%', lg: "auto" },
    display: 'block',
    '&:focus-visible': {
      outline: 'none'
    },
  },
  defaultmodalstyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width:  { xs: '98%', lg: '30%', sm:"60%"  },
    bgcolor: 'background.paper',
    border: 'transparent',
    boxShadow: 24,
    borderRadius: '6px',
    p: 4,
    display: 'block',
},
  // drop: {

  //   borderRadius: "6px",
  //   boxShadow: "0px 0px 0px 3px rgb(235 103 105 / 35%)",
  //   border: "2px solid #eb6769",
  //   // '&:focus-visible': {
  //   //   outline: 'none'
  //   // },
  //   '&:hover': {
  //     border: "none"
  //   },
  // },
  headings: {
    fontSize: "1.625rem",
    lineHeight: "2.5rem"
  },
  subtitle: {
    fontSize: 12,
    color: "#9095a0"
  },
  card_text: {
    fontSize: 14
  },
  card: {
    boxShadow: "0px 0px 5px rgba(0,0,0,15%)",
    padding: 10,
    height: "100%",
    border: '1px solid transparent',
    cursor:"pointer"
  },
  cmn_btn: {
    color: "#ef9834",
    borderColor: "#ef9834",
    fontSize:12,
    paddingLeft:20
  },
  btn_outer: {
    textAlign: "right" as const,
    marginTop: "2rem"
  },
  iconPostions: {
    position: "absolute"
  },
  card_desc: {
    fontSize: 12
  },
  active: {
    border: '1px solid #1278bc'
  },
  title: {
    fontSize: 14
},

}

export type experienceStartProps = {
  handleExperienceStart: () => void,
  setExperienceStartId: Dispatch<SetStateAction<string>>,
  setExperienceStartName: Dispatch<SetStateAction<string>>,
  handleFeedbackModal : () => void
  
}

function ExperienceStart(props: experienceStartProps) {
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const [disable, setDisable] = React.useState(true)
  const [newvalue,setnewValue] = React.useState('')
  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false)
    }
  }
  const [data, setData] = React.useState([])
  const [ID, setId] = React.useState("")
  const [NAME, setName] = React.useState("")
  const [openDefalt, setOpenDefault] = React.useState(false)
  const handleDefalutClose = () => setOpenDefault(false);
  const user = useAuth()

  useEffect(() => {
    handleOpen()
    getdata()

  }, [])
  // fetching data from the recomended_options collection
  const getdata = async () => {
    const data = await getOptions()
    setData(data)

  }

 

  const handle_recomended_options = (id, name) => {
    if (id !== "" && name !== "") {
      setDisable(false)
    }
    if (name === "Anders") {
      setOpenDefault(true)
  }
    props.setExperienceStartId(id)
    props.setExperienceStartName(name)
    // const check_data = data?.map((options) => ({
    //   ...options,style : false
    // }))
    // setData(check_data)
    const index = data.findIndex(el => el.id === id)
    if (index !== -1) {
      // make all styles false.
      for (let i = 0; i < data.length; i++) {
        if (data[i].style) {
          data[i].style = false;
        }
      }
      data[index]["style"] = true;
    }
  }

    
  const handleSaveCustomOptions = async () => {
    // console.log("id::",user.user.id)
    // console.log("newvalue",newvalue)
    const data = {
        user_id:user?.user?.id,
        modal:"1st",
        name:newvalue
    }
 let response= await saveExperiencemodal(data)
//  console.log("response",response)
 setOpenDefault(false);
 props.handleExperienceStart()

}

  return (

    <div>

      <Button onClick={handleOpen}>Open modal</Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        keepMounted
      >
        <Box sx={styles.modalstyle}>

          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={12} md={7}>

              <Typography style={styles.headings} align='center' id="modal-modal-title" variant="h2" component="h2">
                Hoi, <br />
                Leuk je te ontmoeten,  laten we elkaar beter leren kennen! <br />
                Ten eerste, wat brengt jou hier?

              </Typography>
              <Typography style={styles.subtitle} id="modal-modal-description" align="center" sx={{ mt: 3, mb: 3 }}>
              *Wij gebruiken deze gegevens om je de beste gebruikerservaring te bieden
              </Typography>

              <Grid container spacing={2} rowSpacing={3} justifyContent="center">
                {
                  // using map function to reflect data on modal
                  data?.map((options, index) => {
                    return <Grid item xs={12} sm={6} md={5} key={index} onClick={() => { handle_recomended_options(options?.id, options?.name) }}>
                      <Card style={{ ...styles.card, ...(options?.style == true && styles.active) }} variant="outlined">
                        {options?.icon === "work_icon.png" ? <img src="/img/work_icon.png" /> : null}
                        {options?.icon === "personal_icon.png" ? <img src="/img/personal_icon.png" /> : null}
                        {options?.icon === "other_icon.png" ? <img src="/img/other_icon.png" /> : null}
                        {options?.icon === "onderwijis_icon.png" ? <img src="/img/onderwijis_icon.png" /> : null}

                        <Typography style={styles.card_text} id="modal-modal-description" align="center" sx={{  mb:3 }}>
                          {options?.name}
                        </Typography>
                      </Card>
                    </Grid>
                  })
                }
              </Grid>

            </Grid >

             {/* <Grid item xs={12}>
             <Typography style={styles.title} align='center' sx={{ mb: 3, mt: 3 }}  >
                            Staat de tekstsoort die je zoekt er niet tussen? Laat ons deze dan toevoegen
                            </Typography>
                            <div style={styles.btn_outer}>
                                <Button variant="outlined" style={styles.cmn_btn} onClick = {props.handleFeedbackModal}>Ik wil een tekstsoort laten toevoegen</Button>
                            </div>
                            </Grid> */}
          </Grid>

          <div style={styles.btn_outer}>
          <Typography style={styles.card_text} id="modal-modal-description" align="center" >
                      1/4
                        </Typography>
            <Button variant="outlined" style={styles.cmn_btn} onClick={props.handleExperienceStart} disabled={disable}>Laten we beginnen! <ArrowRightIcon /></Button>
          </div>
        </Box>
      </Modal>

      <Modal
                open={openDefalt}
                onClose={handleDefalutClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styles.defaultmodalstyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                    Noteer hier je eigen keuze
                    </Typography>
                    <TextField sx={{ mt: 3, mb: 3 }} id="outlined-basic" label="Bericht" variant="outlined" multiline
                        maxRows={4} fullWidth value={newvalue} onChange={(e)=>setnewValue(e.target.value)} />
                    <Button variant="outlined" onClick={handleSaveCustomOptions} >Verstuur</Button>
                </Box>
            </Modal>


    </div>

  )
}

export default ExperienceStart;
