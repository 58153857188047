import React, { ReactNode } from 'react'
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'

interface WriterSelectionProps<T> {
  values: T[]
  onSelection: (value: T) => void
  displayValue?: (value: T) => ReactNode
}

const styles = {
  radioButton: {
    m: 1,
    p: 1,
    borderRadius: 5,
    border: `1px solid`,
    borderColor: 'grey.400'
  }
}

function WriterSelection<T>({
  values,
  onSelection,
  displayValue = (value) => String(value)
}: WriterSelectionProps<T>) {
  if (!values) {
    return null
  }

  return (
    <FormControl style={{ width: '100%' }}>
      <RadioGroup onChange={(e) => onSelection(values[Number(e.target.value)])}>
        {values.map((value, i) => (
          <FormControlLabel
            key={i}
            label={displayValue(value)}
            value={String(i)}
            sx={styles.radioButton}
            control={<Radio />}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

export default WriterSelection
