import React, { ReactNode } from 'react'
import IconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress'

interface InnerProps {
  isLoading: boolean
}

export function canLoad<T extends { children?: ReactNode; disabled?: boolean }>(
  Component: React.ComponentType<T>
) {
  const ComponentCanLoad = ({ isLoading, ...props }: T & InnerProps) => {
    return (
      <Component
        {...(props as unknown as T)}
        disabled={props.disabled || isLoading}
      >
        <>
          {isLoading && (
            <CircularProgress
              size={24}
              sx={{ position: 'absolute', inset: 0, margin: 'auto' }}
            />
          )}
          {props.children}
        </>
      </Component>
    )
  }
  return ComponentCanLoad
}

export const IconButtonWithLoading = canLoad(IconButton)
