import React, { useEffect } from 'react'

import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { get_about_options } from '../../util/db'
import { Dispatch, SetStateAction } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TextField from '@mui/material/TextField';
import { useAuth } from '../../util/auth';
import {saveExperiencemodal} from '../../util/db'


const styles = {
    startButton: {
        px: 5
    },
    header: {
        display: {
            xs: 'none',
            sm: 'block'
        }
    },
    modalstyle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        bgcolor: 'background.paper',
        border: 'unset',
        boxShadow: 24,
        borderRadius: '6px',
        p: 4,
        display: 'block',
        '&:focus-visible': {
            outline: 'none'
        },
    },
    defaultmodalstyle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width:  { xs: '98%', lg: '30%', sm:"60%"  },
        bgcolor: 'background.paper',
        border: 'unset',
        boxShadow: 24,
        borderRadius: '6px',
        p: 4,
        display: 'block',
    },
    drop: {

        borderRadius: "6px",
        boxShadow: "0px 0px 0px 3px rgb(235 103 105 / 35%)",
        border: "2px solid #eb6769",
        '&:hover': {
            border: "none"
        },
    },
    headings: {
        fontSize: "1.625rem",
        lineHeight: "2.5rem"
    },
    subtitle: {
        fontSize: 12,
        color: "#9095a0"
    },
    card_text: {
        fontSize: 14
    },
    card: {
        boxShadow: "0px 0px 5px rgba(0,0,0,15%)",
        padding: 10,
        border: "unset",
        height: "100%"
    },
    cmn_btn: {
        color: "#ef9834",
        borderColor: "#ef9834",
        fontSize: 12
    },
    btn_outer: {
        textAlign: 'right' as const,
        marginTop: "2rem" as const
    },
    iconPostions: {
        position: "absolute"
    },
    card_desc: {
        fontSize: 12
    },
    icons: {
        margin: '-6px'
    },
    title: {
        fontSize: 14
    },

}

type experienceProps = {
    handleExperience: () => void
    handleFeedbackModalTwo: () => void
    setExperienceId: Dispatch<SetStateAction<string>>,
    setExperienceName: Dispatch<SetStateAction<string>>,
    handleExperienceBack: () => void
}

function Experience(props: experienceProps) {
    const auth =useAuth()
    const [open, setOpen] = React.useState(true);
    const [openDefalt, setOpenDefault] = React.useState(false)
    const handleDefalutClose = () => setOpenDefault(false);
    const handleOpen = () => setOpen(true);
    const [disable, setDisable] = React.useState(true)
    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false)
        }
    }
    const [age, setAge] = React.useState('');
    const [data, setData] = React.useState([])
    const [selected_value, setSelected_value] = React.useState('')
    const [newvalue,setnewValue]=React.useState('')

    useEffect(() => {
        getAboutOptions()
    }, [])
    // getting the data from about_option collection from firebse
    const getAboutOptions = async () => {
        const data = await get_about_options()
        // console.log(data, "daaaataaa")
        const index = data.indexOf()
        setData(data)
    }

    const handleSetValues = (id, name) => {
        if (id !== "" && name !== "") {
            setDisable(false)
        }
        if (name === "Anders") {
            setOpenDefault(true)
        }
        props.setExperienceId(id)
        props.setExperienceName(name)

        const index = data.findIndex(el => el.id === id)
        if (index !== -1) {
            data[index]["style"] = true;
        }
    }

    const handleChange = (event) => {
        setSelected_value(event.target.value)
    }
   
    const handleSaveCustomOptions = async () => {
        // console.log("id::",auth.user.id)
        // console.log("newvalue",newvalue)
        const data = {
            user_id:auth?.user?.id,
            modal:"2nd",
            name:newvalue
        }
     let response= await saveExperiencemodal(data)
    //  console.log("response",response)
     setOpenDefault(false);
     props.handleExperience()

    }

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                keepMounted
            >
                <Box sx={styles.modalstyle}>
                    <Button onClick={props.handleExperienceBack}><ArrowBackIcon /></Button>
                    <Grid container alignItems="center" justifyContent="center">
                        <Grid item xs={12} md={7}>
                            <Typography style={styles.headings} sx={{ mb: 3 }} align='center' id="modal-modal-title" variant="h2" component="h2">
                                Hoe ben je bij ons terechtgekomen?
                            </Typography>
                            <Grid container spacing={2} justifyContent={"center"} >
                                <Grid item xs={12} md={7}>
                                    <FormControl sx={{ m: 1, minWidth: '100%', minHeight: 300 }}>
                                        <Select
                                            style={styles.drop}
                                            IconComponent={ExpandMoreIcon}
                                            value={selected_value}
                                            onChange={(event) => { handleChange(event) }}
                                            displayEmpty

                                        >
                                            <MenuItem>Maak een keuze</MenuItem>
                                            {Array.isArray(data) && data.length > 0 && data.map((options, index) => {
                                                return (
                                                    <MenuItem key={index} value={options?.name} onClick={() => handleSetValues(options?.id, options?.name)

                                                    }>{options?.name}</MenuItem>
                                                )

                                            })}

                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <div style={styles.btn_outer}>
                        <Typography style={styles.card_text} id="modal-modal-description" align="center" >
                            2/4
                        </Typography>
                        <Button variant="outlined" style={styles.cmn_btn} onClick={props.handleExperience} disabled={disable}>Volgende<ArrowRightIcon style={styles.icons} /></Button>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={openDefalt}
                onClose={handleDefalutClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styles.defaultmodalstyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                    Noteer hier je eigen keuze.
                    </Typography>
                    <TextField sx={{ mt: 3, mb: 3 }} id="outlined-basic" label="Bericht" variant="outlined" multiline
                        maxRows={4} fullWidth value={newvalue} onChange={(e)=>setnewValue(e.target.value)}/>
                    <Button variant="outlined" onClick={handleSaveCustomOptions}>Verstuur</Button>
                </Box>
            </Modal>
        </>


    )
}

export default Experience;
