import React from 'react'
import { useAuth } from '../util/auth'
import { Alert, Link, Theme } from '@mui/material'
import { RoutePath } from '../util/routes'
import { SxProps } from '@mui/system'
import { merge } from 'lodash-es'

const getStyles = (credits: number) => ({
  root: {
    color: credits ? 'primary.light' : 'warning.light',
    backgroundColor: 'background.default',
    '& > .MuiAlert-message': {
      p: 0
    }
  }
})

interface CreditsInfoProps {
  sx?: Record<keyof ReturnType<typeof getStyles>, SxProps<Theme>>
}

function CreditsInfo({ sx }: CreditsInfoProps) {
  const { user } = useAuth()
  const severity = user?.credits ? 'info' : 'warning'
  const styles = getStyles(user?.credits)
  const mergedStyles = merge(styles, sx)

  if (!user) {
    return null
  }

  return (
    <Alert
      severity={severity}
      icon={false}
      variant="outlined"
      sx={mergedStyles.root}
    >
      Nog {user?.paymentType === "subscription" ? "Unlimited" : user.credits} woorden over
      {!user.credits && (
        <div>
          Alle woorden zijn verbruikt. Upgrade je abonnement om meer woorden te krijgen:{' '}
          <Link href={RoutePath.pricing}>Abonnement upgraden</Link>
        </div>
      )}
    </Alert>
  )
}

export default CreditsInfo
