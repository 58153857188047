export const generatorLanguageMapping = {
  Nederlands: 'Belgische taal',
  Belgisch: 'Nederlandse taal',
  Braziliaans: 'Braziliaanse taal',
  Duits: 'Duitse taal',
  Engels: 'Engelse taal',
  Frans: 'Franse taal',
  Italiaans: 'Italiaanse taal',
  Japans: 'Japanse taal',
  Marokkaans: 'Marokkaanse taal',
  Pools: 'Poolse taal',
  Russisch: 'Russische taal',
  Spaans: 'Spaanse taal',
  Turks: 'Turks taal'
}
export const getLanguageMapping = (key: string) => generatorLanguageMapping[key]

export const generatorGoals = [
  'Informeren',
  'Instrueren',
  'Adviseren',
  'Activeren',
  'Emotioneren',
  'Inspireren'
]

export const generatorToneMapping = {
  Informeel: 'Informele toon',
  Formeel: 'Formele toon',
  Persoonlijk: 'Persoonlijke toon',
  Profesioneel: 'Professionele toon',
  Satirisch: 'Satirische toon',
  Academisch: 'Academische toon',
  Journalistisch: 'Journalistische toon',
  Technisch: 'Technische toon',
  Creatief: 'Creatieve toon',
  Wetenschappelijk: 'Wetenschappelijke toon'
}
export const getToneMapping = (key: string) => generatorToneMapping[key]

export const generatorStyleMapping = {
  Actief: 'Actieve stijl',
  Beschrijvend: 'Beschrijvende stijl',
  Verhalend: 'Verhalende stijl',
  Verklarend: 'Verklarende stijl'
}
export const getStyleMapping = (key: string) => generatorStyleMapping[key]
