import React from 'react'
import Meta from '../../components/Meta.js'
import { requireAuth } from '../../util/auth.js'
import AiWriter from '../../features/ai-writer/ai-writer'
import requirePlan from '../../hocs/requirePlan'
import AppLayout from '../../components/AppLayout' 

function WriterPage() {
  const title = 'Digilo Blog'
  return (
    <AppLayout title={title} isFixedHeight={true}>
      <Meta title={title} />

      <AiWriter />
    </AppLayout>
  )
}

// export default requireAuth(requirePlan(WriterPage, 'premium'))
export default requireAuth(WriterPage)
