import React, { useEffect, useState } from 'react'
import { getOptions } from '../util/db'
import AppLayout from '../components/AppLayout' 
import MainPanel from '../components/MainPanel'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button } from '@mui/material';
import { Typography } from '@material-ui/core';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { handledeleteinfo } from "../util/db"
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { savetorecommendedopt } from "../util/db"
import { updateRecommendedoptions } from "../util/db"
import requirePlan from '../hocs/requirePlan';
import { requireAuth } from '../util/auth';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    border: "transparent",
    borderRadius: "8px"
};
const AdminRecommendedOptions = () => {
    return (
        <AppLayout title='Adminuser'>
            <MainPanel

                main={<AdminRecommendedOptionsMain />}
            />
        </AppLayout>
    )
}
const AdminRecommendedOptionsMain = () => {
    const [open, setOpen] = React.useState(false);
    const [editopen, setEditOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleCloseedit = () => setEditOpen(false);
    const [data, setData] = React.useState([])
    const [age, setAge] = React.useState('');
    const [inputicon, setinputicon] = React.useState('')
    const [newdata, setnewData] = React.useState('')
    const [icon, seticon] = React.useState('')
    const [editedicon, seteditedicon] = React.useState('')
    const [initialId, setInitialID] = React.useState("")
    const [initialName, setInitialName] = React.useState("")
    const [initialicon, setInitialicon] = React.useState("")




    useEffect(() => {
        getdata();
    }, [])


    const getdata = async () => {
        const getrecommendedoptions = await getOptions()

        setData(getrecommendedoptions)
    }


    const handledelete = async (id) => {

        const deletedinfo = await handledeleteinfo(id)

        getdata();
    }

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const handleEditChange = (event) => {
        setInitialicon(event.target.value)
    }
    const handlesubmit = async () => {
        const data = {
            name: newdata,
            icon: age
        }
        const details = await savetorecommendedopt(data)
        setOpen(false);
        setnewData("")
        setAge("")
        getdata()
    }

    const handleedit = async () => {


        const data = {
            name: initialName,
            icon: initialicon,

        }
        const updatedname = await updateRecommendedoptions(initialId, data)

        setEditOpen(false);
        getdata()
    }
    const handleupdate = (id, name, icon) => {
        setInitialID(id)
        setInitialName(name)
        setInitialicon(icon)
        setEditOpen(true);

    }
    return (
        <>
            <Stack
                justifyContent="space-between"
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
                alignItems="center"
            >
                <Typography variant="h6" mt={2}>
                    Recommended Options
                </Typography>
                <Button variant="contained" onClick={handleOpen}>Add</Button>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Add Data
                        </Typography>

                        <form action="">
                            <TextField id="outlined-basic" sx={{ my: 3 }} label="Name" variant="outlined" type='text' fullWidth onChange={(e) => setnewData(e.target.value)} />
                            <FormControl fullWidth sx={{ mb: 3 }}>
                                <InputLabel id="demo-simple-select-label" sx={{ background: "#fff" }} >Icon Name</InputLabel>

                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={age}
                                    onChange={(event) => { handleChange(event) }}

                                >
                                    {
                                        data.map((Element, index) => {
                                            return (

                                                <MenuItem key={index} value={Element.icon} >{Element.icon}</MenuItem>

                                            )
                                        })
                                    }


                                </Select>
                            </FormControl>
                            <Button variant="contained" onClick={handlesubmit} >Submit</Button>
                        </form>
                    </Box>
                </Modal>
            </Stack>
            <TableContainer component={Paper} sx={{ mt: 3 }}>
                <Table sx={{ minWidth: 650 }} aria-label="table">
                    <TableHead>

                        <TableRow>
                            <TableCell sx={{ fontWeight: 700 }} >Sr.no</TableCell>
                            <TableCell sx={{ fontWeight: 700 }} align="center">Name</TableCell>
                            <TableCell sx={{ fontWeight: 700 }} align="center">Icon Name</TableCell>
                            <TableCell sx={{ fontWeight: 700 }} align="center">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data.map((element, index) => {
                                return (
                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell>
                                            {index + 1}
                                        </TableCell>
                                        <TableCell align="center">
                                            {element.name}
                                        </TableCell>
                                        <TableCell align="center">
                                            {element.icon}
                                        </TableCell>

                                        <TableCell align="center">
                                            <Button variant="contained" sx={{ mr: 2 }} onClick={() => handleupdate(element.id, element.name, element.icon)}>Edit</Button>
                                            <Button variant="contained" onClick={() => handledelete(element.id)} >Delete</Button>
                                        </TableCell>

                                    </TableRow>
                                )

                            })
                        }


                    </TableBody>
                </Table>
            </TableContainer>
            <Modal
                open={editopen}
                onClose={handleCloseedit}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Edit
                    </Typography>

                    <form action="">

                        <TextField defaultValue={initialName} id="outlined-basic" sx={{ my: 3 }} label="Name" variant="outlined" type='text' fullWidth onChange={(e) => setInitialName(e.target.value)} />
                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <InputLabel id="demo-simple-select-label" sx={{ background: "#fff" }} >Icon Name</InputLabel>

                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                defaultValue={initialicon}
                                onChange={(event) => { handleEditChange(event) }}

                            >
                                {
                                    data.map((Element, index) => {
                                        return (

                                            <MenuItem key={index} value={Element.icon} >{Element.icon}</MenuItem>

                                        )
                                    })
                                }

                            </Select>
                        </FormControl>
                        <Button variant="contained" onClick={handleedit}>Submit</Button>
                    </form>
                </Box>
            </Modal>
        </>
    )
}

export default requireAuth(requirePlan(AdminRecommendedOptions, 'premium'))