export type FromArray<T> = T extends (infer E)[] ? E : T
export type Writeable<T> = { -readonly [P in keyof T]: T[P] }

export function removeReadonly<T extends Readonly<any>>(
  readOnlyObj: T
): Writeable<T> {
  return readOnlyObj
}

// https://stackoverflow.com/a/68252190
export type Mutable<T> = T extends object
  ? {
      -readonly [K in keyof T]: Mutable<T[K]>
    }
  : T

export type DeepReadonly<T> = {
  readonly [K in keyof T]: DeepReadonly<T[K]>
}

// https://stackoverflow.com/a/70067918
export const safeType =
  <U>() =>
  <T extends DeepReadonly<U>>(input: T): Mutable<T> =>
    input as unknown as Mutable<T>

export function satisfy<TSatisfied>(): <T extends TSatisfied>(value: T) => T {
  return (value) => value
}

export function getKeys<T>(val: T): Array<keyof T> {
  return Object.keys(val) as Array<keyof T>
}

export function tuple<Args extends any[]>(items: [...Args]): Args {
  return items;
}

