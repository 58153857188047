import React from 'react'
import FaqSection from '../../components/FaqSection.js'
import { requireAuth } from '../../util/auth.js'
import AppLayout from '../../components/AppLayout'
import SectionHeader from '../../components/SectionHeader.js'
import Section from '../../components/Section.js'
import Container from '@mui/material/Container'
import { NewUserVideo } from '../../components/NewUserPopup'

function HelpPage(props) {
  return (
    <AppLayout title="Help">
      <InstructionVideoSection />
      <FaqSection
        disableDivider={true}
        bgColor="default"
        bgImage=""
        bgImageOpacity={1}
        title="Veel Gestelde Vragen"
        subtitle=""
      />
    </AppLayout>
  )
}

function InstructionVideoSection() {
  return (
    <Section>
      <Container maxWidth="md">
        <SectionHeader title="Instructie video" size={4} textAlign="center" />
        <NewUserVideo />
      </Container>
    </Section>
  )
}

export default requireAuth(HelpPage)
