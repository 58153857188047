import React from 'react'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'

const styles = {
  container: {
    width: '100%',
    boxSizing: 'border-box'
  },
  input: {
    width: '100%'
  }
}

interface WriterInputProps {
  value: string
  placeholder: string
  onChange: (value: string) => void
}

function WriterInput({ value, placeholder, onChange }: WriterInputProps) {
  return (
    <Box sx={styles.container}>
      <TextField
        size="small"
        placeholder={placeholder}
        defaultValue={value}
        onChange={(event) => onChange(event.target.value)}
        sx={styles.input}
        variant="outlined"
        multiline
        minRows={1}
        maxRows={3}
      />
    </Box>
  )
}

export default WriterInput
