import React from 'react'
import TrailContent from './TrailContent'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon'

import ListItemText from '@mui/material/ListItemText';
const styles = {
    heading: {
        fontSize: {
            xs: "22px",
            md: "28px",
            lg: "40px"
        },
        textAlign: "center",
        color: "#171A1FFF"
    },
    headingTop: {
        fontSize: {
            xs: "22px",
            md: "28px",
            lg: "40px"
        },
        textAlign: "center",
        color: "#171A1FFF",
        borderBottom:"1px solid #ddd",
        paddingTop:{
       
                xs: "1.5em",
               
         
        },
        paddingBottom:"1.5em"
    },
    whyChooseHead: {
        fontSize: {
            xs: "22px",
            md: "28px",
            lg: "40px"
        },
        textAlign: "center",
        color: "#171A1FFF",
       
    },
    text: {
        fontSize: {
            xs: "14px",
            md: "22px",
            lg: "32px"
        },
        textAlign: "center",
        marginBottom: {
            xs: "30px",
            md: "40px",
            lg: "60px"
        },
        marginTop: "15px"
    },
    smallText: {
        fontSize: {
            xs: "14px",
            md: "18px",
            lg: "20px"
        },
        fontWeight: "600",
        color: "#323842FF"
    },
    whyChooseWrapper: {
        padding: {
            xs: "3em 1em",
            md: "2em",
            lg: "3em 2em"
        }
    },

    card: {
        background: ' #FFFFFFFF', /* white */
        borderRadius: "6px",/* border-m */
        borderWidth: " 5px",
        borderColor: "#BCC1CAFF", /* neutral-400 */
        borderStyle: "solid",
        padding: "30px 20px",
        height: "100%",
        width: "100%"
    },
    btn: {
        color: "#fff",
        backgroundColor: "#45A7E8FF",
        marginBottom: {
            xs: "20px",
            sm: "25px",
            md: "35px",
        },
        minWidth: "155px"
    },
    listItem: {
        paddingTop: 0.10,
        paddingBottom: 0.10,
        alignItems: "flex-start",
        columnGap: "15px"
    },
    lists: {
        span: {
            fontSize: "16px"
        }
    },
    perkIcon: {
        minWidth: 40,
        color: 'success.main',
        paddingTop: "10px"
    },
    listsHeading: {
        span: {
            fontSize: {
                xs: "24px",
                md: "28px",
                lg: "32px"
            },
            fontWeight: "bold"
        }

    },
    listSubHeading: {

        span: {
            fontSize: "18px",
            fontWeight: " 600",
            color: " #565E6CFF"/* neutral-600 */
        }
    },
    cards:{
        padding:{
            md:"15px",
            lg:"30px",
            xs:"15px",
        },
        boxShadow:"0px 0px 5px rgba(0,0,0,20%)",
        minHeight:"93%",
        margin: {
            lg:"60px 0px",
            md:"60px 15px",
            xs:"40px 15px"
        }
    },
    seperate:{
        height:"100%",
        width:"1px",
        background:"#ddd",
        margin:"auto"
    }

}

function DigiloAdvantage(props) {

    return (

        <>
           <TrailContent/>
       
              <Typography variant="h2" component="h2" sx={styles.headingTop}>
                 Wat zijn de voordelen van Digilo?
                </Typography>
            <Box sx={styles.warrom}>
                <Grid container justifyContent="center" spacing={0}>
                    <Grid item lg={5} md={6} sm={12}>
                        <Box sx={styles.cards} >
                            <List aria-label="perks" sx={{ padding: "0px" }}>

                                <ListItem
                                    sx={styles.listItem}
                                    disableGutters
                                >
                                    <ListItemIcon sx={styles.perkIcon}>
                                        {/* <CheckIcon /> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" width='40' height='40' fill="#54E087FF" id="Flat" viewBox="0 0 256 256">
                                            <path d="M243.33,90.91,114.92,219.31a16,16,0,0,1-22.63,0l-71.62-72a16,16,0,0,1,0-22.61l24-24a16,16,0,0,1,22.57-.06l36.64,35.27.11.11h0l92.73-91.37a16,16,0,0,1,22.58,0l24,23.56A16,16,0,0,1,243.33,90.91Z" />
                                        </svg>
                                    </ListItemIcon>
                                    <Box>
                                        <ListItemText sx={styles.listsHeading}>1.Bespaar op kosten</ListItemText>
                                        <ListItemText sx={styles.listSubHeading}>***Met Digilo Verminder Je Uitgaven!***  </ListItemText>
                                        <ListItemText sx={styles.lists}>Dankzij onze geavanceerde technologie kun je besparen op content- en schrijfkosten.</ListItemText>
                                    </Box>
                                </ListItem>

                                <ListItem
                                    sx={styles.listItem}
                                    disableGutters
                                >
                                    <ListItemIcon sx={styles.perkIcon}>
                                        {/* <CheckIcon /> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" width='40' height='40' fill="#54E087FF" id="Flat" viewBox="0 0 256 256">
                                            <path d="M243.33,90.91,114.92,219.31a16,16,0,0,1-22.63,0l-71.62-72a16,16,0,0,1,0-22.61l24-24a16,16,0,0,1,22.57-.06l36.64,35.27.11.11h0l92.73-91.37a16,16,0,0,1,22.58,0l24,23.56A16,16,0,0,1,243.33,90.91Z" />
                                        </svg>
                                    </ListItemIcon>
                                    <Box>
                                        <ListItemText sx={styles.listsHeading}>2. Ultrasnelle output</ListItemText>
                                        <ListItemText sx={styles.listSubHeading}>***Digilo Levert Bliksemsnel!***  </ListItemText>
                                        <ListItemText sx={styles.lists}>Zeg vaarwel tegen lange wachttijden en verwelkom snelle, kwalitatieve content.</ListItemText>
                                    </Box>
                                </ListItem>
                                <ListItem
                                    sx={styles.listItem}
                                    disableGutters
                                >
                                    <ListItemIcon sx={styles.perkIcon}>
                                        {/* <CheckIcon /> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" width='40' height='40' fill="#54E087FF" id="Flat" viewBox="0 0 256 256">
                                            <path d="M243.33,90.91,114.92,219.31a16,16,0,0,1-22.63,0l-71.62-72a16,16,0,0,1,0-22.61l24-24a16,16,0,0,1,22.57-.06l36.64,35.27.11.11h0l92.73-91.37a16,16,0,0,1,22.58,0l24,23.56A16,16,0,0,1,243.33,90.91Z" />
                                        </svg>
                                    </ListItemIcon>
                                    <Box>
                                        <ListItemText sx={styles.listsHeading}>3. Makkelijk opschaalbaar</ListItemText>
                                        <ListItemText sx={styles.listSubHeading}>***Digilo Groeit met Je Mee!***   </ListItemText>
                                        <ListItemText sx={styles.lists}>Of je nu 10 of 1000 artikelen nodig hebt, Digilo staat voor je klaar.</ListItemText>
                                    </Box>
                                </ListItem>
                                <ListItem
                                    sx={styles.listItem}
                                    disableGutters
                                >
                                    <ListItemIcon sx={styles.perkIcon}>
                                        {/* <CheckIcon /> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" width='40' height='40' fill="#54E087FF" id="Flat" viewBox="0 0 256 256">
                                            <path d="M243.33,90.91,114.92,219.31a16,16,0,0,1-22.63,0l-71.62-72a16,16,0,0,1,0-22.61l24-24a16,16,0,0,1,22.57-.06l36.64,35.27.11.11h0l92.73-91.37a16,16,0,0,1,22.58,0l24,23.56A16,16,0,0,1,243.33,90.91Z" />
                                        </svg>
                                    </ListItemIcon>
                                    <Box>
                                        <ListItemText sx={styles.listsHeading}>4. Altijd beschikbaar</ListItemText>
                                        <ListItemText sx={styles.listSubHeading}>***Digilo: Jouw 24/7 Schrijfpartner!***  </ListItemText>
                                        <ListItemText sx={styles.lists}>Ongeacht het moment, Digilo staat altijd klaar om je te assisteren.</ListItemText>
                                    </Box>
                                </ListItem>
                                <ListItem
                                    sx={styles.listItem}
                                    disableGutters
                                >
                                    <ListItemIcon sx={styles.perkIcon}>
                                        {/* <CheckIcon /> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" width='40' height='40' fill="#54E087FF" id="Flat" viewBox="0 0 256 256">
                                            <path d="M243.33,90.91,114.92,219.31a16,16,0,0,1-22.63,0l-71.62-72a16,16,0,0,1,0-22.61l24-24a16,16,0,0,1,22.57-.06l36.64,35.27.11.11h0l92.73-91.37a16,16,0,0,1,22.58,0l24,23.56A16,16,0,0,1,243.33,90.91Z" />
                                        </svg>
                                    </ListItemIcon>
                                    <Box>
                                        <ListItemText sx={styles.listsHeading}>5. Minder fouten</ListItemText>
                                        <ListItemText sx={styles.listSubHeading}>***Digilo Garandeert Kwaliteit!***    </ListItemText>
                                        <ListItemText sx={styles.lists}>Minimaliseer menselijke fouten en maximaliseer consistentie met onze AI.</ListItemText>
                                    </Box>
                                </ListItem>
                                <ListItem
                                    sx={styles.listItem}
                                    disableGutters
                                >
                                    <ListItemIcon sx={styles.perkIcon}>
                                        {/* <CheckIcon /> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" width='40' height='40' fill="#54E087FF" id="Flat" viewBox="0 0 256 256">
                                            <path d="M243.33,90.91,114.92,219.31a16,16,0,0,1-22.63,0l-71.62-72a16,16,0,0,1,0-22.61l24-24a16,16,0,0,1,22.57-.06l36.64,35.27.11.11h0l92.73-91.37a16,16,0,0,1,22.58,0l24,23.56A16,16,0,0,1,243.33,90.91Z" />
                                        </svg>
                                    </ListItemIcon>
                                    <Box>
                                        <ListItemText sx={styles.listsHeading}>6. Bulk content creatie</ListItemText>
                                        <ListItemText sx={styles.listSubHeading}> ***Grootschalige Content? Digilo Kan het!***   </ListItemText>
                                        <ListItemText sx={styles.lists}>Geen project is te groot, van enkele artikelen tot duizenden.</ListItemText>
                                    </Box>
                                </ListItem>
                                <ListItem
                                    sx={styles.listItem}
                                    disableGutters
                                >
                                    <ListItemIcon sx={styles.perkIcon}>
                                        {/* <CheckIcon /> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" width='40' height='40' fill="#54E087FF" id="Flat" viewBox="0 0 256 256">
                                            <path d="M243.33,90.91,114.92,219.31a16,16,0,0,1-22.63,0l-71.62-72a16,16,0,0,1,0-22.61l24-24a16,16,0,0,1,22.57-.06l36.64,35.27.11.11h0l92.73-91.37a16,16,0,0,1,22.58,0l24,23.56A16,16,0,0,1,243.33,90.91Z" />
                                        </svg>
                                    </ListItemIcon>
                                    <Box>
                                        <ListItemText sx={styles.listsHeading}>7. Zonder pauzes</ListItemText>
                                        <ListItemText sx={styles.listSubHeading}> ***Digilo Werkt Non-Stop!***   </ListItemText>
                                        <ListItemText sx={styles.lists}>Terwijl jij pauzeert, blijft Digilo aan de slag voor ononderbroken productiviteit.</ListItemText>
                                    </Box>
                                </ListItem>
                                <ListItem
                                    sx={styles.listItem}
                                    disableGutters
                                >
                                    <ListItemIcon sx={styles.perkIcon}>
                                        {/* <CheckIcon /> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" width='40' height='40' fill="#54E087FF" id="Flat" viewBox="0 0 256 256">
                                            <path d="M243.33,90.91,114.92,219.31a16,16,0,0,1-22.63,0l-71.62-72a16,16,0,0,1,0-22.61l24-24a16,16,0,0,1,22.57-.06l36.64,35.27.11.11h0l92.73-91.37a16,16,0,0,1,22.58,0l24,23.56A16,16,0,0,1,243.33,90.91Z" />
                                        </svg>
                                    </ListItemIcon>
                                    <Box>
                                        <ListItemText sx={styles.listsHeading}>8. Gelijktijdige taken</ListItemText>
                                        <ListItemText sx={styles.listSubHeading}>***Multitasken? Laat Dat maar aan Digilo Over!***    </ListItemText>
                                        <ListItemText sx={styles.lists}>Diverse projecten tegelijkertijd? Geen probleem voor Digilo.</ListItemText>
                                    </Box>
                                </ListItem>
                                <ListItem
                                    sx={styles.listItem}
                                    disableGutters
                                >
                                    <ListItemIcon sx={styles.perkIcon}>
                                        {/* <CheckIcon /> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" width='40' height='40' fill="#54E087FF" id="Flat" viewBox="0 0 256 256">
                                            <path d="M243.33,90.91,114.92,219.31a16,16,0,0,1-22.63,0l-71.62-72a16,16,0,0,1,0-22.61l24-24a16,16,0,0,1,22.57-.06l36.64,35.27.11.11h0l92.73-91.37a16,16,0,0,1,22.58,0l24,23.56A16,16,0,0,1,243.33,90.91Z" />
                                        </svg>
                                    </ListItemIcon>
                                    <Box>
                                        <ListItemText sx={styles.listsHeading}>9. Directe respons</ListItemText>
                                        <ListItemText sx={styles.listSubHeading}>***Directe Feedback met Digilo!***   </ListItemText>
                                        <ListItemText sx={styles.lists}>Snelle reacties en aanpassingen op jouw verzoeken.</ListItemText>
                                    </Box>
                                </ListItem>
                                <ListItem
                                    sx={styles.listItem}
                                    disableGutters
                                >
                                    <ListItemIcon sx={styles.perkIcon}>
                                        {/* <CheckIcon /> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" width='40' height='40' fill="#54E087FF" id="Flat" viewBox="0 0 256 256">
                                            <path d="M243.33,90.91,114.92,219.31a16,16,0,0,1-22.63,0l-71.62-72a16,16,0,0,1,0-22.61l24-24a16,16,0,0,1,22.57-.06l36.64,35.27.11.11h0l92.73-91.37a16,16,0,0,1,22.58,0l24,23.56A16,16,0,0,1,243.33,90.91Z" />
                                        </svg>
                                    </ListItemIcon>
                                    <Box>
                                        <ListItemText sx={styles.listsHeading}>10. 24/7 beschikbaar</ListItemText>
                                        <ListItemText sx={styles.listSubHeading}> ***Digilo Slaapt Nooit!***   </ListItemText>
                                        <ListItemText sx={styles.lists}>Dag of nacht, Digilo is er om je te ondersteunen in je contentbehoeften.</ListItemText>
                                    </Box>
                                </ListItem>

                            </List>
                        </Box>
                    </Grid>
                    <Grid item lg={1} sx={{display:{lg:"block",md:"none",xs:"none"}}}>
                        <Box sx={styles.seperate}></Box>
                    </Grid>
                    <Grid item lg={5} md={6} sm={12}>
                        <Box sx={styles.cards}>
                            <List aria-label="perks" sx={{ padding: "0px" }}>

                                <ListItem
                                    sx={styles.listItem}
                                    disableGutters
                                >
                                    <ListItemIcon sx={styles.perkIcon}>
                                        {/* <CheckIcon /> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" width='40' height='40' fill="#54E087FF" id="Flat" viewBox="0 0 256 256">
                                            <path d="M243.33,90.91,114.92,219.31a16,16,0,0,1-22.63,0l-71.62-72a16,16,0,0,1,0-22.61l24-24a16,16,0,0,1,22.57-.06l36.64,35.27.11.11h0l92.73-91.37a16,16,0,0,1,22.58,0l24,23.56A16,16,0,0,1,243.33,90.91Z" />
                                        </svg>
                                    </ListItemIcon>
                                    <Box>
                                        <ListItemText sx={styles.listsHeading}>11. Uniforme kwaliteit</ListItemText>
                                        <ListItemText sx={styles.listSubHeading}> ***Digilo Biedt Standaard Topkwaliteit!***   </ListItemText>
                                        <ListItemText sx={styles.lists}>Elk stuk content, elke keer weer, voldoet aan de hoogste normen.</ListItemText>
                                    </Box>
                                </ListItem>
                                <ListItem
                                    sx={styles.listItem}
                                    disableGutters
                                >
                                    <ListItemIcon sx={styles.perkIcon}>
                                        {/* <CheckIcon /> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" width='40' height='40' fill="#54E087FF" id="Flat" viewBox="0 0 256 256">
                                            <path d="M243.33,90.91,114.92,219.31a16,16,0,0,1-22.63,0l-71.62-72a16,16,0,0,1,0-22.61l24-24a16,16,0,0,1,22.57-.06l36.64,35.27.11.11h0l92.73-91.37a16,16,0,0,1,22.58,0l24,23.56A16,16,0,0,1,243.33,90.91Z" />
                                        </svg>
                                    </ListItemIcon>
                                    <Box>
                                        <ListItemText sx={styles.listsHeading}>12. AVG- Compliance</ListItemText>
                                        <ListItemText sx={styles.listSubHeading}>***Gegevensveilig met Digilo***    </ListItemText>
                                        <ListItemText sx={styles.lists}>Werk met een gerust hart. Digilo zorgt ervoor dat jouw content voldoet aan de AVG-voorschriften, en houdt jouw gegevens veilig en beschermd.</ListItemText>
                                    </Box>
                                </ListItem>
                                <ListItem
                                    sx={styles.listItem}
                                    disableGutters
                                >
                                    <ListItemIcon sx={styles.perkIcon}>
                                        {/* <CheckIcon /> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" width='40' height='40' fill="#54E087FF" id="Flat" viewBox="0 0 256 256">
                                            <path d="M243.33,90.91,114.92,219.31a16,16,0,0,1-22.63,0l-71.62-72a16,16,0,0,1,0-22.61l24-24a16,16,0,0,1,22.57-.06l36.64,35.27.11.11h0l92.73-91.37a16,16,0,0,1,22.58,0l24,23.56A16,16,0,0,1,243.33,90.91Z" />
                                        </svg>
                                    </ListItemIcon>
                                    <Box>
                                        <ListItemText sx={styles.listsHeading}>13. Multitalige teksten</ListItemText>
                                        <ListItemText sx={styles.listSubHeading}>***Wereldwijd Schrijven met Digilo!***    </ListItemText>
                                        <ListItemText sx={styles.lists}>Of het nu Nederlands, Engels of Spaans is, Digilo spreekt jouw taal.</ListItemText>
                                    </Box>
                                </ListItem>
                                <ListItem
                                    sx={styles.listItem}
                                    disableGutters
                                >
                                    <ListItemIcon sx={styles.perkIcon}>
                                        {/* <CheckIcon /> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" width='40' height='40' fill="#54E087FF" id="Flat" viewBox="0 0 256 256">
                                            <path d="M243.33,90.91,114.92,219.31a16,16,0,0,1-22.63,0l-71.62-72a16,16,0,0,1,0-22.61l24-24a16,16,0,0,1,22.57-.06l36.64,35.27.11.11h0l92.73-91.37a16,16,0,0,1,22.58,0l24,23.56A16,16,0,0,1,243.33,90.91Z" />
                                        </svg>
                                    </ListItemIcon>
                                    <Box>
                                        <ListItemText sx={styles.listsHeading}>14. Direct inzetbaar</ListItemText>
                                        <ListItemText sx={styles.listSubHeading}>***Digilo: Klaar voor Actie!***    </ListItemText>
                                        <ListItemText sx={styles.lists}>Geen training nodig. Digilo is direct klaar om je projecten te ondersteunen.</ListItemText>
                                    </Box>
                                </ListItem>
                                <ListItem
                                    sx={styles.listItem}
                                    disableGutters
                                >
                                    <ListItemIcon sx={styles.perkIcon}>
                                        {/* <CheckIcon /> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" width='40' height='40' fill="#54E087FF" id="Flat" viewBox="0 0 256 256">
                                            <path d="M243.33,90.91,114.92,219.31a16,16,0,0,1-22.63,0l-71.62-72a16,16,0,0,1,0-22.61l24-24a16,16,0,0,1,22.57-.06l36.64,35.27.11.11h0l92.73-91.37a16,16,0,0,1,22.58,0l24,23.56A16,16,0,0,1,243.33,90.91Z" />
                                        </svg>
                                    </ListItemIcon>
                                    <Box>
                                        <ListItemText sx={styles.listsHeading}>15. Adapteert snel</ListItemText>
                                        <ListItemText sx={styles.listSubHeading}>***Digilo Leert Continu!***    </ListItemText>
                                        <ListItemText sx={styles.lists}>Of het nu gaat om nieuwe onderwerpen of stijlveranderingen, Digilo past zich razendsnel aan.</ListItemText>
                                    </Box>
                                </ListItem>
                                <ListItem
                                    sx={styles.listItem}
                                    disableGutters
                                >
                                    <ListItemIcon sx={styles.perkIcon}>
                                        {/* <CheckIcon /> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" width='40' height='40' fill="#54E087FF" id="Flat" viewBox="0 0 256 256">
                                            <path d="M243.33,90.91,114.92,219.31a16,16,0,0,1-22.63,0l-71.62-72a16,16,0,0,1,0-22.61l24-24a16,16,0,0,1,22.57-.06l36.64,35.27.11.11h0l92.73-91.37a16,16,0,0,1,22.58,0l24,23.56A16,16,0,0,1,243.33,90.91Z" />
                                        </svg>
                                    </ListItemIcon>
                                    <Box>
                                        <ListItemText sx={styles.listsHeading}>16. Consistente stijl</ListItemText>
                                        <ListItemText sx={styles.listSubHeading}> ***Altijd Consistent met Digilo!***   </ListItemText>
                                        <ListItemText sx={styles.lists}>Je content zal altijd een consistente toon en stijl hebben.</ListItemText>
                                    </Box>
                                </ListItem>
                                <ListItem
                                    sx={styles.listItem}
                                    disableGutters
                                >
                                    <ListItemIcon sx={styles.perkIcon}>
                                        {/* <CheckIcon /> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" width='40' height='40' fill="#54E087FF" id="Flat" viewBox="0 0 256 256">
                                            <path d="M243.33,90.91,114.92,219.31a16,16,0,0,1-22.63,0l-71.62-72a16,16,0,0,1,0-22.61l24-24a16,16,0,0,1,22.57-.06l36.64,35.27.11.11h0l92.73-91.37a16,16,0,0,1,22.58,0l24,23.56A16,16,0,0,1,243.33,90.91Z" />
                                        </svg>
                                    </ListItemIcon>
                                    <Box>
                                        <ListItemText sx={styles.listsHeading}>17. Op maat gemaakte outputs</ListItemText>
                                        <ListItemText sx={styles.listSubHeading}> ***Digilo Personaliseert voor Jou!***   </ListItemText>
                                        <ListItemText sx={styles.lists}>Ontvang content die specifiek is afgestemd op jouw unieke behoeften.</ListItemText>
                                    </Box>
                                </ListItem>
                                <ListItem
                                    sx={styles.listItem}
                                    disableGutters
                                >
                                    <ListItemIcon sx={styles.perkIcon}>
                                        {/* <CheckIcon /> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" width='40' height='40' fill="#54E087FF" id="Flat" viewBox="0 0 256 256">
                                            <path d="M243.33,90.91,114.92,219.31a16,16,0,0,1-22.63,0l-71.62-72a16,16,0,0,1,0-22.61l24-24a16,16,0,0,1,22.57-.06l36.64,35.27.11.11h0l92.73-91.37a16,16,0,0,1,22.58,0l24,23.56A16,16,0,0,1,243.33,90.91Z" />
                                        </svg>
                                    </ListItemIcon>
                                    <Box>
                                        <ListItemText sx={styles.listsHeading}>18. Leert van feedback</ListItemText>
                                        <ListItemText sx={styles.listSubHeading}>***Digilo Evolueert met Jouw Feedback!***    </ListItemText>
                                        <ListItemText sx={styles.lists}>Feedback wordt niet alleen geaccepteerd, maar actief gebruikt om te verbeteren.</ListItemText>
                                    </Box>
                                </ListItem>
                                <ListItem
                                    sx={styles.listItem}
                                    disableGutters
                                >
                                    <ListItemIcon sx={styles.perkIcon}>
                                        {/* <CheckIcon /> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" width='40' height='40' fill="#54E087FF" id="Flat" viewBox="0 0 256 256">
                                            <path d="M243.33,90.91,114.92,219.31a16,16,0,0,1-22.63,0l-71.62-72a16,16,0,0,1,0-22.61l24-24a16,16,0,0,1,22.57-.06l36.64,35.27.11.11h0l92.73-91.37a16,16,0,0,1,22.58,0l24,23.56A16,16,0,0,1,243.33,90.91Z" />
                                        </svg>
                                    </ListItemIcon>
                                    <Box>
                                        <ListItemText sx={styles.listsHeading}>19. Geoptimaliseerde teksten</ListItemText>
                                        <ListItemText sx={styles.listSubHeading}>***Topprestaties Teksten door Digilo!***    </ListItemText>
                                        <ListItemText sx={styles.lists}>Elk stuk is geoptimaliseerd voor maximale impact en betrokkenheid.</ListItemText>
                                    </Box>
                                </ListItem>
                                <ListItem
                                    sx={styles.listItem}
                                    disableGutters
                                >
                                    <ListItemIcon sx={styles.perkIcon}>
                                        {/* <CheckIcon /> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" width='40' height='40' fill="#54E087FF" id="Flat" viewBox="0 0 256 256">
                                            <path d="M243.33,90.91,114.92,219.31a16,16,0,0,1-22.63,0l-71.62-72a16,16,0,0,1,0-22.61l24-24a16,16,0,0,1,22.57-.06l36.64,35.27.11.11h0l92.73-91.37a16,16,0,0,1,22.58,0l24,23.56A16,16,0,0,1,243.33,90.91Z" />
                                        </svg>
                                    </ListItemIcon>
                                    <Box>
                                        <ListItemText sx={styles.listsHeading}>20. Brede onderwerpdekking</ListItemText>
                                        <ListItemText sx={styles.listSubHeading}> ***Geen Onderwerp is te Moeilijk voor Digilo!***   </ListItemText>
                                        <ListItemText sx={styles.lists}>Van technologie tot kunst, Digilo heeft de expertise om over elk onderwerp te schrijven.</ListItemText>
                                    </Box>
                                </ListItem>
                            </List>
                        </Box>
                    </Grid>
                </Grid>

                </Box>
             
       
        </>
    )
}

export default DigiloAdvantage
