import { MutableRefObject, useEffect, useState } from 'react'

export function useElementSize(
  ref: MutableRefObject<HTMLElement>,
  onSizeChange?: (size: { width: number; height: number }) => void
) {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  useEffect(() => {
    setWidth(ref.current.clientWidth)
    setHeight(ref.current.clientHeight)
  })

  useEffect(() => {
    onSizeChange && onSizeChange({ width, height })
  }, [width, height])
  return { width, height }
}
