import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { useForm } from 'react-hook-form'
import { useAuth } from '../util/auth'
import { updateUser } from '../util/db'

// list from here: https://stackoverflow.com/a/44247399
// translated with chatGPT
const authErrors = {
  'auth/admin-restricted-operation':
    'Deze actie is alleen toegestaan voor beheerders.',
  'auth/argument-error': '',
  'auth/app-not-authorized':
    'Deze app, geïdentificeerd door de domeinnaam waarop deze gehost is, is niet geautoriseerd om Firebase Authenticatie te gebruiken met de opgegeven API-sleutel. Controleer uw sleutelconfiguratie in de Google API-console.',
  'auth/app-not-installed':
    'De aangevraagde mobiele applicatie die overeenkomt met de opgegeven identifier (Android-pakketnaam of iOS-bundel-ID) is niet geïnstalleerd op dit apparaat.',
  'auth/captcha-check-failed':
    'Het verstrekte reCAPTCHA-antwoordtoken is ongeldig, verlopen, al gebruikt of het domein dat ermee is verbonden, komt niet overeen met de lijst met toegestane domeinen.',
  'auth/code-expired':
    'De SMS-code is verlopen. Stuur de verificatiecode opnieuw om het opnieuw te proberen.',
  'auth/cordova-not-ready': 'Het Cordova-framework is niet gereed.',
  'auth/cors-unsupported': 'Deze browser wordt niet ondersteund.',
  'auth/credential-already-in-use':
    'Deze referenties zijn al gekoppeld aan een ander gebruikersaccount.',
  'auth/custom-token-mismatch':
    'Het aangepaste token komt overeen met een ander publiek.',
  'auth/requires-recent-login':
    'Deze actie is gevoelig en vereist recente authenticatie. Log opnieuw in voordat u deze aanvraag opnieuw probeert.',
  'auth/dynamic-link-not-activated':
    'Activeer Dynamic Links in de Firebase Console en ga akkoord met de voorwaarden.',
  'auth/email-change-needs-verification':
    'Multi-factor gebruikers moeten altijd een geverifieerd e-mailadres hebben.',
  'auth/email-already-in-use':
    'Het e-mailadres wordt al gebruikt door een ander account.',
  'auth/expired-action-code': 'De actiecode is verlopen.',
  'auth/cancelled-popup-request':
    'Deze actie is geannuleerd vanwege een andere conflicterende popup die is geopend.',
  'auth/internal-error': 'Er is een interne fout opgetreden.',
  'auth/invalid-app-credential':
    'Het telefoonverificatieverzoek bevat een ongeldige applicatie-verifier. Het reCAPTCHA-tokenantwoord is ongeldig of verlopen.',
  'auth/invalid-app-id':
    'De mobiele app-identifier is niet geregistreerd voor het huidige project.',
  'auth/invalid-user-token':
    'De referenties van deze gebruiker zijn niet geldig voor dit project. Dit kan gebeuren als het token van de gebruiker is gemanipuleerd, of als de gebruiker niet voor het project is waarmee deze API-sleutel is geassocieerd.',
  'auth/invalid-auth-event': 'Er is een interne fout opgetreden.',
  'auth/invalid-verification-code':
    'De SMS-verificatiecode die is gebruikt om de telefoonverificatie-referenties te maken, is ongeldig. Stuur de verificatiecode opnieuw en gebruik de verificatiecode die door de gebruiker is verstrekt.',
  'auth/invalid-continue-uri':
    'De continue URL die is opgegeven in de aanvraag is ongeldig.',
  'auth/invalid-cordova-configuration':
    'De volgende Cordova-plug-ins moeten zijn geïnstalleerd om OAuth-aanmelding in te schakelen: cordova-plugin-buildinfo, cordova-universal-links-plugin, cordova-plugin-browsertab, cordova-plugin-inappbrowser en cordova-plugin-customurlscheme.',
  'auth/invalid-custom-token':
    'Het formaat van het aangepaste token is onjuist. Controleer de documentatie.',
  'auth/invalid-dynamic-link-domain':
    'Het opgegeven dynamische link-domein is niet geconfigureerd of geautoriseerd voor het huidige project.',
  'auth/invalid-email': 'Het e-mailadres is slecht geformatteerd.',
  'auth/invalid-api-key':
    'Uw API-sleutel is ongeldig. Controleer of u deze correct heeft gekopieerd.',
  'auth/invalid-cert-hash':
    'De SHA-1-certificaathash die is opgegeven, is ongeldig.',
  'auth/invalid-credential':
    'De opgegeven authenticatie-gegevens zijn onjuist of verlopen.',
  'auth/invalid-message-payload':
    'De e-mailsjabloon die overeenkomt met deze actie, bevat ongeldige tekens in het bericht. Ga naar de Auth-e-mailsjablonen sectie in de Firebase Console om dit te corrigeren.',
  'auth/invalid-multi-factor-session':
    'De aanvraag bevat geen geldig bewijs van een succesvolle eerste-factoraanmelding.',
  'auth/invalid-oauth-provider':
    'EmailAuthProvider wordt niet ondersteund voor deze bewerking. Deze bewerking ondersteunt alleen OAuth-providers.',
  'auth/invalid-oauth-client-id':
    'De opgegeven OAuth-client-ID is ongeldig of komt niet overeen met de opgegeven API-sleutel.',
  'auth/unauthorized-domain':
    'Dit domein is niet gemachtigd voor OAuth-operaties voor uw Firebase-project. Bewerk de lijst met geautoriseerde domeinen vanuit de Firebase-console.',
  'auth/invalid-action-code':
    'De actiecode is ongeldig. Dit kan gebeuren als de code onjuist is, verlopen is of al is gebruikt.',
  'auth/wrong-password':
    'Het wachtwoord is onjuist of de gebruiker heeft geen wachtwoord.',
  'auth/invalid-persistence-type':
    'Het opgegeven persistentietype is ongeldig. Het kan alleen lokaal, sessie of geen zijn.',
  'auth/invalid-phone-number':
    'Het formaat van het opgegeven telefoonnummer is onjuist. Voer het telefoonnummer in in een indeling die kan worden geanalyseerd tot E.164-indeling. E.164-telefoonnummers zijn geschreven in de indeling [+][landcode][abonneenummer inclusief netnummer].',
  'auth/invalid-provider-id': 'De opgegeven provider-ID is ongeldig.',
  'auth/invalid-recipient-email':
    'De e-mail die overeenkomt met deze actie, kon niet worden verzonden omdat het opgegeven e-mailadres van de ontvanger ongeldig is.',
  'auth/invalid-sender':
    'De e-mailsjabloon die overeenkomt met deze actie, bevat een ongeldig afzender e-mailadres of -naam. Ga naar de Auth-e-mailsjablonen sectie in de Firebase Console om dit te corrigeren.',
  'auth/invalid-verification-id':
    'De verificatie-ID die is gebruikt om de telefonische verificatie-inloggegevens te maken, is ongeldig.',
  'auth/invalid-tenant-id': 'De tenant-ID van de Auth-instantie is ongeldig.',
  'auth/multi-factor-info-not-found':
    'De gebruiker heeft geen tweede factor die overeenkomt met de opgegeven identifier.',
  'auth/multi-factor-auth-required':
    'Bewijs van eigendom van een tweede factor is vereist om de aanmelding te voltooien.',
  'auth/missing-android-pkg-name':
    'Een Android-pakketnaam moet worden opgegeven als de Android-app moet worden geïnstalleerd.',
  'auth/auth-domain-config-required':
    'Zorg ervoor dat u authDomain opneemt bij het aanroepen van firebase.initializeApp (), door de instructies in de Firebase-console te volgen.',
  'auth/missing-app-credential':
    'De telefoonverificatieaanvraag mist een verificatiebewering van de toepassing. Er moet een reCAPTCHA-respons-token worden verstrekt.',
  'auth/missing-verification-code':
    'De telefoonverificatie-inloggegevens zijn gemaakt met een lege SMS-verificatiecode.',
  'auth/missing-continue-uri':
    'Een doorgaan-URL moet worden opgegeven in de aanvraag.',
  'auth/missing-iframe-start': 'Er is een interne fout opgetreden.',
  'auth/missing-ios-bundle-id':
    'Een iOS-bundel-ID moet worden opgegeven als er een App Store-ID is opgegeven.',
  'auth/missing-multi-factor-info': 'Er wordt geen tweede factor-ID verstrekt.',
  'auth/missing-multi-factor-session':
    'De aanvraag mist bewijs van een succesvolle aanmelding van de eerste factor.',
  'auth/missing-or-invalid-nonce':
    'De aanvraag bevat geen geldige nonce. Dit kan voorkomen als de SHA-256-hash van de opgegeven rauwe nonce niet overeenkomt met de gehashte nonce in het ID-token-payload.',
  'auth/missing-phone-number':
    'Om verificatiecodes te verzenden, moet u een telefoonnummer opgeven voor de ontvanger.',
  'auth/missing-verification-id':
    'De telefoonverificatie-inloggegevens zijn gemaakt met een lege verificatie-ID.',
  'auth/app-deleted': 'Deze instantie van FirebaseApp is verwijderd.',
  'auth/account-exists-with-different-credential':
    'Er bestaat al een account met hetzelfde e-mailadres maar verschillende aanmeldingsgegevens. Meld u aan met een provider die is gekoppeld aan dit e-mailadres.',
  'auth/network-request-failed':
    'Er is een netwerkfout opgetreden (zoals time-out, onderbroken verbinding of onbereikbare host).',
  'auth/no-auth-event': 'Er is een interne fout opgetreden.',
  'auth/no-such-provider':
    'Gebruiker was niet gekoppeld aan een account met de opgegeven provider.',
  'auth/null-user':
    'Er is een null-gebruikersobject opgegeven als argument voor een bewerking die een niet-null-gebruikersobject vereist.',
  'auth/operation-not-allowed':
    'De opgegeven aanmeldingsprovider is uitgeschakeld voor dit Firebase-project. Schakel het in de Firebase-console in, onder het tabblad Aanmeldingsmethode van de Auth-sectie.',
  'auth/operation-not-supported-in-this-environment':
    'Deze bewerking wordt niet ondersteund in de omgeving waarop deze toepassing wordt uitgevoerd. "location.protocol" moet http, https of chrome-extension zijn en webopslag moet zijn ingeschakeld.',
  'auth/popup-blocked':
    'Kan geen verbinding maken met de popup. Het kan zijn geblokkeerd door de browser.',
  'auth/popup-closed-by-user':
    'De popup is door de gebruiker gesloten voordat de bewerking is voltooid.',
  'auth/provider-already-linked':
    'Een gebruiker kan slechts aan één identiteit worden gekoppeld voor de opgegeven provider.',
  'auth/quota-exceeded':
    'De quotum voor dit project voor deze bewerking is overschreden.',
  'auth/redirect-cancelled-by-user':
    'De omleidingsbewerking is geannuleerd door de gebruiker voordat deze is voltooid.',
  'auth/redirect-operation-pending':
    'Er is al een omleidingsaanmeldingsbewerking in behandeling.',
  'auth/rejected-credential':
    'De aanvraag bevat onjuiste of niet-overeenkomende referenties.',
  'auth/second-factor-already-in-use':
    'De tweede factor is al ingeschreven voor dit account.',
  'auth/maximum-second-factor-count-exceeded':
    'Het maximum toegestane aantal tweede factoren voor een gebruiker is overschreden.',
  'auth/tenant-id-mismatch':
    'De opgegeven tenant-ID komt niet overeen met de tenant-ID van de Auth-instantie',
  'auth/timeout': 'De bewerking is verlopen.',
  'auth/user-token-expired':
    'De inloggegevens van de gebruiker zijn niet langer geldig. De gebruiker moet opnieuw inloggen.',
  'auth/too-many-requests':
    'We hebben alle verzoeken van dit apparaat geblokkeerd vanwege ongebruikelijke activiteit. Probeer het later opnieuw.',
  'auth/unauthorized-continue-uri':
    'Het domein van de doorgaan-URL is niet op de whitelist. Voeg het domein toe aan de whitelist in de Firebase-console.',
  'auth/unsupported-first-factor':
    'Het inschrijven van een tweede factor of aanmelden bij een multi-factor-account vereist aanmelding met een ondersteunde eerste factor.',
  'auth/unsupported-persistence-type':
    'De huidige omgeving ondersteunt het opgegeven persistentietype niet.',
  'auth/unsupported-tenant-operation':
    'Deze bewerking wordt niet ondersteund in een multi-tenant-context.',
  'auth/unverified-email': 'De bewerking vereist een geverifieerd e-mailadres.',
  'auth/user-cancelled':
    'De gebruiker heeft uw toepassing niet de gevraagde toestemmingen verleend.',
  'auth/user-not-found':
    'Er is geen gebruikersrecord dat overeenkomt met deze identifier. De gebruiker is mogelijk verwijderd.',
  'auth/user-disabled':
    'Het gebruikersaccount is uitgeschakeld door een beheerder.',
  'auth/user-mismatch':
    'De opgegeven referenties komen niet overeen met de eerder aangemelde gebruiker.',
  'auth/user-signed-out': '',
  'auth/weak-password': 'Het wachtwoord moet minimaal 6 tekens lang zijn.',
  'auth/web-storage-unsupported':
    'Deze browser wordt niet ondersteund of cookies en gegevens van derden kunnen zijn uitgeschakeld.'
}

function AuthForm(props) {
  const auth = useAuth()
  const [pending, setPending] = useState(false)
  const { handleSubmit, register, errors, getValues } = useForm()

  const submitHandlersByType = {
    signin: ({ email, pass }) =>
      auth.signin(email, pass).then((user) => {
        // Call auth complete handler
        props.onAuth(user)
      }),
    signup: ({ email, pass, name }) =>
      auth.signup(email, pass, name).then((user) => {
        // Call auth complete handler
        props.onAuth(user)
      }),
    forgotpass: ({ email }) =>
      auth.sendPasswordResetEmail(email).then(() => {
        setPending(false)
        // Show success alert message
        props.onFormAlert({
          type: 'success',
          message: 'Password reset email sent'
        })
      }),
    changepass: ({ pass }) =>
      auth.confirmPasswordReset(pass).then(() => {
        setPending(false)
        // Show success alert message
        props.onFormAlert({
          type: 'success',
          message: 'Your password has been changed'
        })
      })
  }

  // Handle form submission
  const onSubmit = ({ email, pass, name }) => {
    // Show pending indicator
    setPending(true)

    // Call submit handler for auth type
    submitHandlersByType[props.type]({
      email,
      pass,
      name
    }).catch(async (error) => {
      console.log(error.errorCode, error.message, error.code)
      setPending(false)
      // Show error alert message
      props.onFormAlert({
        type: 'error',
        message: authErrors[error.code] || error.message
      })
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        {['signup'].includes(props.type) && (
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              type="text"
              label="Naam"
              name="name"
              error={!!errors.name}
              helperText={errors.name && errors.name.message}
              fullWidth
              inputRef={register({
                required: 'Voer uw naam in'
              })}
            />
          </Grid>
        )}

        {['signup', 'signin', 'forgotpass'].includes(props.type) && (
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              type="email"
              label="E-mail"
              name="email"
              placeholder="user@example.com"
              error={!!errors.email}
              helperText={errors.email && errors.email.message}
              fullWidth
              inputRef={register({
                required: 'Voer uw e-mail in'
              })}
            />
          </Grid>
        )}

        {['signup', 'signin', 'changepass'].includes(props.type) && (
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              type="password"
              label="Wachtwoord "
              name="pass"
              error={!!errors.pass}
              helperText={errors.pass && errors.pass.message}
              fullWidth
              inputRef={register({
                required: 'Voer een wachtwoord in'
              })}
            />
          </Grid>
        )}

        {['signup', 'changepass'].includes(props.type) && (
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              type="password"
              label="Wachtwoord bevestigen"
              name="confirmPass"
              error={!!errors.confirmPass}
              helperText={errors.confirmPass && errors.confirmPass.message}
              fullWidth
              inputRef={register({
                required: 'Voer uw wachtwoord opnieuw in',
                validate: (value) => {
                  if (value === getValues().pass) {
                    return true
                  }
                  return 'Dit komt niet overeen met uw wachtwoord'
                }
              })}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            disabled={pending}
            fullWidth
          >
            {!pending && <span>{props.buttonAction}</span>}

            {pending && <CircularProgress size={28} />}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default AuthForm
