import { generatorPath, RoutePath } from '../../util/routes'
import React, { useState } from 'react';
import {
  Approval,
  Article,
  Assessment,
  AutoStories,
  Biotech,
  CollectionsBookmark,
  Compare,
  Construction,
  Contacts,
  ContentPasteSearch,
  Create,
  Email,
  Facebook,
  FavoriteBorder,
  Feed,
  Gavel,
  Google,
  Groups2,
  Handshake,
  HistoryEdu,
  ImportContacts,
  Inventory,
  LibraryBooks,
  LinkedIn,
  Lyrics,
  Newspaper,
  Note,
  PlayLesson,
  PsychologyAlt,
  RateReview,
  RequestQuote,
  School,
  Science,
  Sell,
  Storefront,
  Subject,
  SvgIconComponent,
  Textsms,
  Twitter,
  Work,
  BuildSharp,
  GroupAddSharp,
  Edit
} from '@mui/icons-material'
import {
  generatorConfigs,
  GeneratorType
} from '../../shared-types/generator.types'
import { capitalizeFirstLetter } from '../../util/util'
import { useAuth } from '../../util/auth';

export interface LinkItem {
  link: string
  Icon: SvgIconComponent
  label: string
  minimumPlan?: 'plus' | 'premium'
}


function createLinkItem(
  generatorType: GeneratorType,
  Icon: SvgIconComponent,
  { minimumPlan }: Pick<LinkItem, 'minimumPlan'> = {}
) {
  // const {auth} = useAuth()
  // console.log("auth---",auth)
  return {
    link: generatorPath(generatorType),
    Icon,
    label: capitalizeFirstLetter(generatorConfigs[generatorType].name),
    minimumPlan
  }
}

export function isListItem(item: LinkItem | NestedListItem): item is LinkItem {
  return !!(item as LinkItem).link
}

export interface NestedListItem {
  label: string
  linkItems: LinkItemList
  Icon: SvgIconComponent

}

export type LinkItemList = (LinkItem | NestedListItem)[]


const digiloNavbarItems: LinkItemList = [
  {
    link: RoutePath.assistance,
    Icon: Textsms,
    label: 'Brainstorm Buddy'
  },
  {
    link: RoutePath.writer,
    Icon: Article,
    label: 'Digilo Blog',
    minimumPlan: 'plus'
  },
  {
    link: RoutePath.improver,
    Icon: BuildSharp,
    label: 'Quick Fix',
    minimumPlan: 'plus'
  },
  {
    link: RoutePath.digiloFixAi,
    Icon: Construction,
    label: 'Content Assistent',
    minimumPlan: 'plus'
  },
  {
    link: RoutePath.AdminUsers,
    Icon: CollectionsBookmark,
    label: 'Users',
    minimumPlan: 'plus'
  },
  {
    link: RoutePath.AboutOptions,
    Icon: Textsms,
    label: 'About Options',
    minimumPlan: 'plus'
  },
  {
    link: RoutePath.AdminRecommendedOptions,
    Icon: Work,
    label: 'Recommended Options',
    minimumPlan: 'plus'
  },
  {
    link: RoutePath.AdminUseoptions,
    Icon: Create,
    label: 'Use Options',
    minimumPlan: 'plus'
  },
  {
    link: RoutePath.AdminTextinputoptions,
    Icon: Sell,
    label: 'Text Input Options',
    minimumPlan: 'plus'
  },
  {
    link: RoutePath.AdminUserTextOptions,
    Icon: Science,
    label: 'User selected options',
    minimumPlan: 'plus'
  },
  {
    link: RoutePath.AdminCustomOptions,
    Icon: Construction,
    label: 'User Custom Options',
    minimumPlan: 'plus'
  },
  {
    link: RoutePath.addBlog,
    Icon: GroupAddSharp,
    label: 'Blog List',
    minimumPlan: 'plus'
  },
  {
    link: RoutePath.createBlog,
    Icon: GroupAddSharp,
    label: 'Add Blog',
    minimumPlan: 'plus'
  },
  {
    link: RoutePath.editBlog,
    Icon: GroupAddSharp,
    label: 'Edit Blog',
    minimumPlan: 'plus'
  }
]

const navbarBusinessItem = {
  label: 'Formeel',
  Icon: Work,
  linkItems: [
    createLinkItem('note', Note),
    createLinkItem('assessment', Assessment),
    createLinkItem('contract', Gavel),
    createLinkItem('briefing', Groups2),
    createLinkItem('whitePaper', Feed, { minimumPlan: 'premium' }),
    createLinkItem('presentation', PlayLesson, { minimumPlan: 'premium' }),
    createLinkItem('email', Email),
    createLinkItem('suggestion', Approval),
    createLinkItem('offer', RequestQuote),
    createLinkItem('agreement', Handshake)
  ]
}

const navbarPersonalItem = {
  label: 'Informeel',
  Icon: Create,
  linkItems: [
    createLinkItem('article', Article),
    createLinkItem('story', AutoStories),
    createLinkItem('comparison', Compare),
    createLinkItem('biography', LibraryBooks),
    createLinkItem('poem', FavoriteBorder),
    createLinkItem('song', Lyrics),
    createLinkItem('journal', ImportContacts),
    createLinkItem('letter', HistoryEdu),
    createLinkItem('bookReview', RateReview)
  ]
}

const navbarMarketingItem = {
  label: 'Commercieel',
  Icon: Sell,
  linkItems: [
    {
      label: 'Advertenties',
      Icon: Storefront,
      linkItems: [
        createLinkItem('facebookAd', Facebook),
        createLinkItem('googleAd', Google),
        createLinkItem('linkedInAd', LinkedIn)
      ]
    },
    createLinkItem('twitterTweet', Twitter),
    createLinkItem('socialMediaReport', Newspaper),
    createLinkItem('productDescription', Inventory)
  ]
}

const navbarScienceItem = {
  label: 'Wetenschappelijk',
  Icon: Science,
  linkItems: [
    createLinkItem('thesis', School),
    createLinkItem('researchReport', ContentPasteSearch),
    createLinkItem('methodology', Biotech),
    createLinkItem('hypothesis', PsychologyAlt),
    createLinkItem('bibliography', Contacts)
  ]
}

const navbarDigiloContent = {
  label: 'Digilo Content',
  Icon: Subject,
  minimumPlan: 'plus',
  linkItems: [
    navbarPersonalItem,
    navbarBusinessItem,
    navbarMarketingItem,
    navbarScienceItem
  ]
}

export const navbarItems: LinkItemList = [
  {
    link: RoutePath.library,
    Icon: CollectionsBookmark,
    label: 'Startpagina'
  },
  ...digiloNavbarItems,
  // navbarDigiloContent
]

export const libraryItems: NestedListItem[] = [
  {
    linkItems: digiloNavbarItems,
    label: 'Digilo',
    Icon: null
  },
  navbarPersonalItem,
  navbarBusinessItem,
  navbarMarketingItem,
  navbarScienceItem
]
