import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton'
import Snackbar from '@mui/material/Snackbar'
import { SaveOutlined,ThumbDownOffAlt,ThumbUpOffAlt, ThumbUp, } from '@mui/icons-material'
import { isFunction } from 'lodash-es'
import { Alert, Menu, MenuItem } from '@mui/material'
import { useProjectsByOwner } from '../features/projects/projects-api'
import { createContent } from '../features/projects/content-api'
import { useAuth } from '../util/auth'
import { ProjectItem } from '../shared-types/db-types'
import digiloFixAi from '../pages/app/digiloFixAi'
import { useLocation } from '../util/router';
import { Link } from '../util/router';
import FeedbackPopup from './FeedbackPopup/FeedbackPopup';

// import Link from '@mui/material';
// import { Link } from '@material-ui/core';
interface SaveContentButtonProps {
  text: string | (() => string)
  type: string,
  disabled?: boolean,
  
}

function SaveContentButton({ text, type, disabled }: SaveContentButtonProps) {
  const [saved, setSaved] = useState(false)
  const [snackbarText, setSnackbarText] = useState<string | null>(null)
  const { user } = useAuth()
  const { data: projects } = useProjectsByOwner(user.uid, 100, 0)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [feedbackModalOne, setfedbackmodalone] = useState<boolean>(false)

  const [anchorFix, setAnchorFix] = useState("")
  const [like, setlike] = useState(false)
  const [feedbackopen, setFeedbackOpen] = useState<boolean>(false)

  const handleClose = () => setFeedbackOpen(false);
  const isMenuOpen = Boolean(anchorEl)
  let history = useHistory();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  useEffect(() => {
    setAnchorFix(text);
  });

  const location = useLocation();


  const handleSaveContent = async (project: ProjectItem) => {
    const textValue = isFunction(text) ? text() : text
    setSaved(true)
    setSnackbarText(`De tekst is opgeslagen!`)
    await createContent(project.id, {
      owner: user.uid,
      name: type,
      text: textValue
    })
  }

  const handleCopy = (event: React.MouseEvent<HTMLButtonElement>) => {
    // event.preventDefault();
    if (type === "Digilo") {
      window.open("/app/digiloFixAi", "_blank");
      localStorage.setItem('text',anchorFix)
      
      // const serializedText = JSON.stringify(text);
      // setAnchorFix(text);
      
      // history.push({ 
      //   pathname: "/app/digiloFixAi",

      //   state: { anchorFix: anchorFix }
      // });
   
    } else {
      handleCopyToAi()
    }
  };

  const handleCopyToAi = () => {
    // event.preventDefault();
    if (anchorFix !== "") {
      window.open("/app/digiloFixAi", "_blank");
      localStorage.setItem('text',anchorFix)
    }
  }
  const handleCopyQucikFix = () => {
    if (anchorFix !== "") {
      window.open("/app/improver", "_blank");
      localStorage.setItem('text',anchorFix)

      // history.push({
      //   pathname: "/app/improver",
      //   state: { anchorFix: anchorFix ? anchorFix : "good to know you are here" }
      // });
    }
  }

  const handleLike = () => {
    setSnackbarText("Like")
    setlike(true)
}
  const handLikeUnlike = () => {
 setFeedbackOpen(true)
}

  const handleSnackbarClose = () => {
    setSnackbarText(null)
  }

  function SelectProjectMenu() {
    const handleMenuClose = () => {
      setAnchorEl(null)
    }

    const handleMenuItemClick = (project: ProjectItem) => {
      handleSaveContent(project)
      handleMenuClose()
    }

    return (
      <Menu
        id="select-project-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
       { projects?.length == 0 ?  <MenuItem ><Link className="add_project" to="/app">Add Projecten</Link> </MenuItem> :

        projects?.map((project) => (
          <MenuItem
            key={project.id}
            onClick={() => handleMenuItemClick(project)}
          >
            {project.name}
          </MenuItem>
        ))}
      </Menu>
    )
  }

  // if (!projects?.length) {
  //   return null
  // }

  return (
    <>  

<IconButton
        disabled={disabled}
        onClick={handleLike}
        aria-label="like"
        title="like"
        size="small"
      >
{like ? <ThumbUp sx={{color:"#EF9834FF"}} /> :
<ThumbUpOffAlt />}
        </IconButton>
<IconButton
        disabled={disabled}
        onClick={handLikeUnlike}
        aria-label="unlike"
        title="unlike"
        size="small"
      >
<ThumbDownOffAlt/>
        </IconButton>
      <IconButton
        disabled={disabled}
        onClick={handleClick}
        aria-label="Tekst opslaan"
        title="Tekst opslaan"
        size="small"
      >
        <SaveOutlined color={saved ? 'secondary' : 'inherit'} />
      </IconButton>

        {location.pathname == '/app/improver' || location.pathname == '/app/digiloFixAi' ? null : 
        <IconButton
        // disabled={disabled}
        onClick={handleCopyQucikFix}
        aria-label="Copy Quick Fix"
        title="Copy Quick Fix"
        size="small"
      >

        <svg xmlns="http://www.w3.org/2000/svg" width='24' height='24' fill="#686583" id="Flat" viewBox="0 0 256 256">
          <path d="M200,32H163.74a47.92,47.92,0,0,0-71.48,0H56A16,16,0,0,0,40,48V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V48A16,16,0,0,0,200,32Zm-72,0a32,32,0,0,1,32,32H96A32,32,0,0,1,128,32Zm32,128H96a8,8,0,0,1,0-16h64a8,8,0,0,1,0,16Zm0-32H96a8,8,0,0,1,0-16h64a8,8,0,0,1,0,16Z" />
        </svg>
      </IconButton>
        } 
        { location.pathname == '/app/digiloFixAi' ? null :
     <IconButton
        // disabled={disabled}
        onClick={handleCopy}
        aria-label="Copy to Content Assistent"
        title="Copy to Content Assistent"
        size="small"
      >
              
        <svg xmlns="http://www.w3.org/2000/svg" width='24' height='24' fill="#686583" id="Flat" viewBox="0 0 256 256">
          <path d="M200,32H163.74a47.92,47.92,0,0,0-71.48,0H56A16,16,0,0,0,40,48V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V48A16,16,0,0,0,200,32Zm-72,0a32,32,0,0,1,32,32H96A32,32,0,0,1,128,32Z" />
        </svg>
     
      </IconButton>
       } 

      <SelectProjectMenu />
      <Snackbar
        open={!!snackbarText}
        autoHideDuration={1500}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="success">
          {snackbarText}
        </Alert>
      </Snackbar>
    <FeedbackPopup onClose={handleClose} open={feedbackopen} setFeedbackOpen={setFeedbackOpen} feedbackModalOne={feedbackModalOne} />
    {/* <FeedbackPopup onClose={null} open={null} setFeedbackOpen={null} feedbackModalOne={null} setExperienceStart={null} setMenuDialog={null}/> */}
 
    </>
  )
}

export default SaveContentButton;
