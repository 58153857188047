// import React from 'react'
// import { Helmet } from 'react-helmet'
// import { useRouter } from '../util/router.js'

// function Meta(props) {
//   const { children, ...customPageMeta } = props
//   const router = useRouter()

//   // Meta values that are the same across all pages
//   const globalMeta = {
//     // Site name
//     siteName: 'My App',
//     // Your production domain (example: https://myapp.com)
//     domain: '',
//     // Your Twitter handle (example: @divjoy)
//     twitterHandle: ''
//   }

//   // Default meta values for current page (override with props)
//   const defaultPageMeta = {
//     // Page title
//     title: 'Home',
//     // Page description
//     description: "Digilo | Ontdek onze geavanceerde AI tekstschrijver die moeiteloos AI schrijven beheerst. Onze AI schrijver is een krachtige ai content generator, ontworpen om hoogwaardige content te produceren. Probeer nu onze gratis AI schrijver en ervaar het gemak van geautomatiseerd schrijven.",
//     // Social share image (create this file in /public/images/)
//     image: '/images/social.png',
//     // Page type (see https://ogp.me/#types)
//     type: 'website',
//     keywords:"Digilo, AI tekstschrijver, AI schrijven, AI schrijver, ai content generator, Gratis AI schrijver, Tekstschrijven AI, ai writing software, Schrijven AI, Schrijven kunstmatige intelligentie, ai tekst schrijven gratis, gratis ai writer, ai text writer, ai article writer, ai article generator, ai tekst genereren"
//   }
//   // Construct meta object from global, default, and custom meta
//   const meta = { ...globalMeta, ...defaultPageMeta, ...customPageMeta }

//   // Note: Each tag should have a unique `key` so that they are de-deduped if other
//   // `Meta` components are rendered on the same page or within nested components.
//   // prettier-ignore
//   return (
//     <Helmet>
//       <title>{meta.title}</title>
//       <meta content={meta.description} name="description" key="description" />
//       {meta.domain && <link rel="canonical" href={`${meta.domain}${router.pathname}${router.location.search}`} key="canonical" />}
//       <meta name="keywords" content={meta.keywords}></meta>
//       {/* Open Graph */}
//       <meta property="og:title" content={meta.title} key="og-title" />
//       <meta property="og:description" content={meta.description} key="og-description" />
//       <meta property="og:site_name" content={meta.siteName} key="og-site-name" />
//       <meta property="og:type" content="website" key="og-type" />
//       {meta.domain && <meta property="og:url" content={`${meta.domain}${router.pathname}${router.location.search}`} key="og-url" />}
//       {meta.domain && meta.image && <meta property="og:image" content={`${meta.domain}${meta.image}`} key="og-image" />}
//       {meta.image && <meta property="og:image" content={`${meta.domain}${meta.image}`} key="og-image" />}

//       {/* Twitter */}
//       <meta name="twitter:title" content={meta.title} key="twitter-title" />
//       <meta name="twitter:description" content={meta.description} key="twitter-description" />
//       <meta name="twitter:card" content="summary_large_image" key="twitter-card" />
//       {meta.twitterHandle && <meta name="twitter:site" content={meta.twitterHandle} key="twitter-site" />}
//       {meta.domain && meta.image && <meta name="twitter:image" content={`${meta.domain}${meta.image}`} key="twitter-image" />}
//       {meta.image && <meta name="twitter:image" content={`${meta.domain}${meta.image}`} key="twitter-image" />}
//        </Helmet>
//   )
// }

// export default Meta
import React from 'react';
import { Helmet } from 'react-helmet';
import { useRouter,useLocation } from '../util/router.js';
function Meta(props) {
  const { children, ...customPageMeta } = props;
  const router = useRouter();
  const location = useLocation();
console.log(router,"router")
  // Meta values that are the same across all pages
  const globalMeta = {
    // Site name
    siteName: 'My App',
    // Your production domain (example: https://myapp.com)
    domain: '',
    // Your Twitter handle (example: @divjoy)
    twitterHandle: ''
  };

  // Default meta values for current page (override with props)
  const defaultPageMeta = {
    // Page title
    title: 'Home',
    // Page description
    description: "Digilo | Ontdek onze geavanceerde AI tekstschrijver die moeiteloos AI schrijven beheerst. Onze AI schrijver is een krachtige ai content generator, ontworpen om hoogwaardige content te produceren. Probeer nu onze gratis AI schrijver en ervaar het gemak van geautomatiseerd schrijven.",
    // Social share image (create this file in /public/images/)
    image: '/img/digilomain.png',
    // Page type (see https://ogp.me/#types)
    type: 'website',
    keywords: "Digilo, AI tekstschrijver, AI schrijven, AI schrijver, ai content generator, Gratis AI schrijver, Tekstschrijven AI, ai writing software, Schrijven AI, Schrijven kunstmatige intelligentie, ai tekst schrijven gratis, gratis ai writer, ai text writer, ai article writer, ai article generator, ai tekst genereren"
  };

  // Construct meta object from global, default, and custom meta
  const meta = { ...globalMeta, ...defaultPageMeta, ...customPageMeta };

  // Get the canonical URL from props or construct it dynamically
  // const canonicalUrl = window.location.href;
  const origin = window.location.origin;
  // Get the canonical URL from the router's location object
  // const canonicalUrl = `${origin}${router.location.pathname}`;
  const canonicalUrl = `${origin}${location.pathname}`;
  console.log("canonicalUrlNew",canonicalUrl)
  return (
    <Helmet>
      <title>{meta.title}</title>
      <meta content={meta.description} name="description" key="description" />
     <link rel="canonical" href={canonicalUrl} key="canonical" />
      <meta name="keywords" content={meta.keywords}></meta>

      {/* Open Graph */}
      <meta property="og:title" content={meta.title} key="og-title" />
      <meta property="og:description" content={meta.description} key="og-description" />
      <meta property="og:site_name" content={meta.siteName} key="og-site-name" />
      <meta property="og:type" content="website" key="og-type" />
      {meta.domain && <meta property="og:url" content={`${globalMeta.domain}${router.pathname}${router.location.search}`} key="og-url" />}
      {(meta.domain || meta.domain =="" ) && meta.image && <meta property="og:image" content={`${meta.domain}${meta.image}`} key="og-image" />}

      {/* Twitter */}
      <meta name="twitter:title" content={meta.title} key="twitter-title" />
      <meta name="twitter:description" content={meta.description} key="twitter-description" />
      <meta name="twitter:card" content="summary_large_image" key="twitter-card" />
      {meta.twitterHandle && <meta name="twitter:site" content={meta.twitterHandle} key="twitter-site" />}
      {(meta.domain || meta.domain =="" ) && meta.image && <meta name="twitter:image" content={`${meta.domain}${meta.image}`} key="twitter-image" />}
    </Helmet>
  );
}

export default Meta;

