import React, { useEffect } from 'react'
import AppLayout from '../components/AppLayout'
import MainPanel from '../components/MainPanel'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@material-ui/core';
import { Button } from '@mui/material';
import { getAllUsers, getUserSelectedOptions, getAllUsersByPage, getSearchResult, getUseOptions, getOptions, get_about_options, get_text_input_options, getSearchResultByEmail,get_custom_options } from '../util/db';
// import { fetchmore } from '../util/db';
import requirePlan from '../hocs/requirePlan';
import { requireAuth } from '../util/auth';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { getDate } from 'date-fns';
import { Search } from '@mui/icons-material';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '50%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: "8px",
    border: "unset",
    p: 4,
};
const inputStyle = {
    border: "1px solid #dddd",
    padding: '10px',
    margin: "10px 0px"
}
const AdminUsers = () => {

    return (
        <AppLayout title='Adminuser'>
            <MainPanel

                main={<AdminUsersMain />}
            />

        </AppLayout>
    )

}
const AdminUsersMain = () => {
    const [data, setData] = React.useState([])
    const [lastdoc, setlastdoc] = React.useState()
    const [searchValue, setSearchValue] = React.useState([])
    const [search, setSearch] = React.useState(false)
    const [selectedData, setSelectedData] = React.useState([])
    const [open, setOpen] = React.useState(false);
    const [selectedName, setSelectedName] = React.useState('')
    const [useOptions, setUseOptions] = React.useState([])
    const [recomendedOptions, setRecomendedOptions] = React.useState([])
    const [aboutOptions, setAboutOptions] = React.useState([])
    const [textOptions, setTextOptions] = React.useState([])
    const [input, setInput] = React.useState(false)
    const [customData,setCustomData] = React.useState([])
    const [selectedId,setSelectedId] = React.useState('')
    useEffect(() => {
        getdata()
        getAllUseOptions()
        getAllRecomendedOptions()
        getAllAboutOptions()
        getAllTextOptions()
        adminCustomdata()
    }, [])

  

    const adminCustomdata = async () => {
        const adminCustomData = await get_custom_options();
        setCustomData(adminCustomData);
    }

    const getAllUseOptions = async () => {
        const data = await getUseOptions()
        setUseOptions(data)
    }

    const getAllRecomendedOptions = async () => {
        const recomended_options = await getOptions()
        setRecomendedOptions(recomended_options)
    }

    const getAllAboutOptions = async () => {
        const about_options = await get_about_options()
        setAboutOptions(about_options)
    }

    const getAllTextOptions = async () => {
        const text_options = await get_text_input_options()
        setTextOptions(text_options)
    }

    const getdata = async () => {
        const data = await getAllUsers();
        setData(data);

        setlastdoc(data[data.length - 1]);
    };
    if (data?.length === 0) {
        return <h1> loading...</h1>
    }
    const handleClose = () => setOpen(false);

    const handleSelectedOptions = async (id) => {

        const data = await getUserSelectedOptions(id)
        console.log(data, "::ddta of singlre item")
        setSelectedData(data);
        setOpen(true);
    }

    const handleSearch = async () => {
        var testEmail = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i;

        if (testEmail.test(searchValue)) {
            const result = await getSearchResultByEmail(searchValue)
            // console.log(result)
            setData(result);
            setSearch(true)
        }
        else {
            const result = await getSearchResult(searchValue)
            // console.log(result)
            setData(result);
            setSearch(true)
        }


    }

    const handleClear = () => {
        getdata()
        setSearch(false)
        setSearchValue('')
    }




    return (
        <>
            <Stack
                justifyContent="space-between"
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
                alignItems="center"
            >

                <Typography variant="h6">
                    Users
                </Typography>
                <Stack
                    justifyContent="space-between"
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 1, sm: 2, md: 4 }}
                    alignItems="center"
                >
                    <TextField onChange={(e) => { setSearchValue(e.target.value) }} value={searchValue} id="outlined-search" label="Search field" type="search" />
                    {!search && <Button onClick={handleSearch} >Search</Button>}
                    {search && <Button onClick={handleClear} >Clear</Button>}
                </Stack>
            </Stack>
            <TableContainer component={Paper} sx={{ mt: 3 }}>
                <Table sx={{ minWidth: 650 }} aria-label="table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 700 }} >Name</TableCell>
                            <TableCell sx={{ fontWeight: 700 }} align="center">Email</TableCell>
                            <TableCell sx={{ fontWeight: 700 }} align="center">Info_Completed</TableCell>
                            <TableCell sx={{ fontWeight: 700 }} align="center">isOnboarded</TableCell>
                            <TableCell sx={{ fontWeight: 700 }} align="center">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.map((result, index) => {
                            return (
                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell>
                                        {result?.name}
                                    </TableCell>
                                    <TableCell align="center">
                                        {result?.email}
                                    </TableCell>
                                    <TableCell align="center">
                                        {result?.info_completed ? "true" : "Not Available"}
                                    </TableCell>
                                    <TableCell align="center">
                                        {result?.isOnboarded ? "true" : "Not Available"}
                                    </TableCell>
                                    <TableCell align="center">
                                        <Button variant="contained" disabled={!result?.info_completed} onClick={() => { handleSelectedOptions(result?.id), setSelectedName(result?.name) ,setSelectedId(result?.id) } }> Details</Button>

                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <strong> {selectedName}</strong> Selected Options
                    </Typography>
                    {selectedData.slice(0, 1).map((result, index) => {
                        return (
                            <>
                                <div key={index}>
                                    <Grid container spacing={2} sx={{ mt: 2 }}>
                                        <Grid item lg={6}>
                                            <Typography>Use Options </Typography>
                                            <Box sx={inputStyle}>
                                                <Typography>
                                                    {
                                                        useOptions?.map((name, index) => {
                                                            if (name?.id == result?.use_option_id) {
                                                                return name?.name
                                                            }
                                                        })
                                                    }
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={6}>
                                            <Typography>Recommended Options </Typography>
                                            <Box sx={inputStyle}>
                                                <Typography>
                                                    {
                                                        recomendedOptions?.map((name, index) => {
                                                            if (name?.id == result?.recomended_option_id) {
                                                                return name.name
                                                            }
                                                        })
                                                    }
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={6}>
                                            <Typography>About Options </Typography>
                                            <Box sx={inputStyle}>
                                                <Typography>
                                                    {
                                                        aboutOptions?.map((name, index) => {
                                                            if (name?.id == result?.about_option_id) {
                                                                return name?.name
                                                            }
                                                        })
                                                    }
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={6}>
                                            <Typography>Text Input Options </Typography>
                                            <Box sx={inputStyle}>
                                                <Typography>
                                                    {
                                                        textOptions?.map((name, index) => {
                                                            if (name?.id == result?.text_input_option_id) {
                                                                return name?.name
                                                            }
                                                        })
                                                    }
                                                </Typography>
                                            </Box>
                                        </Grid>
                                      <Grid item xs={12}>
                                      <Grid container spacing={2} sx={{borderTop:"1px solid #ddd",mt:1}}>
                                           {
                                            customData?.map((name,index) => {
                                                if(name.user_id === selectedId)
                                                return   <Grid item lg={6}>
                                                {name.modal === "1st" && <Typography>custom recommended option</Typography>}
                                                {name.modal === "2nd" &&  <Typography>custom About option </Typography>}
                                                {name.modal === "3rd" && <Typography>custom use option </Typography>}
                                                <Box sx={inputStyle}>
                                                    <Typography>
                                                        {name.name}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            })
                                           }
                                          
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                </div>
                            </>
                        )
                    })}

                </Box>
            </Modal>
        </>
    )
}

export default requireAuth(requirePlan(AdminUsers, 'premium'))
