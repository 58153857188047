import React, { useState } from 'react'
import MainPanel from '../../components/MainPanel'
import MainPanelHeader from '../../components/MainPanelHeader'
import CreditsInfo from '../../components/CreditsInfo'
import ImproverForm from './improver-form'
import ImproverOutput from './improver-output'
import { improverApi } from './improver-api'
import {
  ImproverExplainOption,
  ImproverFormValues
} from '../../shared-types/improver.types'

interface AiImproverProps {
  title: string
}

function AiImprover({ title }: AiImproverProps) {
  const [formValues, setFormValues] = useState<ImproverFormValues | undefined>()
  const [resultText, setResultText] = useState<string | undefined>()
  const [explainText, setExplainText] = useState<string | undefined>()
  const [isStreaming, setIsStreaming] = useState(false)
  const [improverExplainOption, setImproverExplainOption] = useState<
    ImproverExplainOption | undefined
  >(undefined)

  const handleFormSubmit = async (formValues: ImproverFormValues) => {
    setResultText(undefined)
    setExplainText(undefined)
    setImproverExplainOption(undefined)
    setFormValues(formValues)
    setIsStreaming(true)
    await improverApi(formValues, setResultText)
    setIsStreaming(false)
  }

  const handleExplainAction = async (
    improverExplainOption: ImproverExplainOption
  ) => {
    setExplainText('')
    setImproverExplainOption(improverExplainOption)
    setIsStreaming(true)
    await improverApi(
      { ...formValues, improverExplainOption, resultText },
      setExplainText
    )
    setIsStreaming(false)
  }

  const handleRetryAction = async () => {
    improverExplainOption ? setExplainText('') : setResultText(undefined)
    setIsStreaming(true)
    await improverApi(
      { ...formValues, improverExplainOption, resultText },
      improverExplainOption ? setExplainText : setResultText
    )
    setIsStreaming(false)
  }

  return (
    <MainPanel
      mobileDisplayState={isStreaming || resultText ? 'right' : 'left'}
      mainLeft={
        <ImproverForm
          onFormSubmit={handleFormSubmit}
          isStreaming={isStreaming}
        />
      }
      mainRight={
        <ImproverOutput
          onBack={() => setResultText(undefined)}
          isStreaming={isStreaming}
          outputText={explainText != null ? explainText : resultText}
          onExplainAction={handleExplainAction}
          onRetry={handleRetryAction}
        />
      }
      header={<MainPanelHeader additional={<CreditsInfo />} />}
    ></MainPanel>
  )
}

export default AiImprover
