import {
  hashQueryKey,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
  useQuery
} from 'react-query'
import {
  connectFirestoreEmulator,
  doc,
  endBefore,
  getDoc,
  getFirestore,
  onSnapshot,
  startAfter,
  updateDoc,
  getDocs,
  collection,
  addDoc,
  limit,
  orderBy,
  query,
  deleteDoc,
  where,


} from 'firebase/firestore'
import { firebaseApp } from './firebase'
import { UseQueryResult } from 'react-query/types/react/types'
import { useEffect, useState } from 'react'
import { first, last } from 'lodash-es'
import {
  QueryEndAtConstraint,
  QueryStartAtConstraint
} from '@firebase/firestore'
import { dictionary } from 'pdfkit/js/page'

// Initialize Firestore
export const db = getFirestore(firebaseApp)
if (process.env.REACT_APP_PROD !== 'true') {
  connectFirestoreEmulator(db, 'localhost', 5023)
}

// React Query client
const client = new QueryClient()

/** ** USERS *** */

// Subscribe to user data
// Note: This is called automatically in `auth.js` and data is merged into `auth.user`
export function useUser(uid) {
  // Manage data fetching with React Query: https://react-query.tanstack.com/overview
  return useQuery(
    // Unique query key: https://react-query.tanstack.com/guides/query-keys
    ['user', { uid }],
    // Query function that subscribes to data and auto-updates the query cache
    createQuery(() => doc(db, 'users', uid)),
    // Only call query function if we have a `uid`
    { enabled: !!uid }
  )
}

// Fetch user data once (non-hook)
// Useful if you need to fetch data from outside of a component
export function getUser(uid) {
  return getDoc(doc(db, 'users', uid)).then(format)
}
// export function getFeedback(uid) {
//   return getDoc(doc(db, 'options',uid)).then(format)
// }
//for recomended options get documents
export function getOptions() {
  const optionRef = collection(db, 'recomended_options')
  const result = getDocs(optionRef).then(format)
  return result
}

export function getUseOptions() {
  const optionRef = collection(db, 'use_options')
  const result = getDocs(optionRef).then(format)
  return result
}


export function getAllUsers() {
  // const optionRef =  collection(db,'use_options')
  const first = query(collection(db, "users"), orderBy("created", "desc"))
  const result = getDocs(first).then(format)
  return result
}







export function handledeleteuser(id) {
  const taskdoc = doc(db, "about_options", id)
  try {
    deleteDoc(taskdoc)
    return ("document deleted ")
  } catch (err) {
    console.log("ERROR::", err)
  }
}

export function handledeleteinfo(id) {
  const taskdoc = doc(db, "recomended_options", id)
  try {
    deleteDoc(taskdoc)
    return ("document deleted successfully")
  } catch (err) {
    console.log("ERROR::", err)
  }
}


export function handledeleteuseoption(id) {
  const taskdoc = doc(db, "use_options", id)
  try {
    deleteDoc(taskdoc)
    return ("document deleted successfully")
  } catch (err) {
    console.log("ERROR::", err)
  }
}

//for about_options get documents
export function get_about_options() {
  const optionRef = collection(db, 'about_options')
  const result = getDocs(optionRef).then(format)
  return result
}
export function get_custom_options() {
  const optionRef = collection(db, 'custom_recommended_options')
  const result = getDocs(optionRef).then(format)
  return result
}

export function get_text_input_options() {
  const optionRef = collection(db, 'text_input_options')
  const result = getDocs(optionRef).then(format)
  return result
}

export function save_input_data(data) {
  const optionRef = collection(db, "user_text_inputs");
  const result = addDoc(optionRef, data)
  return result
}

export function saveuseoptions(data) {
  const optionRef = collection(db, "use_options");
  const result = addDoc(optionRef, data)
  return result
}

export function save_blogs(data) {
  const optionRef = collection(db, "digilo_blogs");
  const result = addDoc(optionRef, data)
  return result
}

export function get_all_blogs() {
  const optionRef = collection(db, 'digilo_blogs')
  const result = getDocs(optionRef).then(format)
  return result
}

export function get_selected_blog(title) {
  const q = query(collection(db, 'digilo_blogs'), where("url", "==", title));
  return getDocs(q).then((querySnapshot) => {
    if (!querySnapshot.empty) {
      return querySnapshot.docs[0].data(); // Return the data of the first document
    }
    throw new Error("Document not found");
  });
}

export function delete_blog(id) {
  const taskdoc = doc(db, "digilo_blogs", id)
  try {
    deleteDoc(taskdoc)
    return ("document deleted successfully")
  } catch (err) {
    console.log("ERROR::", err)
  }
}

export async function update_blog(id, data) {
  // Query for the document where title equals id
  const q = query(collection(db, 'digilo_blogs'), where("title", "==", id));
  
  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(async (doc) => {
      // Update the document
      await updateDoc(doc.ref, data);
    });
    console.log("Documents updated successfully.");
  } catch (error) {
    console.error("Error updating documents: ", error);
  }
}

export function save_details(data) {
  const optionRef = collection(db, "about_options");
  const result = addDoc(optionRef, data)
  return result
}

export function savetextoptions(data) {
  const optionRef = collection(db, "text_input_options");
  const result = addDoc(optionRef, data)
  return result
}


export function savetorecommendedopt(data) {
  const optionRef = collection(db, "recomended_options");
  const result = addDoc(optionRef, data)
  return result
}

export function saveExperiencemodal(data) {
  const optionRef = collection(db, "custom_recommended_options");
  const result = addDoc(optionRef, data)
  return ("details saved successfully")
}


// Update an existing user
export function updateUser(uid, data) {
  return updateDoc(doc(db, 'users', uid), data)
}

export function updateOptions(optionId, data) {
  const result = updateDoc(doc(db, 'options', optionId), data)
}


export function updateaboutoptions(uid, data) {
  const taskdoc = doc(db, "about_options", uid)
  try {
    updateDoc(taskdoc, data)
    return ("document updated successfully")
  } catch (error) {
    console.log("ERROR::", error)
  }

}


export function updateRecommendedoptions(uid, data) {
  const taskdoc = doc(db, "recomended_options", uid)
  try {
    updateDoc(taskdoc, data)
    return ("document updated successfully")
  } catch (error) {
    console.log("ERROR::", error)
  }

}




export function editTextinputoptions(uid, data) {
  const taskdoc = doc(db, "text_input_options", uid)
  try {
    updateDoc(taskdoc, data)
    return ("document updated successfully")
  } catch (error) {
    console.log("ERROR::", error)
  }

}



export function setEditeduseoption(uid, data) {
  const taskdoc = doc(db, "use_options", uid)
  try {
    updateDoc(taskdoc, data)
    return ("document updated successfully")
  } catch (error) {
    console.log("ERROR::", error)
  }

}



export function handledeletetextoption(uid) {
  const taskdoc = doc(db, "text_input_options", uid)
  try {
    deleteDoc(taskdoc)
    return ("document updated successfully")
  } catch (error) {
    console.log("ERROR::", error)
  }

}
export function handledeletetSuggestedOptions(uid) {
  const taskdoc = doc(db, "custom_recommended_options", uid)
  try {
    deleteDoc(taskdoc)
  } catch (error) {
    console.log("ERROR::", error)
  }

}

export function getUserSelectedOptions(user_id) {
  const q = query(
    collection(db, "user_text_inputs"),
    where("user_id", "==", user_id),
  );

  const docsSnap = getDocs(q).then(format);
  return docsSnap
}

export function getSearchResult(value) {
  const q = query(
    collection(db, "users"),
    where("name", "==", value),
  );
  const docsSnap = getDocs(q).then(format);
  return docsSnap
}
export function getSearchResultByEmail(value) {
  console.log(value)
  const q = query(
    collection(db, "users"),
    where("email", "==", value),
  );
  const docsSnap = getDocs(q).then(format);
  return docsSnap
}



export function get_user_inputs_data() {
  const user_text_input_ref = collection(db, "user_text_inputs")

  const result = getDocs(user_text_input_ref).then(format)
  return result









}

/** ** HELPERS *** */

// Store Firestore unsubscribe functions
const unsubs = {}

export function createQuery(getRef) {
  // Create a query function to pass to `useQuery`
  return async ({ queryKey }) => {
    let unsubscribe
    let firstRun = true
    // Wrap `onSnapshot` with a promise so that we can return initial data
    const data = await new Promise((resolve, reject) => {
      unsubscribe = onSnapshot(
        getRef(),
        // Success handler resolves the promise on the first run.
        // For subsequent runs we manually update the React Query cache.
        (response) => {
          const data = format(response)
          if (firstRun) {
            firstRun = false
            resolve(data)
          } else {
            client.setQueryData(queryKey, data)
          }
        },
        // Error handler rejects the promise on the first run.
        // We can't manually trigger an error in React Query, so on a subsequent runs we
        // invalidate the query so that it re-fetches and rejects if error persists.
        (error) => {
          if (firstRun) {
            firstRun = false
            reject(error)
          } else {
            client.invalidateQueries(queryKey)
          }
        }
      )
    })

    // Unsubscribe from an existing subscription for this `queryKey` if one exists
    // Then store `unsubscribe` function so it can be called later
    const queryHash = hashQueryKey(queryKey)
    unsubs[queryHash] && unsubs[queryHash]()
    unsubs[queryHash] = unsubscribe

    return data
  }
}

// Automatically remove Firestore subscriptions when all observing components have unmounted
; (client as any).queryCache.subscribe(({ type, query }) => {
  if (
    type === 'observerRemoved' &&
    query.getObserversCount() === 0 &&
    unsubs[query.queryHash]
  ) {
    // Call stored Firestore unsubscribe function
    unsubs[query.queryHash]()
    delete unsubs[query.queryHash]
  }
})

// Format Firestore response
export function format(response) {
  // Converts doc into object that contains data and `doc.id`
  const formatDoc = (doc) => ({ id: doc.id, ...doc.data() })
  if (response.docs) {
    // Handle a collection of docs
    return response.docs.map(formatDoc)
  }
  // Handle a single doc
  return response.exists() ? formatDoc(response) : null
}

// React Query context provider that wraps our app
export function QueryClientProvider(props) {
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <QueryClientProviderBase client={client}>
      {props.children}
    </QueryClientProviderBase>
  )
}

export function useQueryPagination<T extends { id: string }>(
  page: number,
  getOrderPropsFn: (doc: any) => T[]
): {
  updateQueryResult: (val: UseQueryResult<T[]>) => UseQueryResult<T[]>
  pageQuery: (QueryEndAtConstraint | QueryStartAtConstraint)[]
} {
  const [queryResult, setQueryResult] = useState<UseQueryResult<T[]>>()
  const [pageQuery, setPageQuery] = useState<
    (QueryEndAtConstraint | QueryStartAtConstraint)[]
  >([])

  const [previousPage, setPreviousPage] = useState(0)
  const pageDiff = page - previousPage

  useEffect(() => {
    if (!pageDiff || !queryResult) {
      return
    }

    if (page === 0) {
      setPageQuery([])
    } else {
      const lastDoc = last(queryResult.data)
      const firstDoc = first(queryResult.data)
      if (lastDoc && pageDiff > 0) {
        setPageQuery([startAfter(getOrderPropsFn(lastDoc))])
      } else if (firstDoc && pageDiff < 0) {
        setPageQuery([endBefore(getOrderPropsFn(lastDoc))])
      }
    }
    setPreviousPage(page)
  }, [pageDiff, queryResult])

  const updateQueryResult = (val) => {
    if (val.data?.[0]?.id !== queryResult?.data?.[0]?.id) {
      setQueryResult(val)
    }
    return val
  }

  return {
    updateQueryResult,
    pageQuery
  }
}
