// import React from 'react'
// import Container from '@mui/material/Container'
// import Grid from '@mui/material/Grid'
// import Card from '@mui/material/Card'
// import CardContent from '@mui/material/CardContent'
// import Typography from '@mui/material/Typography'
// import CardHeader from '@mui/material/CardHeader'
// import Avatar from '@mui/material/Avatar'
// import { makeStyles } from '@mui/styles'
// import Section from './Section'
// import SectionHeader from './SectionHeader'

// const useStyles = makeStyles((theme) => ({
//   header: {
//     paddingTop: 0
//   },
//   avatar: {
//     width: theme.spacing(7),
//     height: theme.spacing(7)
//   }
// }))

// function TestimonialsSection(props) {
//   const classes = useStyles()

//   const items = [
//     {
//       avatar: 'https://uploads.divjoy.com/pravatar-150x-5.jpeg',
//       name: 'Amanda',
//       testimonial: `Ik ben zo blij dat ik Digilo heb ontdekt! Als freelance schrijver heb ik altijd het gevoel dat
//       ik tegen tijdnood aanloop, dus ik ben dankbaar voor alle hulp die ik kan krijgen. Digilo
//       heeft al mijn verwachtingen overtroffen met zijn uitgebreide lijst aan features.
//       Ik heb de tekstgeneratiefunctie gebruikt om snel en gemakkelijk content te creëren voor
//       mijn blogs en artikelen. De spellings- en grammaticacontrole heeft me ontzettend
//       geholpen om fouten te vermijden en mijn werk professioneel te laten lijken. De stijl- en
//       tonaliteitsanalyses hebben me geholpen om mijn teksten aan te passen aan het publiek
//       waarvoor ik schrijf, wat heeft geresulteerd in een groter bereik en betere feedback.
//       Ik kan niet genoeg zeggen over hoe dankbaar ik ben voor Digilo en hoeveel het mijn
//       werk als schrijver heeft verbeterd. Ik raad het iedereen aan die op zoek is naar een AI
//       schrijver met uitgebreide en nuttige features.`
//     },
//     {
//       avatar: 'https://uploads.divjoy.com/pravatar-150x-12.jpeg',
//       name: 'Peter',
//       testimonial: `Ik ben ontzettend blij met Digilo! Ik heb de tekstgeneratie- en
// zoekwoordoptimalisatiefuncties gebruikt en ben echt onder de indruk van de resultaten.
// De tekstgeneratie heeft me geholpen om snel en gemakkelijk professionele teksten te
// creëren voor mijn werk als marketeer. Ik hoefde alleen maar mijn onderwerp in te voeren
// en Digilo heeft de rest gedaan. Het heeft me uren tijd bespaard en het eindresultaat was
// geweldig.
// Ik kan Digilo aan iedereen aanbevelen die op zoek is naar een eenvoudig te gebruiken en
// effectief AI schrijfhulpmiddel. Ik ben zeer tevreden met mijn ervaringen tot nu toe en zal
// zeker blijven gebruiken in de toekomst.`
//     },
//     {
//       avatar: 'https://uploads.divjoy.com/pravatar-150x-48.jpeg',
//       name: 'Joana',
//       testimonial: `Ik ben zo dankbaar voor Digilo! Het heeft me geholpen om efficiënter te communiceren
// op verschillende manieren.
// Ten eerste heeft het me geholpen om gemakkelijk en op de juiste toon e-mails te
// beantwoorden. Ik ben ondernemer en krijg vaak veel e-mails, dus het is fijn om een
// hulpmiddel te hebben dat me kan helpen zonder dat ik daar veel tijd aan hoef te
// besteden.
// Ik heb ook Digilo gebruikt om snel berichten te versturen via WhatsApp. Het heeft me
// geholpen om mijn communicatie met vrienden en familie te verbeteren.
// Ten slotte heeft Digilo me geholpen om te communiceren met mensen uit het
// buitenland. Het heeft me geholpen om gemakkelijk te vertalen en te communiceren in
// verschillende talen, wat heeft geleid tot een verbeterde samenwerking met mijn
// internationale zakenpartners.
// Ik kan Digilo aan iedereen aanbevelen die op zoek is naar een gemakkelijk te gebruiken
// hulpmiddel om hun communicatie te verbeteren. Het heeft mijn leven echt
// gemakkelijker gemaakt en ik ben dankbaar voor alles wat het voor me heeft gedaan.`
//     }
//   ]

//   return (
//     <Section
//       bgColor={props.bgColor}
//       size={props.size}
//       bgImage={props.bgImage}
//       bgImageOpacity={props.bgImageOpacity}
//     >
//       <Container>
//         <SectionHeader
//           title={props.title}
//           subtitle={props.subtitle}
//           size={4}
//           textAlign="center"
//         />
//         <Grid container justifyContent="center" spacing={4}>
//           {items.map((item, index) => (
//             <Grid item xs={12} sm={4} key={index}>
//               <Card>
//                 <CardContent>
//                   <Typography variant="body1" component="p">
//                     "{item.testimonial}"
//                   </Typography>
//                 </CardContent>
//                 <CardHeader
//                   className={classes.header}
//                   avatar={
//                     <Avatar
//                       src={item.avatar}
//                       alt={item.name}
//                       className={classes.avatar}
//                     />
//                   }
//                   title={item.name}
//                   subheader={item.company}
//                 />
//               </Card>
//             </Grid>
//           ))}
//         </Grid>
//       </Container>
//     </Section>
//   )
// }

// export default TestimonialsSection
import React from 'react'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import Avatar from '@mui/material/Avatar'
import { makeStyles } from '@mui/styles'
import Section from './Section'
import SectionHeader from './SectionHeader'
import Box from '@mui/material/Box'

const styles ={
  name:{
    fontWeight:"600",
    marginTop:"10px"
  },
  quote: {
    fontSize:{
      xs: "16px",
      sm: "24px",
      lg: "32px",
      
  },
    fontWeight: "400",
    textAlign: "center",
    padding: {
      xs: "2em 1em",
      sm: "2em 1em",
      md: "2em 1em",
    }
  }
}
function TestimonialsSection(props) {
  // const classes = useStyles()

  const items = [
    {
      avatar: 'https://uploads.divjoy.com/pravatar-150x-5.jpeg',
      name: 'Amanda',
      testimonial: `Ik ben zo blij dat ik Digilo heb ontdekt! Als freelance schrijver heb ik altijd het gevoel dat
      ik tegen tijdnood aanloop, dus ik ben dankbaar voor alle hulp die ik kan krijgen. Digilo
      heeft al mijn verwachtingen overtroffen met zijn uitgebreide lijst aan features.
      Ik heb de tekstgeneratiefunctie gebruikt om snel en gemakkelijk content te creëren voor
      mijn blogs en artikelen. De spellings- en grammaticacontrole heeft me ontzettend
      geholpen om fouten te vermijden en mijn werk professioneel te laten lijken. De stijl- en
      tonaliteitsanalyses hebben me geholpen om mijn teksten aan te passen aan het publiek
      waarvoor ik schrijf, wat heeft geresulteerd in een groter bereik en betere feedback.
      Ik kan niet genoeg zeggen over hoe dankbaar ik ben voor Digilo en hoeveel het mijn
      werk als schrijver heeft verbeterd. Ik raad het iedereen aan die op zoek is naar een AI
      schrijver met uitgebreide en nuttige features.`
    },
    {
      avatar: 'https://uploads.divjoy.com/pravatar-150x-12.jpeg',
      name: 'Peter',
      testimonial: `Ik ben ontzettend blij met Digilo! Ik heb de tekstgeneratie- en
zoekwoordoptimalisatiefuncties gebruikt en ben echt onder de indruk van de resultaten.
De tekstgeneratie heeft me geholpen om snel en gemakkelijk professionele teksten te
creëren voor mijn werk als marketeer. Ik hoefde alleen maar mijn onderwerp in te voeren
en Digilo heeft de rest gedaan. Het heeft me uren tijd bespaard en het eindresultaat was
geweldig.
Ik kan Digilo aan iedereen aanbevelen die op zoek is naar een eenvoudig te gebruiken en
effectief AI schrijfhulpmiddel. Ik ben zeer tevreden met mijn ervaringen tot nu toe en zal
zeker blijven gebruiken in de toekomst.`
    },
    {
      avatar: 'https://uploads.divjoy.com/pravatar-150x-48.jpeg',
      name: 'Joana',
      testimonial: `Ik ben zo dankbaar voor Digilo! Het heeft me geholpen om efficiënter te communiceren
op verschillende manieren.
Ten eerste heeft het me geholpen om gemakkelijk en op de juiste toon e-mails te
beantwoorden. Ik ben ondernemer en krijg vaak veel e-mails, dus het is fijn om een
hulpmiddel te hebben dat me kan helpen zonder dat ik daar veel tijd aan hoef te
besteden.
Ik heb ook Digilo gebruikt om snel berichten te versturen via WhatsApp. Het heeft me
geholpen om mijn communicatie met vrienden en familie te verbeteren.
Ten slotte heeft Digilo me geholpen om te communiceren met mensen uit het
buitenland. Het heeft me geholpen om gemakkelijk te vertalen en te communiceren in
verschillende talen, wat heeft geleid tot een verbeterde samenwerking met mijn
internationale zakenpartners.
Ik kan Digilo aan iedereen aanbevelen die op zoek is naar een gemakkelijk te gebruiken
hulpmiddel om hun communicatie te verbeteren. Het heeft mijn leven echt
gemakkelijker gemaakt en ik ben dankbaar voor alles wat het voor me heeft gedaan.`
    }
  ]

  return (
    <>
     <Typography variant="h2" component="h2" sx={styles.quote}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" fill="#000" id="Flat" viewBox="0 0 256 256">
          <path d="M116,72v88a48.05,48.05,0,0,1-48,48,8,8,0,0,1,0-16,32,32,0,0,0,32-32v-8H40a16,16,0,0,1-16-16V72A16,16,0,0,1,40,56h60A16,16,0,0,1,116,72ZM216,56H156a16,16,0,0,0-16,16v64a16,16,0,0,0,16,16h60v8a32,32,0,0,1-32,32,8,8,0,0,0,0,16,48.05,48.05,0,0,0,48-48V72A16,16,0,0,0,216,56Z" />
        </svg>
        &nbsp;"Het is klaar met met writer’s block en eindeloze uren zwoegen op één stuk tekst"
      </Typography>
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <Grid container alignItems="center" spacing={4} sx={{marginTop:"20px"}}>
        <Grid item xs={12} sm={4}>
            <Box sx={{paddingRight:{xs:"0px",sm:"1em",md:"2em",lg:"3em"}}}>
            <video width="100%" controls playsInline muted autoPlay>
      <source src="/video/client01.mp4" type="video/mp4" />
    </video>
              </Box>
          </Grid>
        <Grid item xs={12} sm={8}>
           <Box>
           <img src="/img/Rating.png" width="" />
           <Typography sx={styles.desc}>
           "Ik kan me geen leven meer voorstellen zonder Digilo. Digilo heeft mijn schrijfproces volledig getransformeerd. Of ik nu een boeiende blogpost, een foutloos e-book of talloze nieuwsbrieven nodig heb, Digilo maakt het allemaal moeiteloos mogelijk. Maar dat is nog niet alles...het verbetert en verrijkt ook mijn bestaande teksten, en helpt me mijn schrijfstijl en grammatica te verbeteren. Dankzij Digilo zijn mijn teksten nu niet alleen foutloos en professioneel, maar ook levendig, overtuigend en uniek"
           </Typography>
           <Typography sx={styles.name}>
           Winnie L
            </Typography>
           </Box>
          </Grid>
       
        </Grid>
        <Grid container alignItems="center" spacing={4} sx={{marginTop:"3em"}}>
     
        <Grid item xs={12} sm={8} sx={{order:{xs:"2",sm:"1",md:"1",lg:"1"}}}>
           <Box sx={{textAlign:{xs:"left",sm:"right"}}}>
           <img src="/img/Rating.png" width="" />
           <Typography sx={styles.desc}>
           "Met Digilo is schrijven een fluitje van een cent geworden. Digilo helpt mij om moeiteloos boeiende blogs, perfecte e-books en effectieve nieuwsbrieven te creëren. Het verbetert niet alleen mijn bestaande teksten, maar verfijnt ook mijn schrijfstijl en grammatica. Mijn teksten zijn nu professioneel, foutloos en uniek. Digilo heeft echt de manier waarop ik schrijf veranderd.
 
       <br/> Probeer het zelf en laat je woorden spreken!"
        </Typography>
           <Typography sx={styles.name}>
           Hendrik T
            </Typography>
           </Box>
          </Grid>
          <Grid item xs={12} sm={4} sx={{order:{xs:"1",sm:"2",md:"2",lg:"2"}}}>
            <Box sx={{paddingLeft:{xs:"0px",sm:"1em",md:"2em",lg:"3em"}}}>
            <video width="100%" controls playsInline muted autoPlay>
      <source src="/video/Digilo_compress.mp4" type="video/mp4" />
    </video>
              </Box>
          </Grid>
        </Grid>
      </Container>
    </Section>
    </>
  )
}

export default TestimonialsSection
