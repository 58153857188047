import React, { useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Container from '@mui/material/Container'
import Toolbar from '@mui/material/Toolbar'
import Hidden from '@mui/material/Hidden'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Divider from '@mui/material/Divider'
import NightsStayIcon from '@mui/icons-material/NightsStay'
import WbSunnyIcon from '@mui/icons-material/WbSunny'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { makeStyles } from '@mui/styles'
import Section from './Section'
import { Link } from '../util/router'
import { useAuth } from '../util/auth'
import { useDarkMode } from '../util/theme'
import { HashLink } from 'react-router-hash-link'
import { RoutePath, routeWithSlugs } from '../util/routes'
import { useMenu } from '../hooks/useMenu'
import AccountMenu from './AccountMenu'
import ListItemButton from '@mui/material/ListItemButton'

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 48,
    marginRight: theme.spacing(2)
  },
  drawerList: {
    width: 250
  },
  spacer: {
    flexGrow: 1
  },
  sticky: {
    position: 'sticky',
    top: 0,
    zIndex: 10000
  },
  logoLink: {
    alignSelf: 'stretch',
    display: 'flex',
    alignItems: 'center'
  }
}))

function Navbar(props) {
  const classes = useStyles()

  const auth = useAuth()
  const darkMode = useDarkMode()
  const [drawerOpen, setDrawerOpen] = useState(false)

  // Use inverted logo if specified
  // and we are in dark mode
  const logo =
    props.logoInverted && darkMode.value ? props.logoInverted : props.logo

  const { closeMenu, menuState, openMenu } = useMenu()

  return (
    <Section bgColor={props.color} size="auto" className="header">
      <AppBar position="static" color="transparent" elevation={0}>
        <Container disableGutters>
          <Toolbar>
            <Link to={RoutePath.root} className={classes.logoLink}>
              <img src={logo} alt="Logo" className={classes.logo} />
            </Link>
            <div className={classes.spacer} />
            <Hidden mdUp implementation="css">
              <IconButton
                onClick={() => {
                  setDrawerOpen(true)
                }}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
            <Hidden mdDown implementation="css">
              {auth.user && (
                <>
                  <Button
                    component={Link}
                    to={RoutePath.dashboard}
                    color="inherit"
                  >
                    Dashboard
                  </Button>
                  {auth.user.info_completed ? <Button
                    component={Link}
                    to={RoutePath.assistance}
                    color="inherit"
                  >
                    Digilo
                  </Button>
                    :
                    null
                  }
                  {auth.user.info_complete ? <Button
                    component={Link}
                    to={RoutePath.writer}
                    color="inherit"
                  >
                    Digilo Blog
                  </Button>
                    :
                    null
                  }
                </>
              )}

              {!auth.user && (
                <>
                  <Button
                    component={HashLink}
                    to={`${RoutePath.root}#features`}
                    color="inherit"
                  >
                    Digilo Features
                  </Button>
                </>
              )}

              <Button component={Link} to={RoutePath.pricing} color="inherit">
                Prijzen
              </Button>

              <Button component={Link} to={RoutePath.contact} color="inherit">
                Contact
              </Button>
              <Button component={Link} to={RoutePath.blog} color="inherit">
                Blogs
              </Button>

              {!auth.user && (
                <>
                  <Button
                    component={Link}
                    to={routeWithSlugs(RoutePath.auth, 'signin')}
                    color="inherit"
                  >
                    Aanmelden
                  </Button>
                  <Box component="span" ml={1}>
                    <Button
                      component={Link}
                      to={routeWithSlugs(RoutePath.auth, 'signup')}
                      variant="contained"
                      color="primary"
                    >
                      Registreren
                    </Button>
                  </Box>
                </>
              )}

              {auth.user && (
                <>
                  <Button component={Link} to={RoutePath.help} color="inherit">
                    Help
                  </Button>
                  <Button
                    color="inherit"
                    aria-label="Account"
                    aria-controls="account-menu"
                    aria-haspopup="true"
                    onClick={(event) => {
                      openMenu(event, 'account-menu')
                    }}
                  >
                    Mijn Digilo
                    <ExpandMoreIcon className={classes.buttonIcon} />
                  </Button>
                  <AccountMenu closeMenu={closeMenu} menuState={menuState} />
                </>
              )}

              <IconButton
                color="inherit"
                onClick={darkMode.toggle}
                style={{ opacity: 0.6 }}
              >
                {darkMode.value && <NightsStayIcon />}

                {!darkMode.value && <WbSunnyIcon />}
              </IconButton>
            </Hidden>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <List
          className={classes.drawerList}
          onClick={() => setDrawerOpen(false)}
        >
          {auth.user && (
            <>
              <ListItemButton component={Link} to={RoutePath.dashboard}>
                <ListItemText>Dashboard</ListItemText>
              </ListItemButton>
              <ListItemButton component={Link} to={RoutePath.assistance}>
                <ListItemText>Digilo</ListItemText>
              </ListItemButton>
              {/* <ListItemButton component={Link} to={RoutePath.writer}>
                <ListItemText>Digilo Blog</ListItemText>
              </ListItemButton> */}
            </>
          )}

          {!auth.user && (
            <ListItemButton
              component={HashLink}
              to={`${RoutePath.root}#features`}
            >
              <ListItemText>Digilo Features</ListItemText>
            </ListItemButton>
          )}
          <ListItemButton component={Link} to={RoutePath.blog}>
            <ListItemText>Blog</ListItemText>
          </ListItemButton>
          <ListItemButton component={Link} to={RoutePath.pricing}>
            <ListItemText>Prijzen</ListItemText>
          </ListItemButton>

          <ListItemButton component={Link} to={RoutePath.contact}>
            <ListItemText>Contact</ListItemText>
          </ListItemButton>


          {!auth.user && (
            <>
              <ListItem>
                <Button
                  component={Link}
                  to={routeWithSlugs(RoutePath.auth, 'signin')}
                >
                  Aanmelden
                </Button>
              </ListItem>
              <ListItem>
                <Button
                  component={Link}
                  to={routeWithSlugs(RoutePath.auth, 'signup')}
                  variant="contained"
                  color="primary"
                >
                  Registreren
                </Button>
              </ListItem>
            </>
          )}

          {auth.user && (
            <>
              <ListItemButton component={Link} to={RoutePath.help}>
                <ListItemText>Help</ListItemText>
              </ListItemButton>
              <ListItemButton
                component={Link}
                to={routeWithSlugs(RoutePath.settings, 'general')}
              >
                <ListItemText>Settings</ListItemText>
              </ListItemButton>
              <Divider />
              <ListItemButton
                onClick={(event) => {
                  auth.signout()
                }}
              >
                <ListItemText>Sign out</ListItemText>
              </ListItemButton>
            </>
          )}

          <ListItem>
            <IconButton
              color="inherit"
              onClick={darkMode.toggle}
              style={{ opacity: 0.6 }}
            >
              {darkMode.value && <NightsStayIcon />}

              {!darkMode.value && <WbSunnyIcon />}
            </IconButton>
          </ListItem>
        </List>
      </Drawer>
    </Section>
  )
}

export default Navbar
