import { satisfy } from './types'

export const improverOptions = satisfy<Record<string, string>>()({
  improve:
    'Door gebruik te maken van deze knop, verbetert Digilo de tekst op zijn eigen manier.',
  correct:
    'Door middel van deze knop kun je spelfouten uit je tekst verwijderen.',
  rewriteMe:
    'Met behulp van deze knop kun je de tekst herschrijven in de ik-vorm.',
  rewriteYou:
    'Met behulp van deze knop kun je de tekst herschrijven in de jij-vorm.',
  rewriteWe:
    'Met behulp van deze knop kun je de tekst herschrijven in de wij-vorm.',
  objective:
    'Gebruik deze knop om de tekst op een objectieve manier te herschrijven.',
  subjective:
    'Gebruik deze knop om de tekst op een subjectieve manier te herschrijven.',
  professionalize:
    'Door middel van deze knop kun je je teksten professionaliseren.',
  create: 'Met behulp van deze knop kun je je teksten creatiever maken.',
  technical:
    'Gebruik deze knop om je teksten op een technische manier te herschrijven.',
  journalistic:
    'Door gebruik te maken van deze knop kun je je teksten journalistisch maken.',
  condense:
    'Met behulp van deze knop kun je je teksten verkorten en vereenvoudigen.',
  summary: 'Door middel van deze knop kun je lange teksten samenvatten.',
  explainToChild:
    'Gebruik deze knop om moeilijke teksten eenvoudig te verduidelijken.',
  taskAndSteps:
    'Gebruik deze knop om gewenste acties te vinden in bijvoorbeeld werkmails of andere tekstsoorten.'
})

export type ImproverOption = keyof typeof improverOptions

export const improverOptionPrompts = satisfy<Record<ImproverOption, string>>()({
  improve: 'Verbeter deze zin of tekst:',
  correct: 'Haal de spelfouten uit deze tekst:',
  rewriteMe: 'Herschrijf deze tekst in de ik vorm:',
  rewriteYou: 'Herschrijf deze tekst in de jij vorm:',
  rewriteWe: 'Herschrijf deze tekst in de wij vorm:',
  objective: 'Herschijf deze tekst op een objectieve manier:',
  subjective: 'Herschijf deze tekst op een subjectieve manier:',
  professionalize: 'Schrijf deze tekst professioneler:',
  create: 'Schrijf deze tekst creatiever:',
  technical: 'Schrijf deze tekst op een technische manier:',
  journalistic: 'Schrijf deze tekst op een journalistische manier:',
  condense: 'Verkort of vereenvoudig deze tekst:',
  summary: 'Maak een korte samenvatting van deze tekst:',
  explainToChild:
    'leg deze tekst uit op een eenvoudige manier zodat een 6 jarig kind het kan begrijpen:',
  taskAndSteps:
    'Wat word er van mij gevraagd en welke stappen moet ik nemen op basis van deze tekst:'
})

export const improverExplainOptions = satisfy<Record<string, string>>()({
  explanation:
    'Met behulp van deze knop kun je erachter komen hoe Digilo Fix je tekst heeft verbeterd.',
  suggestion:
    'Door gebruik te maken van deze knop krijg je een suggestie die je kan helpen bij het verbeteren van je teksten.'
})

export type ImproverExplainOption = keyof typeof improverExplainOptions

export const improverExplainOptionPrompts = satisfy<
  Record<ImproverExplainOption, string>
>()({
  explanation:
    'Hoe heb je deze tekst verbeterd en hoe heb je het grammaticaal verbeterd?',
  suggestion:
    'Geef me zoveel mogelijk verbeter tips en verbetertips van gramaticale aard zodat ik het voortaan zelf kan:'
})

export interface ImproverFormValues {
  text: string
  improverOption: ImproverOption | ''
}

export interface ImproverPayload extends ImproverFormValues {
  improverExplainOption?: ImproverExplainOption | null
  resultText?: string
}
