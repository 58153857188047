import React, { useState } from 'react'
import { Alert, Card, Modal, Snackbar, Stack } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { Close } from '@mui/icons-material'
import Logo from '../Logo'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import { useFormik } from 'formik'
import TextField from '@mui/material/TextField'
import { useAuth } from '../../util/auth'
import Button from '@mui/material/Button'
import { createFeedback } from './feedbackApi'
import * as yup from 'yup'
import { Dispatch, SetStateAction } from "react";

const styles = {
  modal: {
    px: {
      xs: 1,
      sm: 4
    },
    '&:focus': {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  card: {
    width: '100%',
    maxWidth: 600,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  closeButton: {
    position: 'absolute',
    right: 0
  }
}

function createInitialValues() {
  return {
    feedback: ''
  }
}

const validationSchema = yup.object({
  feedback: yup.string().required()
})

interface FeedbackPopupProps {
  open: boolean
  onClose: () => void
  setFeedbackOpen: Dispatch<SetStateAction<boolean>>
  setMenuDialog ?: Dispatch<SetStateAction<boolean>>
  setExperienceStart ?: Dispatch<SetStateAction<boolean>>
  feedbackModalOne : boolean
}

function FeedbackPopup({ open, onClose, setFeedbackOpen , setMenuDialog,feedbackModalOne,setExperienceStart }: FeedbackPopupProps) {
  const { user } = useAuth()

  const formik = useFormik({
    initialValues: createInitialValues(),
    validationSchema,
    validateOnMount: true,
    onSubmit: async ({ feedback }, formikHelpers) => {
      createFeedback({ feedback, owner: user?.uid })
      formikHelpers.setSubmitting(false)
      setSnackbarOpen(true)
      onClose()
      setFeedbackOpen(false)
      // setMenuDialog(true)
      {feedbackModalOne && setMenuDialog(true)}
        formikHelpers.resetForm();
    }
  })

  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const handleSnackbarClose = () => setSnackbarOpen(false)

  return (
    <>
      <Modal open={open} onClose={onClose} sx={styles.modal}>
        <Card sx={styles.card}>
          <Stack
            justifyContent="center"
            direction="row"
            position="relative"
            width="100%"
          >
            <Logo link={false} sx={{ py: 2 }} />
            {/* <IconButton onClick={onClose} sx={styles.closeButton}>
              <Close />
            </IconButton> */}
          </Stack>
          <CardContent sx={{ width: '100%', maxWidth: 500 }}>
            <Typography variant="h5" textAlign="center">
              Feedback Formulier
            </Typography>

            <Stack
              component="form"
              onSubmit={formik.handleSubmit}
              sx={{ mt: 2 }}
            >
              <Typography>Noteer in dit veld je feedback</Typography>

              <TextField
                multiline={true}
                rows={6}
                size="small"
                {...formik.getFieldProps('feedback')}
              />
              <Button
                disabled={formik.isSubmitting || !formik.isValid}
                color="secondary"
                type="submit"
                variant="contained"
                fullWidth={false}
                sx={{ mt: 1, alignSelf: 'center' }}
              >
                Verstuur
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={1500}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="success">
          Bedankt voor je feedback!
        </Alert>
      </Snackbar>
    </>
  )
}

export default FeedbackPopup
