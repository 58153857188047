import {React} from 'react'
import AppLayout from '../components/AppLayout'
import MainPanel from '../components/MainPanel'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Link } from '../util/router'
import './digiloPurchase.css'
import { useAuth } from '../util/auth'
import { RoutePath } from '../util/routes'
import Button from '@mui/material/Button'

// const DigiloPurchase = () => {
//     return (
//         <AppLayout title='Adminuser'>
//             <MainPanel

//                 main={<DigiloPurchaseMain />}
//             />
//         </AppLayout>
//     )
// }
const DigiloPurchase = ()=>{
    const auth = useAuth()
 
    return(
        <>
       <div className='digiloWrapper'>
       
        <div className='container'>
            <div className='kiesHeading_wrapper'>
          <h2 className='kies_heading'>Kies het abonnement dat bij jou past</h2>
          <h6 className='kiesHeading'>*Probeer nu gratis gedurende 5 dagen. Er zijn geen kosten verbonden en er wordt geen geld van je bankrekening of creditcard afgeschreven.</h6>

            </div>
          <div className='kies_btn_wrapper'>
            <button className='kies_btn'>Maandelijks</button>
          </div>
          <div className='purchase_container'>
          <div className='purchase_outer'>
           <div className='purchase_innerContent'>
           <button className='kies_btn'>Starter</button>
           <h6>Na 5 dagen wordt je automatisch overgezet naar het standaard abonnement</h6>
           <h2>Gratis</h2>
           <p>Perfect voor startende content creaters, marketeers en kleine bedrijven. Biedt basisfuncties zoals snelle tekstproductie, met upgrade-opties als je behoeften toenemen.</p>
           </div>
           <div className='purchase_innerContent'>
           <ul>
            <div className='check_icon'>
            <CheckIcon/>
            <li>
            Brainstorm Buddy  - De brainstorm assistent voor nieuwe content-ideeën
            </li>
            </div>
            <div className='check_icon'>
            <CheckIcon/>
            
            {/* <li>30000 Woorden ( goed voor ongeveer 50 advertenties, 25 email/nieuwsbrieven en 5 e-books)</li> */}
            
            </div>
            {/* <div className='check_icon'>
            <CheckIcon/>
            <li>Chat geschiedenis automatisch bewaard </li>
            </div> */}
            <div className='check_icon'>
            <CheckIcon/>
            <li>Digilo Quick Fix -  Optimaliseer je teksten binnen een seconde! </li>
            </div>
            <div className='check_icon'>
            <CheckIcon/>
            <li>Digilo Content Assistent -Creëer nieuwe teksten of verbeter bestaande teksten met behulp van AI</li>
            </div>
            <div className='check_icon'>
            <CheckIcon/>
            <li><h4>GDPR & privacybescherming</h4></li>
            </div>
         
         
           </ul>
           <div className='startBtn_outer'>
           <button className='startBtn'>Start <ArrowRightAltIcon/></button>

           </div>
           </div>
          </div>
          {/* tab1 */}
          <div className='purchase_outer'>
           <div className='purchase_innerContent'>
           <button className='standard_btn'>Standard</button>
           {/* <h6>Na 5 dagen wordt je automatisch overgezet naar het standaard abonnement</h6> */}
           <h2>€25/PM</h2>
           <p>Ideaal voor digitale marketeers en (e-commerce) ondernemers. Biedt gepersonaliseerde content en SEO-optimalisatie voor uitgebreide tekstbehoefte.</p>
           </div>
           <div className='purchase_innerContent'>
           <ul>
            <div className='check_icon'>
            <CheckIcon/>
            <li>
            Alle opties uit Starter
            </li>
            </div>
            <div className='check_icon'>
            <CheckIcon/>
            
            <li>Chat geschiedenis automatisch bewaard </li>
            
            </div>
            <div className='check_icon'>
            <CheckIcon/>
            <li>30000 Woorden ( goed voor ongeveer 50 advertenties, 25 email/nieuwsbrieven en 5 e-books)</li>
            </div>
         
         
           </ul>
           <div className='startBtn_outer'>
           <button className='startBtn cmn_btn'>Start <ArrowRightAltIcon/></button>

           </div>
           </div>
          </div>
         {/* tab3 */}
         <div className='purchase_outer'>
           <div className='purchase_innerContent'>
           <button className='standard_btn'>Plus</button>
           {/* <h6>Na 5 dagen wordt je automatisch overgezet naar het standaard abonnement</h6> */}
           <h2>€50/PM</h2>
           <p>Bedoeld voor ervaren marketeers en grote bedrijven. 
Geeft toegang tot alle Starter en Standaard functies, plus extra woorden en premium functies voor optimale marketingresultaten.</p>
           </div>
           <div className='purchase_innerContent'>
           <ul>
            <div className='check_icon'>
            <CheckIcon/>
            <li>
            Alles opties uit Standaard
            </li>
            </div>
            <div className='check_icon'>
            <CheckIcon/>
            
            <li>Plus support ( antwoordt binnen 8 uur)</li>
            
            </div>
            <div className='check_icon'>
            <CheckIcon/>
            <li>70000 Woorden ( goed voor ongeveer 100 advertenties, 50 e-mails/nieuwsbrieven en 10 e-books))</li>
            </div>
         
         
           </ul>
           <div className='startBtn_outer'>
           <button className='startBtn cmn_btn'>Start <ArrowRightAltIcon/></button>

           </div>
           </div>
          </div>
          {/* tab4 */}
          <div className='purchase_outer'>
           <div className='purchase_innerContent'>
           <button className='standard_btn'>Pay for use </button>
           {/* <h6>Na 5 dagen wordt je automatisch overgezet naar het standaard abonnement</h6> */}
           <h2>€10/Per Bundel</h2>
           <p>Woordentegoed Model: Betalen per gebruik, ideaal voor projecten of variërende contentbehoeften.</p>
           </div>
           <div className='purchase_innerContent'>
           <ul>
            <div className='check_icon'>
            <CheckIcon/>
            <li>
            Alles opties uit  Starter
            </li>
            </div>
            <div className='check_icon'>
            <CheckIcon/>
            
            <li>8000 Woorden ( goed voor ongeveer 15 advertenties, 8 digitlale nieuwsbrieven en 1 e-book)</li>
            
            </div>
            <div className='check_icon'>
            <CheckIcon/>
            <li>Chat geschiedenis automatisch bewaard </li>
            </div>
         
         
           </ul>
           <div className='startBtn_outer'>
           <Button
           component={Link}
           to={
             auth.user
               ? 'Starter'
                 ? `/purchase/Starter`
                 : RoutePath.dashboard
               : 'Starter'
               ? `/auth/signup?next=/purchase/Starter`
               : RoutePath.auth
           }
           variant="contained"
           color="primary"
           size="large"
           fullWidth
           className='startBtn cmn_btn'>Start <ArrowRightAltIcon/></Button>

           </div>
           </div>
          </div>
          </div>
        </div>
       </div>
    
        </>
    )
}
export default DigiloPurchase