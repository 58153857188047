import React from 'react'
import { NeedsCreditsButton } from '../../hocs/needsCredits'
import CardContent from '@mui/material/CardContent'
import { GeneratorConfig } from '../../shared-types/generator.types'
import Button from '@mui/material/Button'
import EditableText from '../../components/EditableText'

interface GeneratorOutputProps {
  values: Record<string, string> | null
  output: string | null
  onRetry: () => void
  isRetrying: boolean
  generatorConfig: GeneratorConfig
  onBack: () => void
}

const styles = {
  root: {
    height: '100%',
    display: 'flex',
    justifyContent: 'stretch',
    flexDirection: 'column'
  },
  retryButton: {
    m: 2,
    alignSelf: 'center',
    px: 4
  },
  backButton: {
    display: { xs: 'block', md: 'none' },
    alignSelf: 'center'
  }
}

function GeneratorOutput({
  output,
  onRetry,
  values,
  isRetrying,
  generatorConfig,
  onBack
}: GeneratorOutputProps) {
  return (
    <CardContent sx={styles.root}>
      <EditableText
        text={output}
        contentType={generatorConfig.name}
        isReadonly={!values || isRetrying}
      />
      <NeedsCreditsButton
        sx={styles.retryButton}
        color="secondary"
        variant="contained"
        type="submit"
        onClick={onRetry}
        disabled={!values || !output || isRetrying}
      >
        Nogmaals proberen
      </NeedsCreditsButton>

      <Button
        sx={styles.backButton}
        variant="contained"
        color="inherit"
        onClick={onBack}
      >
        Vorige
      </Button>
    </CardContent>
  )
}

export default GeneratorOutput
