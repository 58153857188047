import { useQuery } from 'react-query'
import {
  addDoc,
  collection,
  collectionGroup,
  deleteDoc,
  doc,
  getCountFromServer,
  limit,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where
} from 'firebase/firestore'
import { createQuery, db, useQueryPagination } from '../../util/db'
import { useState } from 'react'
import { UseQueryResult } from 'react-query/types/react/types'
import { ContentItem, ProjectItem } from '../../shared-types/db-types'

function getCollection(projectId: string) {
  return collection(db, 'projects', projectId, 'contents')
}

function getDoc(projectId: string, contentId: string) {
  return doc(getCollection(projectId), contentId)
}

export function useContent(
  id: string,
  projectId: string
): UseQueryResult<ProjectItem> {
  return useQuery(
    ['content', { id, projectId }],
    createQuery(() => getDoc(projectId, id)),
    { enabled: !!id }
  )
}

function contentByProjectQuery(projectId: string, owner: string) {
  return [
    !projectId ? collectionGroup(db, 'contents') : getCollection(projectId),
    where('owner', '==', owner)
  ] as const
}

export function useContentsByProject(
  projectId: string,
  owner: string,
  pageSize: number,
  page: number
) {
  const { pageQuery, updateQueryResult } = useQueryPagination<ContentItem>(
    page,
    (doc) => doc.createdAt
  )
  const queryResult = useQuery(
    ['contents', { owner, pageSize, pageQuery }],
    createQuery(() =>
      query(
        ...contentByProjectQuery(projectId, owner),
        orderBy('createdAt', 'desc'),
        ...pageQuery,
        limit(pageSize)
      )
    ),
    { enabled: !!owner }
  )
  return updateQueryResult(queryResult as UseQueryResult<ContentItem[]>)
}

export function createContent(
  projectId: string,
  data: Pick<ContentItem, 'owner' | 'name' | 'text'>
) {
  return addDoc(getCollection(projectId), {
    ...data,
    createdAt: serverTimestamp()
  })
}

export function updateContent(
  id: string,
  projectId: string,
  data: Partial<ContentItem>
) {
  return updateDoc(getDoc(projectId, id), data)
}

export function deleteContent(id: string, projectId: string) {
  return deleteDoc(getDoc(projectId, id))
}

export function useCountContents(projectId: string | null, owner: string) {
  const [invalidateCount, setInvalidateCount] = useState<number | null>(null)
  return {
    ...useQuery(
      ['contentsCount', { owner, invalidateCount }],
      async () => {
        const query_ = query(...contentByProjectQuery(projectId, owner))
        const snapshot = await getCountFromServer(query_)
        return snapshot.data().count
      },
      { enabled: !!owner, keepPreviousData: true }
    ),
    invalidateCount: () => setInvalidateCount(Date.now())
  }
}
