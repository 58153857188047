import { streamApiRequest } from '../../util/requests'
import { parseAnswer } from '../ai-assistance/text-generate-api'

export async function digiloGeneratorApi(
  path : string,
  values: Record<string, string>,
  callback: (message: string) => void
): Promise<void> {
  let openAnswer = ''
  await streamApiRequest(
    path,
    'backend',
    'POST',
    values,
    (data) => {
      openAnswer += parseAnswer(data)
      callback(openAnswer.trim())
    }
  )
}
