import React from 'react'
import { requireAuth } from '../../util/auth.js'

import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'
import { RoutePath } from '../../util/routes.js'
import AppLayout from '../AppLayout.jsx'
import Box from '@mui/material/Box'
import { useSelectedProject } from '../../hooks/useRouterState.jsx'
import { ArrowBack } from '@mui/icons-material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid'; 
import Card from '@mui/material/Card';
import {  Backdrop } from '@material-ui/core';
import { getUseOptions,saveExperiencemodal } from '../../util/db'
import { Dispatch, SetStateAction } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TextField from '@mui/material/TextField';
import { useAuth } from '../../util/auth.js'


const styles = {
  startButton: {
    px: 5
  },
  header: {
    display: {
      xs: 'none',
      sm: 'block'
    }
  },
  modalstyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    border: 'unset',
    boxShadow: 24,
    p: 4,
    borderRadius:'6px',
    overflow:{xs:'scroll', lg:'unset'},
    height:{xs:'100%',lg:"auto"},
    display:'block',

    '&:focus-visible': {
      outline: 'none'
    },
  },
  defaultmodalstyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width:  { xs: '98%', lg: '30%', sm:"60%"  },
    bgcolor: 'background.paper',
    border: 'unset',
    boxShadow: 24,
    borderRadius: '6px',
    p: 4,
    // overflow:{xs:'scroll', lg:'unset'},
    // height:{xs:'100%',lg:"auto"},
    display: 'block',

    '&:focus-visible': {
        outline: 'none'
    },
},
  drop:{
  
    borderRadius: "6px", 
    boxShadow:"0px 0px 0px 3px rgb(235 103 105 / 35%)",
    border:"2px solid #eb6769",
    // '&:focus-visible': {
    //   outline: 'none'
    // },
    '&:hover': {
    border:"none"
    },
  },
  headings: {
    fontSize: "1.625rem",
    lineHeight: "2.5rem"
  },
  subtitle: {
    fontSize: 12,
    color: "#9095a0"
  },
  card_text: {
    fontSize: 14
  },
  card: {
    boxShadow: "0px 0px 5px rgba(0,0,0,15%)",
    padding: 5,
    border: "1px solid transparent",
    height:"100%",
    cursor : "pointer"
  },
  cmn_btn: {
    color: "#ef9834",
    borderColor: "#ef9834",
    fontSize:"12"
  },
  btn_outer: {
    textAlign: "right" as const,
    marginTop: "2rem" as const
  },
  iconPostions:{
   position:"absolute" as const
  },
  card_desc:{
    fontSize:12
  },
  active: {
    border: '1px solid #1278bc'
  },
  icons:{
    margin:'-6px'
  },
  title: {
    fontSize: 14
},

}

type experienceEndProps = {
  handleExperienceEnd : () => void,
  handleFeedbackModalThree : () => void,
  setExperienceEndId : Dispatch<SetStateAction<string>>,
  setExperienceEndName : Dispatch<SetStateAction<string>>,
  setExperienceEndCode : Dispatch<SetStateAction<number>>
  setPaymentModal : Dispatch<SetStateAction<boolean>>
  handleExperienceEndBack : () => void
}

function ExperienceEnd(props : experienceEndProps) {
  const auth=useAuth()
  const [open, setOpen] = React.useState(true);
  const [disable,setDisable] = React.useState(true)
  const [data, setData] = React.useState([]);
  // console.log('date--test--',data)
  const handleOpen = () => setOpen(true);
  const [newvalue,setnamevalue]=React.useState("")
  const [openDefalt, setOpenDefault] = React.useState(false)
  const handleDefalutClose = () => setOpenDefault(false);
  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
        setOpen(false)
      }
}

React.useEffect(() => {
  getdata()
},[])
// fetching information from the use_options collection from firebase
const getdata = async () => {
  const data = await getUseOptions()
  setData(data)
}

const handleSetValues = (id,name,code) => {
  if(name === "Anders"){
    setOpenDefault(true)
  }
  if( id !== "" && name !== "" && code !== null) {
     setDisable(false)
  }
  props.setExperienceEndCode(code),
  props.setExperienceEndId(id),
  props.setExperienceEndName(name)
  const index = data.findIndex(el => el.id === id)
  if(index !== -1){
   // make all styles false.
   for(let i=0;i<data.length;i++){
       if(data[i].style){
          data[i].style = false;
      }
   }
   data[index]["style"] = true;
  }
}


const handlemodalSubmit =async()=>{
  const data={
    user_id:auth?.user?.id,
    modal:"3rd",
    name:newvalue
  }
  const response= await saveExperiencemodal(data)
  setOpenDefault(false);
  props.handleExperienceEnd()
}

  

  return (
   
      <div>
      <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          keepMounted
        >
          <Box sx={styles.modalstyle}>
           <Button onClick = {props.handleExperienceEndBack}> <ArrowBackIcon/></Button>
            <Grid container alignItems="center" justifyContent="center">
              <Grid item xs={12} md={12} lg={8} >

                <Typography sx={{mb:4}} style={styles.headings} id="modal-modal-title" align="center" variant="h2" component="h2">
                Waarvoor  wil je Digilo gebruiken?

                </Typography>
                <Grid container spacing={2} rowSpacing={3} >
                  {
                    // use map function to reflect data on the modals
                    data.length !== 0 &&
                    data.map((options,index) => {
                      return <Grid item xs={12} md={6} lg={4} sm={6} key = {index}>
                      <Card style={{...styles.card , ...(options.style == true && styles.active)}} variant="outlined" onClick = {() => handleSetValues(options.id,options.name,options.code)}>
                       <Box style={styles.iconPostions} >
                         {options?.icon === "E-commerce.png" && <img style={styles.iconPostions}  src="/img/E-commerce.png"/>}
                         {options?.icon === "Marketing.png" && <img style={styles.iconPostions}  src="/img/Marketing.png"/>}
                         {options?.icon === "Social_media.png" && <img style={styles.iconPostions}  src="/img/Social_media.png"/>}
                         {options?.icon === "Creatief_schrijven.png" && <img style={styles.iconPostions}  src="/img/Creatief_schrijven.png"/>}
                         {options?.icon === "Onderwijs.png" && <img style={styles.iconPostions}  src="/img/Onderwijs.png"/>}
                         {options?.icon === "Zakelijk.png" && <img style={styles.iconPostions}  src="/img/Zakelijk.png"/>}
                         {options?.icon === "Juridisch.png" && <img style={styles.iconPostions}  src="/img/Juridisch.png"/>}
                         {options?.icon === "Academisch.png" && <img style={styles.iconPostions}  src="/img/Academisch.png"/>}
                         {options?.icon === "Fitness.png" && <img style={styles.iconPostions}  src="/img/Fitness.png"/>}
                         {options?.icon === "Anders.png" && <img style={styles.iconPostions}  src="/img/Anders.png"/>}
                         {options?.icon === "Financieel.png" && <img style={styles.iconPostions}  src="/img/Financieel.png"/>}
                         {options?.icon === "Content_creatie.png" && <img style={styles.iconPostions}  src="/img/Content_creatie.png"/>}
                       </Box>
                        <Typography style={styles.card_text} id="modal-modal-description" align="center">
                        {options?.name}
                        </Typography>
                        <Typography style={styles.card_desc} sx={{ mb:1, mt:1 }} id="modal-modal-description" align="center">
                        {options?.description}
                        </Typography>
                      </Card>
                    </Grid> 
                    })
                  }

                </Grid>            
              </Grid>
            </Grid>
              <div style={styles.btn_outer}>
              <Typography style={styles.card_text} id="modal-modal-description" align="center" >
                      3/4
                        </Typography>
              <Button variant="outlined" style={styles.cmn_btn} onClick = {props.handleExperienceEnd} disabled={disable}>Volgende<ArrowRightIcon style={styles.icons} /></Button>
              </div>
          </Box>
        </Modal>
    
        <Modal
                open={openDefalt}
                onClose={handleDefalutClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styles.defaultmodalstyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                    Noteer hier je eigen keuze.
                    </Typography>
                    <TextField sx={{ mt: 3, mb: 3 }} id="outlined-basic" label="Bericht" variant="outlined" multiline
                        maxRows={4} fullWidth value={newvalue} onChange={(e)=>setnamevalue(e.target.value)} />
                    <Button variant="outlined" onClick={handlemodalSubmit} >Verstuur</Button>
                </Box>
            </Modal>
      </div>

  )
}

export default ExperienceEnd;
