import React from 'react'
import Section from './Section'
import SectionHeader from './SectionHeader'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { Stack } from '@mui/material'
import Typography from '@mui/material/Typography'

const styles = {
  logo: {
    m: {
      xs: 0.5,
      md: 2
    }
  },
  counter: {
    padding: "100px 0px",
    backgroundColor: "#66C1FF4F"
  },
  counterHeading: {
    fontSize: {
      xs: "22px",
      md: "28px",
      lg: "40px"
    },
    textAlign:"center",
    fontWeight: "700"
  },
  counterText: {
    color: "#565e6c",
    fontWeight: "600",
    fontSize: {
      sm: "16px",
      md: "22px",
      lg: "32px"
    },
    textAlign:"center",
  },
}

function ClientsSection(props) {
  const items = [
    {
      name: 'Sano Academy',
      image: '/img/clients/sano-logo.png',
      width: '140px'
    },
    {
      name: 'Studio Beeldtaal',
      image: '/img/clients/beeldtaal-logo.png',
      width: '140px'
    },
    {
      name: 'FMB Digital Online',
      image: '/img/clients/fmb-logo.png',
      width: '120px'
    },
    {
      name: 'Woombe',
      image: '/img/clients/woombe-logo.png',
      width: '80px',
      p: {
        xs: 1,
        md: 0
      }
    },
    {
      name: 'Salon Webshop',
      image: '/img/clients/salon-logo.png',
      width: '70px',
      p: {
        xs: 1,
        md: 0
      }
    }
  ]

  return (
    <>
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <Box textAlign="center">
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            size={2}
            textAlign="center"
            sx={{color:"#9095A0FF !important"}}
          />
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            {items.map((item, index) => (
              <Box
                key={index}
                sx={{
                  ...styles.logo,
                  width: { xs: '20%', sm: item.width },
                  p: item.p
                }}
              >
                <img src={item.image} width="100%" alt={item.name} />
              </Box>
            ))}
          </Stack>
        </Box>
        <Typography sx={{color:"#9095A0FF",fontWeight:"700",textAlign:"center",marginTop:"15px"}}>
        + Tientallen andere bedrijven, freelancers, marketeers, trainers, professionals en studenten    
        </Typography>
      </Container>
    </Section>
    <Box style={styles.counter}>
        <Container>
          <Stack
           direction={{ xs: 'column', sm: 'row' }}
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Box sx={{width:{sm:"auto",xs:"100%"}}}>
              <Typography variant="h6" component="h6" sx={styles.counterHeading}>
                2000 +
              </Typography>
              <Typography sx={styles.counterText}>
                Gebruikers
              </Typography>
            </Box>
            <Box sx={{width:{sm:"auto",xs:"100%"}}}>
              <Typography variant="h6" component="h6" sx={styles.counterHeading}>
                10 X
              </Typography>
              <Typography sx={styles.counterText}>
                Sneller teksten maken
              </Typography>
            </Box>
            <Box sx={{width:{sm:"auto",xs:"100%"}}}>
              <Typography variant="h6" component="h6" sx={styles.counterHeading}>
                97/10
              </Typography>
              <Typography sx={styles.counterText}>
                Tevreden klanten
              </Typography>
            </Box>

          </Stack>
        </Container>
      </Box>
    </>
  )
}

export default ClientsSection
