import React, { ReactNode } from 'react'
import Navbar from '../components/Navbar'
import IndexPage from './index'
import HelpPage from './app/help.js'
import ContactPage from './contact'
import PricingPage from './pricing'
import DashboardPage from './app/dashboard'
import AuthPage from './auth'
import SettingsPage from './app/settings.js'
import LegalPage from './legal'
import WriterPage from './app/writer'
import AssistancePage from './app/assistance'
import { Route, Router, Switch, useLocation } from '../util/router'
import PurchasePage from './purchase'
import FirebaseActionPage from './firebase-action'
import NotFoundPage from './404'
import Footer from '../components/Footer'
import '../util/analytics'
import { AuthProvider } from '../util/auth'
import { ThemeProvider } from '../util/theme'
import { QueryClientProvider, get_about_options } from '../util/db'
import { RoutePath } from '../util/routes'
import AboutPage from './about'
import AddBlog from './app/addBlog'
import Blog from './blog'
import BlogTemplate from './blogTemplate'
import EditBlog from './app/editBlog'
import CreateBlog from './app/createBlog'
import { StyledEngineProvider } from '@mui/styled-engine' 
import Generator from './app/generator'
import Library from './app/library'
import Improver from './app/improver'
import NewUserPopup from '../components/NewUserPopup'
import ExitPopup from '../components/ExitPopup/ExitPopup'
import FeedbackButton from '../components/FeedbackPopup/FeedbackButton'
import adminLogin from '../Admin/AdminLogin'
import adminUsers from '../Admin/AdminUsers'
import aboutOptions from '../Admin/AboutOptions'
import adminRecommendedoptions from '../Admin/AdminRecommendedOptions'
import adminUseoptions from '../Admin/AdminUseoptions'
import adminTextinputoptions from '../Admin/AdminTextinputoptions'
import AdminUserTextOptions from '../Admin/AdminUserTextOptions'
import { useAuth } from '../util/auth'
import AdminCustomOptions from '../Admin/AdminCustomOptions'
import OpenAi from "../components/openAi/OpenAi"
import DigiloFixAi from "./app/digiloFixAi"
import './app.css'
import Thankyou from './thankyou'



import DigiloPurchase from '../purchase digilo/digiloPurchase'

function App() {
  
  const [location_info, setLocation_info] = React.useState(false)




  React.useEffect(() => {
    if (location.pathname === '/admin') {
      setLocation_info(true)
    } else {
      setLocation_info(false)
    }
  }, [])



  return (
    <QueryClientProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider>
          <AuthProvider>
            <Router>
              {!location_info ?
                <>
                  <ConditionalHeader>
                    <Switch>
                      <Route exact path={RoutePath.root} component={IndexPage} />

                      <Route exact path={RoutePath.help} component={HelpPage} />

                      <Route
                        exact
                        path={RoutePath.contact}
                        component={ContactPage}
                      />
                      <Route
                        exact
                        path={RoutePath.addBlog}
                        component={AddBlog}
                      />
                      <Route
                        exact
                        path={RoutePath.editBlog}
                        component={EditBlog}
                      />
                      <Route
                        exact
                        path={RoutePath.createBlog}
                        component={CreateBlog}
                      />
                      <Route
                        exact
                        path={RoutePath.blog}
                        component={Blog}
                      />
                      <Route
                        exact
                        path={RoutePath.thankyou}
                        component={Thankyou}
                      />
                      <Route
                        exact
                        path={RoutePath.blogTemplate}
                        component={BlogTemplate}
                      />
                       <Route
                        exact
                        path={RoutePath.openAi}
                        component={OpenAi}
                      />
                       <Route
                        exact
                        path={RoutePath.digiloFixAi}
                        component={DigiloFixAi}
                      />

                      <Route
                        exact
                        path={RoutePath.pricing}
                        component={PricingPage}
                      />

                      <Route exact path={RoutePath.about} component={AboutPage} />

                      <Route exact path={RoutePath.library} component={Library} />

                      <Route
                        exact
                        path={RoutePath.improver}
                        component={Improver}
                      />


                      <Route
                        exact
                        path={RoutePath.generator}
                        component={Generator}
                      />

                      <Route
                        exact
                        path={[
                          RoutePath.dashboard,
                          RoutePath.app,
                          RoutePath.project
                        ]}
                        component={DashboardPage}
                      />

                      <Route exact path={RoutePath.auth} component={AuthPage} />

                      <Route
                        exact
                        path={RoutePath.settings}
                        component={SettingsPage}
                      />

                      <Route exact path={RoutePath.legal} component={LegalPage} />

                      <Route
                        exact
                        path={RoutePath.assistance}
                        component={AssistancePage}
                      />
                      <Route
                        exact
                        path={RoutePath.writer}
                        component={WriterPage}
                      />

                      <Route
                        exact
                        path={RoutePath.purchase}
                        component={PurchasePage}
                      />

                      <Route
                        exact
                        path={RoutePath.firebaseAction}
                        component={FirebaseActionPage}
                      />
                      
                      <Route
                        exact path={RoutePath.AdminUsers} component={adminUsers}
                      />
                      <Route
                        exact path={RoutePath.AboutOptions} component={aboutOptions}
                      />
                      <Route
                        exact path={RoutePath.AdminRecommendedOptions} component={adminRecommendedoptions}
                      />
                      <Route
                        exact path={RoutePath.AdminUseoptions} component={adminUseoptions}
                      />
                      <Route
                        exact path={RoutePath.AdminTextinputoptions} component={adminTextinputoptions}
                      />
                      <Route
                        exact path={RoutePath.AdminUserTextOptions} component={AdminUserTextOptions}
                      />
                      <Route
                        exact path={RoutePath.AdminCustomOptions} component={AdminCustomOptions}
                      />
                         <Route
                        exact path={RoutePath.DigiloPurchase} component={DigiloPurchase}                        
                      />
                      <Route component={NotFoundPage} />
                    </Switch>
                  </ConditionalHeader>

                  <ConditionalFooter />

                </>
                :
                <>
                  <Route
                    exact path={RoutePath.AdminLogin} component={adminLogin}
                  />

                </>
              }
            </Router>
          </AuthProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </QueryClientProvider>
  )
}

function ConditionalHeader({ children }: { children: ReactNode }) {
  const isApp = useIsApp()
  if (isApp) {
    return (
      <>
        <NewUserPopup />
        <ExitPopup />
        <FeedbackButton />
        {children}
      </>
    )
  } else {
    return (
      <>
        <Navbar
          color="default"
          logo="/img/digilo-logo.png"
          logoInverted="/img/digilo-logo-inverted.png"
        />
        {children}
      </>
    )
  }
}

function ConditionalFooter() {
  const isApp = useIsApp()
  return !isApp ? (
    <Footer
      bgColor="default"
      size="medium"
      bgImage=""
      bgImageOpacity={1}
      copyright={`© ${new Date().getFullYear()} Digilo`}
      logo="/img/digilo-logo.png"
      logoInverted="/img/digilo-logo-inverted.png"
      sticky
    />
  ) : null
}

function useIsApp() {
  const location = useLocation()
  return !!location.pathname.startsWith(RoutePath.app)
}

export default App
