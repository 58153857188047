import React from 'react'
import Meta from '../components/Meta'
import Typography from '@mui/material/Typography'
import Contact from '../components/Contact'
import { Container, Grid } from '@mui/material'

const styles = {
  root: {
    alignItems: 'center',
    textAlign: 'center',
    paddingBottom:"70px"
  },
  digilo: {
    px: 2,
    pb: 4,
    display: { xs: 'none', md: 'flex' },
    flexDirection: 'column',
    justifyContent: 'center'
  },
  title: {
    my: 4
  },
  subtitle: {
    my: 4,
    color: 'grey.600'
  }
}

function ContactPage(props) {
  return (
    <>
      <Meta title="Contact"  />
      <Container sx={styles.root}>
        <Typography variant="h4" sx={styles.title}>
          Waarmee kunnen we je helpen?
        </Typography>

        <Typography variant="h6" sx={styles.subtitle}>
          Vul het formulier in
        </Typography>

        <Grid container>
          <Grid item xs={0} md={3}></Grid>
          <Grid item xs={12} md={6}>
            <Contact showNameField={true} />
          </Grid>
          <Grid item xs={0} md={3} sx={styles.digilo}>
            <img src="/img/contact_us.png" style={{ width: '100%' }} />
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default ContactPage
