import React, { useState, useEffect } from 'react'
import { Container } from '@mui/material'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { Box } from '@material-ui/core'
import AddIcon from '@mui/icons-material/Add'
import { useHistory } from '../../util/router'
import { get_all_blogs } from '../../util/db'
import { delete_blog } from '../../util/db'
import CircularProgress from '@mui/material/CircularProgress'
import AppLayout from '../../components/AppLayout'
import MainPanel from '../../components/MainPanel'
import MainPanelHeader from '../../components/MainPanelHeader'
import requirePlan from '../../hocs/requirePlan'
import { requireAuth } from '../../util/auth'
const styles = {

  title: {
    my: 4,
    textAlign: 'left'
  },
  subtitle: {
    my: 4,
    color: 'grey.600'
  },
  cmn_bg: {
    backgroundColor: '#EF9834FF',   
  },
  cmn_border: {
    borderColor: '#EF9834FF',
    color: '#EF9834FF'
  },
  btns: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}
const AddBlog = () => {
  return (
      <AppLayout title='Add Blog'>
          <MainPanel
               header={<MainPanelHeader/>}
              main={<AddBlogMain />}
          />
      </AppLayout>
  )
}
const AddBlogMain = () => {
  const history = useHistory()
  const [blog_data, setBlog_data] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    get_blogs()
  }, [])

  const get_blogs = async () => {
    setLoading(true)
    const data = await get_all_blogs()
    if (data) {
      setLoading(false)
      setBlog_data(data)
    }
  }

  const handle_delete = async (id) => {
    const data = await delete_blog(id)
    get_blogs()
  }

  return (
    <Box>
        <Box style={styles.btns}>
          <Typography variant="h4" sx={styles.title}>
            Add Blogs
          </Typography>
          <Button
            onClick={() => {
              // Go to the next page in the app when the button is clicked
              history.push('/app/createBlog')
            }}
            variant="contained"
            style={styles.cmn_bg}
            endIcon={<AddIcon />}
          >
            Add Blog
          </Button>
        </Box>
      
       
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell align="left" sx={{width:"90%"}}>Title</TableCell>
                  <TableCell align="left">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className='position-relative'>
                {loading ? (
                  <Box className='loader_table'>
                    <CircularProgress />
                  </Box>
                ) : (
                  blog_data?.map((blog, i) => {
                    return (
                      <TableRow
                        key={i}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 }
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {i + 1}
                        </TableCell>
                        <TableCell align="left">{blog?.title}</TableCell>
                        <TableCell align="left">
                          <Box style={styles.btns}>
                            <Button
                              variant="contained"
                              style={styles.cmn_bg}
                              endIcon={<EditIcon />}
                              onClick={() =>
                                history.push(`/app/editBlog/${blog?.url}`)
                              }
                            >
                              Edit
                            </Button>
                            <Button
                              variant="outlined"
                              style={styles.cmn_border}
                              startIcon={<DeleteIcon />}
                              onClick={() => handle_delete(blog?.id)}
                            >
                              Delete
                            </Button>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
     
      </Box>

  )
}

export default requireAuth(requirePlan(AddBlog, 'premium'))