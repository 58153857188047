import React, { useEffect } from 'react'
import { requireAuth } from '../../util/auth.js'
import MainPanel from '../../components/MainPanel'
import MainPanelHeader from '../../components/MainPanelHeader'
import ProjectContent from '../../features/projects/ProjectContent'
import ProjectsOverview from '../../features/projects/ProjectsOverview'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'
import { RoutePath } from '../../util/routes'
import AppLayout from '../../components/AppLayout'
import Box from '@mui/material/Box'
import { useSelectedProject } from '../../hooks/useRouterState'
import { ArrowBack } from '@mui/icons-material'
import Modal from '@mui/material/Modal';
import MenuDialog from '../../components/Popups/MenuDialoge'
import Experience from '../../components/Popups/Experience'
import ExperienceStart from '../../components/Popups/ExperienceStart'
import ExperienceEnd from '../../components/Popups/ExperienceEnd'
import { useAuth } from "../../util/auth.js";
import { useHistory } from 'react-router-dom'
import FeedbackPopup from '../../components/FeedbackPopup/FeedbackPopup'
import Payment from '../../components/Popups/Payment'

const styles = {
  startButton: {
    px: 5
  },
  header: {
    display: {
      xs: 'none',
      sm: 'block'
    }
  },
  modalstyle: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: "500px",
    bgcolor: 'background.paper',
    border: 'unset',
    boxShadow: 24,
    p: 4,
    overflow: { xs: 'scroll', lg: 'unset' },
    height: { xs: '100%', lg: "auto" },
    display: 'block',
    textAlign: "center",
    borderRadius: "10px",
    '&:focus-visible': {
      outline: 'none'
    },
  },
  drop: {
    borderRadius: "6px",
    boxShadow: "0px 0px 0px 3px rgb(235 103 105 / 35%)",
    border: "2px solid #eb6769",
    // '&:focus-visible': {
    //   outline: 'none'
    // },
    '&:hover': {
      border: "none"
    },
  },
  headings: {
    fontSize: "1.625rem",
    lineHeight: "2.5rem"
  },
  subtitle: {
    fontSize: 12,
    color: "#9095a0"
  },
  card_text: {
    fontSize: 14
  },
  card: {
    boxShadow: "0px 0px 5px rgba(0,0,0,15%)",
    padding: 10,
    border: "unset",
    height: "100%"
  },
  cmn_btn: {
    color: "#ef9834",
    borderColor: "#ef9834"
  },
  btn_outer: {
    textAlign: "right" as "right",
    marginTop: "2rem"
  },
  iconPostions: {
    position: "absolute" as "absolute"
  },
  card_desc: {
    fontSize: 12
  }

}



function DashboardPage() {
  const history = useHistory()
  const { projectId, setProjectId } = useSelectedProject()
  const [open, setOpen] = React.useState(false);
  const [experienceStart, setExperienceStart] = React.useState(true)
  const [experience, setExperience] = React.useState(false)
  const [experienceEnd, setExperienceEnd] = React.useState(false)
  const [menuDialog, setMenuDialog] = React.useState(false)
  const [menuInformal, setMenuInformal] = React.useState(false)
  const [menuFormeel, setMenuFormeel] = React.useState(false)
  const [commercial, setCommercial] = React.useState(false)
  const [watenDialog, setWatenDialog] = React.useState(false)
  const [experienceStartId, setExperienceStartId] = React.useState("")
  const [experienceStartName, setExperienceStartName] = React.useState("")
  const [experienceId, setExperienceId] = React.useState("")
  const [experiencename, setExperienceName] = React.useState("")
  const [experienceEndId, setExperienceEndId] = React.useState("")
  const [experienceEndName, setExperienceEndName] = React.useState("")
  const [experienceEndCode, setExperienceEndCode] = React.useState(null)
  const [menuDialogId, setMenuDialogId] = React.useState("");
  const [menuDialogName, setMenuDialogName] = React.useState("")
  const [newuservideo, setNewUserVideo] = React.useState(false)
  const [stateTrue, setStateTrue] = React.useState(false)
  const [feedbackopen, setFeedbackOpen] = React.useState(false)
  const [feedbackModalOne, setfedbackmodalone] = React.useState(false)
  const [paymentModal, setPaymentModal] = React.useState(false)
  const [enablePrice, setEnablePrice] = React.useState(false)
  const [newPopPrice, setNewPopPrice] = React.useState(false)
  const [congratulation, setCongratulation] = React.useState(false)

  const auth = useAuth();
  
  useEffect(() => {
    const SignUpTime = auth?.user?.created?.seconds;
    const timestampInMilliseconds = SignUpTime;
    const newTimestampInMilliseconds = timestampInMilliseconds;
    let date: any = new Date(newTimestampInMilliseconds * 1000);
    let currentTime: any = new Date();
    var timeDifference = currentTime - date;
    var time_in_seconds = timeDifference / 1000;
    var time_in_minutes = time_in_seconds / 60;
    // console.log(time_in_minutes)
    if (time_in_minutes > 30) {
      setNewPopPrice(true)
    }
  },[auth]);

  // useEffect(() => {
  //   let intervalId; // Declare the interval variable

  //   const checkTimeAndSetPopPrice = () => {
  //     const SignUpTime = auth?.user?.created?.seconds;

  //     if (SignUpTime) {
  //       let date: any = new Date(SignUpTime * 1000);
  //       let currentTime: any = new Date();
  //       var timeDifference = currentTime - date;
  //       var time_in_seconds = timeDifference / 1000;
  //       var time_in_minutes = time_in_seconds / 60;
  //       console.log(time_in_minutes);

  //       if (time_in_minutes > 2) {
  //         setNewPopPrice(true);
  //       }
  //     }
  //   };

  //   // Set up the interval and save the ID in the intervalId variable
  //   intervalId = setInterval(checkTimeAndSetPopPrice, 1000); // Run every second

  //   // Clear the interval when the component is unmounted
  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, [auth]);


  // const [dataArray , setDataArray] = useState([])
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [age, setAge] = React.useState('');

  const handleExperienceStart = () => {
    setExperienceStart(false)
    setExperience(true)

  }

  const handleExperience = () => {
    setExperience(false)
    setExperienceEnd(true)
  }


  const hanldeExperienceEnd = () => {
    setExperienceEnd(false)
    setMenuDialog(true)
  }

  //send user submitted options to the user_text_inputs collection and storing only id's
  const handleMenuDialog = () => {
    setMenuDialog(false)
    setFeedbackOpen(true)
    setfedbackmodalone(true)
    setEnablePrice(true)
    setCongratulation(true)



  }
  const handleFeedbackModal = () => {
    setFeedbackOpen(true)
  }

  const handleFeedbackModalTwo = () => {
    setFeedbackOpen(true)
  }

  const handleFeedbackModalThree = () => {
    setFeedbackOpen(true)
  }

  const handleExperienceBack = () => {
    setExperience(false)
    setExperienceStart(true)
  }

  const handleExperienceEndBack = () => {
    setExperience(true)
    setExperienceEnd(false)
  }

  const handleMenuDialogBack = () => {
    setExperienceEnd(true)
    setMenuDialog(false)
  }





  const mainContent =
    projectId != null ? (
      <ProjectContent selectedProject={projectId} />
    ) : (
      <ProjectsOverview setSelectedProject={setProjectId} />
    )

  const navigationButton =
    projectId != null ? (
      <Button
        sx={styles.startButton}
        color="secondary"
        variant="contained"
        onClick={() => setProjectId(null)}
      >
        <ArrowBack />
      </Button>
    ) : (
      <Button
        sx={styles.startButton}
        color="secondary"
        variant="contained"
        component={Link}
        to={RoutePath.library}
      >
        Bibliotheek
      </Button>
    )

  return (
    <AppLayout title="Dashboard">
      <MainPanel
        header={
          <Box sx={styles.header}>


            <MainPanelHeader additional={navigationButton} />
          </Box>
        }
        main={mainContent}
      />


      {/* send props to the experienceStartModal */}
      {experienceStart && auth.user.info_completed == false ? <ExperienceStart handleExperienceStart={handleExperienceStart}
        setExperienceStartId={setExperienceStartId}
        setExperienceStartName={setExperienceStartName}
        handleFeedbackModal={handleFeedbackModal}

      /> : null}
      {/* sending props to the experience modal  */}
      {experience && auth.user.info_completed == false ? <Experience handleExperience={handleExperience}
        setExperienceName={setExperienceName}
        setExperienceId={setExperienceId}
        handleFeedbackModalTwo={handleFeedbackModalTwo}
        handleExperienceBack={handleExperienceBack}

      /> : null}
      {/* sending props to experience modal */}
      {experienceEnd && auth.user.info_completed == false ? <ExperienceEnd handleExperienceEnd={hanldeExperienceEnd}
        setExperienceEndId={setExperienceEndId}
        setExperienceEndCode={setExperienceEndCode}
        setExperienceEndName={setExperienceEndName}
        handleFeedbackModalThree={handleFeedbackModalThree}
        handleExperienceEndBack={handleExperienceEndBack}
        setPaymentModal={setPaymentModal}

      /> : null}
      {/* sending props to the menudialog modal */}
      {menuDialog && auth.user.info_completed == false ? <MenuDialog handleMenuDialog={handleMenuDialog}
        experienceEndCode={experienceEndCode}
        setMenuDialogId={setMenuDialogId}
        setMenuDialogName={setMenuDialogName}
        experienceStartId={experienceStartId}
        experienceId={experienceId}
        experienceEndId={experienceEndId}
        handleMenuDialogBack={handleMenuDialogBack}
        setMenuDialog={setMenuDialog}
        setPaymentModal={setPaymentModal}
        setEnablePrice={setEnablePrice}
        setCongratulation={setCongratulation}
      /> : null}

      {congratulation ? 
        <Modal
          open={congratulation}
          onClose={() => setCongratulation(false)}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={styles.modalstyle}>
            <h3 id="child-modal-title" style={{ marginBottom: "20px" }}>Geniet 30 minuten van de mogelijkheden die Digilo te bieden heeft. Daarna kun je een proefperiode van 5 dagen starten door de optie 'Starter' te kiezen.</h3>

            <Box sx={{ textAlign: "center" }}>
              <Button color="secondary"
                variant="contained" sx={styles.startButton} onClick={() => {setCongratulation(false) ; history.push('/app/assistance')}}>Sluiten</Button>
            </Box>
          </Box>
        </Modal>
         : null}

      {/* {
        //  paymentModal && auth.user.credits === 0 ||  auth.user.stripeSubscriptionStatus !== 'active' ?  <Payment /> : null
         auth.user.paymentType === "subscription" ? (paymentModal && auth.user.stripeSubscriptionStatus != 'trialing' && auth.user.credits != 0) ? <Payment /> : (paymentModal && auth.user.stripeSubscriptionStatus != 'active' && auth.user.credits != 0) ? <Payment /> : null : auth.user.paymentStatus != "succeeded" && paymentModal && auth.user.credits != 0  ? <Payment /> : null
      }  */}


      {newPopPrice && !auth.user.paymentType && auth.user.stripeSubscriptionStatus === undefined && auth.user.paymentStatus === undefined && auth?.user?.role != 'admin' &&
        <Payment />}
      {/* //  paymentModal && auth.user.credits === 0 ||  auth.user.stripeSubscriptionStatus !== 'active' ?  <Payment /> : null */}
      {newPopPrice && auth.user.paymentType === "subscription" ? auth.user.stripeSubscriptionStatus == 'trialing' && !paymentModal ? null : auth.user.stripeSubscriptionStatus == 'active' && !paymentModal ? null : auth.user.stripeSubscriptionStatus == 'cancelled' ? <Payment /> : auth.user.stripeSubscriptionStatus == 'pause' && !paymentModal ? <Payment /> : null : newPopPrice && auth.user.paymentStatus != "succeeded" && paymentModal && auth.user.credits != 0 ? <Payment /> : null

      }





      <div>
        <FeedbackPopup onClose={handleClose} open={feedbackopen} setFeedbackOpen={setFeedbackOpen} setMenuDialog={setMenuDialog} feedbackModalOne={feedbackModalOne}
          setExperienceStart={setExperienceStart} />

      </div>
    </AppLayout>
  )
}

export default requireAuth(DashboardPage)
