import { apiRequest } from '../../util/requests'
import { WriterState } from './ai-writer.model'

export async function generateTitleApi(state: WriterState): Promise<string[]> {
  const result = await apiRequest('article/title', 'backend', 'POST', state)
  return result.results
}

export async function generateDescriptionApi(
  state: WriterState
): Promise<string[]> {
  const result = await apiRequest(
    'article/description',
    'backend',
    'POST',
    state
  )
  return result.results
}

export async function generateOutlineApi(
  state: WriterState
): Promise<string[][]> {
  const result = await apiRequest('article/outline', 'backend', 'POST', state)
  return result.results
}

export async function generateArticleApi(
  state: WriterState
): Promise<string[]> {
  const result = await apiRequest(
    'article/generate',
    'backend',
    'POST',
    state
  )
  return result.results
}
