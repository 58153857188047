import React, { useEffect, useState } from 'react'
import AppLayout from '../components/AppLayout'
import MainPanel from '../components/MainPanel'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@material-ui/core';
import { get_user_inputs_data, getAllUsers, getUseOptions, getOptions, get_about_options, get_text_input_options } from "../util/db"
import { IndeterminateCheckBoxRounded } from '@mui/icons-material';
import requirePlan from '../hocs/requirePlan';
import { requireAuth } from '../util/auth';
const AdminUserTextOptions = () => {
    return (
        <AppLayout title='Adminuser'>
            <MainPanel

                main={<AdminUserTextOptionsMain />}
            />
        </AppLayout>
    )

}
const AdminUserTextOptionsMain = () => {
    const [data, setData] = useState([])
    const [userData, setUserData] = useState([])
    const [useOptions, setUseOptions] = useState([])
    const [recomendedOptions, setRecomendedOptions] = useState([])
    const [aboutOptions, setAboutOptions] = useState([])
    const [textOptions, setTextOptions] = useState([])

    useEffect(() => {
        getUserInputs()
        getusers()
        getAllUseOptions()
        getAllRecomendedOptions()
        getAllAboutOptions()
        getAllTextOptions()
    }, [])
    const getUserInputs = async () => {
        const getUSerData = await get_user_inputs_data()
        setData(getUSerData)
    }

    const getusers = async () => {
        const user_data = await getAllUsers()
        setUserData(user_data)
    }

    const getAllUseOptions = async () => {
        const use_options = await getUseOptions()
        setUseOptions(use_options)
    }
    const getAllRecomendedOptions = async () => {
        const recomended_options = await getOptions()
        setRecomendedOptions(recomended_options)
    }
    const getAllAboutOptions = async () => {
        const about_options = await get_about_options()
        setAboutOptions(about_options)
    }
    const getAllTextOptions = async () => {
        const text_options = await get_text_input_options()
        setTextOptions(text_options)
    }
const handleSearch = (e) =>{
    
}

    return (
        <>          
            <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 700 }}>Sr.no</TableCell>
                            <TableCell sx={{ fontWeight: 700 }} align="center">User Name</TableCell>
                            <TableCell sx={{ fontWeight: 700 }} align="center">Use Options</TableCell>
                            <TableCell sx={{ fontWeight: 700 }} align="center">Recomended options</TableCell>
                            <TableCell sx={{ fontWeight: 700 }} align="center">About options</TableCell>
                            <TableCell sx={{ fontWeight: 700 }} align="center">Text options</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data?.map((options, index1) => {
                                return (
                                    <>
                                        <TableRow key={index1}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {index1 + 1}
                                            </TableCell>
                                            <TableCell align="center" component="th" scope="row">
                                                {userData?.map((name, index) => {
                                                    if (name?.id == options?.user_id) {
                                                        return <span key={index}>{name?.name}</span>
                                                    }
                                                })}
                                            </TableCell>
                                            <TableCell align="center" component="th" scope="row">
                                                {useOptions?.map((name, index) => {
                                                    if (name?.id == options?.use_option_id) {
                                                        return <span key={index}>{name?.name}</span>
                                                    }
                                                })}
                                            </TableCell>
                                            <TableCell align="center" component="th" scope="row">
                                                {recomendedOptions?.map((name, index) => {
                                                    if (name?.id == options?.recomended_option_id) {
                                                        return <span key={index}>{name?.name}</span>
                                                    }
                                                })}
                                            </TableCell>
                                            <TableCell align="center" component="th" scope="row">
                                                {aboutOptions?.map((name, index) => {
                                                    if (name?.id == options?.about_option_id) {
                                                        return <span key={index}>{name?.name}</span>
                                                    }
                                                })}
                                            </TableCell>
                                            <TableCell align="center" component="th" scope="row">
                                                {textOptions?.map((name, index) => {
                                                    if (name?.id == options?.text_input_option_id) {
                                                        return <span key={index}>{name?.name}</span>
                                                    }
                                                })}
                                            </TableCell>

                                        </TableRow>
                                    </>
                                )
                            })
                        }

                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default requireAuth(requirePlan(AdminUserTextOptions, 'premium'))
