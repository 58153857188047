import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { FieldError, useForm } from 'react-hook-form'
import contact from '../util/contact'
import Typography from '@mui/material/Typography'
import { Alert } from '@mui/material'

const styles = {
  input: {
    textAlign: 'start'
  }
}

function Contact(props) {
  const [pending, setPending] = useState(false)
  const [formAlert, setFormAlert] = useState(null)
  const { handleSubmit, register, errors, reset } = useForm()

  const onSubmit = (data) => {
    // Show pending indicator
    setPending(true)

    contact
      .submit(data)
      .then(() => {
        // Clear form
        reset()
        // Show success alert message
        setFormAlert({
          type: 'success',
          message: 'Je bericht is verzonden!'
        })
      })
      .catch((error) => {
        // Show error alert message
        setFormAlert({
          type: 'error',
          message: error.message
        })
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false)
      })
  }

  return (
    <>
      {formAlert && (
        <Box mb={3}>
          <Alert severity={formAlert.type}>{formAlert.message}</Alert>
        </Box>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          {props.showNameField && (
            <>
              <Grid item xs={12} sm={6}>
                <ContactInput
                  id="firstName"
                  label="Naam"
                  placeholder="Vul je naam in"
                  error={errors.firstName}
                  inputRef={register({
                    required: 'Please enter your name'
                  })}
                ></ContactInput>
              </Grid>

              <Grid item xs={12} sm={6}>
                <ContactInput
                  id="lastName"
                  label="Achternaam"
                  placeholder="Vul je achternaam in"
                  error={errors.lastName}
                  inputRef={register({
                    required: 'Please enter your name'
                  })}
                ></ContactInput>
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <ContactInput
              id="email"
              label="Email"
              placeholder="Noteer je e-mail adres"
              error={errors.email}
              inputRef={register({
                required: 'Please enter your email'
              })}
            ></ContactInput>
          </Grid>
          <Grid item xs={12}>
            <ContactInput
              lines={3}
              id="message"
              label="Waarmee kunnen we je helpen?"
              placeholder="Noteer hier je vraag"
              error={errors.message}
              inputRef={register({
                required: 'Please enter a message'
              })}
            ></ContactInput>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              fullWidth={true}
              type="submit"
              disabled={pending}
            >
              {!pending && <span>Indienen</span>}

              {pending && <CircularProgress size={28} />}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

function ContactInput({
  label,
  error,
  inputRef,
  placeholder,
  id,
  lines
}: {
  placeholder: string
  label: string
  error: FieldError | undefined
  inputRef: React.Ref<any>
  id: string
  lines?: number
}) {
  return (
    <Box sx={styles.input}>
      <Typography variant="subtitle1">{label}</Typography>
      <TextField
        name={id}
        variant="outlined"
        fullWidth={true}
        multiline={!!lines}
        rows={lines ?? 1}
        placeholder={placeholder}
        autoComplete="off"
        error={!!error}
        helperText={error?.message}
        inputRef={inputRef}
      />
    </Box>
  )
}

export default Contact
