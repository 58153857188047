import React from 'react'
import Meta from '../components/Meta'
import PricingSection from '../components/PricingSection'

function PricingPage(props) {
  const title = "Prijzen"
  return (
    <>
      <Meta title={title} />
      <PricingSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Kies een passend abonnement. "
      />
    </>
  )
}

export default PricingPage
