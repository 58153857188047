import React, { createRef, useCallback, useRef } from 'react'
import { Stack } from '@mui/material'
import SaveContentButton from './SaveContentButton'
import CopyToClipboardButton from './CopyToClipboardButton'
import { SxProps } from '@mui/system'
import RichTextEditor from './RichTextEditor'
import { Editor } from 'tinymce'

interface EditableTextProps {
  text: string
  contentType: string
  isReadonly?: boolean
  sx?: SxProps
}

const styles = {
  text: {
    height: 0,
    flex: '1 1 0'
  },
  textContainer: {
    border: 1,
    borderColor: 'grey.400',
    borderRadius: 2,
    flex: 1
  }
}

function EditableText({
  text,
  contentType,
  isReadonly,
  sx = {}
}: EditableTextProps) {
  const editorRef = useRef<Editor>()

  const getEditedPlainText = useCallback(
    () => editorRef.current?.getContent({ format: 'text' }) || '',
    [editorRef.current]
  )

  const getEditedFormattedText = useCallback(
    () => editorRef.current?.getContent({ format: 'html' }) || '',
    [editorRef.current]
  )

  return (
    <Stack sx={{ ...styles.textContainer, ...sx }}>
      {!!text && (
        <>
          <RichTextEditor
            text={text}
            ref={editorRef}
            sx={styles.text}
            disabled={isReadonly}
          />
          <Stack direction="row" justifyContent="flex-end">
            <SaveContentButton
              text={getEditedFormattedText}
              disabled={isReadonly}
              type={contentType}
            />
            <CopyToClipboardButton
              text={getEditedPlainText}
              disabled={isReadonly}
              formattedText={getEditedFormattedText}
            />
          </Stack>
        </>
      )}
    </Stack>
  )
}

export default EditableText
