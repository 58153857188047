import React from 'react'
import * as colors from '@mui/material/colors'
import CssBaseline from '@mui/material/CssBaseline'
import useMediaQuery from '@mui/material/useMediaQuery'

import { Theme } from '@mui/material/styles'
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
  useTheme
} from '@mui/material'
import { merge } from 'lodash-es'
import { useDarkModeStorage } from '../hooks/useLocalStorage'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {
    palette: {
      background: {
        appDefault: string
      } & Theme['palette']['background']
    } & Theme['palette']
  }
}

const headerFontFamily = '"Poppins", "Arial", sans-serif'

const themeConfig = {
  // Light theme
  light: {
    palette: {
      mode: 'light',
      background: {
        // Background for <body>
        // and <Section color="default">
        default: '#fff',
        // Background for elevated
        // components (<Card>, etc)
        paper: '#fff',
        appDefault: '#F3F4F6FF'
      }
    }
  },

  // Dark theme
  dark: {
    palette: {
      mode: 'dark',
      background: {
        default: colors.grey['900'],
        paper: colors.grey['800'],
        appDefault: '#363636'
      }
    }
  },

  // Values for both themes
  common: {
    palette: {
      primary: {
        main: '#1278BC',
        light: '#73c4e3'
      },
      secondary: {
        main: '#EF9834FF',
        dark: '#c4761a',
        contrastText: '#fff'
      },
      success: {
        main: '#72D94EFF',
        contrastText: '#fff'
      }
    },
    typography: {
      fontSize: 14,
      fontFamily: '"Mulish", "Arial", sans-serif',
      button: {
        textTransform: 'none',
        fontWeight: 'bold'
      },
      h1: {
        fontWeight: 600,
        fontSize: '2.6rem',
        fontFamily: headerFontFamily
      },
      h2: {
        fontWeight: 600,
        fontSize: '2.25rem',
        fontFamily: headerFontFamily
      },
      h3: {
        fontFamily: headerFontFamily
      },
      h4: {
        fontWeight: 'bold',
        fontFamily: headerFontFamily
      },
      h5: {
        fontFamily: headerFontFamily
      },
      h6: {
        fontFamily: headerFontFamily
      },
      subtitle1: {
        fontWeight: 'bold'
      }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1200,
        xl: 1920
      }
    },
    // Override component styles
    overrides: {
      // Global styles
      MuiCssBaseline: {
        '@global': {
          '#root': {
            // Flex column that is height
            // of viewport so that footer
            // can push self to bottom by
            // with auto margin-top
            minHeight: '100%',
            display: 'flex',
            flexDirection: 'column',
            // Prevent child elements from
            // shrinking when content
            // is taller than the screen
            // (quirk of flex parent)
            '& > *': {
              flexShrink: 0
            }
          }
        }
      }
    },
    components: {
      MuiAlert: {
        styleOverrides: {
          filledSuccess: {
            color: '#fff'
          }
        }
      },
      MuiDrawer: {
        variants: [createColorVariant({ color: 'primary' })]
      },
      MuiSnackbar: {
        defaultProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            minWidth: 36
          }
        }
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: 36
          }
        }
      }
    }
  }
}

function createColorVariant(props: { color?: string; variant?: string }) {
  return {
    props,
    style: {
      '& .MuiDrawer-paper': {
        color: '#fff',
        backgroundColor: '#1278BCFF'
      },
      '& .MuiSvgIcon-root': {
        color: '#fff'
      }
    }
  }
}

function getTheme(name) {
  // Create MUI theme from themeConfig
  return createTheme(
    merge(
      themeConfig[name],
      // Merge in common values
      themeConfig.common
    )
  )
}

export function ThemeProvider(props) {
  // Get system dark mode preference
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)', {
    noSsr: true
  })

  // Get stored dark mode preference
  const [isDarkModeStored, setIsDarkModeStored] = useDarkModeStorage()

  // Use stored dark mode with fallback to system preference
  const isDarkMode = isDarkModeStored
  // use light mode as default regardless of preference
  // isDarkModeStored === undefined ? prefersDarkMode : isDarkModeStored

  // Get MUI theme object
  const themeName = isDarkMode ? 'dark' : 'light'
  const theme = getTheme(themeName) as any
  theme.type = themeName

  // Add toggle function to theme object
  theme.toggle = () => setIsDarkModeStored((value) => !value as any)

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {props.children}
    </MuiThemeProvider>
  )
}

// Hook for detecting dark mode and toggling between light/dark
// More convenient than reading theme.palette.type from useTheme
export function useDarkMode() {
  // Get current Material UI theme
  const theme = useTheme() as any
  // Check if it's the dark theme
  const isDarkMode = theme.type === 'dark'
  // Return object containing dark mode value and toggle function
  return { value: isDarkMode, toggle: theme.toggle }
}
