import React from 'react'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

const styles = {
    heading: {
        fontSize: {
            xs: "22px",
            md: "28px",
            lg: "40px"
        },
        padding:{
            xs:"15px",
            sm:"1em"
        },
        textAlign: "center",
        color: "#171A1FFF",
        borderBottom:"1px solid #ddd"
    },
    text: {
        fontSize:{
            xs: "18px",
            sm: "24px",
            lg: "28px",
        },
        
        fontWeight: "600",marginBottom:"10px" ,
        color:"#000"
    },
    desc: {
        fontSize:{
            xs: "14px",
            sm: "18px",
            lg: "22px",
        },
        
  color:"#565e6c",fontWeight:"600"
    },
   
    whyChooseWrapper: {
        padding: {
            xs: "1em 2em",
            md: "2em",
            lg: "3em 2em"
        }
    },

    card: {
        background: ' #FFFFFFFF', /* white */
        borderRadius: "6px",/* border-m */
        borderWidth: " 5px",
        borderColor: "#BCC1CAFF", /* neutral-400 */
        borderStyle: "solid",
        padding: "30px 15px"
    },
    btn: {
        color: "#fff",
        backgroundColor: "#45A7E8FF",
        marginBottom: {
            xs: "20px",
            sm: "25px",
            md: "35px",
        }
    },
    imgGrid:{
        borderLeft:"1px dashed #000",
        padding: {
            xs: "1em 2em",
            sm: "100px 30px"
        }
    }

}

function Valuable(props) {

    return (
        <Box>
       <Typography variant="h2" component="h2" sx={styles.heading}>
       Voor wie is Digilo waardevol?
       </Typography>
            
      

   <Grid container   alignItems="center" spacing={2}>
       <Grid item lg={9} sm={8}>
       <Box sx={styles.whyChooseWrapper}>      
          <Box sx={{marginBottom:"36px"}}> 
          <Typography variant="h4" component="h4" sx={styles.text}>
               Contentmakers & Bloggers:
           </Typography>
           <Typography  sx={styles.desc}>
               Digilo helpt bloggers en content makers met ideeën genereren, teksten bewerken en SEO-optimalisatie.
           </Typography>
          </Box>
          <Box sx={{marginBottom:"36px"}}> 
           <Typography variant="h4" component="h4" sx={styles.text}>
               Copywriters & Digital Marketeers:
           </Typography>
           <Typography sx={styles.desc}>
               Digilo ondersteunt copywriters en digitale marketeers bij het creëren van content, SEO en social media automatisering.
           </Typography>
           </Box>
           <Box sx={{marginBottom:"36px"}}> 
           <Typography variant="h4" component="h4" sx={styles.text}>
               E-commerce bedrijven, Freelancers en Ondernemers:
           </Typography>
           <Typography sx={styles.desc}>
               Digilo helpt e-commerce bedrijven, freelancers en ondernemers met productbeschrijvingen, marketingcontent en geautomatiseerde klantcommunicatie.
           </Typography>
           </Box>
           <Box sx={{marginBottom:"36px"}}> 
           <Typography variant="h4" component="h4" sx={styles.text}>
               Onderwijsgevenden en Proffesionals:
           </Typography>
           <Typography sx={styles.desc}>
               Digilo helpt onderwijsgevenden en professionals met het creëren van lesmateriaal, genereren van rapporten en optimaliseren van documenten.
           </Typography>
           </Box>
           <Typography variant="h4" component="h4" sx={styles.text}>
               Studenten:
           </Typography>
           <Typography sx={styles.desc}>
               Digilo helpt studenten met essays, onderzoeksvoorstellen, bibliografieën, scripties en presentaties.
           </Typography>
        </Box>
       </Grid>
       <Grid item lg={3} sm={4}>
               <Box sx={styles.imgGrid}>
                   <img src="/img/valuable_person.png" width={"100%"}/>
                   </Box>
           </Grid>
   </Grid>
</Box>
      
    )
}

export default Valuable 
