import React from 'react'
import LongContent from './LongContent'

function LegalPrivacy(props) {
  return (
    <LongContent>
      <h1>Privacybeleid - Digilo</h1>

      <p>Ons doel is om schrijvers en alle andere beroepsgroepen die inhoud schrijven en creëren te helpen om dit effectiever en sneller te doen en zo tijd te besparen. Onze tool wordt voorzien van tekstuele input van de content creator, en geeft een output terug waarmee de content creator al dan niet verder kan gaan. </p>
      <p>
        We respecteren de privacy van individuen en houden ons aan de toepasselijke regelgeving inzake gegevensbescherming, in het bijzonder de Algemene Verordening Gegevensbescherming (EU 2016/679) van de Europese Unie. In dit Privacybeleid proberen we zo duidelijk mogelijk uit te leggen welke gegevens we verzamelen, hoe we deze gebruiken en welke rechten jullie hebben met betrekking tot deze gegevens.
      </p>
      <p>Als je vragen of opmerkingen hebt over ons privacybeleid, neem dan gerust contact met ons op via <a href="mailto:info@digilo.io">info@digilo.io</a>  </p>
      <h2>1.WIE ZIJN WIJ </h2>
      <p>
        Digilo is een commerciële naam die wordt gebruikt door FMB Digital Online ("wij", "onze", "ons"), een geregistreerde onderneming in Rotterdam, Nederland, ingeschreven bij de Nederlandse Kamer van Koophandel onder nummer 77846656
      </p>
      <h2>2.HOE WE GEGEVENS VERZAMELEN EN VERWERKEN </h2>
      <p>
        We treden op als gegevensbeheerder en verzamelen alleen openbaar beschikbare gegevens, uit open bronnen van het web, zoals LinkedIn, Stack Overflow, GitHub of andere professionele en carrièrewebsites. Wanneer we persoonlijke gegevens verzamelen en verwerken, is ten minste een van de volgende zaken van toepassing: De betrokkene heeft toestemming gegeven voor de verwerking van zijn of haar persoonsgegevens voor werving doeleinden. De verwerking is noodzakelijk om te voldoen aan een wettelijke verplichting, zoals een verzoek van overheidsinstanties. De verwerking is noodzakelijk met het oog op onze legitieme belangen van het aanbieden van talent search-diensten, waarbij we ervoor zorgen dat deze belangen nooit zwaarder wegen dan de belangen of fundamentele rechten en vrijheden van de betrokkene.
      </p>

      <p>
        De persoonsgegevens die we verzamelen kunnen het volgende omvatten: <br />
        Naam <br />
        e-mailadres <br />
        Bedrijf <br />
        Betalingsinfo (alleen voor het leveren van onze abonnementsdiensten)

      </p>
      <p>We verzamelen of verwerken geen speciale categorieën van persoonsgegevens, zoals religieuze opvattingen, politieke opvattingen, genetische gegevens, biometrische gegevens, seksuele geaardheid of informatie over geestelijke of lichamelijke gezondheid. We nemen geen geautomatiseerde beslissingen die rechtsgevolgen voor u hebben, en we maken geen gebruik </p>
      <p>
        van e-recruiting zonder menselijke tussenkomst. We voeren alleen een geautomatiseerde zoekopdracht uit in openbaar beschikbare gegevens voor onze klanten, de werving beslissingen worden genomen door mensen die bij onze klant bedrijven werken. <br />
        We respecteren de principes voor gegevensverwerking zoals uiteengezet in de Algemene Verordening Gegevensbescherming:

      </p>


      <p>
        We houden ons aan gegevens minimalisatie en verzamelen en verwerken enkel persoonlijke gegevens die noodzakelijk zijn voor onze doeleinden om talent zoekdiensten aan te bieden of om te voldoen aan verzoeken van overheidsinstanties. We houden ons aan de gegevens nauwkeurig en reviseren minstens twee keer per jaar alle persoonlijke gegevens die we opslaan om ervoor te zorgen dat de gegevens accuraat, up-to-date en volledig zijn. Als we tijdens de revisie ontdekken dat bepaalde persoonlijke gegevens onnauwkeurig, verouderd of onvolledig zijn geworden, corrigeren of wissen we die gegevens onmiddellijk. We kunnen de nauwkeurigheid van de openbare bronnen die we gebruiken om gegevens te verzamelen echter niet garanderen.
      </p>
      <p>We respecteren het principe van opslag beperking en bewaren persoonlijke gegevens in een vorm die het mogelijk maakt de betrokkenen te identificeren en bewaren ze niet langer dan nodig is voor het leveren van talent zoekdiensten of om te voldoen aan verzoeken van overheidsinstanties. Zoals hierboven uitgelegd, herzien we alle persoonlijke gegevens in onze systemen minstens twee keer per jaar. Als we merken dat bepaalde persoonlijke gegevens niet langer nodig zijn voor onze doeleinden, wissen we die persoonlijke gegevens.</p>
      <p>We waarborgen de integriteit en vertrouwelijkheid van persoonlijke gegevens door passende technische en organisatorische maatregelen te nemen. Als we op de hoogte zijn van een inbreuk op het beveiligingssysteem, kunnen we proberen u hiervan elektronisch op de hoogte te stellen, zodat u de juiste beschermingsmaatregelen kunt treffen. We kunnen een bericht op onze website plaatsen als er sprake is van een inbreuk op de beveiliging. Als u zich bewust wordt van een mogelijke inbreuk op de beveiliging van onze systemen, breng ons dan op de hoogte via het hierboven vermelde e-mailadres.</p>


      <h2>3.GEGEVENS DELEN </h2>
      <p>
        Wij kunnen uw persoonlijke gegevens delen met de volgende partijen:Zakelijke partners: alleen voor werving doeleinden en voor zover zij interesse hebben getoond in onze producten en diensten en dat nodig is om aan onze contractuele verplichtingen te voldoen.
        Overheidsinstanties: om te voldoen aan wettelijke verplichtingen onder toepasselijke wetten of om te voldoen aan verzoeken van overheidsinstanties en voor zover wetten of instanties ons verplichten om uw persoonsgegevens te delen.

      </p>
      <p><strong>Derden:</strong> we gebruiken bepaalde vertrouwde derden, zoals workflow providers en web serviceproviders, om ons te helpen onze producten en diensten te leveren en te verbeteren. We delen je persoonlijke gegevens enkel in de mate dat dit nodig is om onze diensten voor het zoeken naar talent aan te bieden.</p>
      <h2>4.INDIVIDUELE RECHTEN</h2>
      <p>
        Recht om vergeten te worden: u kunt uw persoonlijke gegevens volledig uit ons systeem laten verwijderen. Het is echter mogelijk dat we uw persoonsgegevens nog gedurende een bepaalde periode moeten bewaren om te voldoen aan wettelijke verplichtingen, zoals verzoeken van overheidsinstanties. Recht op rectificatie: als u vindt dat de persoonlijke gegevens die wij over u opslaan onnauwkeurig of onvolledig zijn, kunt u uw persoonlijke gegevens laten bijwerken en rectificeren. Recht om bezwaar te maken: als u om welke reden dan ook wilt dat wij geen persoonlijke gegevens over u verwerken, kunt u bezwaar maken en zullen wij stoppen met het verwerken van uw persoonlijke gegevens. Het is echter mogelijk dat we uw persoonsgegevens nog gedurende een bepaalde periode moeten bewaren om te voldoen aan wettelijke verplichtingen, zoals verzoeken van overheidsinstanties.
      </p>
      <p>Recht op beperking van de verwerking: u kunt de manieren waarop we uw persoonsgegevens verwerken beperken en beslissen welke delen van uw persoonsgegevens we mogen gebruiken en voor welke doeleinden. U kunt de verwerking van uw persoonsgegevens echter niet beperken om te voldoen aan wettelijke verplichtingen, zoals verzoeken van overheidsinstanties. </p>
      <p>Recht op toegang: u kunt een kopie van uw persoonlijke gegevens opvragen om te zien wat we over u in onze systemen opslaan. Recht op gegevensoverdraagbaarheid: u kunt ons verzoeken om uw persoonlijke gegevens over te dragen aan een andere dienstverlener van uw keuze. Wij zijn echter niet verantwoordelijk voor de manier waarop deze derde partij uw persoonsgegevens gebruikt en verwerkt. </p>
      <p>Recht op informatie: als dit Privacybeleid u niet voldoende informeert, kunt u altijd contact met ons opnemen om informatie te krijgen over hoe uw persoonlijke gegevens worden verwerkt. Bovendien kunt u, als u uw zichtbaarheid in zoekmachines, sociale mediasites, professionele en carrièresites of andere openbare bronnen wilt beperken, uw profiel instellingen aanpassen om grenzen te stellen aan hoeveel van uw profiel informatie openbaar beschikbaar zal zijn en in welke mate zoekmachines toegang hebben tot uw persoonlijke gegevens. We kunnen de nauwkeurigheid van de openbare bronnen die we gebruiken om gegevens te verzamelen niet garanderen.</p>

      <h2>5.HOE U UW RECHTEN KUNT UITOEFENEN </h2>
      <p>
        Als u vragen hebt of een van uw rechten als betrokkene wilt uitoefenen, kunt u contact met ons opnemen via het volgende e-mailadres: <br />

        <a href="mailto:info@digilo.io">info@digilo.io</a>

      </p>
      <p>In antwoord op een verzoek kunnen we u vragen om uw identiteit te verifiëren en om aanvullende informatie te verstrekken die nodig is om uw verzoek te behandelen. We zullen u zo snel mogelijk antwoorden, binnen maximaal 30 dagen. </p>
      <h2>6.COOKIES</h2>
      <p>
        Wij kunnen cookies en andere tracking technologieën gebruiken op onze website. Cookies zijn kleine gegevensbestanden (tekst of pixels) die op uw computer of mobiele apparaat worden opgeslagen. Wij kunnen cookies gebruiken om het surfen op onze website te vergemakkelijken en om onze website te optimaliseren en het websiteverkeer te analyseren. Daarbij helpen cookies om onze website en jouw surfervaring te verbeteren.
      </p>
      <h2>7.MARKETING E-MAILS</h2>
      <p>
        U kunt zich op onze website inschrijven voor onze nieuwsbrief. Als u deze e-mails in de toekomst niet meer wilt ontvangen, kunt u zich op elk gewenst moment afmelden van onze marketing e-maillijst door te klikken op de afmeldlink in de e-mails die wij versturen of door contact met ons op te nemen via de hierboven vermelde gegevens. U wordt dan verwijderd van de lijst met marketing e-mails.
      </p>
      <h2>8.UPDATES VAN DIT PRIVACYBELEID </h2>
      <p>
        We kunnen dit Privacybeleid van tijd tot tijd bijwerken. Raadpleeg onze website voor de meest recente versie.
      </p>

    </LongContent>
  )
}

export default LegalPrivacy
